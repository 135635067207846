import { computed, ref } from 'vue'
import { Endpoint, authRequest, useAPI } from '@/composition/api/useAPI'
import { useAccount } from '@/composition/account/useAccount'
import { Targets } from '@opteo/types'
import { showSnackbar } from '@opteo/components-next'
import { delay } from '@/lib/globalUtils'

export enum CycleStartDay {
    MONDAY = 1,
    TUESDAY = 2,
    WEDNESDAY = 3,
    THURSDAY = 4,
    FRIDAY = 5,
    SATURDAY = 6,
    SUNDAY = 0,
}

export enum CycleStartDate {
    DAY_1 = 1,
    DAY_2 = 2,
    DAY_3 = 3,
    DAY_4 = 4,
    DAY_5 = 5,
    DAY_6 = 6,
    DAY_7 = 7,
    DAY_8 = 8,
    DAY_9 = 9,
    DAY_10 = 10,
    DAY_11 = 11,
    DAY_12 = 12,
    DAY_13 = 13,
    DAY_14 = 14,
    DAY_15 = 15,
    DAY_16 = 16,
    DAY_17 = 17,
    DAY_18 = 18,
    DAY_19 = 19,
    DAY_20 = 20,
    DAY_21 = 21,
    DAY_22 = 22,
    DAY_23 = 23,
    DAY_24 = 24,
    DAY_25 = 25,
    DAY_26 = 26,
    DAY_27 = 27,
    DAY_28 = 28,
}

export function usePerformanceGoals(overrideAccountId?: string) {
    const { accountId: globalAccountId, currencySymbol } = useAccount()

    // Use the override if provided, otherwise fall back to global accountId
    const accountId = computed(() => overrideAccountId || globalAccountId.value)

    // Loading state
    const isLoading = ref(true)

    // Fetch account performance goals data
    const { data: accountPerformanceGoals, mutate: mutateAccountPerformanceGoals } =
        useAPI<Targets.AccountPerformanceGoals>(Endpoint.GetAccountPerformanceGoals, {
            uniqueId: () => accountId.value,
            waitFor: () => accountId.value,
            body: () => ({
                accountId: accountId.value,
            }),
        })

    // Form state refs that return undefined when no data
    const primaryGoal = computed(() => accountPerformanceGoals.value?.primaryGoal)

    const goalAmount = computed(() => accountPerformanceGoals.value?.goalAmount)

    const goalCycle = computed(() => accountPerformanceGoals.value?.goalCycle)

    const secondaryGoal = computed(() => accountPerformanceGoals.value?.secondaryGoal)

    const cycleStartDay = computed(() => accountPerformanceGoals.value?.cycleStartDay)

    const cycleStartDate = computed(() => accountPerformanceGoals.value?.cycleStartDate)

    const updatePerformanceGoalsLoading = ref(false)

    // Input refs
    const goalAmountInput = ref()
    const updatePerformanceGoalsButton = ref()
    const goalsForm = ref()

    // Initialize accountPerformanceGoals with default values if it's undefined
    const ensurePerformanceGoals = () => {
        if (!accountPerformanceGoals.value) {
            accountPerformanceGoals.value = {
                primaryGoal: undefined,
                goalAmount: undefined,
                goalCycle: undefined,
                secondaryGoal: undefined,
                cycleStartDay: undefined,
                cycleStartDate: undefined,
            }
        }
    }

    // Set default values when the composable is initialized
    // This ensures we have a valid accountPerformanceGoals object to work with
    // even before the API call completes
    ensurePerformanceGoals()

    // Computed values with getters and setters
    const computedPrimaryGoal = computed({
        get: () => primaryGoal.value,
        set: (val: Targets.PrimaryPerformanceGoal) => {
            // Ensure the accountPerformanceGoals object exists
            ensurePerformanceGoals()

            // Update the value
            if (accountPerformanceGoals.value) {
                accountPerformanceGoals.value = {
                    ...accountPerformanceGoals.value,
                    primaryGoal: val,
                }
            }
            // Reset goalAmount when primary goal changes
            if (accountPerformanceGoals.value) {
                accountPerformanceGoals.value.goalAmount = undefined
            }
        },
    })

    const computedGoalCycle = computed({
        get: () => goalCycle.value,
        set: (val: Targets.PerformanceGoalCycle) => {
            // Ensure the accountPerformanceGoals object exists
            ensurePerformanceGoals()

            if (accountPerformanceGoals.value) {
                accountPerformanceGoals.value = {
                    ...accountPerformanceGoals.value,
                    goalCycle: val,
                }
            }
        },
    })

    const computedGoalAmount = computed({
        get: () => goalAmount.value,
        set: (val: number) => {
            // Ensure the accountPerformanceGoals object exists
            ensurePerformanceGoals()

            if (accountPerformanceGoals.value) {
                accountPerformanceGoals.value = {
                    ...accountPerformanceGoals.value,
                    goalAmount: val,
                }
            }
        },
    })

    const computedSecondaryGoal = computed({
        get: () => secondaryGoal.value,
        set: (val: Targets.SecondaryPerformanceGoal) => {
            // Ensure the accountPerformanceGoals object exists
            ensurePerformanceGoals()

            if (accountPerformanceGoals.value) {
                accountPerformanceGoals.value = {
                    ...accountPerformanceGoals.value,
                    secondaryGoal: val,
                }
            }
        },
    })

    const computedCycleStartDay = computed({
        get: () => cycleStartDay.value,
        set: (val: CycleStartDay | undefined) => {
            // Ensure the accountPerformanceGoals object exists
            ensurePerformanceGoals()

            if (accountPerformanceGoals.value) {
                accountPerformanceGoals.value = {
                    ...accountPerformanceGoals.value,
                    cycleStartDay: val,
                    // Reset the date if we're changing to day
                    cycleStartDate: undefined,
                }
            }
        },
    })

    const computedCycleStartDate = computed({
        get: () => cycleStartDate.value,
        set: (val: CycleStartDate | undefined) => {
            // Ensure the accountPerformanceGoals object exists
            ensurePerformanceGoals()

            if (accountPerformanceGoals.value) {
                accountPerformanceGoals.value = {
                    ...accountPerformanceGoals.value,
                    cycleStartDate: val,
                    // Reset the day if we're changing to date
                    cycleStartDay: undefined,
                }
            }
        },
    })

    // Map enums to display names
    const primaryGoalNameMap: { [goal in Targets.PrimaryPerformanceGoal]: string } = {
        [Targets.PrimaryPerformanceGoal.CONVERSIONS]: 'Conversions',
        [Targets.PrimaryPerformanceGoal.CONVERSION_VALUE]: 'Conversion Value',
    }

    const secondaryGoalNameMap: { [goal in Targets.SecondaryPerformanceGoal]: string } = {
        [Targets.SecondaryPerformanceGoal.IMPROVE_CTR]: 'Improve Click Through Rate',
        [Targets.SecondaryPerformanceGoal.IMPROVE_SIS]: 'Improve Search Impression Share',
        [Targets.SecondaryPerformanceGoal.IMPROVE_AVG_QS]: 'Improve Average Quality Score',
        [Targets.SecondaryPerformanceGoal.REDUCE_CPC]: 'Reduce Cost Per Click',
    }

    const goalCycleNameMap: { [goal in Targets.PerformanceGoalCycle]: string } = {
        [Targets.PerformanceGoalCycle.DAY]: 'Daily',
        [Targets.PerformanceGoalCycle.WEEK]: 'Weekly',
        [Targets.PerformanceGoalCycle.MONTH]: 'Monthly',
        [Targets.PerformanceGoalCycle.QUARTER]: 'Quarterly',
        [Targets.PerformanceGoalCycle.YEAR]: 'Yearly',
    }

    const cycleStartDayNameMap: { [day in CycleStartDay]: string } = {
        [CycleStartDay.MONDAY]: 'Monday',
        [CycleStartDay.TUESDAY]: 'Tuesday',
        [CycleStartDay.WEDNESDAY]: 'Wednesday',
        [CycleStartDay.THURSDAY]: 'Thursday',
        [CycleStartDay.FRIDAY]: 'Friday',
        [CycleStartDay.SATURDAY]: 'Saturday',
        [CycleStartDay.SUNDAY]: 'Sunday',
    }

    const cycleStartDateNameMap: { [date in CycleStartDate]: string } = {
        [CycleStartDate.DAY_1]: '1st of Month',
        [CycleStartDate.DAY_2]: '2nd of Month',
        [CycleStartDate.DAY_3]: '3rd of Month',
        [CycleStartDate.DAY_4]: '4th of Month',
        [CycleStartDate.DAY_5]: '5th of Month',
        [CycleStartDate.DAY_6]: '6th of Month',
        [CycleStartDate.DAY_7]: '7th of Month',
        [CycleStartDate.DAY_8]: '8th of Month',
        [CycleStartDate.DAY_9]: '9th of Month',
        [CycleStartDate.DAY_10]: '10th of Month',
        [CycleStartDate.DAY_11]: '11th of Month',
        [CycleStartDate.DAY_12]: '12th of Month',
        [CycleStartDate.DAY_13]: '13th of Month',
        [CycleStartDate.DAY_14]: '14th of Month',
        [CycleStartDate.DAY_15]: '15th of Month',
        [CycleStartDate.DAY_16]: '16th of Month',
        [CycleStartDate.DAY_17]: '17th of Month',
        [CycleStartDate.DAY_18]: '18th of Month',
        [CycleStartDate.DAY_19]: '19th of Month',
        [CycleStartDate.DAY_20]: '20th of Month',
        [CycleStartDate.DAY_21]: '21st of Month',
        [CycleStartDate.DAY_22]: '22nd of Month',
        [CycleStartDate.DAY_23]: '23rd of Month',
        [CycleStartDate.DAY_24]: '24th of Month',
        [CycleStartDate.DAY_25]: '25th of Month',
        [CycleStartDate.DAY_26]: '26th of Month',
        [CycleStartDate.DAY_27]: '27th of Month',
        [CycleStartDate.DAY_28]: '28th of Month',
    }

    const primaryGoalMetricMap = computed<{ [goal in Targets.PrimaryPerformanceGoal]: string }>(
        () => ({
            [Targets.PrimaryPerformanceGoal.CONVERSIONS]: 'Conv.',
            [Targets.PrimaryPerformanceGoal.CONVERSION_VALUE]: currencySymbol.value,
        })
    )

    const goalMetric = computed(() => {
        if (primaryGoal.value === undefined) return undefined
        return primaryGoalMetricMap.value[primaryGoal.value]
    })

    // Generate select options
    const primaryGoals: Targets.PrimaryPerformanceGoal[] =
        Object.keys(primaryGoalNameMap).map(Number)
    const secondaryGoals: Targets.SecondaryPerformanceGoal[] =
        Object.keys(secondaryGoalNameMap).map(Number)
    const goalCycles: Targets.PerformanceGoalCycle[] = Object.keys(goalCycleNameMap).map(Number)
    const cycleStartDays: CycleStartDay[] = Object.keys(cycleStartDayNameMap).map(Number)
    const cycleStartDates: CycleStartDate[] = Object.keys(cycleStartDateNameMap).map(Number)

    const primaryGoalItems = computed(() =>
        primaryGoals.map(goal => ({
            label: primaryGoalNameMap[goal],
            value: goal,
        }))
    )

    const secondaryGoalItems = computed(() =>
        secondaryGoals.map(goal => ({
            label: secondaryGoalNameMap[goal],
            value: goal,
        }))
    )

    const goalCycleItems = computed(() =>
        goalCycles.map(goalCycle => ({
            label: goalCycleNameMap[goalCycle],
            value: goalCycle,
        }))
    )

    const cycleStartDayItems = computed(() =>
        cycleStartDays.map(day => ({
            label: cycleStartDayNameMap[day],
            value: day,
        }))
    )

    const cycleStartDateItems = computed(() =>
        cycleStartDates.map(date => ({
            label: cycleStartDateNameMap[date],
            value: date,
        }))
    )

    // Determine if cycle start field should be shown based on selected goal cycle
    const showCycleStart = computed(() => {
        // Get the selected cycle value from the computed property to handle cases
        // where there's no saved goal yet but the user has made a selection
        const selectedCycle = computedGoalCycle.value

        // Only show for weekly or monthly cycles
        return (
            selectedCycle === Targets.PerformanceGoalCycle.WEEK ||
            selectedCycle === Targets.PerformanceGoalCycle.MONTH
        )
    })

    // Determine which cycle start items to show based on goal cycle
    const cycleStartItems = computed(() => {
        // Use computedGoalCycle to get the currently selected value
        const selectedCycle = computedGoalCycle.value

        if (selectedCycle === Targets.PerformanceGoalCycle.WEEK) {
            return cycleStartDayItems.value
        } else if (selectedCycle === Targets.PerformanceGoalCycle.MONTH) {
            return cycleStartDateItems.value
        }
        return []
    })

    // Determine the appropriate cycle start value based on the selected goal cycle
    const computedCycleStart = computed({
        get: () => {
            // Use computedGoalCycle to get the currently selected value
            const selectedCycle = computedGoalCycle.value

            if (selectedCycle === Targets.PerformanceGoalCycle.WEEK) {
                return computedCycleStartDay.value
            } else if (selectedCycle === Targets.PerformanceGoalCycle.MONTH) {
                return computedCycleStartDate.value
            }
            return undefined
        },
        set: (val: CycleStartDay | CycleStartDate | undefined) => {
            // Use computedGoalCycle to get the currently selected value
            const selectedCycle = computedGoalCycle.value

            if (selectedCycle === Targets.PerformanceGoalCycle.WEEK) {
                computedCycleStartDay.value = val as CycleStartDay
            } else if (selectedCycle === Targets.PerformanceGoalCycle.MONTH) {
                computedCycleStartDate.value = val as CycleStartDate
            }
        },
    })

    // Label for the cycle start field
    const cycleStartLabel = computed(() => {
        // Use computedGoalCycle to get the currently selected value
        const selectedCycle = computedGoalCycle.value

        if (selectedCycle === Targets.PerformanceGoalCycle.WEEK) {
            return 'Goal Start Day'
        } else if (selectedCycle === Targets.PerformanceGoalCycle.MONTH) {
            return 'Goal Start Date'
        }
        return 'Goal Start'
    })

    // Check if there are any changes
    const hasChanges = computed(() => {
        // Skip if data isn't loaded yet
        if (!accountPerformanceGoals.value) return false

        const original = accountPerformanceGoals.value

        return (
            primaryGoal.value !== original.primaryGoal ||
            goalAmount.value !== original.goalAmount ||
            goalCycle.value !== original.goalCycle ||
            secondaryGoal.value !== original.secondaryGoal ||
            cycleStartDay.value !== original.cycleStartDay ||
            cycleStartDate.value !== original.cycleStartDate
        )
    })

    // Submit function
    async function updatePerformanceGoals() {
        if (!hasChanges.value) {
            showSnackbar({
                message: 'No changes made',
            })
            return
        }

        if (!goalAmountInput.value?.state.valid) {
            throw new Error('Invalid goal amount')
        }

        updatePerformanceGoalsLoading.value = true

        await delay(1000)

        await authRequest(Endpoint.UpdateAccountPerformanceGoals, {
            body: {
                account_id: accountId.value,
                performance_goals: {
                    primaryGoal: primaryGoal.value,
                    goalAmount: goalAmount.value,
                    goalCycle: goalCycle.value,
                    secondaryGoal: secondaryGoal.value,
                    cycleStartDay: cycleStartDay.value,
                    cycleStartDate: cycleStartDate.value,
                },
            },
        })

        await mutateAccountPerformanceGoals()

        updatePerformanceGoalsLoading.value = false
        showSnackbar({
            message: 'Performance Goals updated successfully',
        })
    }

    return {
        // Refs
        primaryGoal,
        goalAmount,
        goalCycle,
        secondaryGoal,
        cycleStartDay,
        cycleStartDate,
        goalAmountInput,
        updatePerformanceGoalsButton,
        goalsForm,
        updatePerformanceGoalsLoading,

        // Computed
        computedPrimaryGoal,
        computedGoalCycle,
        computedGoalAmount,
        computedSecondaryGoal,
        computedCycleStartDay,
        computedCycleStartDate,
        computedCycleStart,
        goalMetric,
        primaryGoalItems,
        secondaryGoalItems,
        goalCycleItems,
        cycleStartDayItems,
        cycleStartDateItems,
        cycleStartItems,
        cycleStartLabel,
        showCycleStart,
        hasChanges,

        // Loading state
        isLoading,

        // Functions
        updatePerformanceGoals,

        // Direct access to data
        accountPerformanceGoals,
    }
}
