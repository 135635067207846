<template>
    <Popout v-model="showPopout" placement="bottom-end" :offset="[4, 6]" externalToggleButton>
        <Button v-if="above480" size="small" color="secondary" @click="showPopout = !showPopout">
            <template #before>
                <Dot :size="above480 ? 12 : 12" />
            </template>
            {{ checks.checks.length }} Checks <span v-if="above480">Completed</span>
        </Button>
        <IndicatorTag v-else color="green">{{ checks.checks.length }} Checks</IndicatorTag>
        <template #content>
            <Container
                color="background-plus-two"
                shadow="large"
                :borderRadius="16"
                :width="260"
                hideOverflow
            >
                <Scroller nested :padding="[18]" maxHeight="388px">
                    <Stack :gap="16" direction="column" width="100%">
                        <Stack
                            v-for="(check, index) in checks.checks"
                            :key="check.name"
                            direction="row"
                            align="start"
                            :gap="11"
                            width="100%"
                        >
                            <Container
                                :borderRadius="999"
                                :width="18"
                                :height="18"
                                align="center"
                                justify="center"
                                color="green"
                            >
                                <CheckSmall hex="#fff" />
                            </Container>
                            <Stack
                                :padding="[2, 0, 0, 0]"
                                :gap="12"
                                direction="column"
                                width="100%"
                            >
                                <Text as="h6" size="headline-seven" width="100%" :wrap="false">
                                    {{ check.name }}
                                </Text>
                                <Stack direction="column" :gap="6">
                                    <Stack
                                        v-for="subCheck in check.subChecks"
                                        :key="subCheck.label"
                                        direction="row"
                                        :gap="8"
                                        align="center"
                                    >
                                        <Container
                                            :width="6"
                                            :height="6"
                                            :color="['green', 'background-plus-three-hover']"
                                            :outline="false"
                                            shadow="none"
                                            :borderRadius="999"
                                        />
                                        <Text
                                            as="p"
                                            size="body-extra-small"
                                            color="foreground"
                                            width="100%"
                                            :wrap="false"
                                        >
                                            <b>{{ subCheck.value }}</b> {{ subCheck.label }}
                                        </Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Scroller>
                <Stack
                    :padding="[12, 20, 14, 20]"
                    :gap="0"
                    borderTop
                    align="center"
                    justify="center"
                    width="100%"
                >
                    <Text
                        as="p"
                        size="body-extra-small"
                        align="center"
                        color="foreground-minus-one"
                    >
                        Last checked <b>{{ formatLastChecked }}</b>
                    </Text>
                </Stack>
            </Container>
        </template>
    </Popout>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

// Components
import {
    Popout,
    Button,
    Container,
    Scroller,
    Stack,
    CheckSmall,
    Text,
    useMediaQuery,
    Dot,
} from '@opteo/components-next'
import IndicatorTag from '@/components/global/IndicatorTag.vue'

// Props
const props = defineProps({
    checks: {
        type: Object as PropType<{
            lastChecked: Date
            checks: {
                name: string
                subChecks: { value: number; label: string }[]
            }[]
        }>,
        required: true,
    },
})

// Setup
const showPopout = ref(false)
const { above480 } = useMediaQuery()

// formatLastChecked
const formatLastChecked = computed(() => {
    if (!props.checks.lastChecked) return ''

    return formatDistanceToNow(props.checks.lastChecked, { addSuffix: true }).replace(
        /^about\s/,
        ''
    )
})
</script>

<style scoped lang="scss"></style>
