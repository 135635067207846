<template>
    <Modal v-model="open" @close="closeModal" title="Create Group">
        <template #content>
            <Stack direction="column" align="start" :gap="20">
                <Container
                    :borderRadius="20"
                    width="100%"
                    :minWidth="346"
                    :padding="[24, 16]"
                    align="center"
                    justify="center"
                    hideOverflow
                >
                    <AccountGroup :color="groupColor" :name="groupName" />
                </Container>
                <TextInput v-model="groupName" label="Group Name" autofocus />
                <SelectableList type="color" v-model="groupColor" fullWidth label="Group Colour" />
                <Stack direction="column" align="start" :gap="0">
                    <Label>Select Accounts</Label>
                    <Container :borderRadius="20" width="100%" :minWidth="328" hideOverflow>
                        <Stack direction="row" :gap="20" :padding="[16]" borderBottom>
                            <TextInput v-model="accountSearch" icon="Search" />
                        </Stack>
                        <Container
                            v-for="(account, index) in mappedAccountsList"
                            :key="account.id"
                            color="transparent"
                            width="100%"
                            clickable
                            :showFocusRing="false"
                            tabindex="-1"
                            @click="toggleSelected(String(account.id))"
                        >
                            <Stack
                                :gap="16"
                                :padding="[16, 20]"
                                :borderBottom="filteredDomainList.length != index + 1"
                            >
                                <Checkbox
                                    v-model="account.selected"
                                    readOnly
                                    @click="toggleSelected(String(account.id))"
                                />
                                <Avatar
                                    :initials="getDomainInitials(account.name)"
                                    :color="mapHexToAvatarColor(account.color)"
                                    :platform="account.platform"
                                />
                                <Stack direction="column" :gap="4" align="start">
                                    <Stack :gap="6">
                                        <Text
                                            as="h6"
                                            size="button"
                                            :color="mapHexToTextColor(account.color)"
                                            :wrap="false"
                                        >
                                            {{ account.name }}
                                        </Text>
                                    </Stack>
                                    <Stack :padding="[0, 0, 0, 0]">
                                        <Text
                                            as="span"
                                            size="label-small"
                                            color="foreground-minus-one"
                                            :wrap="false"
                                        >
                                            {{ computeLastActive(account.lastVisitedAt) }}
                                        </Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Container>
                    </Container>
                    <Stack align="center" justify="center" width="100%" class="selected-accounts">
                        <Container
                            width="auto"
                            color="background-plus-two"
                            :padding="[8, 12]"
                            hideOverflow
                            :borderRadius="999"
                        >
                            <Stack :gap="8">
                                <Dot :size="10" :color="groupColor" />
                                <Text
                                    as="span"
                                    size="button-small"
                                    color="foreground"
                                    :wrap="false"
                                >
                                    {{ selectedCount }} Selected
                                </Text>
                            </Stack>
                        </Container>
                    </Stack>
                </Stack>
            </Stack>
        </template>
        <template #buttons>
            <Button @click="closeModal" color="secondary">Cancel</Button>
            <Button @click="saveChanges" color="primary">Create Group</Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch, computed } from 'vue'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import parseISO from 'date-fns/parseISO'

// Composition
import { useAccountList } from '@/composition/user/useAccountList'
import { mapHexToAvatarColor, mapHexToTextColor } from '@/composition/utils/utils'
import { getDomainInitials } from '@/composition/domain/useDomain'

// Components
import {
    Modal,
    Button,
    Stack,
    Container,
    TextInput,
    SelectableList,
    Label,
    Checkbox,
    Text,
    Avatar,
    Dot,
} from '@opteo/components-next'
import AccountGroup from '@/components/global/AccountGroup.vue'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const open = ref(false)
const accountSearch = ref('')
const { filteredDomainList } = useAccountList()

// Computed

// Accounts + Select
const selectedMap = ref<Record<string, boolean>>({})
const selectedCount = computed(() => {
    return Object.values(selectedMap.value).filter(Boolean).length
})

const mappedAccountsList = computed(() => {
    if (!filteredDomainList.value) return []

    return filteredDomainList.value.map(account => {
        const key = String(account.id)
        // Check existing state in selectedMap, default to false if none
        const selected = selectedMap.value[key] ?? false

        return {
            id: account.id, // store original
            name: account.name,
            color: account.color,
            platform: account.platform,
            lastVisitedAt: account.lastVisitedAt,
            selected,
        }
    })
})
function toggleSelected(id: string) {
    selectedMap.value[id] = !selectedMap.value[id]
}

const computeLastActive = (lastVisitedAt: string | null) => {
    if (!lastVisitedAt) {
        return 'Never opened'
    }
    try {
        return `Active ${formatDistanceToNowStrict(parseISO(lastVisitedAt))} ago`
    } catch {
        return 'Never opened'
    }
}

// groupName
const groupName = ref('')

// groupColor
const colorOptions = ['purple', 'blue', 'teal', 'green', 'pink']
// Pick a random color
const groupColor = ref<string>(colorOptions[Math.floor(Math.random() * colorOptions.length)])

// Functions
function closeModal() {
    open.value = false
    groupName.value = ''
    accountSearch.value = ''
    groupColor.value = colorOptions[Math.floor(Math.random() * colorOptions.length)]
    selectedMap.value = {}
}
function saveChanges() {
    open.value = false
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss">
.selected-accounts {
    @include sticky;
    bottom: 0.375rem;
    @include z-2;
}
</style>
