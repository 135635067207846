<template>
    <ExternalPageWrapper :maxWidth="384">
        <EmptyState
            icon="ExclamationExtraLarge"
            headline="Page Not Found"
            message="We couldn't find the page you were looking for. Please check the URL and try again. If the issue persists, click below to go back to Accounts."
            hideContainer
            :padding="0"
            :contentMaxWidth="300"
        >
            <Button iconAfter="ArrowUpRight" color="secondary" @click="goToAccounts">
                Go to Accounts
            </Button>
        </EmptyState>
    </ExternalPageWrapper>
</template>
<script setup lang="ts">
// Imports
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Components
import { EmptyState, Button } from '@opteo/components-next'

// Setup
const router = useRouter()

// Functions
function goToAccounts() {
    router.push({ name: Routes.Root })
}
</script>

<style scoped lang="scss"></style>
