import { SWRVCache } from 'swrv'
import cloneDeep from 'lodash-es/cloneDeep'
import { set, del, entries, clear } from 'idb-keyval'
import { version } from '../../../package.json'
import { ENABLE_PERSISTENT_SWRV_CACHE } from '@/lib/env'
/**
 * Inspired by https://github.com/Kong/swrv/blob/master/src/cache/adapters/localStorage.ts (which is too slow)
 *
 * How this works:
 * - Every time swrv saves something to its cache, we intercept that request and also save it to indexedDB
 * - On app startup, we pre-load the swrv cache with the indexedDB data.
 * - All default swrv cache functionality is maintained as-is -- this only adds a layer of persistence.
 *
 * Notes:
 * - The idb-keyval library is a wrapper around indexedDB that makes it behave like a key-value store.
 * - indexedDB cache keys are prefixed with the app version and the env (eg "swrv:development:1.0.0:").
 * - On app startup, we clear away any keys from other versions
 */

interface CacheItem {
    data: {
        data: any
        isValidating: boolean
        error: any
    }
    expiresAt: number
    ts: number
}

const MAX_CACHE_ITEM_AGE_MS = 1000 * 60 * 60 * 24 * 7 // 1 week

export default class IndexedDBStorageCache extends SWRVCache<any> {
    private STORAGE_KEY

    constructor(prefix = 'swrv:', ttl = 0) {
        super(ttl)
        this.STORAGE_KEY = `${prefix}${version}:`

        // Clear indexedDB keys that are too old or from other versions
        this.clearOldKeys(prefix)

        // Pre-load the cache with indexedDB data
        this.restoreCache()
    }

    set(key: string, data: any, ttl: number) {
        // Call the original set method to save to the real swrv cache
        super.set(key, data, ttl)

        // Do nothing if disabled
        if (!ENABLE_PERSISTENT_SWRV_CACHE) {
            return
        }

        // This cannot be allowed to fail, so we wrap it in a try/catch.
        try {
            // Create an object similar to the one that swrv would save to its cache, to save to IndexedDB
            const timeToLive = ttl || this.ttl
            const now = Date.now()
            const item: CacheItem = {
                data: {
                    ...data,
                    // Do a cloneDeep of data to "collapse" any proxies/refs that might not be happy to be saved into IDB
                    data: cloneDeep(data.data),
                },
                expiresAt: timeToLive ? now + timeToLive : Infinity,
                ts: now, // not required by swrv, but useful for clearing oldest items
            }

            const _key = this.serializeKey(key)

            set(this.STORAGE_KEY + _key, item).catch(e => {
                console.warn('Error saving to indexedDB for cache key:', _key, item)
                console.warn(e)
                // cache is probably full, so we don't save anything.
                // the cache will be cleared on next version upgrade.
            })
        } catch (e) {
            console.error(e)
        }
    }

    private async restoreCache() {
        // Pre-load the cache with IndexedDB data
        const _entries = await entries<string, any>()

        for (const [key, item] of _entries) {
            if (!item) continue

            if (!key.startsWith(this.STORAGE_KEY)) continue

            if (item.expiresAt && item.expiresAt < Date.now()) continue

            super.set(key.replace(this.STORAGE_KEY, ''), item.data, this.ttl)
        }
    }

    private async clearOldKeys(prefix: string) {
        // Clear everything if IndexedDB caching is disabled
        if (!ENABLE_PERSISTENT_SWRV_CACHE) {
            clear()
            return
        }

        const _entries = await entries<string, any>()
        for (const [key, item] of _entries) {
            // If key starts with `swrv` but doesn't contain this version number, delete it
            if (key.startsWith(prefix) && !key.startsWith(this.STORAGE_KEY)) {
                del(key)
            }

            // If key starts with storage_key but is too old, delete it
            if (key.startsWith(this.STORAGE_KEY)) {
                if (!item || (item.ts && item.ts < Date.now() - MAX_CACHE_ITEM_AGE_MS)) {
                    del(key)
                }
            }
        }
    }
}

export function clearCache() {
    clear()
}
