<template>
    <input
        ref="fileInput"
        name="imageuploaderfileInput"
        type="file"
        style="display: none"
        :accept="acceptedTypesString"
        :disabled="disabled"
        @change="selected"
    />
    <Stack
        :padding="[32, 20, showButton ? 74 : 32, 20]"
        direction="column"
        justify="center"
        :gap="24"
    >
        <!-- Slot -->
        <Stack justify="center">
            <slot></slot>
        </Stack>

        <!-- Buttons -->
        <Button
            v-if="url && showButton"
            size="extra-small"
            color="secondary"
            :disabled="disabled"
            :style="actionButtonStyle"
            @click="remove"
        >
            Remove Image
        </Button>
        <Button
            v-else-if="!url && showButton"
            size="extra-small"
            color="secondary"
            :disabled="disabled"
            :style="actionButtonStyle"
            @click="selectFile"
        >
            Upload Image
        </Button>

        <!-- Error -->
        <ErrorMessage v-if="uploadError"> {{ uploadError }}. </ErrorMessage>

        <!-- Description -->
        <Container
            v-if="hasDescription && !url"
            :padding="[14, 20]"
            color="background-plus-two"
            :borderRadius="14"
        >
            <Text size="body-extra-small">
                Image must be <b>PNG</b>, <b>GIF</b>, or <b>JPG</b> format. Transparent backgrounds
                are preferred. Image must be at least <b>300px</b> wide and no larger than
                <b>{{ ACCEPTED_FILE_SIZE }}MB</b>.
            </Text>
        </Container>
    </Stack>
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import { Location, useImageUploader } from '@/composition/global/useImageUploader'
import { Button, Text, Stack, Spacer, ErrorMessage, Container } from '@opteo/components-next'

type Props = {
    location: Location
    url: string
    hasDescription?: boolean
    showButton?: boolean
    disabled?: boolean
    buttonOnBounds?: boolean
    borderRadius?: string
    fullImageUrlOnUpload?: boolean
}

const props = withDefaults(defineProps<Props>(), {
    hasDescription: false,
    borderRadius: '1rem',
    buttonOnBounds: false,
    fullImageUrlOnUpload: false,
})

const emit = defineEmits(['uploaded', 'beforeRemove', 'afterRemove'])

const {
    fileInput,
    selectFile,
    selected,
    remove,
    uploadError,
    acceptedTypesString,
    ACCEPTED_FILE_SIZE,
} = useImageUploader({
    location: props.location,
    fullImageUrlOnUpload: props.fullImageUrlOnUpload,
    emit,
})

const actionButtonStyle = computed(
    () => 'bottom: 1rem; left: 50%; transform: translateX(-50%); position: absolute; z-index: 2;'
)
</script>

<style scoped lang="scss"></style>
