<template>
    <ExternalPageWrapper>
        <Container :width="316" :borderRadius="22">
            <Stack :padding="[18, 20, 20, 20]" :gap="0" direction="column" align="start">
                <!-- Description -->
                <Text as="h6" size="headline-six">Payment Method Updated</Text>
                <Spacer :height="12" />
                <Text as="span" size="body-extra-small" color="foreground-minus-one">
                    Thanks for updating your payment method. Your most recent unpaid invoice has
                    been resolved and your preferred payment method has been saved for future
                    invoices. If you have any questions, please don't hesitate to
                    <Text
                        as="span"
                        color="foreground-minus-one"
                        weight="bold"
                        @click="showNewMessage()"
                        style="cursor: pointer"
                        >message our support team</Text
                    >.
                </Text>
                <Spacer :height="16" />
                <!-- Button -->
                <Button size="medium" fullWidth iconAfter="ArrowUpRight" @click="toAccountCentre">
                    Continue to Accounts
                </Button>
            </Stack>
        </Container>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Components
import { Button, Text, Spacer, Container, Stack } from '@opteo/components-next'
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Setup
const router = useRouter()
const { showNewMessage } = useIntercom()

// Functions
const toAccountCentre = () => router.push({ name: Routes.AccountCentre })
</script>

<style scoped lang="scss"></style>
