<template>
    <PageHeader v-if="showPageHeader" title="Improvements">
        <template #titleSuffix>
            <PageSwitcher v-if="above480" :pages="pageSwitcherPages" />
        </template>
        <template #right>
            <!-- Sort -->
            <SelectableList
                type="sort"
                :modelValue="improvementSort"
                @update:modelValue="updateImprovementSort"
                @update:sortDirection="updateImprovementSortDirection"
                :items="sortItems"
                :showKeyboardShortcuts="false"
                :popoutWidth="128"
                :offset="[4, 6]"
                v-if="currentRoute.name === Routes.ImprovementsActive"
                buttonSize="small"
                placement="bottom-end"
            />

            <!-- Improvement Types -->
            <Popout
                v-model="showActiveImprovementTypes"
                :offset="[4, 6]"
                placement="bottom-end"
                trapFocus
                externalToggleButton
                v-if="currentRoute.name === Routes.ImprovementsActive"
            >
                <Button
                    color="secondary"
                    iconBefore="Lightning"
                    iconAfter="CaretDown"
                    size="small"
                    @click="showActiveImprovementTypes = !showActiveImprovementTypes"
                >
                    {{ selectedRecActions.length }} Types
                </Button>
                <template #content>
                    <Container
                        color="background-plus-two"
                        shadow="large"
                        :maxHeight="590"
                        :padding="0"
                        :borderRadius="14"
                        :width="320"
                        hideOverflow
                    >
                        <Scroller :padding="6" nested containX containY>
                            <Stack
                                v-for="(group, index) in groupedRecActionPreferences"
                                direction="column"
                                :gap="0"
                                :key="group.name"
                                width="100%"
                                :padding="0"
                            >
                                <Checkbox
                                    :indeterminate="someSelected(group) && !allSelected(group)"
                                    :modelValue="allSelected(group)"
                                    :label="group.name"
                                    @updated="val => toggleGroup(group, val)"
                                    buttonColor="background-plus-three"
                                    buttonMode
                                    fullWidth
                                />
                                <Stack direction="column" :gap="0" :padding="[0, 0, 0, 26]">
                                    <div class="indent-indicator" />
                                    <Checkbox
                                        v-for="pref in group.items"
                                        :key="pref.static_title"
                                        :label="pref.static_title"
                                        v-model="pref.selected"
                                        @updated="updateActiveImpTypes"
                                        buttonColor="background-plus-three"
                                        buttonMode
                                        fullWidth
                                    />
                                </Stack>
                            </Stack>
                        </Scroller>
                    </Container>
                </template>
            </Popout>

            <!-- Targets -->
            <Button
                v-if="above768"
                color="secondary"
                iconBefore="Loaders"
                size="small"
                @click="goToTargets"
            >
                Targets
            </Button>
        </template>
    </PageHeader>

    <!-- Onboarding -->
    <OnboardingTour
        step-name="tour_inbox"
        :buttonText="'Continue to Improvements'"
        contentMaxWidth="26.25rem"
    >
        <template #header>Improvements</template>
        <template #copy>
            Improvements are smart recommendations that can be pushed live in seconds. Open an
            improvement by clicking a row, review the changes, then click the green button to push
            live. You can also push batches of improvements using the checkboxes at the left-hand
            side of each row.
        </template>
    </OnboardingTour>

    <!-- DismissFeedbackModal -->
    <DismissFeedbackModal />

    <PageContent>
        <router-view></router-view>
    </PageContent>
</template>

<script setup lang="ts">
// Imports
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import groupBy from 'lodash-es/groupBy'

// Composition
import { useImprovementPreferences } from '@/composition/improvement/useImprovementPreferences'
import { provideBatchBox } from '@/composition/improvement/useBatchBox'

// Components
import {
    Button,
    SelectableList,
    Popout,
    Checkbox,
    Container,
    Stack,
    Scroller,
    Spacer,
    Text,
    useMediaQuery,
} from '@opteo/components-next'
import PageSwitcher from '@/components/global/PageSwitcher.vue'
import OnboardingTour from '@/components/user/OnboardingTour.vue'
import DismissFeedbackModal from '@/components/improvement/DismissFeedbackModal.vue'

// Setup
const { currentRoute, push } = useRouter()
const { recActionPreferences, updateActiveImpTypes } = useImprovementPreferences()
const { improvementSort, updateImprovementSort, updateImprovementSortDirection } = provideBatchBox()
const { above480, above768 } = useMediaQuery()

// sortItems
const sortItems = [
    { label: 'Priority', value: 'priority' },
    { label: 'Type', value: 'type' },
    { label: 'Date', value: 'created' },
]

// showPageHeader
const showPageHeader = computed(() => {
    if (
        currentRoute.value.name === Routes.ImprovementsActive ||
        currentRoute.value.name === Routes.ImprovementsDismissed ||
        currentRoute.value.name === Routes.ImprovementsCompleted
    ) {
        return true
    } else return false
})

// activeImprovementTypes
const showActiveImprovementTypes = ref(false)
const selectedRecActions = computed(() => {
    if (recActionPreferences.value) {
        return recActionPreferences.value
            .filter(pref => pref.selected)
            .map(pref => pref.static_title)
    } else return []
})
function allSelected(group: { items: any[] }) {
    return group.items.every(item => item.selected === true)
}
function someSelected(group: { items: any[] }) {
    return group.items.some(item => item.selected === true)
}
function toggleGroup(group: { items: any[] }, val: boolean) {
    group.items.forEach(item => {
        item.selected = val
    })
}

// TODO(components-updates): Handle categories in luckymetrics to allow for check functionality
const groupOrder = ['Exclusions', 'Adjustments', 'Keywords', 'Negatives', 'Deprecated']
const groupedRecActionPreferences = computed(() => {
    const grouped = groupBy(recActionPreferences.value, pref => {
        // Check each rec_action in the array to determine the group
        if (
            pref.rec_actions.some(action =>
                [
                    'check_query_relevance',
                    'fix_negatives_blocking_keywords',
                    'increase_keyword_bid_v2',
                    'adjust_keyword_bid_v2',
                    'pause_duplicate_keywords_v2',
                    'pause_keyword_v2',
                    'remove_broad_keywords_v2',
                ].includes(action)
            )
        ) {
            return 'Keywords'
        } else if (
            pref.rec_actions.some(action =>
                [
                    'add_robot_negative',
                    'add_negative_geo_keyword',
                    'add_negative_ngram',
                    'add_missing_campaigns_to_shared_set',
                    'resync_exclusion_lists',
                ].includes(action)
            )
        ) {
            return 'Negatives'
        } else if (
            pref.rec_actions.some(action =>
                [
                    'disable_interest_locations_v2',
                    'disable_search_partners',
                    'exclude_single_demographic',
                    'exclude_single_device',
                    'exclude_location',
                    'exclude_content',
                ].includes(action)
            )
        ) {
            return 'Exclusions'
        } else if (
            pref.rec_actions.some(action =>
                [
                    'add_frequency_capping',
                    'adjust_ad_group_multi_targets',
                    'adjust_single_placement_demo_bid',
                    'adjust_multiple_placement_demo_bids',
                    'adjust_single_device_bid',
                    'adjust_multiple_device_bids',
                    'adjust_location_bids',
                    'lift_campaign_budget_cap',
                    'reduce_campaign_bids',
                    'reduce_ngram_bids',
                ].includes(action)
            )
        ) {
            return 'Adjustments'
        } else {
            return 'Deprecated'
        }
    })

    // Map grouped data to match groupOrder and ensure all groups exist
    return groupOrder.map(name => ({
        name,
        items: grouped[name] || [], // Default to an empty array if the group has no items
    }))
})

// PageSwitcher
const pageSwitcherPages = ref([
    { label: 'Active', route: Routes.ImprovementsActive },
    { label: 'Completed', route: Routes.ImprovementsCompleted },
    { label: 'Dismissed', route: Routes.ImprovementsDismissed },
])

// Functions
const goToTargets = () => {
    push({ name: Routes.Targets })
}
</script>

<style scoped lang="scss">
.indent-indicator {
    @include absolute;
    height: calc(100% - 8px);
    width: 0;
    top: 4px;
    left: 1.1875rem;
    @include br-999;
    border: 1px solid;
    @include border-background-plus-three;
}
</style>
