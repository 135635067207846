// These keys should match the route record "name" field
export enum Routes {
    // Misc
    Root = 'root',
    Login = 'login',
    ResetPassword = 'reset',
    ResetPasswordLink = 'resetlink',
    PageNotFound = '404',
    EmailLink = 'link',
    InviteWelcome = 'invitewelcome',
    Thanks = 'thanks',
    SharedChat = 'c',

    // Onboarding
    CreateAccount = 'createaccount',
    AfterCreate = 'aftercreate',
    ConnectGoogleAds = 'connectgoogleads',
    ConnectMetaAds = 'connectmetaads',

    // User
    User = 'user',
    AccountCentre = 'accountcentre',
    AccountCentreInit = 'accountcentreinit',
    Chat = 'chat',
    Reports = 'reports',
    Basecamp = 'basecamp',
    Alerts = 'alerts',
    Alert = 'alert', // "/alerts/:id"
    LinkedAccounts = 'linkedAccounts',
    LinkedAccountsPlatform = 'linkedAccountsplatform',
    LinkedAccountsGoogle = 'linkedAccountsgoogle',
    LinkedAccountsMicrosoft = 'linkedAccountsmicrosoft',
    LinkedAccountsMeta = 'linkedAccountsmeta',
    LinkedAccountsLinkedIn = 'linkedAccountslinkedin',
    NewPaymentMethod = 'newpaymentmethod',
    Thankyou = 'thankyou',

    // User Settings
    UserSettingsIndex = 'usersettingsindex',
    UserSettings = 'usersettings',
    Invoices = 'invoices',
    ImpactStatistics = 'impactstatistics',
    Billing = 'billing',

    // Account
    Account = 'account',

    // Domain (Legacy)
    DomainSettings = 'domainsettings',
    Notes = 'notes',
    Setup = 'setup',

    // Improvements
    Improvements = 'improvements',
    ImprovementsActive = 'improvementsactive',
    ImprovementsCompleted = 'improvementscompleted',
    ImprovementsDismissed = 'improvementsdismissed',

    // Open Improvements
    Improvement = 'improvement', // "/active/:id"
    CompletedImprovement = 'completedimprovement', // "/completed/:id"
    DismissedImprovement = 'dismissedimprovement', // "/dismissed/:id"

    // Targets
    Targets = 'targets',

    // Issues
    Issues = 'issues',

    // Performance
    Performance = 'performance',
    PerformanceGraphs = 'performancegraphs',
    PerformanceScorecard = 'performancescorecard',
    PerformanceScorecardList = 'performancescorecardlist',
    PerformanceScorecardSingle = 'performancescorecardsingle',
    PerformanceScorecardCreate = 'performancescorecardcreate',
    PerformanceSegments = 'performancesegments',
    PerformanceTable = 'performancetable',

    // Reports
    // Reports = 'reports',
    // ReportsActive = 'reportsactive',
    // ReportsArchive = 'reportsarchive',
    // ReportActive = 'reportactive', // "/report/:id"
    // ReportArchive = 'reportarchive', // "/report/:id"
    // ReportSlides = 'reportslides',
    // ReportSlidesArchive = 'reportslidesarchive',
    // ReportCreate = 'createreport',
    // ReportDownload = 'downloadreport',
    // ReportDownloadArchive = 'downloadreportarchive',
    // ReportUpdate = 'updatereport',

    // Toolkit
    Toolkit = 'toolkit',
    ToolkitTools = 'toolkittools',

    // N-Gram Finder
    ToolkitNGramFinder = 'toolkitngramtool',
    ToolkitNGramFinderSearch = 'toolkitngramtool-search-campaigns',
    ToolkitNGramFinderSearchSelect = 'toolkitngramtool-search-campaigns-select',
    ToolkitNGramFinderSearchConfirm = 'toolkitngramtool-search-campaigns-confirm',
    ToolkitNGramFinderPmax = 'toolkitngramtool-performance-max',
    ToolkitNGramFinderPmaxSelect = 'toolkitngramtool-performance-max-select',
    ToolkitNGramFinderPmaxConfirm = 'toolkitngramtool-performance-max-confirm',
    ToolkitNGramFinderShopping = 'toolkitngramtool-shopping-campaigns',
    ToolkitNGramFinderShoppingSelect = 'toolkitngramtool-shopping-campaigns-select',
    ToolkitNGramFinderShoppingConfirm = 'toolkitngramtool-shopping-campaigns-confirm',

    // Keyword Finder
    ToolkitKeywordFinder = 'toolkitkeywordfinder',

    // Account Manager
    ToolkitAccountManager = 'toolkitaccountmanager',

    // Product Manager
    ToolkitProductManager = 'toolkitproductmanager',

    // Shareable link pages
    ShareableLinkScorecard = 'shareablelinkscorecard',

    // PDF pages
    PdfScorecard = 'pdfscorecard',
}

export const QueryParam = {
    from: {
        scorecard: 'scorecard',
    },
} as const
