<template>
    <Modal
        title="Share Feedback"
        v-model="showFeedbackModal"
        :width="380"
        @closed="feedbackContent = ''"
    >
        <template #content>
            <Container
                color="primary-translucent"
                :padding="[16, 20]"
                :borderRadius="18"
                width="100%"
            >
                <Text as="p" size="body-small" color="primary">
                    Let us know why you dismissed this improvement and how we could do things better
                    in the future.
                </Text>
            </Container>
            <Spacer :height="20" />
            <Container
                color="background-plus-one"
                :borderRadius="15"
                width="100%"
                :padding="[11, 16, 11, 18]"
                hideOverflow
                align="center"
            >
                <Stack direction="row" :gap="16">
                    <Text as="h6" size="headline-six" color="foreground" :wrap="false">
                        {{ dismissedImprovement?.improvementTitle }}
                    </Text>

                    <Stack direction="row" :gap="8">
                        <ImprovementLocation
                            v-for="location in dismissedImprovement?.improvementLocation"
                            :key="location.entity"
                            :entity="location.entity"
                            :label="location.label || ''"
                        />
                    </Stack>
                </Stack>
            </Container>
            <Spacer :height="20" />
            <!-- Form -->
            <Form @submitted="submitFeedback" validateOnSubmit ref="feedbackForm">
                <Container
                    color="background-plus-one"
                    :padding="[18, 20, 20, 20]"
                    :borderRadius="18"
                    width="100%"
                >
                    <Textarea
                        v-model="feedbackContent"
                        label="Share Feedback"
                        name="feedback"
                        id="feedback-textarea"
                        autofocus
                        required
                    />
                </Container>
            </Form>
        </template>
        <template #buttons>
            <Button @click="showFeedbackModal = false" color="secondary">Cancel</Button>
            <Button @click="feedbackForm.submit()">Share Feedback</Button>
        </template>
    </Modal>
</template>
<script setup lang="ts">
// Imports
import { ref } from 'vue'

// Composition
import { useDismissFeedbackModal } from '@/composition/improvement/useDismissFeedbackModal'

// Components
import {
    Button,
    Modal,
    Text,
    Form,
    Textarea,
    Stack,
    Container,
    Spacer,
} from '@opteo/components-next'
import ImprovementLocation from './ImprovementLocation.vue'

// Setup
const { showFeedbackModal, feedbackContent, dismissedImprovement, submitFeedback } =
    useDismissFeedbackModal()
const feedbackForm = ref()
</script>

<style scoped lang="scss"></style>
