<template>
    <Container v-if="!loading" :maxWidth="512" :borderRadius="22">
        <Stack direction="column" :gap="0">
            <!-- Renew Text -->
            <Stack direction="column" :padding="[18, 22]">
                <Text v-if="trialLength && currentPlan" as="p" size="body-small" width="100%">
                    To start a new free trial, please click the button below. Your new trial will
                    last for
                    <b>{{ trialLength }} days</b>. You will be subscribed to the
                    <b>{{ currentPlan.name }}</b> plan. If you have any questions, please don't
                    hesitate to message support.
                </Text>
                <Text v-else-if="cancelsOn" as="p" size="body-small">
                    <Text as="span">
                        Your subscription will end on <b>{{ cancelsOn }}</b
                        >. Your payment card <b>will not</b> be charged again. To renew your
                        subscription, click</Text
                    >
                    <Text as="span" color="green" weight="bold"> Renew Subscription</Text>.
                </Text>
                <Text v-else as="p" size="body-small">
                    <Text as="span">
                        Your Opteo subscription has ended. To start using Opteo again, please click
                        the
                    </Text>
                    <Text as="span" color="green" weight="bold"> Renew Subscription </Text>
                    button below. You can also change your pricing plan by clicking
                    <b>Change Plan</b>. If you have any questions, please message support.
                </Text>
            </Stack>

            <!-- Plan -->
            <Stack :padding="[0, 22, 22, 22]" direction="column">
                <Container
                    :padding="[14, 20]"
                    :borderRadius="18"
                    color="background-plus-two"
                    width="100%"
                >
                    <Stack justify="between">
                        <Stack direction="column" :gap="2" :padding="[2, 0, 0, 0]" align="start">
                            <Text as="h6" size="headline-six">
                                {{ currentPlan?.name }}
                            </Text>
                            <Text size="body-extra-small" color="foreground-minus-one">
                                <Money
                                    :value="currentPlan?.price"
                                    :currency="currentPlanCurrency"
                                />/{{ currentPlan?.period_interval }}
                            </Text>
                        </Stack>
                        <Stack direction="row" :gap="9">
                            <Button color="tertiary" @click="emit('change-plan')" size="small">
                                Change Plan
                            </Button>
                            <Button color="green" size="small" @click="emit('resubscribe')">
                                {{ resubAction }}
                            </Button>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
        </Stack>
    </Container>
    <Container v-else width="100%" :maxWidth="512" :borderRadius="22">
        <Stack direction="column" :gap="0" :padding="[18, 22]">
            <Stack :height="20" width="100%" align="center">
                <Skeleton :width="456" maxWidth="100%" :height="10" />
            </Stack>
            <Stack :height="20" width="100%" align="center">
                <Skeleton :width="999" maxWidth="100%" :height="10" />
            </Stack>
            <Stack :height="20" width="100%" align="center">
                <Skeleton :width="432" maxWidth="100%" :height="10" />
            </Stack>
        </Stack>
        <Stack :padding="[0, 22, 22, 22]" direction="column">
            <Container
                :padding="[14, 20]"
                :borderRadius="18"
                color="background-plus-two"
                width="100%"
            >
                <Stack justify="between">
                    <Stack direction="column" :gap="2" :padding="[2, 0, 0, 0]" align="start">
                        <Stack :height="18" width="100%" align="center">
                            <Skeleton
                                :width="100"
                                maxWidth="100%"
                                :height="12"
                                color="foreground"
                            />
                        </Stack>
                        <Stack :height="18" width="100%" align="center">
                            <Skeleton
                                :width="64"
                                maxWidth="100%"
                                color="foreground-minus-one"
                                :height="8"
                            />
                        </Stack>
                    </Stack>
                    <Stack direction="row" :gap="9">
                        <Button color="tertiary" disabled size="small">Change Plan</Button>
                        <Button color="tertiary" disabled size="small">
                            {{ resubAction }}
                        </Button>
                    </Stack>
                </Stack>
            </Container>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useSubscription } from '@/composition/billing/useSubscription'

// Components
import { Container, Stack, Text, Money, Button, Skeleton } from '@opteo/components-next'

// Emits
const emit = defineEmits(['resubscribe', 'change-plan'])

// Setup
const { currentPlan, currentPlanCurrency } = useUser()
const { trialLength, cancelsOn, loading } = useSubscription()

// Computed
const resubAction = computed(() =>
    (trialLength.value ?? 0) > 0 ? 'Start New Free Trial' : 'Renew Subscription'
)
</script>

<style scoped lang="scss"></style>
