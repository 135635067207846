import { App } from 'vue'
import { useDevice } from '@opteo/components-next'

export default {
    install(app: App) {
        const { isMobileOrTablet } = useDevice()

        // Only apply gesture prevention if device is mobile or tablet
        if (isMobileOrTablet.value) {
            const handleGestureStart = (e: Event) => {
                e.preventDefault()
            }

            const handleGestureChange = (e: Event) => {
                e.preventDefault()
            }

            const handleGestureEnd = (e: Event) => {
                e.preventDefault()
            }

            // Register event listeners
            document.addEventListener('gesturestart', handleGestureStart)
            document.addEventListener('gesturechange', handleGestureChange)
            document.addEventListener('gestureend', handleGestureEnd)

            // Cleanup event listeners on app unmount using the 'beforeunload' event
            window.addEventListener('beforeunload', () => {
                document.removeEventListener('gesturestart', handleGestureStart)
                document.removeEventListener('gesturechange', handleGestureChange)
                document.removeEventListener('gestureend', handleGestureEnd)
            })
        }
    },
}
