<template>
    <Container :borderRadius="20" :minWidth="360">
        <Form>
            <Stack direction="column" :gap="0">
                <Stack direction="column" :padding="[18, 20, 20]" borderBottom>
                    <TextInput
                        v-model="vatData.adminEmail"
                        name="admin-email"
                        label="Recipient Email Address"
                        type="email"
                        autofocus
                    />
                </Stack>
                <Stack direction="column" :padding="[18, 20, 20]" borderBottom>
                    <!-- TODO: Add disclaimer that this is EU-Only; double check this is still the case or EU + UK-->
                    <TextInput
                        v-model="vatNumber"
                        ref="vatNumberField"
                        name="vat-number"
                        label="VAT Number"
                    />
                </Stack>
                <Stack direction="column" :padding="[18, 20, 20]" :gap="18">
                    <TextInput
                        v-model="vatData.invoiceAddress.company_name"
                        name="company-name"
                        label="Company Name"
                    />
                    <TextInput
                        v-model="vatData.invoiceAddress.street_line_1"
                        name="street-line-1"
                        label="Street Line 1"
                    />
                    <TextInput
                        v-model="vatData.invoiceAddress.street_line_2"
                        name="street-line-2"
                        label="Street Line 2"
                    />
                    <TextInput v-model="vatData.invoiceAddress.city" name="city" label="City" />
                    <TextInput
                        v-model="vatData.invoiceAddress.postal_code"
                        name="postal-code"
                        label="Postal Code"
                    />
                    <Select
                        v-model="vatData.invoiceAddress.country_code"
                        name="country"
                        label="Country"
                        :options="countriesObject"
                    />
                </Stack>
            </Stack>
        </Form>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, defineExpose, watch } from 'vue'
import { useInvoicesSettings } from '@/composition/billing/invoices/useInvoicesSettings'
import { InvoicesVatDataProps } from '@/composition/billing/invoices/useInvoices'
import { Form, Stack, TextInput, Select, Container } from '@opteo/components-next'

// Define
const props = defineProps({
    vatData: {
        type: Object as PropType<InvoicesVatDataProps>,
        required: true,
    },
})

// Setup
const { countriesObject, updateVatForm, vatNumberField, vatNumber } = useInvoicesSettings(props)

// defineExpose
defineExpose({ updateVatForm })
</script>

<style lang="scss" scoped></style>
