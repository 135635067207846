<template>
    <ClickableRow :padding="[12, 24, 12, 22]" @click="openImprovement">
        <!-- Icon, Name, Location -->
        <Stack v-if="!isSkeletonMode" direction="row" :gap="12" class="icon-name-location">
            <!-- Icon -->
            <slot name="icon" v-if="!isSkeletonMode" />
            <!-- Name, Location -->
            <Stack :gap="14" direction="row">
                <Text as="h4" size="headline-seven" color="foreground" :wrap="false">
                    {{ improvement.title }}
                </Text>
                <Stack direction="row" :gap="8">
                    <ImprovementLocation
                        v-for="location in improvement.location"
                        :key="location.entity"
                        :entity="location.entity"
                        :label="location.label || ''"
                    />
                </Stack>
            </Stack>
        </Stack>
        <!-- Buttons -->
        <Stack v-if="!isSkeletonMode" direction="row" width="auto" :gap="8" class="buttons">
            <slot name="buttons" />
        </Stack>
        <!-- Skeleton -->
        <Stack v-else :gap="12" direction="row" align="center">
            <IconSlot :width="20" :height="20">
                <Checkbox :modelValue="false" disabled />
            </IconSlot>
            <Skeleton :width="Math.random() * 120 + 100" :height="16" />
            <template v-for="skid in 2" :key="skid">
                <EntityPill
                    loading
                    color="inactive"
                    type="Campaign"
                    label="Campaign"
                    size="small"
                />
            </template>
        </Stack>
    </ClickableRow>
</template>
<script setup lang="ts">
// Imports
import { computed, PropType } from 'vue'

// Types
import { Improvement } from '@opteo/types'

// Components
import ImprovementLocation from '@/components/improvement/ImprovementLocation.vue'
import {
    Skeleton,
    Stack,
    Text,
    EntityPill,
    Checkbox,
    IconSlot,
    useMediaQuery,
    ClickableRow,
} from '@opteo/components-next'

// Props
const props = defineProps({
    improvement: {
        required: true,
        type: Object as PropType<Improvement.Object>,
    },
    isInteractive: {
        required: false,
        type: Boolean,
        default: true,
    },
})

// Emits
const emit = defineEmits(['improvement-opened'])

// Setup
const { above480 } = useMediaQuery()

// Functions
const openImprovement = () => {
    emit('improvement-opened')
}

// Computed
const isSkeletonMode = computed(() => typeof props.improvement.improvement_id === 'undefined')
</script>

<style scoped lang="scss">
.icon-name-location {
    @include overflow-hidden;
    @include relative;
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 1rem, rgba(0, 0, 0, 1) 4rem);
}
</style>
