<template>
    <!-- Skeleton -->
    <Stack v-if="loading || !account" :gap="16" class="account">
        <Skeleton :width="28" :height="28" />
        <Stack direction="column" :gap="4" align="start">
            <Stack :gap="6">
                <Stack :height="18" width="auto">
                    <Skeleton
                        :width="Math.floor(Math.random() * 32 + 100)"
                        maxWidth="100%"
                        :height="10"
                    />
                </Stack>
            </Stack>
            <Stack :padding="[0, 0, 2, 0]">
                <Stack :height="12" width="auto">
                    <Skeleton
                        :width="Math.floor(Math.random() * 32 + 64)"
                        maxWidth="100%"
                        :height="8"
                    />
                </Stack>
            </Stack>
        </Stack>
    </Stack>
    <!-- Account -->
    <Stack v-else :gap="16" class="account">
        <Avatar
            :initials="accountInitials"
            :color="mapHexToAvatarColor(account.color)"
            :platform="account.platform"
            class="avatar"
            role="button"
            @mouseenter.prevent="showSettingsIcon = true"
            @mouseleave.prevent="showSettingsIcon = false"
            @click="goToSettings({ accountId: account.accountId })"
        >
            <IconSlot v-if="showSettingsIcon" icon="Settings" class="icon" />
            <span v-else="showSettingsIcon">{{ accountInitials }}</span>
        </Avatar>

        <Stack direction="column" :gap="4" align="start">
            <Stack :gap="6">
                <Text as="h6" size="button" :color="computedTextColor" :wrap="false">
                    {{ account.name }}
                </Text>
                <Container
                    :width="18"
                    :height="18"
                    align="center"
                    justify="center"
                    :borderRadius="999"
                    :color="`${mapHexToAvatarColor(account.color)}-translucent`"
                >
                    <Text size="badge" :color="computedTextColor">
                        {{ account.tasksAvailable }}
                    </Text>
                </Container>
            </Stack>
            <Stack :padding="[0, 0, 2, 0]">
                <Text as="span" size="label-small" color="foreground-minus-two" :wrap="false">
                    {{ accountLastActive }}
                </Text>
            </Stack>
        </Stack>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch, toRef, computed } from 'vue'
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict'
import parseISO from 'date-fns/parseISO'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Types
import { Account } from '@opteo/types'

// Composition
import { mapHexToAvatarColor } from '@/composition/utils/utils'
import { getDomainInitials } from '@/composition/domain/useDomain'

// Components
import { Container, Stack, Text, Avatar, Skeleton, IconSlot } from '@opteo/components-next'

// Props
const props = defineProps({
    account: {
        type: Object as PropType<Account.Info>,
        required: true,
    },
})

// Setup
const router = useRouter()

// Modal
const showAccountProfileModal = ref(false)

// goToSettings
function goToSettings({ accountId }: { accountId: Account.ID }) {
    router.push({ name: Routes.DomainSettings, params: { accountId } })
}

// Settings
const showSettingsIcon = ref(false)

// Loading
const loading = ref(true)
const accountName = toRef(props, 'account')
watch(
    () => accountName.value?.name,
    newName => {
        if (newName) {
            loading.value = false
        }
    },
    { immediate: true }
)

// Computed
const accountInitials = computed(() => getDomainInitials(props.account.name))
const accountLastActive = computed(() => {
    const lastVisitedAt = props.account.lastVisitedAt as string | null

    if (!lastVisitedAt) {
        return 'Never opened'
    }

    try {
        return `Active ${formatDistanceToNowStrict(parseISO(lastVisitedAt))} ago`
    } catch {
        return 'Never opened'
    }
})
const computedTextColor = computed(() => {
    const color = props.account?.color?.toLowerCase() || ''

    if (color === '#006dff') {
        return 'blue'
    } else if (color === '#6e3aff') {
        return 'purple'
    } else if (color === '#00a897') {
        return 'teal'
    } else if (color === '#ff365c') {
        return 'pink'
    } else if (color === '#ff471f') {
        return 'orange'
    } else return 'foreground-minus-one'
})
</script>

<style scoped lang="scss">
.account {
    @include overflow-hidden;
    @include relative;
    mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 1rem, rgba(0, 0, 0, 1) 4rem);
}
.avatar {
    @include cursor-pointer;
}
.avatar .icon :deep(svg path) {
    fill: $light-0;
}
</style>
