<template>
    <div class="pulse-wrapper">
        <div class="pulse" />
    </div>
</template>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .pulse-wrapper {
// //     padding: 0.25rem;
// //     width: 1.125rem;
// //     height: 1.125rem;
// // }
// // .pulse {
// //     width: 0.625rem;
// //     height: 0.625rem;
// //     border-radius: 50%;
// //     background-color: $opteo-red;
// //     box-shadow: 0 0 0 $opteo-red;
// //     animation: anim-pulse 3.2s infinite;

// //     @keyframes anim-pulse {
// //         0% {
// //             box-shadow: 0 0 0 0 hsla(0, 100%, 58%, 0.8);
// //         }

// //         50% {
// //             box-shadow: 0 0 0 0.4375rem hsla(0, 100%, 58%, 0);
// //         }

// //         75% {
// //             box-shadow: 0 0 0 0 hsla(0, 100%, 58%, 0);
// //         }
// //     }
// // }

// // // //Mixins
// // // @mixin keyframes($animation-name) {
// // //     @-webkit-keyframes #{$animation-name} {
// // //         @content;
// // //     }
// // //     @-moz-keyframes #{$animation-name} {
// // //         @content;
// // //     }
// // //     @-ms-keyframes #{$animation-name} {
// // //         @content;
// // //     }
// // //     @-o-keyframes #{$animation-name} {
// // //         @content;
// // //     }
// // //     @keyframes #{$animation-name} {
// // //         @content;
// // //     }
// // // }
// // // @mixin animation($str) {
// // //     -webkit-animation: #{$str};
// // //     -moz-animation: #{$str};
// // //     -ms-animation: #{$str};
// // //     -o-animation: #{$str};
// // //     animation: #{$str};
// // // }

// // // // Animation
// // // @include keyframes(ringPulse) {
// // //     0% {
// // //         opacity: 0;
// // //         transform: scale(1);
// // //     }
// // //     2% {
// // //         opacity: 0;
// // //         transform: scale(1);
// // //     }
// // //     50% {
// // //         opacity: 0.4;
// // //     }
// // //     98% {
// // //         opacity: 0;
// // //         transform: scale(2);
// // //     }
// // //     100% {
// // //         opacity: 0;
// // //         transform: scale(2);
// // //     }
// // // }

// // // .pulse {
// // //     --pulse-width: 10px;
// // //     --pulse-height: 10px;
// // //     --border-width: 5px;
// // //     --color-yellow: #f3ed8b;
// // //     position: relative;
// // //     background-color: $opteo-red;
// // //     border-radius: 50%;
// // //     width: var(--pulse-width);
// // //     height: var(--pulse-width);
// // //     cursor: pointer;
// // //     z-index: 998;
// // //     display: inline-block;
// // //     margin: 0 2px;

// // //     &:before,
// // //     &:after {
// // //         position: absolute;
// // //     }

// // //     // Pulsing ring
// // //     &:before {
// // //         position: absolute;
// // //         content: '';
// // //         border-radius: 50%;
// // //         width: calc(var(--pulse-width) + var(--border-width));
// // //         height: calc(var(--pulse-width) + var(--border-width));
// // //         top: calc((var(--border-width) / 2) * -1);
// // //         left: calc((var(--border-width) / 2) * -1);
// // //         border: var(--border-width) solid $opteo-red;
// // //         transform-origin: 50% 50%;
// // //         transition: all 0.5s;
// // //         @include animation(ringPulse 4s infinite);
// // //     }
// // //     &:after {
// // //         background-color: #fff;
// // //     }
// // // }


</style>
