<template>
    <div v-if="loading">
        <!-- Loading State -->
    </div>
    <div v-else>
        <!-- Account -->
        <Container
            :color="`${mapHexToAvatarColor(accountColour)}-translucent`"
            :padding="[14, 15, 14, 16]"
            :borderRadius="16"
            clickable
            clickAnimation
            hideOverflow
        >
            <Stack direction="row" align="center" :gap="14">
                <Avatar
                    :color="mapHexToAvatarColor(accountColour)"
                    :platform="platform"
                    show-account-type
                >
                    {{ accountInitials }}
                </Avatar>
                <Text
                    v-if="accountName && !loading"
                    as="h6"
                    size="headline-six"
                    :color="
                        mapHexToAvatarColor(accountColour) === 'gray'
                            ? 'foreground-minus-one'
                            : mapHexToAvatarColor(accountColour)
                    "
                    truncate
                    width="100%"
                >
                    {{ accountName }}
                </Text>
            </Stack>
        </Container>
        <!-- Last Updated -->
        <!-- <Text as="span" size="f-9" color="gray">{{ lastUpdated }}</Text> -->
        <!-- Alert -->
        <!-- <component v-if="alert" :is="asyncComponent" :key="alert?.id" /> -->
    </div>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'
import upperFirst from 'lodash-es/upperFirst'
import camelCase from 'lodash-es/camelCase'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

// Types
import { Platform } from '@opteo/types'

// Composition
import { useAlert } from '@/composition/alerts/useAlert'
import { mapHexToAvatarColor } from '@/composition/utils/utils'

// Components
import { Text, Container, Avatar, Skeleton, Stack } from '@opteo/components-next'

// Alert Types
import Budget from '@/components/alerts/types/Budget.vue'
import OverBudget from '@/components/alerts/types/OverBudget.vue'
import SmartBiddingExperiment from '@/components/alerts/types/SmartBiddingExperiment.vue'
import Conversion from '@/components/alerts/types/Conversion.vue'
import Delta from '@/components/alerts/types/Delta.vue'
import ConversionActionsFlatline from '@/components/alerts/types/ConversionActionsFlatline.vue'
import ScheduledReport from '@/components/alerts/types/ScheduledReport.vue'
import ImpressionsFlatline from '@/components/alerts/types/ImpressionsFlatline.vue'

// Setup
const { alert, alertType } = useAlert()

// New alert types must be added here.
const alertComponents = {
    Budget,
    Conversion,
    Delta,
    ConversionActionsFlatline,
    ImpressionsFlatline,
    ScheduledReport,
    OverBudget,
    SmartBiddingExperiment,
}

// Computed
const platform = computed(() => alert.value?.platform ?? Platform.Platform.GoogleAds)
const accountName = computed(() => alert.value?.accountName ?? '')
const accountInitials = computed(() => alert.value?.accountInitials)
const accountColour = computed(() => alert.value?.accountColour)
const loading = computed(() => !alert.value)
const asyncComponent = computed(() => {
    const componentName = upperFirst(camelCase(alertType.value)) as keyof typeof alertComponents

    return alertComponents[componentName]
})
const lastUpdated = computed(() => {
    if (!alert.value) {
        return ''
    }
    // format: January 18th 2022 at 5:40pm
    return format(parseISO(alert.value.ts), `MMMM do y 'at' h:mmaaa`)
})
</script>

<style scoped lang="scss"></style>
