<template>
    <!-- Form -->
    <Container
        :width="width"
        :borderRadius="borderRadius"
        :minHeight="200"
        color="background-plus-one"
        hideOverflow
    >
        <Transition name="loading-mask-fade">
            <div v-if="showLoadingMask" :class="['loading-mask']">
                <Spinner />
            </div>
        </Transition>
        <Stack v-show="!stripeElementsLoading" :padding="0" :gap="20" direction="column">
            <Form ref="paymentMethodForm" @submitted="addPaymentMethod">
                <Stack direction="column" align="start" :gap="0">
                    <div id="payment-element" style="width: 100%" />
                    <Stack v-if="showButton" :padding="20">
                        <Button submit fullWidth iconAfter="ArrowUpRight">
                            Add Payment Method
                        </Button>
                    </Stack>
                </Stack>
            </Form>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { ref, watch, onMounted, defineExpose } from 'vue'

// Composition
import { usePaymentElement } from '@/composition/billing/usePaymentElement'
import { useUser } from '@/composition/user/useUser'

// Components
import { Stack, Form, Spinner, Container, Button } from '@opteo/components-next'

// Props
const props = defineProps({
    updatingCard: {
        type: Boolean,
        required: true,
    },
    width: {
        type: Number,
        required: false,
        default: 480,
    },
    borderRadius: {
        type: Number,
        required: false,
        default: 20,
    },
    showButton: {
        type: Boolean,
        required: false,
        default: false,
    },
})

// Emits
const emit = defineEmits(['success', 'error', 'load'])

// Setup
const showLoadingMask = ref(true)
const paymentMethodForm = ref()
const { user } = useUser()
const { stripeElementsLoading, addPaymentMethod, init } = usePaymentElement({
    updatingCard: props.updatingCard,
    emit,
    user,
})

// Watch
watch(stripeElementsLoading, newValue => {
    if (newValue === false) {
        showLoadingMask.value = false
        emit('load')
    }
})

// Submit
function submit() {
    paymentMethodForm.value.submit()
}

defineExpose({
    submit,
})

// onMounted
onMounted(() => {
    init()
})
</script>

<style scoped lang="scss">
.loading-mask {
    @include absolute;
    @include top-0;
    @include left-0;
    @include w-100;
    @include h-100;
    max-height: 486px;
    @include flex;
    @include flex-center;
    @include z-5;
    @include bg-background-plus-one;
}
.loading-mask-fade-enter-active,
.loading-mask-fade-leave-active {
    transition: opacity $timing-standard $curve-ease-in-out 0.2s;
}

.loading-mask-fade-enter-from,
.loading-mask-fade-leave-to {
    opacity: 0;
}
</style>
