<template>
    <div class="nscore-cell">
        <ScorecardDonut
            :score="(nscore ?? 0) * 100"
            :width="24"
            :stroke-width="2"
            :custom-color="!loading ? nScoreColor : null"
            hide-score
            compact
            :hidden="loading"
        />
        <Spacer width="0.5rem" />
        <Popout
            v-if="showPopout"
            :trigger="!loading ? 'mouseenter' : undefined"
            placement="top"
            wrapper-classes="nscore-popout-wrapper"
            max-width="23.75rem"
            :zIndex="9999999999999"
            :offset="[0, 20]"
            :delay="[300, 0]"
            :border-radius="24"
        >
            <template #content>
                <div class="nscore-popout">
                    <div class="nscore-row">
                        <div class="icon-wrapper">
                            <WarnIcon
                                :color="iconColor"
                                :translucent="true"
                                style="transform: scale(1.1667)"
                            />
                        </div>
                        <Text as="p" size="f-8">
                            This n-gram is responsible for a <b>{{ volumeText }}</b> amount of
                            {{ performanceMaxActive ? 'impressions' : 'spend' }} relative to the
                            campaigns included in your analysis.</Text
                        >
                    </div>

                    <div class="nscore-row">
                        <ScorecardDonut
                            :score="isNull(nscore) ? 'N/A' : nscore * 100"
                            :custom-color="nScoreColor"
                            :strokeWidth="2.5"
                            :width="28"
                            hideScore
                            compact
                        />
                        <Text v-if="averageMetric === 'CPA'" as="p" size="f-8">
                            This n-gram has a CPA of <b><Money :value="cpa ?? 0" /></b>, which is
                            <b><Percent :value="vsAvgAbsolute" /></b>
                            {{ performanceComparison }} than the average CPA of the campaigns
                            included in your analysis.</Text
                        >
                        <Text v-else-if="averageMetric === 'ROAS'" as="p" size="f-8">
                            This n-gram has a ROAS of <b><Percent :value="roas ?? 0" /></b>, which
                            is
                            <b><Percent :value="vsAvgAbsolute" /></b>
                            {{ performanceComparison }} than the average ROAS of the campaigns
                            included in your analysis.</Text
                        >
                        <Text v-else-if="averageMetric === 'CPM'" as="p" size="f-8">
                            This n-gram has a CPM of <b>{{ formatCpm(props.cpm ?? 0) }}</b
                            >, which is
                            <b><Percent :value="vsAvgAbsolute" /></b>
                            {{ performanceComparison }} than the average CPM of the campaigns
                            included in your analysis.</Text
                        >
                        <Text v-else="averageMetric === 'VPM'" as="p" size="f-8">
                            This n-gram has a VPM of
                            <b>{{ formatVpm(props.vpm ?? 0) }}</b
                            >, which is
                            <b><Percent :value="vsAvgAbsolute" /></b>
                            {{ performanceComparison }} than the average VPM of the campaigns
                            included in your analysis.</Text
                        >
                    </div>
                </div>
            </template>
            <img class="info-circle-icon" src="@/assets/img/icon-info.svg" />
        </Popout>
        <img v-if="!showPopout" class="info-circle-icon" src="@/assets/img/icon-info.svg" />
    </div>
</template>

<script lang="ts" setup>
import isNull from 'lodash-es/isNull'
import { computed } from 'vue'

// import {
//     Spacer,
//     Popout,
//     usePercent,
//     Percent,
//     Text,
//     WarnIcon,
//} from '@opteo/components-next'
import { Targets } from '@opteo/types'
import Money from '@/components/global/oMoney.vue'
import ScorecardDonut from '@/components/scorecard/ScorecardDonut.vue'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { useAccount } from '@/composition/account/useAccount'

const props = defineProps<{
    ngram?: string
    impressions?: number
    conversionValue?: number
    cost?: number
    vsAvg?: number
    nscore: number | null | undefined
    nScoreColor: string
    vsAverageColor?: 'red' | 'green'
    cpa?: number
    roas?: number
    vpm?: number
    cpm?: number
    percentOfCampaignVolume: number
    loading?: boolean
}>()

const { performanceMode } = useAccount()
const { performanceMaxActive, formatCpm, formatVpm } = useNGramTool()

const showPopout = computed(() => typeof props.nscore !== 'undefined')

const iconColor = computed(() => {
    if (props.nscore === null) return 'grey' // grey
    if (props.nscore >= 0.7) return 'red' // red
    if (props.nscore > 0.5) return 'amber' // amber
    return 'green' // green
})

/*
    0 - 0.5% is negligible
    0.5% - 3% is reasonable
    3%+ is significant
*/
const volumeText = computed(() => {
    if (props.percentOfCampaignVolume < 0.005) return 'negligible'
    if (props.percentOfCampaignVolume > 0.03) return 'significant'
    return 'reasonable'
})

const averageMetric = computed(() => {
    if (performanceMaxActive.value) {
        if (performanceMode.value === Targets.PerformanceMode.CPA) {
            return 'CPM'
        }
        return 'VPM'
    }

    if (performanceMode.value === Targets.PerformanceMode.CPA) {
        return 'CPA'
    }

    return 'ROAS'
})

const vsAvgAbsolute = computed(() => Math.abs(props.vsAvg ?? 0))

const performanceComparison = computed(() => ((props.vsAvg ?? 0) >= 0 ? 'higher' : 'lower'))
</script>

<style scoped lang="scss">

// @import '@/assets/css/theme.scss';
// @import '@/assets/css/variables.scss';

// :deep(.nscore-popout-wrapper) {
//     display: flex;
//     align-items: center;
// }

// .nscore-cell {
//     display: flex;
//     align-items: center;
// }

// .info-circle-icon {
//     width: 1rem;
//     cursor: pointer;
// }

// .icon-wrapper {
//     height: 28px;
//     width: 28px;
//     display: flex;
//     justify-content: center;
//     align-items: center;
// }

// .nscore-row {
//     display: grid;
//     @include items-center;
//     grid-template-columns: 28px 1fr;
//     padding: 20px 24px;
//     column-gap: 20px;
// }

// .nscore-row:not(:last-child) {
//     border-bottom: 1px solid;
//     @include opteo-border;
// }

</style>
