import { computed, ref } from 'vue'
import { Improvement } from '@opteo/types'
import groupBy from 'lodash-es/groupBy'
import sortBy from 'lodash-es/sortBy'
import parseISO from 'date-fns/parseISO'
import differenceInWeeks from 'date-fns/differenceInWeeks'
import { useRoute } from 'vue-router'

import { useAccountList } from '@/composition/user/useAccountList'
import { Endpoint, useAPI } from '../api/useAPI'
import { useAccount } from '../account/useAccount'

export function useDismissedImprovements() {
    const { mutateDomainList } = useAccountList()
    const { accountId } = useAccount()
    const route = useRoute()
    const improvementOpen = computed(() => !!route.params.improvementId)

    const {
        data: rawDismissedImprovements,
        loading: dismissedLoading,
        mutate: mutateDismissed,
    } = useAPI<Improvement.DismissedObject[]>(Endpoint.ListDismissedImprovements, {
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const loading = computed(() => dismissedLoading.value)

    const noDismissedImps = computed(() => rawDismissedImprovements.value?.length === 0)

    // Group dismissed improvements by dismissal time (week or month)
    // Each group is sorted by dismissal time, most recent at top of list
    const dismissedImprovements = computed(() => {
        return groupBy(
            sortBy(rawDismissedImprovements.value, i => -new Date(i.deferred_timestamp).getTime()),
            i => {
                const diff = differenceInWeeks(
                    parseISO(i.deferred_until),
                    parseISO(i.deferred_timestamp)
                )
                if (diff >= 50) return 'forever'
                if (diff >= 4) return 'month'
                return 'week'
            }
        )
    })

    const limit = ref(50)
    const dismissedImprovementsForeverSubset = computed(() =>
        dismissedImprovements.value?.forever.slice(0, limit.value)
    )
    const moreAvailable = computed(() => {
        const forever = dismissedImprovements.value?.forever
        return Array.isArray(forever) && forever.length >= limit.value
    })

    const refreshDismissedList = () => {
        mutateDismissed()
        mutateDomainList() // refresh domain list to update active imps count
    }

    return {
        dismissedImprovements,
        dismissedImprovementsForeverSubset,
        loading,
        improvementOpen,
        noDismissedImps,
        limit,
        moreAvailable,
        refreshDismissedList,
    }
}
