<template>
    <Stack align="center" justify="center" width="100%" height="100%">
        <Stack direction="column" :maxWidth="278" gap="1.25rem">
            <Container
                :width="80"
                :height="80"
                align="center"
                :borderRadius="20"
                :outline="false"
                justify="center"
                :color="['sidebar-item-active', 'background-plus-one-hover']"
                shadow="none"
            >
                <OpteoLogo :width="48" :height="48" />
            </Container>
            <Text size="body-small" color="foreground-minus-one" align="center">
                Welcome to Opteo. To get started and link your first accounts, please click the
                button below.
            </Text>
            <Button
                color="secondary-highlight"
                iconAfter="ArrowUpRight"
                @click="goToLinkedAccounts"
            >
                Open Linked Accounts
            </Button>
        </Stack>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Components
import { Button, Text, Spacer, Container, OpteoLogo, Stack } from '@opteo/components-next'

// Setup
const { push, currentRoute } = useRouter()

// Functions
const goToLinkedAccounts = () => push({ name: Routes.LinkedAccounts })
</script>
