<template>
    <div
        ref="changelogSelectButton"
        class="changelog-select-button"
        @click="tippyOpenRef = !tippyOpenRef"
        role="button"
        :tabindex="0"
        aria-haspopup="listbox"
        :aria-expanded="tippyOpenRef"
        aria-controls="changelog-select-list"
    >
        <span v-if="selectedEmailAddresses" class="changelog-select-label">{{
            selectedEmailAddresses
        }}</span>
        <span v-else style="padding: 0.8125rem 0.875rem">
            <Skeleton width="5rem" height="1rem" />
        </span>
        <SmallDownCaret class="select-arrow" aria-hidden="true" />
    </div>

    <div ref="selectDropdown" class="select-dropdown">
        <div v-if="!availableConnections?.length" class="select-dropdown-empty-state">
            <Text align="center" size="f-9">No emails available</Text>
        </div>
        <perfect-scrollbar
            v-else
            class="changelog-select-items"
            :options="{
                suppressScrollX: true,
            }"
        >
            <ul
                ref="selectList"
                id="changelog-select-list"
                aria-orientation="vertical"
                aria-labelledby="changelog-select-button"
                tabIndex="0"
                role="listbox"
                :aria-activedescendant="hoveredItem"
            >
                <li
                    v-for="(item, index) in availableConnections"
                    :key="item.connectionId"
                    class="select-list-item"
                    role="option"
                    tabindex="0"
                    :aria-selected="item.isCurrent"
                    @click="onUpdate(item.connectionId)"
                    @keyup.enter="onUpdate(item.connectionId)"
                >
                    <span class="select-list-item-name">
                        {{ item.email }}
                    </span>
                </li>
            </ul>
        </perfect-scrollbar>
        <div class="select-dropdown-info">
            <Text size="f-9" color="gray">
                To use another email address, please connect the parent MCC to your Opteo account
                using the <b>Import Google Ads MCC</b> button above.
            </Text>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, nextTick, computed } from 'vue'
import { useTippy } from 'vue-tippy'
import { useFocusTrap } from '@vueuse/integrations/useFocusTrap'

// import { Text, useShortKey } from '@opteo/components-next'
import SmallDownCaret from '@/components/linkedAccounts/icons/SmallDownCaret.vue'
import { Account, Team } from '@opteo/types'
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { mutate } from 'swrv'
import Skeleton from '../util/Skeleton.vue'

const props = defineProps<{
    availableConnections: {
        isCurrent: boolean
        connectionId: number
        loginCustomerId: string
        email: string
    }[]
    accountId: Account.ID
}>()

const selectedEmailAddresses = computed(
    () => props.availableConnections.find(item => item.isCurrent)?.email
)

const changelogSelectButton = ref()
const selectDropdown = ref()
const selectList = ref()

const tippyOpenRef = ref(false)

const hoveredItem = ref()

const { activate, deactivate } = useFocusTrap(selectList, {
    initialFocus: () => {
        selectList.value?.querySelector('li')
    },
    allowOutsideClick: true,
    returnFocusOnDeactivate: true,
})

const { hide, show } = useTippy(changelogSelectButton, {
    content: () => selectDropdown.value,
    interactive: true,
    touch: true,
    animateFill: false,
    animation: 'shift-away',
    duration: [200, 120],
    hideOnClick: true,
    offset: [-2, -2],
    placement: 'bottom-start',
    trigger: 'click',
    maxWidth: 320,
    zIndex: 99999999999999,
    appendTo: () => document.body,
    popperOptions: {
        strategy: 'fixed',
    },
    onShown: () => {
        onOpen()
    },
    onHide: () => {
        onClose()
    },
    onMount: () => {
        document.body.classList.add('no-scroll')
    },
    onHidden: () => {
        document.body.classList.remove('no-scroll')
    },
})

async function onOpen() {
    tippyOpenRef.value = true

    await nextTick()
    activate()
}

function onClose() {
    tippyOpenRef.value = false
    deactivate()
}

const { mutateLinkedAccounts, teamAccounts } = useLinkedAccounts()

const onUpdate = async (connectionId: number) => {
    const loginCustomerId = (props.availableConnections ?? []).find(
        c => c.connectionId === connectionId
    )?.loginCustomerId

    const updatedAccounts: Team.Account[] =
        teamAccounts.value?.accounts?.map(account => {
            if (account.accountId === props.accountId) {
                return {
                    ...account,
                    availableConnections: props.availableConnections.map(connection => {
                        const isCurrent = connection.connectionId === connectionId
                        return {
                            ...connection,
                            isCurrent,
                        }
                    }),
                }
            }

            return account
        }) ?? []

    mutateLinkedAccounts(() => {
        return {
            team: teamAccounts.value?.team!,
            accounts: updatedAccounts,
        }
    })

    /* 
        We are not awaiting this request because, we want to email selection to be instant
        even when the request is slowed downed by the db.transaction in the backend.
    */
    doAuthRequest(connectionId, loginCustomerId!)

    hide()
}

async function doAuthRequest(connectionId: number, loginCustomerId: string) {
    await authRequest(Endpoint.SetAccountConnection, {
        body: {
            account_id: props.accountId,
            connection_id: connectionId,
            login_customer_id: loginCustomerId,
        },
    })
    // mutateLinkedAccounts()
}

useShortKey({
    keys: ['esc'],
    onPressCallBack: () => {
        if (tippyOpenRef.value) {
            hide()
        }
    },
})

useShortKey({
    keys: ['enter'],
    onPressCallBack: () => {
        if (tippyOpenRef.value) return
        show()
    },
    eventTarget: changelogSelectButton,
})
</script>

<style scoped lang="scss">

// @import '@/assets/css/theme.scss';
// @import '@/assets/css/variables.scss';

// .changelog-select-button {
//     display: flex;
//     align-items: center;
//     appearance: none;
//     width: auto;
//     -moz-appearance: none;
//     -webkit-appearance: none;
//     padding: 0.75rem 0;
//     padding-left: 0.875rem;
//     padding-right: 2.25rem;
//     border-radius: 0.75rem;
//     border: none;
//     box-shadow: $opteo-shadow;
//     color: $opteo-black;
//     transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
//     background-color: white;
//     height: 2.75rem;
//     position: relative;
//     user-select: none;
//     -webkit-user-select: none;
//     cursor: pointer;
//     white-space: nowrap;
//     overflow: hidden;
//     text-overflow: ellipsis;
// }

// .changelog-select-button[disabled]::before {
//     background: linear-gradient(90deg, rgba(255, 255, 255, 0.2) 0%, #f7f7f9 30%);
// }

// .changelog-select-button:focus {
//     box-shadow: $opteo-shadow-focus;
//     transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
//     outline: none;
// }

// .changelog-select-button[disabled] {
//     background: $opteo-light-gray;
//     color: rgba(0, 0, 8, 0.25);
//     cursor: default;
// }

// .changelog-select-button[disabled] svg {
//     opacity: 0.6;
// }

// .changelog-select-label {
//     font-size: 14px;
//     line-height: 1.125rem;
//     letter-spacing: -0.005em;
//     @include truncate;
// }

// .changelog-select-button .select-arrow {
//     position: absolute;
//     top: 50%;
//     transform: translateY(-50%);
//     right: 0.875rem;
//     z-index: 2;
// }

// .select-dropdown {
//     box-shadow: $opteo-shadow-xl;
//     @include bg-opteo-white;
//     @include opteo-foreground;
//     border-radius: 1.375rem;
//     overflow: hidden;
// }

// .changelog-select-items {
//     max-height: 220px;
// }

// #changelog-select-list {
//     display: flex;
//     flex-direction: column;
//     gap: 6px;
//     padding: 0.75rem;
// }

// .select-list-item {
//     cursor: pointer;
//     padding: 0.75rem 1rem;
//     font-size: 14px;
//     line-height: 20px;
//     letter-spacing: -0.005em;
//     border-radius: 0.75rem;
//     transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
// }
// .select-list-item:hover {
//     box-shadow: $opteo-shadow;

//     // padding: 0.75rem 1rem;
//     // font-size: 14px;
//     // line-height: 20px;
//     // letter-spacing: -0.005em;
// }
// .select-list-item:focus {
//     box-shadow: $opteo-shadow-focus;
//     outline: none;
// }

// .select-dropdown-info {
//     @include pv-20;
//     @include ph-28;
//     border-top: 1px solid;
//     @include opteo-border;
//     width: 100%;
// }

// .select-dropdown-empty-state {
//     padding: 2rem 2rem;
// }

// .select-value {
//     line-height: 1.125rem;
//     font-size: 0.875rem;
//     letter-spacing: -0.009375rem;
// }

// // Using display:none or visibility:hidden breaks the validation.
// // This set of styles hides it without taking up any space.
// .hidden-select {
//     height: 0;
//     width: 0;
//     padding: 0;
//     border-width: 0;
//     display: block;
//     -webkit-appearance: none;
//     -moz-appearance: none;
//     appearance: none;
//     opacity: 0;
// }
// .hidden-select:focus {
//     outline: none;
// }

</style>
