<template>
    <Scroller height="100dvh" grow>
        <div class="external-page-wrapper">
            <UserSidebarButton class="index-button" @click="goToRoot()">
                <OpteoLogo :width="36" :height="36" />
            </UserSidebarButton>
            <slot></slot>
            <Stack v-if="above640 && showLogoutButton" class="logout-button">
                <Button color="secondary" size="extra-small" @click="emit('logout')">Logout</Button>
            </Stack>
        </div>
    </Scroller>
</template>

<script setup lang="ts">
// Imports
import { PropType, computed } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { useLayouts } from '@/composition/layouts/useLayouts'

// Types
import { Dimension } from '@opteo/components-next'

// Components
import {
    OpteoLogo,
    Stack,
    useDimension,
    Button,
    useMediaQuery,
    Scroller,
} from '@opteo/components-next'
import UserSidebarButton from '@/components/user/UserSidebar/components/UserSidebarButton.vue'

// Props
const props = defineProps({
    maxWidth: {
        type: [Number, Array, String] as PropType<Dimension>,
        required: false,
        default: 300,
    },
    showLogoutButton: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
    },
})

// Emits
const emit = defineEmits(['logout'])

// Setup
const router = useRouter()
const { above640, above1024 } = useMediaQuery()
useLayouts('external')

// Computed
const computedMaxWidth = computed(() => {
    return useDimension(props.maxWidth)
})

// Functions
function goToRoot() {
    router.push({ name: Routes.Root })
}
</script>

<style scoped lang="scss">
.external-page-wrapper {
    margin: 0 auto;
    padding: 8rem 0;
    max-width: v-bind(computedMaxWidth);
    @include flex-center;
    @include direction-column;
    @include w-100;
}
.index-button {
    @include fixed;
    top: 0.625rem;
    left: 0.625rem;
}
.logout-button {
    @include fixed;
    bottom: 1.375rem;
    left: 1.375rem;
}
</style>
