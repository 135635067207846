<template>
    <Stack
        direction="column"
        :gap="0"
        :padding="22"
        align="start"
        width="100%"
        maxWidth="16.5rem"
        :borderRight="above1200 ? border : undefined"
        :borderBottom="above1200 ? undefined : border"
        grow
        height="100%"
        :maxHeight="272"
    >
        <Stack :width="32" :height="24" align="center" justify="start">
            <GoogleGLogo v-if="platform === 'google'" :size="24" />
            <MetaAdsLogo v-else-if="platform === 'meta'" :size="24" />
            <MicrosoftAdsLogo v-else-if="platform === 'microsoft'" :size="24" />
        </Stack>
        <Spacer :height="18" />
        <Text as="h6" size="headline-six" width="100%">Sign in with {{ platformString }}</Text>
        <Spacer :height="12" />
        <Text as="p" size="body-extra-small" color="foreground-minus-one">
            Connect your {{ platformString }} account to login to Opteo with one-click social
            sign-in.
        </Text>
        <Spacer :height="16" />
        <Button
            @click="handleClick"
            size="small"
            color="secondary"
            :iconAfter="!connected ? 'ArrowUpRight' : undefined"
            fullWidth
        >
            <template #before>
                <Stack :width="14" :height="14" align="center" justify="center">
                    <Image
                        v-if="connected"
                        :src="platformAvatarUrl"
                        :width="15"
                        :borderRadius="999"
                        :alt="`${platformString} avatar for ${platformUsername}`"
                        :aspectRatio="1"
                    />
                    <GoogleGLogo v-else-if="platform === 'google'" :size="12" />
                    <MetaAdsLogo v-else-if="platform === 'meta'" :size="12" />
                    <MicrosoftAdsLogo v-else-if="platform === 'microsoft'" :size="12" />
                </Stack>
            </template>
            <span v-if="!connected">Connect {{ platformString }}</span>
            <span v-else>{{ platformUsername }}</span>
            <template #after>
                <Dot v-if="connected" :size="10" />
            </template>
        </Button>
        <Modal v-model="showDisconnectModal" title="Disconnect Account" :width="420">
            <template #content>
                <Container :borderRadius="18" :padding="0">
                    <Stack
                        direction="row"
                        :gap="12"
                        :padding="[17, 20, 19, 20]"
                        width="100%"
                        borderBottom
                    >
                        <Image
                            v-if="connected"
                            :src="platformAvatarUrl"
                            :width="24"
                            :borderRadius="999"
                            :alt="`${platformString} avatar for ${platformUsername}`"
                            :aspectRatio="1"
                        />
                        <Text as="h6" size="headline-seven" width="100%">
                            {{ platformUsername }}
                        </Text>
                        <GoogleGLogo v-if="platform === 'google'" :size="16" />
                        <MetaAdsLogo v-else-if="platform === 'meta'" :size="16" />
                        <MicrosoftAdsLogo v-else-if="platform === 'microsoft'" :size="16" />
                    </Stack>
                    <Stack :padding="[16, 20]">
                        <Text as="p" size="body-small">
                            Please note, disconnecting your account will mean you can no longer use
                            it to login to Opteo. If you would like to use your account for
                            one-click social sign-in after disconnecting, you will have to reconnect
                            it.
                        </Text>
                    </Stack>
                </Container>
            </template>
            <template #buttons>
                <Button color="secondary" @click="showDisconnectModal = false"> Cancel </Button>
                <Button @click="showDisconnectModal = false" color="red" size="medium">
                    Disconnect Account
                </Button>
            </template>
        </Modal>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'
import capitalize from 'lodash-es/capitalize'

// Components
import {
    Stack,
    Text,
    Spacer,
    Button,
    Dot,
    Image,
    Modal,
    Container,
    GoogleGLogo,
    MetaAdsLogo,
    MicrosoftAdsLogo,
    useMediaQuery,
} from '@opteo/components-next'

// Props
const props = defineProps({
    platform: {
        type: String as PropType<string>,
        required: true,
        default: 'google',
    },
    connected: {
        type: Boolean as PropType<boolean>,
        required: true,
        default: false,
    },
    platformUsername: {
        type: String as PropType<string>,
        required: true,
        default: 'Guillaume Devinat',
    },
    platformAvatarUrl: {
        type: String as PropType<string>,
        required: true,
        default: 'https://staging-api.opteo.io/render/avatar.svg?userId=416',
    },
    border: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
    },
})

// Emits
const emit = defineEmits(['button-click'])

// Setup
const { above1200 } = useMediaQuery()
const showDisconnectModal = ref(false)

// Functions
const handleClick = () => {
    if (props.connected) {
        showDisconnectModal.value = true
    } else emit('button-click')
}

// Computed
const platformString = computed(() => {
    return capitalize(props.platform)
})
</script>

<style scoped lang="scss"></style>
