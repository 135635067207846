<template>
    <Container :borderRadius="22" :minWidth="304">
        <Stack v-if="loading" :padding="[18, 22]" direction="column" :gap="11">
            <Stack align="center" justify="between" :height="20">
                <Skeleton :height="10" :width="88" />
                <Skeleton :height="10" :width="48" />
            </Stack>
            <Stack align="center" justify="between" :height="20">
                <Skeleton :height="10" :width="72" />
                <Skeleton :height="10" :width="112" />
            </Stack>
            <Stack align="center" justify="between" :height="20">
                <Skeleton :height="10" :width="90" />
                <Skeleton :height="10" :width="56" />
            </Stack>
            <Stack align="center" justify="between" :height="20">
                <Skeleton :height="10" :width="112" />
                <Skeleton :height="10" :width="74" />
            </Stack>
        </Stack>
        <Stack v-else :padding="[18, 22]" direction="column" :gap="11">
            <!-- Account Status -->
            <Stack align="center" justify="between">
                <Text size="body-small" color="foreground-minus-one" :wrap="false">
                    Account Status
                </Text>
                <Text size="body-small" weight="semibold" :color="subscriptionStatusColor">
                    {{ accountStatus || cancellationState }}
                </Text>
            </Stack>
            <!-- Next Invoice/ Cancels on -->
            <Stack v-if="nextChargeDate || cancelsOn" align="center" justify="between">
                <Text
                    v-if="latestSubscription?.cancel_at_period_end"
                    size="body-small"
                    color="foreground-minus-one"
                    :wrap="false"
                >
                    Subscription End
                </Text>
                <Text
                    v-else-if="!accountStatus.includes('trial')"
                    size="body-small"
                    color="foreground-minus-one"
                    :wrap="false"
                >
                    Next Invoice
                </Text>
                <Text
                    v-else-if="accountStatus === 'trialing'"
                    size="body-small"
                    color="foreground-minus-one"
                    :wrap="false"
                >
                    First Invoice
                </Text>
                <Text size="body-small" weight="semibold" :wrap="false">
                    {{ nextChargeDate || cancelsOn }}
                </Text>
            </Stack>
            <!-- Invoice Amount -->
            <Stack v-if="nextInvoiceAmount" align="center" justify="between">
                <Text size="body-small" color="foreground-minus-one" :wrap="false">
                    Invoice Amount
                </Text>
                <Text size="body-small" weight="semibold" :wrap="false">
                    <Money :value="nextInvoiceAmount" :currency="currentPlanCurrency" />
                </Text>
            </Stack>
            <!-- Payment Method -->
            <Stack v-if="creditCard" align="center" justify="between">
                <Text size="body-small" color="foreground-minus-one" :wrap="false">
                    Payment Method
                </Text>
                <Stack :gap="8" justify="end">
                    <Text size="body-small" weight="semibold" :wrap="false">
                        **** {{ creditCard.last4 }}
                    </Text>
                    <component :size="16" :is="paymentMethodLogo" />
                </Stack>
            </Stack>
        </Stack>
        <Stack v-if="!loading" :padding="[22, 0]" justify="center" borderTop>
            <Button
                v-if="creditCard"
                color="secondary-highlight"
                size="small"
                @click="emit('update-payment-method')"
            >
                Update Payment Method
            </Button>
            <Button
                v-else
                color="secondary-highlight"
                size="small"
                @click="emit('add-payment-method')"
            >
                Add Payment Method
            </Button>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'
import { useSubscription } from '@/composition/billing/useSubscription'
import { useUser } from '@/composition/user/useUser'

// Components
import {
    Container,
    Text,
    Button,
    Stack,
    Skeleton,
    Money,
    // Payment Logos
    VisaLogo,
    MastercardLogo,
    MaestroLogo,
    AmexLogo,
    StripeLogo,
} from '@opteo/components-next'

// Emits
const emit = defineEmits(['update-payment-method', 'add-payment-method'])

// Setup
const { userType, nextChargeDate, coupon, loading, creditCard, latestSubscription, cancelsOn } =
    useSubscription()
const { currentPlan, currentPlanCurrency } = useUser()

// Computed
const paymentMethodLogo = computed(() => {
    if (!creditCard.value) {
        return null
    }

    const brand = creditCard.value?.brand?.toLowerCase() ?? 'Other Payment'
    const logos: Record<string, any> = {
        visa: VisaLogo,
        mastercard: MastercardLogo,
        maestro: MaestroLogo,
        amex: AmexLogo,
        stripe: StripeLogo,
    }

    return logos[brand] ?? null
})

const subscriptionStatusColor = computed(() => {
    if (latestSubscription.value?.status === 'trialing' && !creditCard.value?.last4) {
        return 'foreground'
    }
    if (
        latestSubscription.value?.status === 'active' &&
        creditCard.value?.last4 &&
        !userType.value.includes('update_cc')
    ) {
        return 'green'
    }

    if (
        latestSubscription.value?.status === 'canceled' ||
        latestSubscription.value?.cancel_at_period_end ||
        userType.value.includes('update_cc') ||
        !latestSubscription.value?.status
    ) {
        return 'red'
    }
})

const accountStatus = computed(() => {
    if (!latestSubscription.value) {
        return 'Cancelled'
    }

    if (latestSubscription.value.cancel_at_period_end) {
        return 'Set to Cancel'
    }

    if (latestSubscription.value.status === 'active') {
        return 'Active'
    }

    const subscription_status = userType.value.includes('update_cc')
        ? 'Past Due'
        : userType.value.charAt(0).toUpperCase() + userType.value.slice(1)

    return subscription_status
})

const cancellationState = computed(() => {
    return 'Cancelled'
})

const nextInvoiceAmount = computed(() => {
    // if canceled, return 0.
    if (userType.value.includes('cancel')) {
        return 0
    }

    const planPrice = currentPlan.value?.price || 0

    // if no coupon, return plan price.
    if (!coupon.value) {
        return planPrice
    }

    // if coupon specifies a fixed amount,
    // subtract it and ensure it doesn't go below 0.
    if (coupon.value.amount_off) {
        return Math.max(planPrice - coupon.value.amount_off / 100, 0)
    }

    // otherwise, assume a percentage discount.
    // if coupon.value.percent_off is not provided, default to 0%.
    const discountPercentage = coupon.value.percent_off || 0
    const discountMultiplier = 1 - discountPercentage / 100

    return planPrice * discountMultiplier
})
</script>

<style scoped lang="scss"></style>
