<template>
    <Money :value="value" :currency="currencyCode" :locale="locale" :compact="compact" />
</template>

<script lang="ts">
import { inject, computed, ComputedRef, defineComponent } from 'vue'
// import { Money } from '@opteo/components-next'
import { ProvideKeys } from '@/composition/useProvide'

export default defineComponent({
    name: 'oMoney',
    // components: { Money },
    props: {
        value: {
            type: Number,
            required: true,
        },
        currency: {
            type: String,
            required: false,
        },
        locale: {
            type: String,
            required: false,
        },
        compact: {
            type: Boolean,
            required: false,
        },
    },
    setup({ currency }) {
        const injected = inject<ComputedRef<string>>(ProvideKeys.CurrencyCode)

        const currencyCode = computed(() => {
            return currency ?? injected?.value
        })

        return { currencyCode }
    },
})
</script>
