<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        {{ alert?.title }}
    </Text>
    <Spacer height="1.25rem" />

    <!-- campaignsPaused -->
    <div v-if="status === OverBudgetAlert.CheckBudgetResults.CAMPAIGNS_HAVE_BEEN_PAUSED">
        <Text as="p" size="f-8">
            In order to prevent your account from exceeding its monthly budget, Opteo has paused all
            active campaigns. Campaigns are scheduled to resume spending on
            <b>{{ resumeSpending }}</b
            >. If you would like to restart spending now, adjust your <b>Monthly Budget</b>, update
            your <b>Acceptable Variance</b>, or click below.
        </Text>
        <Spacer height="1.5rem" />
        <oButton
            ref="reactivatePausedCampaignsButton"
            :loading="reactivatingCampaignsLoader"
            @clicked="reactivatePausedCampaigns()"
            >Reactivate Campaigns</oButton
        >
        <Spacer height="1rem" />
        <Text as="span" size="f-10" color="gray">
            Clicking this button will reactivate your campaigns immediately. Please adjust your
            budget settings to prevent these campaigns from being automatically paused again. Spend
            is checked against your budget <b>once per hour</b>.
        </Text>
    </div>

    <!-- campaignsEnabled -->
    <div v-if="status === OverBudgetAlert.CheckBudgetResults.CAMPAIGNS_HAVE_BEEN_ENABLED">
        <!-- Automatically re-enabled -->
        <Text v-if="isFirstOfMonth" as="p" size="f-8">
            To prevent your account from exceeding its monthly budget, Opteo paused all of your
            active campaigns. Today marks the start of a new month, so Opteo has reactivated your
            paused campaigns. To adjust your budget settings, click below.
        </Text>
        <Spacer v-if="isFirstOfMonth" height="1.5rem" />
        <router-link
            v-if="isFirstOfMonth"
            :to="domainSettingsRoute"
            @click="alertModalOpen = false"
        >
            <oButton>Budget Settings</oButton>
        </router-link>
        <!-- Manually re-enabled -->
        <Text v-else as="p" size="f-8">
            You recently updated your monthly budget — this means your campaigns can be reactivated
            because your monthly spend no longer exceeds your monthly budget.
        </Text>
    </div>

    <!-- riskOfOverBudget -->
    <div v-if="status === OverBudgetAlert.CheckBudgetResults.RISK_OF_OVER_BUDGET">
        <Text as="p" size="f-8">
            Opteo projects that you will exceed your monthly budget on
            <b>{{ predictedExceedBudgetDate }}</b
            >. On this date, any active campaigns will be paused to prevent overspend. To stop your
            campaigns from being paused, either adjust your <b>Monthly Budget</b> or update your
            <b>Acceptable Variance</b> by clicking the button below.
        </Text>
        <Spacer height="1.5rem" />
        <router-link :to="domainSettingsRoute" @click="alertModalOpen = false">
            <oButton>Budget Settings</oButton>
        </router-link>
    </div>

    <!-- lostWriteAccess -->
    <div v-if="status === OverBudgetAlert.CheckBudgetResults.LOST_WRITE_ACCESS">
        <Text as="p" size="f-8">
            Opteo no longer has write access to your account, meaning changes can no longer be
            pushed to Google Ads. This means that the
            <b>Pause Campaigns When Over Budget</b> feature has been disabled, and your campaigns
            will continue to run even when exceeding your monthly budget (<b>{{ monthlyBudget }}</b
            >).
            <Spacer height="1.5rem" />
            <div class="lost-write-access-container">
                <div class="opteo-logo-container">
                    <OpteoLogo />
                </div>
                <Spacer width="1.5rem" />
                <div class="warning-icon-container">
                    <WarnIcon style="transform: scale(1.8333)" />
                </div>
                <Spacer width="1.5rem" />
                <div class="gads-icon-container">
                    <GoogleAdsLogo />
                </div>
            </div>
        </Text>
    </div>

    <Spacer height="2rem" />

    <div
        v-if="
            status === OverBudgetAlert.CheckBudgetResults.RISK_OF_OVER_BUDGET ||
            status === OverBudgetAlert.CheckBudgetResults.CAMPAIGNS_HAVE_BEEN_PAUSED
        "
    >
        <div class="chart-container">
            <LineChart
                :series="chartData"
                :metric="{ dataType: 'money', currency: currencyCode }"
                :areas="chartAreas"
                :horizontal-markers="budgetMarker"
                :show-zero="true"
                :chart-height="180"
            >
                <template #tooltip="{ hoveredItem }">
                    <ChartTooltip
                        v-if="hoveredItem"
                        :plot-lines="generateTooltipPlotlines(hoveredItem)"
                    />
                </template>

                <template #markerText="{ markerInfo }">
                    <ColorTag color="red" :title="markerInfo?.label" :content="monthlyBudget" />
                </template>
            </LineChart>
        </div>
        <Spacer height="2rem" />
    </div>

    <div v-if="status !== OverBudgetAlert.CheckBudgetResults.LOST_WRITE_ACCESS">
        <Text
            as="p"
            size="f-8"
            v-if="status === OverBudgetAlert.CheckBudgetResults.CAMPAIGNS_HAVE_BEEN_PAUSED"
        >
            The following campaigns were tagged and paused:
        </Text>
        <Text
            as="p"
            size="f-8"
            v-if="status === OverBudgetAlert.CheckBudgetResults.CAMPAIGNS_HAVE_BEEN_ENABLED"
        >
            The following campaigns have been reactivated:
        </Text>
        <Text
            as="p"
            size="f-8"
            v-if="status === OverBudgetAlert.CheckBudgetResults.RISK_OF_OVER_BUDGET"
        >
            The following campaigns are scheduled to be paused:
        </Text>
        <Spacer height="1.25rem" />

        <oTable
            :headers="[
                {
                    key: 'campaign_label',
                    text: 'Campaign',
                    width: 270,
                },
                {
                    key: 'status',
                    text: 'Status',
                    width: 112,
                },
            ]"
            :items="tableItems"
            :fixed-layout="true"
            order-by="campaign_label"
            order="ASC"
        >
            <template #column.campaign_label="campaign">
                <Tooltip
                    placement="top-start"
                    :offset="[-10, 2]"
                    :content="campaign.value.length > 24 ? campaign.value : ''"
                    :max-width="600"
                >
                    <Text as="p" size="f-8" class="table-label-column">
                        <span class="table-label">
                            {{ campaign.value }}
                        </span>
                    </Text>
                </Tooltip>
            </template>
            <template #column.status="status">
                <Text as="p" size="f-8" :color="status.value === 'Paused' ? 'red' : 'black'">
                    {{ status.value }}
                </Text>
            </template>
        </oTable>
        <div
            v-if="
                numberOfVideoCampaigns > 0 &&
                status === OverBudgetAlert.CheckBudgetResults.CAMPAIGNS_HAVE_BEEN_PAUSED
            "
        >
            <Spacer height="1.25rem" />
            <Text as="span" size="f-10" color="gray">
                Opteo was unable to pause
                <b
                    >{{ numberOfVideoCampaigns }} YouTube campaign{{
                        numberOfVideoCampaigns > 1 ? 's' : ''
                    }}</b
                >.
            </Text>
        </div>
        <Spacer height="2rem" />
    </div>
    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { computed, defineComponent, Ref, ref } from 'vue'

// import {
//     Text,
//     LineChart,
//     Spacer,
//     Number,
//     Percent,
//     LineChartTypes,
//     ChartTooltip,
//     oButton,
//     oTable,
//     Tooltip,
//     OpteoLogo,
//     WarnIcon,
//     GoogleAdsLogo,
//     ColorTag,
//} from '@opteo/components-next'
import InfoIcon from '@/components/global/InfoIcon.vue'
import parseISO from 'date-fns/parseISO'
import formatDate from 'date-fns/format'
import endOfMonth from 'date-fns/endOfMonth'
import differenceInDays from 'date-fns/differenceInDays'
import { Account, OverBudgetAlert } from '@opteo/types'

import { Routes } from '@/router/routes'
import AlertNotes from '@/components/notes/AlertNotes.vue'
import Money from '@/components/global/oMoney.vue'
import { useAlert } from '@/composition/alerts/useAlert'

import { Endpoint, authRequest } from '@/composition/api/useAPI'

export default defineComponent({
    name: 'OverBudget',
    components: {

//         Text,
//         LineChart,
//         Spacer,
//         Number,
//         Money,
//         Percent,
//         AlertNotes,
//         ChartTooltip,
//         oButton,
//         oTable,
//         Tooltip,
//         OpteoLogo,
//         WarnIcon,
//         GoogleAdsLogo,
//         ColorTag,
//         InfoIcon,
    
},
    setup() {
        const { alert, currencyCode, alertModalOpen, accountId } = useAlert<OverBudgetAlert.Body>()

        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        const { body } = alert?.value
        const {
            monthly_budget,
            monthly_budget_raw,
            status,
            campaigns,
            date_above_budget_projection,
            enable_date,
            is_first_of_month,
        } = body

        const monthlyBudget = monthly_budget
        const resumeSpending = enable_date
        const isFirstOfMonth = is_first_of_month
        const predictedExceedBudgetDate = date_above_budget_projection
        let numberOfVideoCampaigns = 0

        const getStatusLabel = status => {
            switch (status) {
                case OverBudgetAlert.CampaignsUpdateStatus.PAUSED:
                    return 'Paused'
                    break
                case OverBudgetAlert.CampaignsUpdateStatus.ENABLED:
                    return 'Active'
                    break
                case OverBudgetAlert.CampaignsUpdateStatus.FAILED_ENABLE:
                    return 'Failed to enable'
                    break
                case OverBudgetAlert.CampaignsUpdateStatus.FAILED_PAUSE:
                    return 'Failed to pause'
                    break
                case OverBudgetAlert.CampaignsUpdateStatus.NO_ACTION_VIDEO:
                    return 'Video campaign'
                    break
                default:
                    return 'Active'
                    break
            }
        }

        const tableItems = campaigns
            ?.map(campaign => {
                const statusLabel = getStatusLabel(campaign.status)
                if (campaign.status === OverBudgetAlert.CampaignsUpdateStatus.NO_ACTION_VIDEO) {
                    numberOfVideoCampaigns++
                    return
                }
                return {
                    campaign_label: campaign.campaign_name,
                    status: statusLabel,
                }
            })
            .filter(c => c)

        /*
            body.categories: current month points (not used)
            body.data1: this month spend so far + projection
            body.points: previous month spend
        */
        const reactivatePausedCampaignsButton = ref()
        const isoDate = parseISO(alert.value?.ts)
        const month = formatDate(isoDate, 'MMMM')
        const percentMonth = alert.value?.body.month_percent / 100
        const date = formatDate(isoDate, 'MMMM io')
        const daysRemaining = differenceInDays(endOfMonth(isoDate), isoDate)
        const reactivatingCampaignsLoader = ref(false)
        const spend = alert.value?.body.spend
        const budget = alert.value?.body.budget
        const overspendPercent =
            (alert.value?.body.projected_spend - alert.value?.body.budget) /
            alert.value?.body.budget
        const underspendPercent =
            ((alert.value?.body.projected_spend - alert.value?.body.budget) /
                alert.value?.body.budget) *
            -1
        const currentOverspendPercent =
            (alert.value?.body.spend - alert.value?.body.budget) / alert.value?.body.budget
        const percentSpend = alert.value?.body.percent_spend / 100
        const projectedSpend = alert.value?.body.projected_spend

        const chartData = computed<LineChartTypes.LineChartSeries[]>(() => {
            const currentMonthPointsFilled = alert.value?.body.spend_points.map(
                ({ x, y, dotted }) => {
                    return { x, y, dotted }
                }
            )

            const currentMonth: LineChartTypes.LineChartSeries = {
                name: 'Current Month',
                items: currentMonthPointsFilled ?? [],
            }

            return [currentMonth]
        })

        const budgetMarker = computed(() => {
            return [
                {
                    label: 'Budget',
                    value: monthly_budget_raw,
                    color: 'red',
                },
            ]
        })

        const generateRedLineChartArea = computed(() => {
            const currentMonthPointsFilled = alert.value?.body.spend_points.find(
                point => point.y > alert.value?.body?.monthly_budget_raw
            )

            const start = currentMonthPointsFilled?.x
            const end = alert.value?.body.spend_points[alert.value?.body.spend_points.length - 1]?.x
            return { start, end }
        })

        const chartAreas = [
            {
                axis: 'x',
                start: new Date(generateRedLineChartArea.value.start),
                end: new Date(generateRedLineChartArea.value.end),
                color: 'red',
            },
        ]

        const reactivatePausedCampaigns = async () => {
            reactivatingCampaignsLoader.value = true
            await authRequest(Endpoint.ReactivatePausedCampaigns, {
                body: { account_id: accountId.value }, // Backend expects a domain_id, will be automatically tranlated in retrieve.ts
            })

            reactivatingCampaignsLoader.value = false
            reactivatePausedCampaignsButton.value.flashSuccess()
        }

        const generateTooltipPlotlines = (
            hoveredItems: { displayYValue: Ref<string>; x: Date; dotted: boolean; color: string }[]
        ) => {
            if (!hoveredItems) return

            const tooltips: { label: string; value: string; dotted: boolean; color: string }[] = []
            hoveredItems.forEach((item, index) => {
                if (!item) return

                const label = formatDate(new Date(item.x), 'do MMM')
                const value = item.displayYValue.value
                const dotted = item.dotted
                const color = item.color

                tooltips.push({ label, value, dotted, color })
            })

            return tooltips
        }

        const domainSettingsRoute = computed(() => {
            return {
                name: Routes.DomainSettings,
                params: {
                    accountId: accountId.value,
                    scroll_position: 'update-budget',
                },
            }
        })

        return {
            alert,
            month,
            percentMonth,
            date,
            daysRemaining,
            spend,
            budget,
            overspendPercent,
            currentOverspendPercent,
            underspendPercent,
            percentSpend,
            projectedSpend,
            chartData,
            isoDate,
            budgetMarker,
            chartAreas,
            generateTooltipPlotlines,
            currencyCode,
            monthlyBudget,
            resumeSpending,
            isFirstOfMonth,
            predictedExceedBudgetDate,
            tableItems,
            OverBudgetAlert,
            status,
            domainSettingsRoute,
            alertModalOpen,
            reactivatePausedCampaigns,
            reactivatingCampaignsLoader,
            reactivatePausedCampaignsButton,
            numberOfVideoCampaigns,
        }
    },
})
</script>
<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .btn-tooltip-container {
// //     @include flex;
// //     @include items-center;
// // }
// // .chart-container {
// //     @include container;
// //     @include pa-24;
// // }
// // .table-label-column {
// //     @include relative;
// //     overflow: hidden;
// // }
// // .table-label-column .table-label::after {
// //     content: '';
// //     background: linear-gradient(270deg, #fff 0.5rem, rgba(255, 255, 255, 0) 100%);
// //     @include absolute;
// //     top: 0;
// //     right: -10px;
// //     width: 3rem;
// //     bottom: 0;
// // }

// // .lost-write-access-container {
// //     @include container;
// //     @include pa-48;
// //     @include w-100;
// //     @include flex-center;
// // }
// // .opteo-logo-container,
// // .gads-icon-container,
// // .warning-icon-container {
// //     @include flex-center;
// //     @include container;
// //     @include bg-opteo-white;
// //     @include pa-16;
// //     @include br-999;
// // }

// // .opteo-logo-container,
// // .gads-icon-container {
// //     width: 4rem;
// //     height: 4rem;
// // }
// // .warning-icon-container {
// //     width: 5rem;
// //     height: 5rem;
// // }


</style>
