// Imports
import { createApp } from 'vue'
import { createHead } from '@unhead/vue'

import pinchPrevent from './plugins/pinchPrevent'

import { LOAD_GTM, LOAD_SENTRY, SENTRY_ENV } from '@/lib/env'

import initialiseGtm from '@/lib/gtm'
import initialiseSentry from '@/lib/sentry'

import App from './App.vue'

// CSS
import '@opteo/components-next/css'
import '@/assets/style/style.scss'

// Fonts
import '@/assets/style/fonts/inter-variable.css'
import '@/assets/style/fonts/aeonik-mono-vf.css'

// Globally available `layout` components
import PageWrapper from '@/components/layout/internalPages/PageWrapper.vue'
import { PageHeader } from '@opteo/components-next'
import PageContent from '@/components/layout/internalPages/PageContent.vue'

// Router
import router from './router'

// Components
import OpteoComponents from '@opteo/components-next'

// Plugins

// Gesture
import { GesturePlugin } from '@vueuse/gesture'

// CSS Paint API + Polyfill
import cssPaintPolyfill from './worklets/css-paint-polyfill.js?url'
import squircle from './worklets/squircle.js?url'
async function setupPaintWorklet() {
    if (Object.prototype.hasOwnProperty.call(CSS, 'paintWorklet')) {
        // @ts-ignore -> Allows paintWorklet to work in .ts
        CSS.paintWorklet.addModule(squircle)
    } else {
        await import(/* @vite-ignore */ cssPaintPolyfill)
        // @ts-ignore -> Allows paintWorklet to work in .ts
        CSS.paintWorklet.addModule(squircle)
    }
}
setupPaintWorklet()

// Init
const startApp: () => void = async () => {
    if (LOAD_GTM) {
        initialiseGtm()
    }

    const app = createApp(App)

    const head = createHead()

    if (LOAD_SENTRY) {
        initialiseSentry(app, router, SENTRY_ENV)
    }

    app.use(router)
    app.use(OpteoComponents)
    app.use(GesturePlugin)
    app.use(head)
    app.use(pinchPrevent)

    // Globally available `layout` components
    app.component('PageWrapper', PageWrapper)
    app.component('PageHeader', PageHeader)
    app.component('PageContent', PageContent)

    app.mount('#app')
}

// Run
startApp()
