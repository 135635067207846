<template>
    <ClickableRow
        :padding="above480 ? [0, 24, 0, 24] : [0, 18, 0, 18]"
        @click="openAlert"
        ref="alertRow"
    >
        <Stack width="100%" height="100%" justify="between">
            <Stack
                align="center"
                :gap="16"
                :height="above480 ? '4.25rem' : '4.125rem'"
                class="left"
            >
                <!-- Avatar -->
                <Avatar
                    v-if="alert?.id"
                    :color="mapHexToAvatarColor(alert?.accountColour)"
                    :platform="platform"
                    show-account-type
                >
                    {{ alert?.accountInitials }}
                </Avatar>
                <!-- Skeleton -->
                <Skeleton v-else :width="28" :height="28" />

                <!-- Alert -->
                <Stack
                    v-if="alert?.id"
                    direction="row"
                    width="100%"
                    :gap="16"
                    :justify="above768 ? 'between' : 'start'"
                    align="center"
                >
                    <Stack
                        direction="column"
                        align="start"
                        :gap="5"
                        :width="above768 ? '100%' : 'auto'"
                        :padding="[0, 0, 0, 0]"
                    >
                        <Text as="h6" size="headline-seven" :wrap="false">{{ alert.title }}</Text>
                        <Text as="p" size="label-small" color="foreground-minus-two" :wrap="false">
                            {{ lastUpdated }} in
                            <b>{{ alert.accountName }}</b>
                            <b v-if="alert.isCampaignLevel"></b>
                        </Text>
                    </Stack>
                    <EntityPill
                        type="Campaign"
                        v-if="alert.isCampaignLevel"
                        :label="alert?.body?.campaign"
                        size="small"
                    />
                    <EntityPill type="AccountLevel" v-else label="Account Level" size="small" />
                </Stack>
                <!-- Skeleton -->
                <Stack
                    v-else
                    direction="row"
                    width="100%"
                    :gap="0"
                    justify="between"
                    align="center"
                >
                    <Stack direction="column" :gap="5" align="start" :padding="[0, 0, 0, 0]">
                        <Stack :height="15" width="auto">
                            <Skeleton
                                :width="Math.floor(Math.random() * 32 + 100)"
                                maxWidth="100%"
                                :height="10"
                            />
                        </Stack>

                        <Stack :height="12" width="auto">
                            <Skeleton
                                :width="Math.floor(Math.random() * 32 + 64)"
                                maxWidth="100%"
                                :height="8"
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
            <!-- Status -->
            <Dot v-if="alert?.id" :color="alert.checked ? 'gray' : 'red'" :size="8" />
            <!-- Skeleton -->
            <Skeleton v-else :width="8" :height="8" />
        </Stack>
    </ClickableRow>
</template>

<script setup lang="ts">
// Imports
import { computed, PropType, ref } from 'vue'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { Routes } from '@/router/routes'

// Composition
import { setFloatingAlertId } from '@/composition/alerts/useAlert'
import { useAlerts } from '@/composition/alerts/useAlerts'
import { mapHexToAvatarColor } from '@/composition/utils/utils'

// Types
import { Alert, Platform } from '@opteo/types'

// Components
import {
    useShortKey,
    Avatar,
    Skeleton,
    Stack,
    Text,
    useMediaQuery,
    EntityPill,
    Dot,
    ClickableRow,
} from '@opteo/components-next'

// Props
const props = defineProps({
    alert: { type: Object as PropType<Alert.Object> },
})

// Setup
const alertRow = ref()
const { alertModalOpen } = useAlerts()
const { above480, above768 } = useMediaQuery()

// Computed
const lastUpdated = computed(() =>
    formatDistanceToNow(parseISO(props.alert?.ts as string), { addSuffix: true })
)
const platform = computed(() => props.alert?.platform ?? Platform.Platform.GoogleAds)

// Functions
function openAlert() {
    alertModalOpen.value = false
    setFloatingAlertId(props.alert?.hashId!)
}
useShortKey({
    keys: ['enter'],
    onPressCallBack: () => openAlert(),
    eventTarget: alertRow.value,
})
</script>

<style scoped lang="scss">
@media screen and (max-width: $mq-768) {
    .left {
        @include overflow-hidden;
        @include relative;
        mask-image: linear-gradient(to left, rgba(0, 0, 0, 0) 1rem, rgba(0, 0, 0, 1) 4rem);
    }
}
</style>
