<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        {{ alert?.title }}
    </Text>
    <Spacer height="1.5rem" />
    <Text as="p" size="f-8">
        Your scheduled report is ready. Your report covers the date range between
        <b>{{ fromDate }}</b> and <b>{{ toDate }}</b
        >. Click the button below to open your report and start making adjustments.
    </Text>
    <Spacer height="1.25rem" />
    <!-- go to new page AND close modal -->
    <router-link :to="reportLink" @click="alertModalOpen = false">
        <oButton color="white" size="medium">Open Scheduled Report</oButton>
    </router-link>
    <Spacer height="2rem" />
    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
// import { Text, Spacer, oButton } from '@opteo/components-next'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import { ScheduledReportAlert, Account } from '@opteo/types'

import AlertNotes from '@/components/notes/AlertNotes.vue'
import { useAlert } from '@/composition/alerts/useAlert'
import { Routes } from '@/router/routes'

export default defineComponent({
    name: 'Flatline',
    components: {

//         AlertNotes,
//         Text,
//         Spacer,
//         oButton,
    
},
    setup() {
        const { alert, alertModalOpen, accountId } = useAlert<ScheduledReportAlert.Body>()

        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        const isoDate = parseISO(alert.value.ts)
        const fromDate = format(parseISO(alert.value.body.from_date), 'MMMM do')
        const toDate = format(parseISO(alert.value.body.to_date), 'MMMM do')

        const reportLink = computed(() => {
            return {
                name: Routes.ReportActive,
                params: {
                    accountId: accountId.value,
                    reportId: alert.value?.body.new_report_id,
                },
            }
        })

        return {
            alert,
            fromDate,
            toDate,
            reportLink,
            isoDate,
            alertModalOpen,
        }
    },
})
</script>
