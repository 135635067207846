<template>
    <LinkedAccountsSkeletonTable v-if="teamAccountsLoading" />
    <oTable
        v-else-if="filteredLinkedAccounts.length > 0"
        ref="linkedAccountsTable"
        :headers="linkedTableHeaders"
        :items="filteredLinkedAccounts"
        :per-page="24"
        pagination-method="intersection"
        :fixed-layout="true"
        :border-radius="24"
        sidebarAdjust
        order-by="spend30d"
        order="DESC"
    >
        <template #header.name>
            <Spacer width="1.875rem" />
            <Tooltip v-if="aboveMobile" content="Select All" placement="top">
                <div
                    v-if="updatingLinkedAccounts && allLinkedAccountsSelected"
                    class="spinner-container"
                >
                    <Spinner />
                </div>
                <Checkbox
                    v-else
                    :model-value="allLinkedAccountsSelected"
                    :inderminate="someLinkedAccountsSelected"
                    @click="selectAllAccounts(true)"
                />
            </Tooltip>
            <Spacer width="1.25rem" />
            <Text size="f-8" weight="500">Account Name</Text>
        </template>
        <template #header.availableConnections>
            <Text size="f-8" weight="500">
                Changelog Email
                <Tooltip
                    :content="`The email selected in this column will be shown in Change History in ${platformWord} Ads whenever a change is made in Opteo.`"
                    :max-width="260"
                    :delay="[400, 0]"
                >
                    <InfoIcon
                        class="tooltip-info-icon"
                        style="margin-left: 0.375rem; transform: translateY(0.125rem)"
                    />
                </Tooltip>
            </Text>
        </template>
        <template #column.name="items">
            <LinkedAccountsAccountName :account="items.row" :linked="true" />
        </template>
        <template #column.spend30d="{ value, row }">
            <LinkedAccountsSpendCell :value="value" :account="row" :linked="true" />
        </template>
        <template #column.connectionStatus="{ row }">
            <LinkedAccountsConnectionStatusCell :account="row" />
        </template>
        <template #column.availableConnections="{ row }">
            <div class="changelog-email-cell">
                <LinkedAccountsChangelogSelect
                    :available-connections="row.availableConnections"
                    :account-id="row.accountId"
                />
            </div>
        </template>
        <template #column.lastLogin="{ value, row }">
            <LinkedAccountsTimeDistanceCell :value="value" :account="row" :linked="true" />
        </template>
        <template #column.teamMembers="{ row }">
            <LinkedAccountsTeamMembers :account="row" :is-linked="true" />
        </template>
    </oTable>

    <!-- All accounts are unlinked -->
    <div v-else-if="linkedAccountsCount === 0" class="empty-state-container">
        <div class="empty-state-text" v-if="platform === Platform.Platform.GoogleAds">
            You currently have no accounts linked to Opteo. You can import an account from Google
            Ads using the <b>Import Google Ads MCC</b> button, or head to the <b>Unlinked</b> tab to
            link an existing account.
        </div>
        <div class="empty-state-text" v-if="platform === Platform.Platform.MicrosoftAds">
            You currently have no accounts linked to Opteo. You can import accounts from Microsoft
            Ads with the <b>Import Microsoft Ads MCC</b> button, or head to the <b>Unlinked</b> tab
            to link an existing account.
        </div>
        <div class="empty-state-text" v-if="platform === Platform.Platform.MetaAds">
            You currently have no accounts linked to Opteo. You can import accounts from Meta Ads
            with the <b>Import Meta Ads Accounts</b> button, or head to the <b>Unlinked</b> tab link
            an existing account.
        </div>
        <div class="empty-state-text" v-if="platform === Platform.Platform.LinkedInAds">
            You currently have no accounts linked to Opteo. You can import accounts from LinkedIn
            with the <b>Import LinkedIn Ads Accounts</b> button, or head to the <b>Unlinked</b> tab
            link an existing account.
        </div>
    </div>

    <!-- There are linked accounts but the search query doesn't match any accounts -->
    <div v-else class="empty-state-container">
        <Text as="h2" size="f-2">
            {{ searchedLinkedAccounts }}
        </Text>
        <div class="empty-state-text">No accounts matching search query.</div>
    </div>
</template>

<script setup lang="ts">
// import { oTable, Checkbox, Spacer, Text, Tooltip, Spinner } from '@opteo/components-next'
import InfoIcon from '@/components/global/InfoIcon.vue'
import LinkedAccountsAccountName from '@/components/linkedAccounts/LinkedAccountsAccountName.vue'
import LinkedAccountsSkeletonTable from '@/components/linkedAccounts/LinkedAccountsSkeletonTable.vue'
import LinkedAccountsTeamMembers from '@/components/linkedAccounts/LinkedAccountsTeamMembers.vue'
import LinkedAccountsChangelogSelect from '@/components/linkedAccounts/LinkedAccountsChangelogSelect.vue'
import LinkedAccountsSpendCell from '@/components/linkedAccounts/LinkedAccountsSpendCell.vue'
import LinkedAccountsTimeDistanceCell from '@/components/linkedAccounts/LinkedAccountsTimeDistanceCell.vue'
import LinkedAccountsConnectionStatusCell from '@/components/linkedAccounts/LinkedAccountsConnectionStatusCell.vue'

import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { Platform } from '@opteo/types'

const {
    linkedTableHeaders,
    allLinkedAccountsSelected,
    someLinkedAccountsSelected,
    selectAllAccounts,
    filteredLinkedAccounts,
    linkedAccountsCount,
    teamAccountsLoading,
    searchedLinkedAccounts,
    updatingLinkedAccounts,
    platform,
    platformWord,
} = useLinkedAccounts()

const { aboveMobile } = useMediaQuery()
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // // Empty States
// // .empty-state-container {
// //     @include container;
// //     @include br-24;
// //     @include flex-center;
// //     flex-direction: column;
// //     gap: 1.5rem;
// //     width: 100%;
// //     padding: 8rem 0;
// // }

// // .empty-state-text {
// //     @include f-8;
// //     @include lh-150;
// //     @include tc;
// //     width: 26rem;
// // }


</style>
