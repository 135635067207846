<template>
    <ImprovementRowContainer :improvement="improvement" @improvement-opened="openImprovement">
        <template #icon>
            <CheckIcon small color="gray" />
        </template>
        <template #buttons>
            <Menu
                buttonSize="small"
                placement="bottom-end"
                :offset="[4, 6]"
                :items="[
                    {
                        value: 'openImprovement',
                        label: 'Open Improvement',
                        icon: 'Overlays',
                        action: () => openImprovement(),
                    },
                    // {
                    //     value: 'createAutomation',
                    //     label: 'Create Automation',
                    //     icon: 'Sparkle',
                    //     action: () => console.log('Created Automation'),
                    // },
                    {
                        value: 'shareImprovement',
                        label: 'Share Improvement',
                        icon: 'Link',
                        action: () => console.log('Shared Improvement'),
                    },
                ]"
            />
        </template>
    </ImprovementRowContainer>
</template>
<script setup lang="ts">
// Imports
import { PropType } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Types
import { Improvement } from '@opteo/types'

// Components
import { Menu, CheckIcon } from '@opteo/components-next'
import ImprovementRowContainer from '@/components/improvement/ImprovementRowContainer.vue'

// Props
const props = defineProps({
    improvement: {
        required: true,
        type: Object as PropType<Improvement.Object>,
    },
})

// Setup
const router = useRouter()

// Functions
const openImprovement = () => {
    const improvementId = props.improvement.improvement_id

    router.push({
        name: Routes.CompletedImprovement,
        params: { improvementId },
    })
}
</script>
<style scoped lang="scss">
// .dark .completed-check :deep(svg circle) {
//     fill: $dark-background-plus-three !important;
// }
</style>
