<template>
    <Stack
        direction="column"
        align="center"
        :borderBottom="showUpdateButton"
        :gap="14"
        :padding="[20, 22, 18, 22]"
    >
        <Select
            :model-value="selectedPerformanceMode"
            @updated="handleSelectUpdated"
            :options="performanceModeItems"
            name="performanceMode"
            label="Performance Mode"
        />
        <Text as="p" size="body-extra-small" color="foreground-minus-one">
            Select the performance mode (ROAS/CPA) best suited to your account. Opteo will display
            your performance data and generate improvements based on your chosen performance mode.
        </Text>
    </Stack>
    <Stack v-if="showUpdateButton" align="center" justify="center" :padding="[22, 0]">
        <Button
            ref="updatePerformanceModeButton"
            :loading="updatingPerformanceMode"
            @click="updatePerformanceMode"
            color="secondary-highlight"
            size="small"
        >
            Update Performance Mode
        </Button>
    </Stack>
</template>

<script lang="ts" setup>
// Imports
import { ref, computed, watch } from 'vue'

// Types
import { Targets } from '@opteo/types'

// Composition
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useAccount } from '@/composition/account/useAccount'

// Components
import { Stack, Select, Button, Text, showSnackbar } from '@opteo/components-next'

// Props
defineProps<{ showUpdateButton?: boolean }>()

// Setup
const { performanceMode, mutateAccount } = useAccount()
const updatedPerformanceMode = ref(
    performanceMode.value !== undefined ? performanceMode.value : Targets.PerformanceMode.CPA
)
const selectedPerformanceMode = computed(() => updatedPerformanceMode.value)
const performanceModeItems = [
    {
        label: 'Cost Per Conversion (CPA)',
        value: Targets.PerformanceMode.CPA,
    },
    {
        label: 'Return On Ad Spend (ROAS)',
        value: Targets.PerformanceMode.ROAS,
    },
]
const updatingPerformanceMode = ref(false)

// Watch
watch(performanceMode, newVal => {
    if (newVal !== undefined) {
        updatedPerformanceMode.value = newVal
    }
})

// Functions
function handleSelectUpdated(newValue: number) {
    updatedPerformanceMode.value = newValue
}

async function updatePerformanceMode() {
    if (performanceMode.value === updatedPerformanceMode.value) {
        const selectedMode =
            performanceModeItems.find(item => item.value === updatedPerformanceMode.value) ||
            performanceModeItems[0]
        showSnackbar({
            message: `No changes made`,
        })
        return
    }

    updatingPerformanceMode.value = true

    try {
        await authRequest(Endpoint.UpdatePerformanceMode, {
            body: { performance_mode: updatedPerformanceMode.value },
        })

        await mutateAccount()

        const selectedMode =
            performanceModeItems.find(item => item.value === updatedPerformanceMode.value) ||
            performanceModeItems[0]

        showSnackbar({
            message: `Performance Mode set to <b>${selectedMode.label}</b>`,
        })
    } catch (error) {
        console.error('Error updating performance mode:', error)
    } finally {
        updatingPerformanceMode.value = false
    }
}

// defineExpose
defineExpose({ updatePerformanceMode })
</script>
