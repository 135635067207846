<template>
    <div
        v-if="!double"
        :class="[{ small: small }, 'rsa-button', 'single']"
        :style="maxWidth ? 'max-width: ' + maxWidth + 'px' : ''"
        tabindex="0"
    >
        <div class="icon-slot">
            <slot></slot>
        </div>
        <Text v-if="value" as="p" size="f-8" class="text-slot">
            {{ value }}
        </Text>
    </div>
    <div v-else :style="maxWidth ? 'max-width: ' + maxWidth + 'px' : ''" class="rsa-button double">
        <div
            class="left"
            :class="leftDisabled ? 'disabled' : ''"
            :tabindex="leftDisabled ? -1 : 0"
            @click="$emit('left-clicked')"
        >
            <div class="icon-slot">
                <slot name="left"></slot>
            </div>
            <Text v-if="leftValue" as="p" size="f-8" class="text-slot">
                {{ leftValue }}
            </Text>
        </div>
        <div class="divider" />
        <div
            class="right"
            :class="rightDisabled ? 'disabled' : ''"
            :tabindex="rightDisabled ? -1 : 0"
            @click="$emit('right-clicked')"
        >
            <div class="icon-slot">
                <slot name="right"></slot>
            </div>
            <Text v-if="rightValue" as="p" size="f-8" class="text-slot">
                {{ rightValue }}
            </Text>
        </div>
    </div>
</template>

<script lang="ts">
// import { Text } from '@opteo/components-next'

export default {
    name: 'RsaButton',
    // components: { Text },
    props: {
        value: {
            type: String,
            required: false,
        },
        leftValue: {
            type: String,
            required: false,
        },
        rightValue: {
            type: String,
            required: false,
        },
        small: {
            type: Boolean,
            default: false,
            required: false,
        },
        double: {
            type: Boolean,
            default: false,
            required: false,
        },
        leftDisabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        rightDisabled: {
            type: Boolean,
            default: false,
            required: false,
        },
        maxWidth: {
            type: Number,
            default: 256,
        },
    },
    emits: ['left-clicked', 'right-clicked'],
}
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // // Base
// // .rsa-button {
// //     display: inline-flex;
// //     align-items: center;
// //     justify-content: center;
// //     border: none;
// //     text-decoration: none;
// //     font-family: inherit;
// //     cursor: pointer;
// //     transition:
// //         transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
// //     -webkit-appearance: none;
// //     -moz-appearance: none;
// //     border-radius: 0.625rem;
// //     position: relative;
// //     font-weight: 500;
// //     user-select: none;
// //     -webkit-user-select: none;
// //     @include opteo-foreground;
// //     box-shadow: $opteo-shadow;
// //     padding: 0.625rem 0.6875rem;
// // }
// // .rsa-button.small {
// //     width: 2rem;
// //     height: 2rem;
// //     padding: 0;
// //     border-radius: 0.375rem;
// // }
// // .rsa-button.single:focus {
// //     outline: none;
// //     box-shadow: $opteo-shadow-focus;
// // }
// // .rsa-button.single:active {
// //     outline: none;
// //     box-shadow: $opteo-shadow-focus;
// //     transform: translate3d(0, 1px, 0);
// // }
// // .rsa-button .icon-slot {
// //     width: 1.5rem;
// //     height: 1.5rem;
// //     @include flex-center;
// // }
// // .rsa-button .text-slot {
// //     @include ml-8;
// //     white-space: nowrap;
// //     text-overflow: ellipsis;
// //     overflow: hidden;
// // }

// // // Double
// // .rsa-button.double {
// //     padding: 0;
// // }
// // .rsa-button.double .left,
// // .rsa-button.double .right {
// //     padding: 0.625rem 0.6875rem;
// //     border-radius: 0.5rem;
// //     transition:
// //         transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
// //     -webkit-appearance: none;
// //     -moz-appearance: none;
// //     @include flex;
// //     @include items-center;
// // }
// // .rsa-button.double .divider {
// //     width: 0.0625rem;
// //     height: 2.75rem;
// //     background-color: $opteo-light-gray;
// // }
// // .rsa-button.double .left:focus,
// // .rsa-button.double .right:focus {
// //     outline: none;
// //     box-shadow: $opteo-shadow-focus-ring;
// //     transform: translate3d(0, 0, 0);
// // }
// // .rsa-button.double .left:active,
// // .rsa-button.double .right:active {
// //     outline: none;
// //     box-shadow: $opteo-shadow-focus-ring;
// //     transform: translate3d(0, 0, 0);
// // }
// // .rsa-button.double .left .icon-slot,
// // .rsa-button.double .right .icon-slot,
// // .rsa-button.double .left .text-slot,
// // .rsa-button.double .right .text-slot {
// //     transition:
// //         transform 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         background 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         box-shadow 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         opacity 0.25s cubic-bezier(0.19, 1, 0.22, 1),
// //         color 0.25s cubic-bezier(0.19, 1, 0.22, 1);
// //     -webkit-appearance: none;
// //     -moz-appearance: none;
// // }
// // .rsa-button.double .left:focus .icon-slot,
// // .rsa-button.double .right:focus .icon-slot {
// //     transform: translate3d(0, 0, 0);
// // }
// // .rsa-button.double .left:active .icon-slot,
// // .rsa-button.double .right:active .icon-slot {
// //     transform: translate3d(0, 1px, 0);
// // }

// // .rsa-button.double .left .text-slot,
// // .rsa-button.double .right .text-slot {
// //     font-weight: 500;
// //     font-size: 0.8125rem;
// //     letter-spacing: -0.0125rem;
// //     transform: translate3d(0, -1px, 0);
// // }
// // .rsa-button.double .left:focus .text-slot,
// // .rsa-button.double .right:focus .text-slot {
// //     transform: translate3d(0, -1px, 0);
// // }
// // .rsa-button.double .left:active .text-slot,
// // .rsa-button.double .right:active .text-slot {
// //     transform: translate3d(0, 0, 0);
// // }

// // // Double disabled states
// // .rsa-button.double .left.disabled,
// // .rsa-button.double .right.disabled {
// //     cursor: default;
// //     opacity: 0.24;
// // }
// // .rsa-button.double .left.disabled .icon-slot,
// // .rsa-button.double .right.disabled .icon-slot {
// //     // opacity: 0.24;
// // }
// // .rsa-button.double .left.disabled:focus,
// // .rsa-button.double .right.disabled:focus {
// //     outline: none;
// //     box-shadow: none;
// //     transform: translate3d(0, 0, 0);
// // }
// // .rsa-button.double .left.disabled:active,
// // .rsa-button.double .right.disabled:active {
// //     outline: none;
// //     box-shadow: none;
// //     transform: translate3d(0, 0, 0);
// // }
// // .rsa-button.double .left.disabled:focus .icon-slot,
// // .rsa-button.double .right.disabled:focus .icon-slot {
// //     transform: translate3d(0, 0, 0);
// // }
// // .rsa-button.double .left.disabled:active .icon-slot,
// // .rsa-button.double .right.disabled:active .icon-slot {
// //     transform: translate3d(0, 0, 0);
// // }


</style>
