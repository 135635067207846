<template>
    <ImprovementRowContainer
        :improvement="improvement"
        :isInteractive="isInteractive"
        @improvement-opened="openImprovement"
    >
        <template #icon>
            <ImprovementBatchIcon
                :improvement="improvement"
                :queued-improvement="queuedImprovement"
                :batch-queue-running="batchQueueRunning"
            />
        </template>
        <template #buttons>
            <Menu
                :buttonSize="above480 ? 'small' : 'extra-small'"
                placement="bottom-end"
                :offset="[4, 6]"
                :items="[
                    {
                        value: 'openImprovement',
                        label: 'Open Improvement',
                        icon: 'Overlays',
                        action: () => openImprovement(),
                    },
                    // {
                    //     value: 'createAutomation',
                    //     label: 'Create Automation',
                    //     icon: 'Sparkle',
                    //     action: () => console.log('Created Automation'),
                    // },
                    {
                        value: 'shareImprovement',
                        label: 'Share Improvement',
                        icon: 'Link',
                        action: () => console.log('Shared Improvement'),
                    },
                    {
                        value: 'dismissWeek',
                        label: 'Dismiss for 1 Week',
                        icon: 'Circle',
                        action: () => dismiss('week'),
                    },
                    {
                        value: 'dismissMonth',
                        label: 'Dismiss for 1 Month',
                        icon: 'Calendar',
                        action: () => dismiss('month'),
                    },
                    {
                        value: 'dismissIndefinitely',
                        label: 'Dismiss Indefinitely',
                        icon: 'Error',
                        action: () => dismiss('forever'),
                    },
                ]"
            />
        </template>
    </ImprovementRowContainer>
</template>

<script setup lang="ts">
// Imports
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useActiveImprovements } from '@/composition/improvement/useActiveImprovements'
import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import type { DismissDuration, EnhancedImprovement } from '@/composition/improvement/types'

// Components
import { Menu, useMediaQuery } from '@opteo/components-next'
import ImprovementBatchIcon from '@/components/improvement/ImprovementBatchIcon.vue'
import ImprovementRowContainer from '@/components/improvement/ImprovementRowContainer.vue'

// Props
type Props = {
    improvement: EnhancedImprovement
    queuedImprovement: QueuedImprovement | undefined
    queueRunning: boolean
    batchQueueRunning: boolean
}
const props = defineProps<Props>()

// Setup
const router = useRouter()
const { dismissImprovementWithFeedBack } = useActiveImprovements()
const { above480 } = useMediaQuery()

// Functions
const openImprovement = () => {
    const improvementId = props.improvement.improvement_id

    router.push({
        name: Routes.Improvement,
        params: { improvementId },
    })
}

const dismiss = (length: DismissDuration) => {
    dismissImprovementWithFeedBack(props.improvement.improvement_id, length)
}

// Computed
// if false, the improvment will no longer appear in the active tab
// after the batch queue has run
const isActive = computed(
    () => !['pushed', 'dismissed'].includes(props.queuedImprovement?.state ?? '')
)

const isInteractive = computed(() => {
    /*
        The row should not be clickable if:
        - it's in a running queue (batch or standard)
        -- or --
        - it's in a done queue & pushed/dismissed
    */
    if (!props.queuedImprovement) {
        return true
    }

    if (props.batchQueueRunning) {
        return false
    }

    if (props.queueRunning && props.queuedImprovement.pushingFrom === 'improvementView') {
        return false
    }

    return isActive.value
})
</script>

<style scoped lang="scss"></style>
