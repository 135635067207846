import sortBy from 'lodash-es/sortBy'
import { Account, Domain, Team, User } from '@opteo/types'
import some from 'lodash-es/some'
import includes from 'lodash-es/includes'

export enum SortBy {
    Name = 'domain_name',
    TasksAvailable = 'tasks_available',
    Spend = '30d_cost_usd',
    Conversions = '30d_conv',
    Issues = 'issues_available',
    Budget = 'budget_sort_order_value',
    Goal = 'goal_status',
    LastActive = 'last_active',
}

export function sortAccountList(
    accounts: (Account.Info & { budgetBar: Domain.BudgetBar })[],
    sortField: SortBy
) {
    return sortBy(accounts, account => {
        if (sortField === SortBy.Name) return account.name

        if (sortField === SortBy.Spend) return -(account?.costUSD30days ?? 0)

        if (sortField === SortBy.TasksAvailable) return -account.tasksAvailable

        if (sortField === SortBy.LastActive) {
            // Dates are returned as strings from the API, so parse it and get the value
            const lastVisitedVal = Date.parse(account.lastVisitedAt as string)
            return -lastVisitedVal
        }

        if (sortField === SortBy.Budget) {
            return account?.budgetBar?.budget_sort_order_value
                ? account?.budgetBar?.budget_sort_order_value
                : 0
        }

        return account.name
    })
}
