<template>
    <Section :group="true" :items="sectionGroupItems">
        <template #selected-items-header-content>
            <div class="step-2-section-buttons">
                <div class="add-ngram-buttons" v-if="addNegativesItemType === 'nGram'">
                    <SquareButton @click="setPanel(ActivePanel.SearchTerms)">
                        View Search Terms
                    </SquareButton>
                    <Select v-model="lookbackWindow" :options="lookbackWindows" />
                </div>
                <!-- Spacer to maintain same height as if there were buttons -->
                <Spacer v-else height="2.75rem" />
            </div>
        </template>
        <template #selected-items-content>
            <!-- Skeletons on loading -->
            <NGramTableSkeleton
                v-if="nGramDataLoading"
                :number-of-rows="initiallySelectedItems.length"
            />
            <oTable
                v-else
                :headers="nGramToolHeaders"
                :items="formattedInitiallySelectedItems as unknown as TableItem[]"
                order-by="cost"
                order="DESC"
                fixed-layout
                :border-radius="24"
            >
                <template #header.text>
                    <Spacer width="1.5rem" />
                    <Tooltip content="Select All" placement="top">
                        <Checkbox :model-value="allItemsSelected" @click="toggleSelectAll" />
                    </Tooltip>
                    <Spacer width="1.25rem" />
                    <Text size="f-8" weight="500">{{ formattedItemType }}</Text>
                </template>
                <template #column.text="{ value, row }">
                    <div class="negative-keyword-cell" @click="toggleItem(row)">
                        <div v-if="addingNgramsToNegative" class="spinner-container">
                            <Spinner />
                        </div>
                        <Checkbox
                            v-else
                            :model-value="
                                selectedItems.some(selectedItem => selectedItem.text === row.text)
                            "
                        />
                        <Spacer width="1.25rem" />
                        <EntityPill
                            v-if="addNegativesItemType === 'nGram'"
                            :type="Improvement.LocationEntity.NGram"
                            :content="value"
                        />
                        <EntityPill
                            v-else
                            :type="Improvement.LocationEntity.SearchTerm"
                            :content="value"
                        />
                    </div>
                </template>

                <template #column.cost="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.impressions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversions="{ value }">
                    <Number :value="value" />
                </template>
                <template #column.conversionValue="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.cpa="{ value }">
                    <Money :value="value" :currency="currencyCode" />
                </template>
                <template #column.roas="{ value }">
                    <Percent :value="value" />
                </template>
                <template #column.cpm="{ value }">
                    {{ formatCpm(value) }}
                </template>
                <template #column.vpm="{ value }">
                    {{ formatVpm(value) }}
                </template>
                <template #column.vsAvg="{ value, row }">
                    <Text size="f-8" weight="500" :color="row.vsAverageColor">
                        <Percent :value="value" :decimal-places="2" include-sign />
                    </Text>
                </template>
                <template #column.nscoreSortValue="{ row }">
                    <NScoreCell v-bind="row" />
                </template>
                <!-- Only relevant when adding N-Grams -->
                <template #column.potentialSavings="{ value }">
                    <Pill><Money :value="value" />/month</Pill>
                </template>
                <!-- Only relevant when adding Search Terms -->
                <template #[`column.${entitySource}`]="{ row }">
                    <NGramEntitySource :search-term-item="row" />
                </template>
                <template #column.actions="{ row: item }: { row: PanelItem }">
                    <ActionsCell :item="item" :item-type="addNegativesItemType" />
                </template>
            </oTable>
        </template>
        <template #negative-match-types-content>
            <NegativeMatchTypesTable :negative-keywords="negativeKeywords" />
        </template>
        <template #keyword-destinations-content>
            <NGramKeywordDestination
                v-if="sharedSetData && campaignData"
                :negative-keywords="negativeKeywords"
                :selection-count="selectedItems.length"
            />
        </template>
    </Section>
</template>

<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue'

// import {
//     Checkbox,
//     EntityPill,
//     Number,
//     oTable,
//     Percent,
//     Section,
//     Select,
//     Spacer,
//     Spinner,
//     SquareButton,
//     Text,
//     Tooltip,
//} from '@opteo/components-next'
import { Gads, Improvement } from '@opteo/types'
import Money from '@/components/global/oMoney.vue'
import ActionsCell from '@/components/toolkit/ngram/ActionsCell.vue'
import NGramEntitySource from '@/components/toolkit/ngram/NGramEntitySource.vue'
import NegativeMatchTypesTable from '@/components/toolkit/ngram/NegativeMatchTypesTable.vue'
import NGramKeywordDestination from '@/components/toolkit/ngram/NGramKeywordDestination.vue'
import NGramTableSkeleton from '@/components/toolkit/ngram/NGramTableSkeleton.vue'
import NScoreCell from '@/components/toolkit/ngram/NScoreCell.vue'
import Pill from '@/components/toolkit/ngram/Pill.vue'
import { useNGramAddNegatives } from '@/composition/toolkit/nGramTool/useNGramAddNegatives'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import { useNGramHeaders } from '@/composition/toolkit/nGramTool/useNGramHeaders'
import { useNgramPanels, ActivePanel } from '@/composition/toolkit/nGramTool/useNgramPanels'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { isNGramItem } from '@/composition/toolkit/nGramTool/utils'

import type { AddNegatives, PanelItem } from '@/composition/toolkit/nGramTool/types/index'

// TODO: we really need to change the type of items in oTable...
type TableItem = InstanceType<typeof oTable>['items'][number]

const { addNegativesItemType, formattedItemType, negativeKeywords } = useNGramAddNegatives()
const { lookbackWindow, lookbackWindows } = useNGramFilters()
const { entitySource, nGramToolHeaders } = useNGramHeaders()
const { setPanel } = useNgramPanels()
const {
    // NGram Tables
    currencyCode,
    nGramDataLoading,
    addingNgramsToNegative,
    finalItemSelection,
    // Keywords Destinations
    sharedSetData,
    campaignData,
    // utils
    formatCpm,
    formatVpm,
    formatItemsForPanels,
} = useNGramTool()

const props = defineProps<{
    initiallySelectedItems: PanelItem[]
}>()

const formattedInitiallySelectedItems = computed(() =>
    formatItemsForPanels(props.initiallySelectedItems)
)
const selectedItems = ref(formattedInitiallySelectedItems.value)

watchEffect(() => {
    finalItemSelection.value = selectedItems.value.map(item => item.text)
})

const sectionGroupItems = computed(() => {
    const ItemSubSectionTitle = {
        nGram: {
            singular: 'Selected N-Gram',
            plural: 'Selected N-Grams',
        },
        searchTerm: {
            singular: 'Selected Search Term',
            plural: 'Selected Search Terms',
        },
    } as const satisfies Record<AddNegatives.ItemType, Record<'singular' | 'plural', string>>

    const MatchTypeSubSectionTitle = {
        singular: 'Negative Match Type',
        plural: 'Negative Match Types',
    } as const satisfies Record<'singular' | 'plural', string>

    const quantity = selectedItems.value.length === 1 ? 'singular' : 'plural'

    return [
        {
            id: 'selected-items',
            title: ItemSubSectionTitle[addNegativesItemType.value][quantity],
            innerShadow: false,
            items: [],
        },
        {
            id: 'negative-match-types',
            title: MatchTypeSubSectionTitle[quantity],
            innerShadow: false,
            items: [],
        },
        {
            id: 'keyword-destinations',
            title: 'Keyword Destinations',
            contentMaxWidth: 604,
            innerShadow: false,
            items: [],
        },
    ]
})

const allItemsSelected = computed(
    () => selectedItems.value.length === props.initiallySelectedItems.length
)

const toggleItem = (item: PanelItem) => {
    if (selectedItems.value.some(selectedItem => selectedItem.text === item.text)) {
        selectedItems.value = selectedItems.value.filter(
            selectedItem => selectedItem.text !== item.text
        )
    } else {
        selectedItems.value = [...selectedItems.value, item]
    }
}

const toggleSelectAll = () => {
    if (allItemsSelected.value) {
        selectedItems.value = []
    } else {
        selectedItems.value = formattedInitiallySelectedItems.value
    }
}

// The final negative list selection with match types
watchEffect(() => {
    negativeKeywords.value = selectedItems.value.map(selectedItem => {
        const existingNegativeKeyword = negativeKeywords.value.find(
            negativeKeyword => negativeKeyword.keyword === selectedItem.text
        )

        if (existingNegativeKeyword) {
            return existingNegativeKeyword
        }

        let potentialSavings = 0
        if (isNGramItem(selectedItem)) {
            potentialSavings = selectedItem.potentialSavings
        }

        return {
            keyword: selectedItem.text,
            matchType:
                formattedItemType.value === 'N-Gram'
                    ? Gads.enums.KeywordMatchType.PHRASE
                    : Gads.enums.KeywordMatchType.EXACT,
            potentialSavings,
        }
    })
})
</script>

<style scoped lang="scss">

// @import '@/assets/css/variables.scss';

// .step-2-section-buttons {
//     display: flex;
//     align-items: center;
//     justify-content: flex-end;
// }

// .add-ngram-buttons {
//     @include flex;
//     @include items-center;
//     gap: 0.75rem;
// }

// .spinner-container {
//     height: 1.25rem;
//     width: 1.25rem;
//     @include flex-center;
// }

// :deep(table td[data-label='Ad Group Source']),
// :deep(table td[data-label='Campaign Source']) {
//     overflow: hidden;
//     position: relative;
// }

// .negative-keyword-cell {
//     overflow: hidden;
//     position: relative;
//     width: 100%;
//     @include flex;
//     @include items-center;
//     @include pl-24;
// }
// .negative-keyword-cell::after {
//     content: '';
//     background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
//     @include absolute;
//     top: 0;
//     right: -1rem;
//     width: 6rem;
//     bottom: 0;
// }

// :deep(table td[data-label='Ad Group Source']::after),
// :deep(table td[data-label='Campaign Source']::after) {
//     content: '';
//     background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
//     @include absolute;
//     top: 0;
//     right: -0.5rem;
//     width: 5rem;
//     bottom: 0;
// }

</style>
