<template>
    <PageHeader title="Billing and Invoices">
        <template #right>
            <Button color="secondary" size="small" iconBefore="User" @click="showNewMessage">
                Message Support
            </Button>
        </template>
    </PageHeader>

    <PageContent>
        <Stack direction="column" :gap="0">
            <!-- Subscription -->
            <section>
                <StickyHeader title="Subscription">
                    <template #titleSuffix>
                        <Button
                            v-if="currentPlan"
                            color="secondary"
                            size="extra-small"
                            @click="toggleSelectPlanModal"
                        >
                            Change Plan
                        </Button>
                    </template>
                </StickyHeader>
                <Stack
                    :padding="20"
                    :gap="20"
                    :direction="above1280 ? 'row' : 'column'"
                    :align="above1280 ? 'start' : 'center'"
                    justify="center"
                    borderBottom
                >
                    <Subscription />
                </Stack>
            </section>

            <!-- Discount Code -->
            <section>
                <StickyHeader title="Discount Code" />
                <Stack :padding="20" justify="center" borderBottom>
                    <DiscountCode />
                </Stack>
            </section>

            <!-- Invoices -->
            <section>
                <StickyHeader title="Invoices">
                    <template #titleSuffix>
                        <Button
                            color="secondary"
                            size="extra-small"
                            @click="toggleInvoiceSettingsModal"
                        >
                            Invoice Settings
                        </Button>
                    </template>
                </StickyHeader>
                <Stack :padding="20" :gap="0" justify="center" borderBottom>
                    <Invoices />
                </Stack>
            </section>

            <!-- Cancel Subscription -->
            <section v-if="customerIsActive">
                <StickyHeader title="Cancel Subscription" />
                <Stack :padding="20" :gap="0" justify="center" borderBottom>
                    <CancelSubscription />
                </Stack>
            </section>
        </Stack>
    </PageContent>
</template>

<script setup lang="ts">
// Imports
import { computed, onMounted } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useIntercom } from '@/lib/intercom/useIntercom'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useSubscription } from '@/composition/billing/useSubscription'
import { useInvoices } from '@/composition/billing/invoices/useInvoices'
import { usePlanSelectionModal } from '@/composition/billing/usePlanSelectionModal'

// Components
import {
    Button,
    Stack,
    StickyHeader,
    useMediaQuery,
    showSnackbar,
    hideSnackbar,
} from '@opteo/components-next'

// Sections
import Subscription from '@/components/billing/Subscription/Subscription.vue'
import DiscountCode from '@/components/billing/DiscountCode.vue'
import Invoices from '@/components/billing/Invoices.vue'
import CancelSubscription from '@/components/billing/CancelSubscription.vue'

// Setup
const route = useRoute()
const router = useRouter()
const { above1280 } = useMediaQuery()
const { showNewMessage } = useIntercom()
const { currentPlan } = useUser()
const { userType, latestSubscription } = useSubscription()
const { toggleInvoiceSettingsModal } = useInvoices()
const { toggleSelectPlanModal } = usePlanSelectionModal()

// Computed
const customerIsActive = computed<boolean>(() => {
    if (latestSubscription.value) {
        return (
            !latestSubscription.value?.cancel_at_period_end &&
            userType.value !== 'cancelled' &&
            userType.value !== 'incomplete'
        )
    }
    return false
})

// Handle `Stripe` redirects, e.g. when setting up PayPal
onMounted(() => {
    // succeeded
    if (route.query.redirect_status === 'succeeded') {
        showSnackbar({
            message: `Payment method saved successfully`,
            timeout: 3000,
            actionText: 'Dismiss',
            actionHandler: () => hideSnackbar(),
        })

        // Cleanup
        router.replace({
            query: {
                ...route.query,
                redirect_status: undefined,
                setup_intent: undefined,
                setup_intent_client_secret: undefined,
            },
        })
    }
    // failed
    else if (route.query.redirect_status === 'failed') {
        const errorMessage = 'Payment setup failed. Please try again.'

        showSnackbar({
            message: `${errorMessage}`,
            timeout: 4000,
            actionText: 'Dismiss',
            actionHandler: () => hideSnackbar(),
        })

        // Cleanup
        router.replace({
            query: {
                ...route.query,
                redirect_status: undefined,
                setup_intent: undefined,
                setup_intent_client_secret: undefined,
            },
        })
    }
})
</script>

<style scoped lang="scss">
section {
    @include w-100;
}
</style>
