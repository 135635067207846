<template>
    <Container :borderRadius="22" width="100%" :maxWidth="324" hideOverflow>
        <Form ref="budgetForm" @submitted="updateAccountMonthlyBudget" validateOnSubmit>
            <!-- Budget -->
            <Stack
                direction="column"
                align="start"
                width="100%"
                :gap="18"
                :padding="[18, 20, 20]"
                borderBottom
            >
                <!-- Budget -->
                <NumberInput
                    ref="budgetInput"
                    name="budget"
                    formatting="money"
                    v-model="accountBudgetDisplay"
                    :prefix="currencySymbol"
                    :validator="budgetValidator"
                    suffix="/month"
                    label="Monthly Budget"
                    autocomplete="off"
                />

                <!-- Variance -->
                <NumberInput
                    ref="budgetVarianceInput"
                    name="budgetVariance"
                    formatting="number"
                    v-model="budgetVarianceDisplay"
                    :decimalPlaces="0"
                    suffix="%"
                    min="0"
                    max="30"
                    label="Acceptable Variance"
                    autocomplete="off"
                />
            </Stack>

            <!-- Pause -->
            <Stack
                direction="column"
                align="start"
                width="100%"
                :padding="[20]"
                :gap="14"
                :borderBottom="showUpdateButton"
            >
                <Toggle
                    :modelValue="pauseSpendWhenOverBudget ?? budgetVariance?.has_budget_auto_pause"
                    :disabled="
                        accountPlatform != Platform.Platform.GoogleAds ||
                        !accountBudget ||
                        budgetValidator(accountBudget)
                    "
                    name="pauseSpendToggle"
                    size="small"
                    textSize="button"
                    label="Pause spend when over budget"
                    @update:modelValue="pauseSpendWhenOverBudget = $event"
                />
                <Text as="p" size="body-extra-small" color="foreground-minus-one">
                    Toggle this option to automatically pause any active campaigns when both your
                    budget and acceptable variance have been exceeded (<b>{{
                        budgetWithVariance
                    }}</b>
                    spend).
                </Text>
                <Button
                    size="extra-small"
                    color="secondary"
                    iconAfter="ArrowUpRight"
                    @click="goToDocs"
                >
                    Learn More
                </Button>
            </Stack>
            <Stack v-if="showUpdateButton" align="center" justify="center" :padding="[22, 0]">
                <Button
                    ref="saveBudgetButton"
                    :loading="saveBudgetButtonLoading"
                    size="small"
                    color="secondary-highlight"
                    submit
                >
                    Update Monthly Budget
                </Button>
            </Stack>
        </Form>
    </Container>
</template>

<script lang="ts" setup>
import {
    Container,
    Text,
    Form,
    Button,
    useMoney,
    NumberInput,
    Stack,
    showSnackbar,
    Toggle,
} from '@opteo/components-next'

import { computed, ref, watch } from 'vue'
import { Account, Currency, Platform, Tracking } from '@opteo/types'
import { Endpoint, authRequest, useAPI } from '@/composition/api/useAPI'
import { useDomainListRow } from '@/composition/user/useDomainListRow'
import { useAlerts } from '@/composition/alerts/useAlerts'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useTracking } from '@/composition/api/useTracking'

const props = defineProps<{
    accountId: Account.ID
    accountPlatform: Platform.Platform
    currencyCode?: Currency.CurrencyCode
    currencySymbol?: Currency.CurrencySymbol
    showUpdateButton?: boolean
}>()

const { mutateRowMetrics } = useDomainListRow(ref(props.accountId))
const { mutateAlerts } = useAlerts()
const { trackAction } = useTracking()

// Fetch budget from server
const { data: budget, mutate: refreshBudgetFromServer } = useAPI<number>(Endpoint.GetDomainBudget, {
    uniqueId: () => props.accountId,
    waitFor: () => props.accountId,
    body() {
        return {
            account_id: props.accountId,
        }
    },
})

// Fetch budget variance from server
const { data: budgetVariance, mutate: refreshBudgetVarianceFromServer } = useAPI<{
    budget_variance: number
    has_budget_auto_pause: boolean
}>(Endpoint.GetDomainBudgetVariance, {
    uniqueId: () => props.accountId,
    waitFor: () => props.accountId,
    body() {
        return {
            account_id: props.accountId,
        }
    },
})

// Set default values
const accountBudget = ref(budget.value)
const accountBudgetVariance = ref(0)
const pauseSpendWhenOverBudget = ref(budgetVariance.value?.has_budget_auto_pause)

// goToDocs
function goToDocs() {
    window.open('https://opteo.com/docs/en/articles/6579831-pause-spend-when-over-budget', '_blank')
}

watch(
    budget,
    newBudget => {
        accountBudget.value = newBudget
    },
    { immediate: true }
)

const accountBudgetDisplay = computed({
    get() {
        return accountBudget.value
    },
    set(newVal) {
        accountBudget.value = newVal
        if (!newVal) {
            pauseSpendWhenOverBudget.value = false
        }
    },
})

watch(
    budgetVariance,
    newVal => {
        accountBudgetVariance.value = (newVal?.budget_variance ?? 0) / 10000
    },
    { immediate: true }
)

const budgetVarianceDisplay = computed({
    get() {
        return accountBudgetVariance.value
    },
    set(newVal) {
        accountBudgetVariance.value = newVal
    },
})

function disableBudgetAutoPauseIfInvalid(budget: number, value: any) {
    if (!budget || budget < 0) {
        value.localValue = false
    }
}

const budgetValidator = (value: number, has_budget_auto_pause?: any) => {
    if (has_budget_auto_pause) {
        disableBudgetAutoPauseIfInvalid(value, has_budget_auto_pause)
    }

    if (value < 0) {
        return 'Budget must be a positive number'
    }
}

const fieldPauseSpendWhenOverBudget = computed(
    () => budgetVariance?.value?.has_budget_auto_pause ?? false
)

const budgetForm = ref<typeof Form>()

const saveBudgetButton = ref()
const saveBudgetButtonLoading = ref(false)

const budgetInput = ref()
const budgetVarianceInput = ref()

const intercom = useIntercom()

const updateAccountMonthlyBudget = async () => {
    if (
        accountBudget.value === budget.value &&
        accountBudgetVariance.value === (budgetVariance.value?.budget_variance ?? 0) / 10_000 &&
        pauseSpendWhenOverBudget.value === !!budgetVariance.value?.has_budget_auto_pause
    ) {
        showSnackbar({
            message: 'No changes made',
        })
        return
    }

    const budgetIsValid = budgetInput.value?.state.valid
    const budgetVarianceIsValid = budgetVarianceInput.value?.state.valid

    if (!budgetIsValid || !budgetVarianceIsValid) return // Invalid budget – Form will show error

    // TODO: Remove this condition when pause spend over budget is available for Microsoft Ads
    if (props.accountPlatform === Platform.Platform.GoogleAds && !budgetVarianceIsValid) return // Invalid budget variance – Form will show error

    saveBudgetButtonLoading.value = true

    const newBudget = accountBudget.value ?? 0
    const newBudgetVariance = accountBudgetVariance.value
        ? accountBudgetVariance.value * 10_000
        : null

    const enabledBudgetAutoPause =
        pauseSpendWhenOverBudget.value && !fieldPauseSpendWhenOverBudget.value

    const disabledBudgetAutoPause =
        !pauseSpendWhenOverBudget.value && fieldPauseSpendWhenOverBudget.value
    await authRequest(Endpoint.SaveAccountBudget, {
        body: {
            account_id: props.accountId,
            budget: newBudget,
            budget_variance: newBudgetVariance,
            has_budget_auto_pause: newBudget ? pauseSpendWhenOverBudget.value : false,
            /* if disabling has budget auto pause, reactivate paused campaigns*/
            disabled_budget_auto_pause: disabledBudgetAutoPause,
            enabled_budget_auto_pause: enabledBudgetAutoPause,
        },
    })

    if (enabledBudgetAutoPause || disabledBudgetAutoPause) {
        trackAction(
            Tracking.ActionName.PauseSpendWhenOverBudgetAction ??
                'pause_spend_when_over_budget_action',
            {
                action: disabledBudgetAutoPause
                    ? 'disabled'
                    : enabledBudgetAutoPause
                      ? 'enabled'
                      : 'no_action',
            }
        )
    }

    await Promise.all([
        mutateRowMetrics(),
        mutateAlerts(),
        refreshBudgetFromServer(),
        refreshBudgetVarianceFromServer(),
    ])

    saveBudgetButtonLoading.value = false
    showSnackbar({
        message: 'Monthly Budget updated successfully',
    })

    intercom.trackEvent('set_budget', { new_budget: budget })
}

// Form validation and formatting
const budgetWithVariance = computed(() => {
    if (!accountBudget.value)
        return useMoney({
            value: 0,
            currency: props.currencyCode,
        }).displayValue.value

    const isInvalid = !!budgetValidator(accountBudget.value)

    if (isInvalid)
        return useMoney({
            value: 0,
            currency: props.currencyCode,
        }).displayValue.value

    const budgetWithVariance = useMoney({
        value: accountBudget.value * (1 + (accountBudgetVariance.value ?? 0) / 100),
        currency: props.currencyCode,
    }).displayValue.value

    return budgetWithVariance
})

// This is needed to trigger the function from the parent component
defineExpose({
    updateAccountMonthlyBudget,
})
</script>

<style scoped lang="scss"></style>
