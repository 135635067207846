<template>
    <div class="chart-outer-container">
        <div ref="chartContainer" class="chart-container">
            <div
                v-for="(bar, index) in items"
                class="bar-container"
                @mouseenter="hoveredBarIndex = index"
                @mouseleave="hoveredBarIndex = null"
            >
                <div class="bar">
                    <ChartTooltip
                        v-if="hoveredBarIndex === index"
                        class="chart-tooltip"
                        :metrics="[
                            {
                                label: bar.label,
                                value: bar.noData ? 'N/A' : bar.score + '%',
                                textColor: bar.noData ? 'white' : scoreTextColor(bar.score),
                            },
                        ]"
                    />
                    <div
                        class="fill"
                        :style="[
                            {
                                height: aboveMobile
                                    ? bar.noData || bar.score < 10
                                        ? '16px'
                                        : bar.score + '%'
                                    : bar.noData || bar.score < 7.5
                                      ? '12px'
                                      : bar.score + '%',
                            },
                            {
                                backgroundColor: bar.noData ? '#e6e6e6' : scoreColor(bar.score).hex,
                            },
                        ]"
                    ></div>
                    <div
                        class="backstroke"
                        :style="[
                            {
                                backgroundColor: bar.noData
                                    ? '#e6e6e62a'
                                    : backstrokeColor(bar.score),
                            },
                        ]"
                    ></div>
                </div>
                <Spacer height="0.5rem" />
                <Text as="p" size="f-9" color="gray">
                    {{ bar.label.slice(0, 1).toUpperCase() }}
                </Text>
            </div>
        </div>

        <Spacer height="2.125rem" />

        <div class="overview-tabs">
            <div v-if="allTimeHigh" class="high">
                <div class="inner">
                    <div>
                        <Text
                            as="p"
                            size="f-4"
                            weight="600"
                            :color="scoreTextColor(allTimeHigh)"
                            style="line-height: 1.25rem"
                        >
                            <Percent :value="allTimeHigh / 100" :decimal-places="0" />
                        </Text>
                        <Spacer height="0.5rem" />
                        <Text
                            as="p"
                            size="f-9"
                            :color="scoreTextColor(allTimeHigh)"
                            style="line-height: 100%"
                        >
                            Highest Score
                        </Text>
                    </div>
                    <ScorecardDonut
                        :score="allTimeHigh"
                        :compact="true"
                        :width="44"
                        :stroke-width="4"
                        hide-score
                    />
                </div>
            </div>
            <!-- noData -->
            <div v-if="!allTimeHigh" class="high no-data">
                <div class="inner">
                    <div>
                        <div class="no-data-cell-skeleton">
                            <div class="cell-fill"></div>
                        </div>
                        <Spacer height="0.5rem" />
                        <Text as="p" size="f-9" color="gray" style="line-height: 100%">
                            Highest Score
                        </Text>
                    </div>
                    <ScorecardDonut
                        :score="0"
                        :compact="true"
                        :width="44"
                        :stroke-width="4"
                        hide-score
                        hidden
                    />
                </div>
            </div>
            <!-- chart has more than one month of data and scores are not equal -->
            <div v-if="(allTimeLow && allTimeHigh !== allTimeLow) || allTimeLow === 0" class="low">
                <div class="inner">
                    <div>
                        <Text
                            as="p"
                            size="f-4"
                            weight="600"
                            :color="scoreTextColor(allTimeLow)"
                            style="line-height: 1.25rem"
                        >
                            <Percent :value="allTimeLow / 100" :decimal-places="0" />
                        </Text>
                        <Spacer height="0.5rem" />
                        <Text
                            as="p"
                            size="f-9"
                            :color="scoreTextColor(allTimeLow)"
                            style="line-height: 100%"
                        >
                            Lowest Score
                        </Text>
                    </div>
                    <ScorecardDonut
                        :score="allTimeLow"
                        :compact="true"
                        :width="44"
                        :stroke-width="4"
                        hide-score
                    />
                </div>
            </div>
            <!-- chart has one month of data or highest/lowest scores are equal -->
            <div v-else class="low no-data">
                <div class="inner">
                    <div>
                        <div class="no-data-cell-skeleton">
                            <div class="cell-fill"></div>
                        </div>
                        <Spacer height="0.5rem" />
                        <Text as="p" size="f-9" color="gray" style="line-height: 100%">
                            Lowest Score
                        </Text>
                    </div>
                    <ScorecardDonut
                        :score="0"
                        :compact="true"
                        :width="44"
                        :stroke-width="4"
                        hide-score
                        hidden
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import isNull from 'lodash-es/isNull'
import { defineComponent, ref, PropType } from 'vue'
import useMediaQuery from '@/composition/global/useMediaQuery'

// import { Percent, oButton, Spacer, Text, ChartTooltip } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'

import { scoreColor } from '@/composition/toolkit/scorecard/utils'
import ScorecardDonut from './ScorecardDonut.vue'

interface Items {
    label: string
    score: number
    noData: boolean
}

export default defineComponent({
    name: 'ScoreHistoryChart',
    components: {

//         oButton,
//         Spacer,
//         Text,
//         ChartTooltip,
//         ScorecardDonut,
//         Percent,
//         Skeleton,
    
},
    props: {
        items: {
            type: Array as PropType<Items[]>,
            required: true,
        },
        allTimeHigh: {
            type: Number,
            required: false,
        },
        allTimeLow: {
            type: Number,
            required: false,
        },
        maxWidth: {
            type: String,
            required: false,
            default: '25rem',
        },
        // hidden: {
        //     type: Boolean,
        // },
    },

    setup(props) {
        const hoveredBarIndex = ref<null | number>(null)

        if (props.items && props.items.length !== 12) {
            throw new Error('ScoreHistoryChart must be given an array of 12 items')
        }

        function scoreTextColor(score: number) {
            return scoreColor(score).name
        }
        function backstrokeColor(score: number) {
            if (scoreColor(score).name === 'green') {
                return '#00a8610D'
            } else if (scoreColor(score).name === 'amber') {
                return '#FF95000D'
            }
            return '#FF28280D'
        }

        const allTimeHigh = !isNull(props.allTimeHigh) ? Math.round(props.allTimeHigh ?? 0) : null
        const allTimeLow = !isNull(props.allTimeLow) ? Math.round(props.allTimeLow ?? 0) : null

        const { aboveMobile } = useMediaQuery()

        return {
            hoveredBarIndex,
            scoreColor,
            scoreTextColor,
            backstrokeColor,
            allTimeHigh,
            allTimeLow,
            aboveMobile,
        }
    },
})
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .chart-outer-container {
// //     width: 100%;
// //     // max-width: v-bind(maxWidth);
// // }
// // .chart-container {
// //     @include flex;
// //     @include items-center;
// //     justify-content: space-between;
// //     @include w-100;
// // }
// // .chart-container .bar-container {
// //     @include flex;
// //     flex-direction: column;
// //     @include items-center;
// //     @include w-100;
// // }
// // .chart-container .bar-container .bar {
// //     @include relative;
// //     height: 160px;
// //     width: 1rem;
// // }
// // .chart-container .bar-container .bar .fill,
// // .chart-container .bar-container .bar .backstroke {
// //     @include w-100;
// //     position: absolute;
// //     left: 0;
// //     bottom: 0;
// //     border-radius: 0.5rem;
// // }
// // .chart-container .bar-container .bar .fill {
// //     // height: 80%;
// //     // background-color: v-bind(scoreColor);
// // }
// // .chart-container .bar-container .bar .backstroke {
// //     @include h-100;
// //     // background-color: v-bind(backstrokeColor);
// // }

// // .chart-tooltip {
// //     position: absolute;
// //     top: -72px;
// //     left: 50%;
// //     transform: translateX(-50%);
// // }

// // .overview-tabs {
// //     @include flex;
// //     @include items-center;
// //     @include w-100;
// // }
// // .overview-tabs .high,
// // .overview-tabs .low {
// //     @include flex;
// //     @include items-center;
// //     @include w-100;
// //     border-radius: 1rem;
// // }
// // .overview-tabs .high {
// //     background-color: v-bind(backstrokeColor(allTimeHigh));
// // }
// // .overview-tabs .low {
// //     background-color: v-bind(backstrokeColor(allTimeLow));
// //     margin-left: 1rem;
// // }
// // .overview-tabs .high.no-data {
// //     background-color: rgba(230, 230, 230, 0.16);
// // }
// // .overview-tabs .low.no-data {
// //     background-color: rgba(230, 230, 230, 0.16);
// // }
// // .overview-tabs .inner {
// //     @include flex;
// //     @include items-center;
// //     justify-content: space-between;
// //     @include w-100;
// //     @include pv-20;
// //     @include ph-24;
// // }

// // .no-data-cell-skeleton {
// //     height: 20px;
// //     width: 54px;
// //     padding: 1px 0 0 0;

// //     & .cell-fill {
// //         height: 16px;
// //         width: 48px;
// //         @include br-32;
// //         background-color: #e6e6e6;
// //     }
// // }

// // // Responsive
// // @media (max-width: $mq-480-max) {
// //     .chart-container .bar-container .bar {
// //         width: 0.75rem;
// //     }
// //     .overview-tabs {
// //         @include block;
// //     }
// //     .overview-tabs .low {
// //         margin-left: unset;
// //         margin-top: 0.75rem;
// //     }
// // }

// // // .lighter-grey-stroke {
// // //     stroke: #e6e6e6;
// // // }
// // // .lighter-green-stroke {
// // //     stroke: $opteo-green-translucent;
// // // }
// // // .lighter-amber-stroke {
// // //     stroke: $opteo-amber-translucent;
// // // }
// // // .lighter-red-stroke {
// // //     stroke: $opteo-red-translucent;
// // // }


</style>
