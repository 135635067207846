<template>
    <Sidebar
        :items="sidebarItems"
        :footerItems="footerItems"
        :route="route"
        :router="router"
        :height="height"
        :width="width"
        :resizeable="height && width ? false : true"
        :border="height && width ? false : true"
        :gap="4"
        @item-clicked="emit('item-clicked')"
    >
        <template #header>
            <Stack :padding="16">
                <Container :borderRadius="16" :padding="[14, 15, 14, 15]" :height="62" width="100%">
                    <Stack :gap="14" direction="row" align="center" width="100%">
                        <ProfileImage
                            :size="28"
                            :imageUrl="profileImageUrl"
                            :boringAvatar="currentUserAvatarUrl"
                        />
                        <Stack :gap="5" direction="column" align="start">
                            <Text as="h6" size="headline-seven" color="foreground">
                                {{ user?.profile.name }}
                            </Text>
                            <Text as="span" size="label" color="foreground-minus-one">
                                {{ user?.profile.username }}
                            </Text>
                        </Stack>
                    </Stack>
                </Container>
            </Stack>
        </template>
    </Sidebar>
</template>

<script setup lang="ts">
// Imports
import { ref, PropType, computed } from 'vue'
import { useLogin } from '@/composition/auth/useLogin'
import { useRouter, useRoute } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

// Types
import {
    SidebarItemObject,
    SidebarGroupObject,
} from '@opteo/components-next/dist/components/navigation/Sidebar/types'

// Components
import { Sidebar, Stack, Container, ProfileImage, Text } from '@opteo/components-next'

// Props
const props = defineProps({
    height: {
        type: String as PropType<string>,
        required: false,
    },
    width: {
        type: String as PropType<string>,
        required: false,
    },
})

// Emits
const emit = defineEmits(['item-clicked'])

// Setup
const router = useRouter()
const route = useRoute()
const { user, userInfo, profileImageUrl } = useUser()
const { currentUserAvatarUrl } = useBoringAvatar()
const { logout } = useLogin()

// sidebarItems
const sidebarItems = computed(() => {
    const baseItems: (SidebarGroupObject | SidebarItemObject)[] = [
        {
            type: 'Item',
            name: 'Account Settings',
            key: 'account-settings',
            routerLink: Routes.UserSettings,
        },
        {
            type: 'Item',
            name: 'Impact Statistics',
            key: 'impact-statistics',
            routerLink: Routes.ImpactStatistics,
        },
        {
            type: 'Item',
            name: 'Logout',
            key: 'logout',
            action: () => logout(),
        },
    ]

    // Conditionally push the Billing item only if user is admin
    if (userInfo.value?.role === 'admin') {
        baseItems.splice(2, 0, {
            type: 'Item',
            name: 'Billing and Invoices',
            key: 'billing-and-invoices',
            routerLink: Routes.Billing,
        })
    }

    return baseItems
})

// footerItems
const footerItems = ref<SidebarItemObject[]>([
    {
        type: 'Item',
        key: 'help-docs',
        name: 'Help Centre',
        externalLink: 'https://opteo.com/docs/en',
    },
    {
        type: 'Item',
        key: 'feature-requests',
        name: 'Feature Requests',
        externalLink: 'https://opteo.canny.io/feature-requests',
    },
    {
        type: 'Item',
        key: 'changelog',
        name: 'Changelog',
        externalLink: 'https://changelog.opteo.com/',
    },
])
</script>

<style scoped lang="scss"></style>
