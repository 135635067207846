<template>
    <FullScreenOverlay @close="handleClose">
        <PageHeader title="Account Setup" />
        <!-- Content -->
        <div class="account-setup-wrapper">
            <div class="account-setup-container">
                <!-- Account Profile -->
                <!-- <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Account Profile</Text>
                </div>
                <div class="section-content">
                    <AccountProfile ref="accountProfile" />
                </div>
            </section> -->

                <!-- Monthly Budget -->
                <!-- <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Monthly Budget</Text>
                </div>
                <div class="section-content">
                    <AccountMonthlyBudget
                        ref="accountMonthlyBudget"
                        :account-id="accountId"
                        :currency-symbol="currencySymbol"
                        :currency-code="currencyCode"
                        :account-platform="accountPlatform"
                    />
                </div>
            </section> -->

                <!-- Performance Mode -->
                <!-- <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Performance Mode</Text>
                </div>
                <div class="section-content">
                    <AccountPerformanceMode ref="accountPerformanceMode" />
                </div>
            </section> -->

                <!-- Conversion Actions -->
                <!-- <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Conversion Actions</Text>
                </div>
                <div class="section-content">
                    <AccountConversionActions ref="accountConversionActions" />
                </div>
            </section> -->

                <!-- Performance Goals -->
                <!-- <section class="form-section">
                <div class="section-header">
                    <Text as="h4" weight="600">Performance Goals</Text>
                </div>
                <div class="section-content">
                    <AccountPerformanceGoals ref="accountPerformanceGoals" />
                </div>
            </section> -->

                <!-- Google Analytics -->
                <!-- <section
                class="form-section"
                v-if="accountPlatform === Platform.Platform.GoogleAds"
            >
                <div class="section-header">
                    <Text as="h4" weight="600">Google Analytics</Text>
                </div>
                <div class="section-content analytics">
                    <Analytics
                        view="domain-settings"
                        button-color="dark-blue"
                        @before-oauth-redirect="saveSettingsBeforeGa4OAuthFlow"
                    >
                        <template #no-connection-copy>
                            Connect a Google Analytics account and corresponding view to get
                            access to Google Analytics data in Opteo.
                        </template>
                    </Analytics>
                </div>
            </section> -->
            </div>
        </div>
    </FullScreenOverlay>
</template>

<script setup lang="ts">
// Imports
import { SKIP_ONBOARDING_DISMISSAL } from '@/lib/env'
import { onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Types
import { Platform } from '@opteo/types'

// Composition
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useAccountSetupBar } from '@/composition/account/useAccountSetupBar'
import { useAccount } from '@/composition/account/useAccount'
import { useAccountList } from '@/composition/user/useAccountList'

// Components
import { Text } from '@opteo/components-next'
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import Analytics from '@/components/connectors/analytics/Analytics.vue'
import AccountProfile from '@/components/account/settings/AccountProfile.vue'
import AccountMonthlyBudget from '@/components/account/settings/AccountMonthlyBudget.vue'
import AccountPerformanceGoals from '@/components/account/settings/AccountPerformanceGoals.vue'
import AccountPerformanceMode from '@/components/account/settings/AccountPerformanceMode.vue'
import AccountConversionActions from '@/components/account/settings/AccountConversionActions.vue'

// Setup
const { showAccountSetupBar, stepValidators, setStepKey } = useAccountSetupBar()
const { accountId, currencyCode, currencySymbol, accountPlatform } = useAccount()
const { mutateDomainList } = useAccountList()
const router = useRouter()
const accountProfile = ref()
const accountMonthlyBudget = ref()
const accountPerformanceMode = ref()
const accountPerformanceGoals = ref()

showAccountSetupBar()
setStepKey('account-setup') // Reset the index to the first step

// Handle close
const handleClose = () => {
    router.push({ name: Routes.Improvements })
}

// onMounted
onMounted(() => {
    mutateDomainList(accountId.value, {
        accountSetupSeen: true,
        lastVisitedAt: new Date().toISOString(),
    })

    if (SKIP_ONBOARDING_DISMISSAL) {
        return
    }

    authRequest(Endpoint.MarkAccountSetupSeen)
})

const saveSettingsBeforeGa4OAuthFlow = async () => {
    await handleSubmit()
}

// Handle submit
const handleSubmit = async () => {
    // Validators
    if (!stepValidators.value['account-setup']) return

    // API requests
    try {
        await Promise.all([
            // Save account profile
            accountProfile.value.updateAccountProfile(),
            // Save Budget
            accountMonthlyBudget.value?.updateAccountMonthlyBudget(),
            // Save Performance Mode
            accountPerformanceMode.value?.updatePerformanceMode(),
            // Save Performance Goals
            accountPerformanceGoals.value?.updatePerformanceGoals(),
        ])
        stepValidators.value['account-setup'].error = false
    } catch (error) {
        stepValidators.value['account-setup'].error = true
    }
}

// Plug in callbacks to the account setup bar
if (stepValidators.value['account-setup'])
    stepValidators.value['account-setup'].callback = handleSubmit
</script>

<style scoped lang="scss"></style>
