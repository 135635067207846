<template>
    <PageContainer>
        <!-- User Sidebar -->
        <UserSidebar v-if="above1024 && layout === 'default-layout'" />
        <!-- User Container -->
        <div
            :class="[
                layout,
                'user-container',
                { external: layout === 'external' },
                { desktop: above1024 },
            ]"
        >
            <router-view v-if="accountId" :key="accountId" />
            <router-view v-else />
        </div>
        <!-- User Bottom Bar -->
        <UserBottomBar v-if="!above1024 && layout === 'default-layout'" />
        <!-- Alert Modal -->
        <AlertModal />
        <!-- Plan Enforcement Modal -->
        <PlanEnforcementModal v-if="user" />
    </PageContainer>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'
import { useTitle } from '@vueuse/core'
import { useRouter } from 'vue-router'

// Composition
import { provideUser } from '@/composition/user/useUser'
import { provideAccountList } from '@/composition/user/useAccountList'
import { provideDeepLinking } from '@/composition/global/useDeepLinking'
import { layout } from '@/composition/layouts/useLayouts'

// Types
import { Account } from '@opteo/types'

// Components
import { useMediaQuery } from '@opteo/components-next'
import PageContainer from '@/components/layout/internalPages/PageContainer.vue'
import UserSidebar from '@/components/user/UserSidebar/UserSidebar.vue'
import UserBottomBar from '@/components/user/UserSidebar/UserBottomBar.vue'
import AlertModal from '@/components/alerts/AlertModal.vue'
import PlanEnforcementModal from '@/components/user/PlanEnforcementModal.vue'

// Setup
const { above1024 } = useMediaQuery()
const { user, error } = provideUser()
const { accountList } = provideAccountList(user)
const router = useRouter()
const { currentRoute } = router
const accountId = computed(() => currentRoute.value.params.accountId as Account.ID | undefined)
provideDeepLinking(accountId)

// useTitle
const windowTitle = computed(() => {
    if (!accountList.value || !accountId) {
        return 'Opteo'
    }

    const domainRow = accountList.value.find(d => d.accountId === accountId.value)

    if (!domainRow) {
        return 'Opteo'
    }

    return `Opteo — ${domainRow.name} (${domainRow.tasksAvailable})`
})
useTitle(windowTitle)
</script>

<style scoped lang="scss">
.user-container {
    @include w-100;
    @include flex;
    @include direction-column;
    @include items-center;
    height: calc(100dvh - 5.0625rem);
}
.user-container.desktop {
    width: calc(100vw - 5.0625rem);
    height: 100dvh;
}
.user-container.external,
.full-screen.user-container.desktop {
    width: 100vw;
    height: 100dvh;
}
</style>
