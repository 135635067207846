<template>
    <Container :width="320" :borderRadius="22" color="background-plus-one">
        <Stack :padding="[18, 22]" :gap="12" direction="column" borderBottom>
            <Text as="p" size="body-small">
                To cancel your subscription, click the button below. If you have a few seconds to
                spare, we would appreciate the chance to ask you a few questions about your Opteo
                experience, why you're choosing to leave, and how we could improve our product in
                the future.
            </Text>
            <Text size="body-extra-small" color="foreground-minus-one">
                To revoke Opteo's API access to your Google Ads account, update your
                <b>
                    <a
                        target="_blank "
                        href="https://security.google.com/settings/security/permissions "
                    >
                        Google App Permissions</a
                    ></b
                >.
            </Text>
        </Stack>
        <Stack :padding="20" justify="center">
            <Button color="red" size="small" @click="showCancellationModal = true">
                Cancel Subscription
            </Button>
        </Stack>
    </Container>
    <!-- Modal -->
    <CancelSubscriptionModal v-model="showCancellationModal" />
</template>

<script setup lang="ts">
// Imports
import { ref } from 'vue'

// Components
import { Container, Stack, Text, Button } from '@opteo/components-next'
import CancelSubscriptionModal from '@/components/billing/Modals/CancelSubscriptionModal.vue'

// Setup
const showCancellationModal = ref(false)
</script>
