<template>
    <Menu
        :items="menuItems"
        :buttonLabel="currentPage"
        :showKeyboardShortcuts="false"
        buttonSize="extra-small"
        placement="bottom-start"
    />
</template>

<script setup lang="ts">
// Imports
import { PropType, computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import router from '@/router'

// Types
import { MenuItemType } from '@opteo/components-next/dist/components/buttons/Menu/types'

// Components
import { Menu } from '@opteo/components-next'

// Props
const props = defineProps({
    pages: {
        type: Array as PropType<{ label: string; route: string }[]>,
        required: true,
    },
})

// Route
const route = useRoute()

// State
const currentPage = ref('')

// Computed
const menuItems = computed(() => {
    return props.pages.map(page => ({
        label: page.label,
        value: page.route,
        action: () => goToRoute(page.route),
    })) as MenuItemType[]
})

// Functions
const goToRoute = (route: string) => {
    router.push({ name: route })
}

// Watch
watch(
    () => route.name,
    newRouteName => {
        const matchingPage = props.pages.find(page => page.route === newRouteName)
        currentPage.value = matchingPage ? matchingPage.label : ''
    },
    { immediate: true }
)
</script>
