<template>
    <Stack direction="column" align="start" :gap="0">
        <Label>{{ title }}</Label>
        <Container
            v-if="!parentSelected"
            :borderRadius="20"
            align="center"
            justify="center"
            width="100%"
            :padding="[32, 22]"
            :minWidth="336"
            hideOverflow
        >
            <Text size="body-extra-small" color="foreground-minus-two" :width="212" align="center">
                {{ emptyStateMessage }}
            </Text>
        </Container>
        <Container v-else :borderRadius="20" width="100%" :minWidth="328" hideOverflow>
            <Stack direction="row" :gap="20" :padding="[16]" borderBottom>
                <TextInput
                    v-model="searchQuery"
                    :ref="entityType"
                    :name="entityType"
                    autocomplete="off"
                    icon="Search"
                />
            </Stack>
            <Stack
                v-if="!entities && parentSelected"
                direction="row"
                :gap="20"
                :padding="[16]"
                borderBottom
            >
                <Spinner />
            </Stack>
            <Container
                v-for="(entity, index) in filteredEntities"
                :key="entity.id"
                color="transparent"
                width="100%"
                clickable
                :showFocusRing="false"
                tabindex="-1"
                @click="selectEntity(entityType, entity.id)"
            >
                <Stack
                    :gap="16"
                    :padding="[16, 20]"
                    :borderBottom="index !== filteredEntities.length - 1"
                >
                    <RadioItemStandalone
                        :modelValue="!!selectedEntity && entity.id === selectedEntity.id"
                    />
                    <Stack direction="column" :gap="4" align="start">
                        <Stack :gap="6">
                            <Text as="h6" size="button" color="foreground" :wrap="false">
                                {{ entity.name }}
                            </Text>
                        </Stack>
                        <Stack :padding="[0, 0, 0, 0]">
                            <Text
                                as="span"
                                size="label-small"
                                color="foreground-minus-one"
                                :wrap="false"
                            >
                                {{ entity.id }}
                            </Text>
                        </Stack>
                    </Stack>
                </Stack>
            </Container>
        </Container>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { computed, ref, watch } from 'vue'

// Types
import { AnalyticsTypes } from '@opteo/types'

// Components
import {
    Label,
    Text,
    TextInput,
    Stack,
    Container,
    Spinner,
    RadioItemStandalone,
} from '@opteo/components-next'

// Props
const props = defineProps<{
    entityType: AnalyticsTypes.MatadataEntityType
    entities?: AnalyticsTypes.MatadataEntityBase[]
    filteredEntities?: AnalyticsTypes.MatadataEntityBase[]
    selectedEntity?: AnalyticsTypes.MatadataEntityBase
    selectEntity: (type: AnalyticsTypes.MatadataEntityType, entityId: string) => void
    searchEntities: (type: AnalyticsTypes.MatadataEntityType, searchQuery: string) => void
    parentSelected: Boolean
    emptyStateMessage?: String
}>()

// Setup
const searchQuery = ref('')

// Watch
watch(searchQuery, update => props.searchEntities(props.entityType, update))

// Computed
const title = computed(() => {
    let entityTitle
    switch (props.entityType) {
        case AnalyticsTypes.MatadataEntityType.Account:
            entityTitle = 'Google Analytics Account'
            break
        case AnalyticsTypes.MatadataEntityType.Property:
            entityTitle = 'Properties & Apps'
            break
        case AnalyticsTypes.MatadataEntityType.Profile:
            entityTitle = 'Views & Data Streams'
            break
    }
    return entityTitle
})
</script>

<style scoped lang="scss"></style>
