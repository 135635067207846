<template>
    <PageWrapper>
        <!-- Header -->
        <PageHeader title="Targets">
            <template #titleSuffix>
                <Text as="p" size="body-extra-small" color="foreground-minus-one">
                    All data taken from the past 90 days.
                </Text>
            </template>

            <template #right>
                <div v-if="!showCampaignGroupsError">
                    <Stack direction="row" :gap="8">
                        <Button
                            size="small"
                            color="secondary"
                            @click="resetModalShown = !resetModalShown"
                        >
                            Reset Groups
                        </Button>
                        <Button
                            size="small"
                            color="secondary"
                            iconBefore="Plus"
                            @click="createNewGroup"
                        >
                            Create Group
                        </Button>
                    </Stack>
                </div>
            </template>
        </PageHeader>

        <!-- Content -->
        <SettingsModalCampaignGroups />

        <!-- Reset Groups Modal -->
        <Modal title="Reset Groups" v-model="resetModalShown" width="474px">
            <template #content>
                <Text as="p" size="body-small">
                    Use this option to reset your campaign groups to their default settings. Your
                    brand campaigns will be sorted into a campaign group called
                    <b>Brand Campaigns</b> and all other campaigns will be sorted by campaign type.
                    Click the button below to confirm and reset.
                </Text>
            </template>
            <template #buttons>
                <Button color="secondary" @click="resetModalShown = false">Cancel</Button>
                <Spacer :width="10" />
                <Button color="primary" @click="resetCampaignGroups" :loading="resetButtonLoading">
                    Confirm and Reset Groups
                </Button>
            </template>
        </Modal>
    </PageWrapper>
</template>

<script setup lang="ts">
// Composition
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useCampaignGroups } from '@/composition/campaignGroups/useCampaignGroups'

// Components
import { Button, Spacer, Text, Modal, Stack } from '@opteo/components-next'
import SettingsModalCampaignGroups from '@/components/improvement/SettingsModalCampaignGroups.vue'

// Setup
const { aboveiPadLandscape } = useMediaQuery()

const {
    showCampaignGroupsError,
    resetModalShown,
    createNewGroup,
    resetCampaignGroups,
    resetButtonLoading,
} = useCampaignGroups()
</script>

<style scoped lang="scss"></style>
