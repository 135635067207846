<template>
    <div class="page-wrapper">
        <ScorecardContent
            v-if="scorecard && isScorecardAvailable && !isDeleted"
            :scorecard-body="scorecard.body"
            :scorecard-history="scorecardHistory"
            :account-info="scorecard.account"
            :created-at="scorecard.createdAt"
            :overview-score="scorecard.overviewScore"
            :download-loading="isDownloading"
            :download-failed="isDownloadFailed"
            @clicked-download="download"
            :viewed-by="viewers"
            mode="live"
            :breakdowns="scorecardBreakdowns"
            :loading-breakdowns="scorecardLoadingBreakdowns"
            @breakdown-requested="sectionType => fetchBreakdown(sectionType)"
        />
        <div v-if="isDeleted" class="scorecard-empty-state-container">
            <div class="empty-state" style="max-width: 13.75rem">
                <img src="@/assets/img/scorecard-empty-state.png" style="width: 13.75rem" />
                <Spacer height="2.5rem" />
                <Text as="p" size="f-8" align="center">
                    This Scorecard has been deleted. Please contact the sender or login to view your
                    latest Scorecards.
                </Text>
                <Spacer height="3rem" />
                <oButton size="large" color="blue" @clicked="goToScorecards">
                    Login to Opteo
                </oButton>
            </div>
        </div>
    </div>
</template>

<script lang="ts">
import { onMounted, ref, computed, onUnmounted, watch } from 'vue'
import { Scorecard } from '@opteo/types'
import { delay } from '@opteo/promise'

// import { showSnackbar, oButton, Text, Spacer } from '@opteo/components-next'

import ScorecardContent from '@/components/scorecard/ScorecardContent.vue'
import { extractSenderInfo, handleIsScorecardAvailable } from '@/components/scorecard/utils'
import { Endpoint, noAuthRequest } from '@/composition/api/useAPI'
import { API_URL } from '@/lib/env'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { useDownloadFile } from '@/composition/api/useDownloadFile'
import { getCurrentCredentials } from '@/composition/auth/useAuth'
import { LS_USER_ID } from '@/lib/cookies'
import { useNoAuthScorecardBreakdowns } from '@/composition/toolkit/scorecard/useScorecardBreakdowns'

export default {
    name: 'Thanks',
    components: {
        //         ScorecardContent,
        //         Spacer,
        //         Text,
        //         oButton,
    },
    setup() {
        const router = useRouter()

        const scorecard = ref<Scorecard.SingleScorecardFull>()

        const isScorecardAvailable = computed(
            () => !!scorecard.value && handleIsScorecardAvailable(scorecard.value)
        )
        const isDeleted = ref(false)
        const scorecardHistory = ref<Scorecard.ScorecardListItem[]>()

        const scorecardLinkId = router.currentRoute.value.params.scorecardLinkId as string

        const goToScorecards = () => {
            const { userId, accountId } = getCurrentCredentials()
            if (userId && accountId) {
                router.push({
                    name: Routes.PerformanceScorecardList,
                    params: { id: userId, accountId },
                })
                return
            }

            router.push({ name: Routes.Login })
        }

        let viewerUpdateInterval: NodeJS.Timeout

        onMounted(async () => {
            const { data, meta } = await noAuthRequest<
                | {
                      data: {
                          scorecard: Scorecard.SingleScorecardFull
                          scorecardHistory: Scorecard.ScorecardListItem[]
                      }
                      meta: { type: 'result' }
                  }
                | { data: string; meta: { type: 'error' } }
            >(`/api/${Endpoint.GetScorecardFromShareableLink}`, {
                meta: {
                    function: 'scorecard:getScorecardFromShareableLink',
                    args: { scorecard_link_id: scorecardLinkId },
                },
            })

            if (meta.type === 'error' && data === 'No scorecard found for that scorecard link id') {
                isDeleted.value = true
            } else if (meta.type === 'error' || typeof data === 'string') {
                throw new Error(data as string)
            } else {
                scorecard.value = data?.scorecard
                scorecardHistory.value = data?.scorecardHistory
                viewerUpdateInterval = setInterval(updateViewers, 2000)
            }
        })

        onUnmounted(() => {
            clearInterval(viewerUpdateInterval)
        })

        const exportScorecardUrl = computed(
            () => `${API_URL}/render/render_scorecard/?scl=${scorecardLinkId}`
        )

        const accountName = computed(() => scorecard.value?.body.coverPageData.accountName || '')
        const filename = computed(() => `${accountName.value} Scorecard.pdf`)

        const {
            isDownloading,
            hasErrored: isDownloadFailed,
            download,
            resetErrorState,
        } = useDownloadFile(exportScorecardUrl, filename, 'application/pdf')

        watch(isDownloadFailed, async newVal => {
            if (newVal) {
                showSnackbar({
                    message: 'Something went wrong. Please try again later.',
                })
                await delay(2000)
                resetErrorState()
            }
        })

        interface ShareableLinkViewer {
            name: string
            avatarUrl: string
            userId: number
            lastSeen: string
        }

        const viewers = ref<Scorecard.LiveViewer[]>([])

        async function updateViewers() {
            const { data, meta } = await noAuthRequest<
                | {
                      data: ShareableLinkViewer[]
                      meta: { type: 'result' }
                  }
                | { data: string; meta: { type: 'error' } }
            >(`/api/${Endpoint.GetShareableLinkViewers}`, {
                meta: {
                    function: 'scorecard:getShareableLinkViewers',
                    args: {
                        scorecard_link_id: scorecardLinkId,
                        user_id: localStorage[LS_USER_ID] ? +localStorage[LS_USER_ID] : undefined,
                    },
                },
            })

            if (meta.type === 'error' || typeof data === 'string') {
                throw new Error(data as string)
            }

            // @ts-expect-error: Not sure what's going on here
            viewers.value = data
        }

        const { scorecardBreakdowns, scorecardLoadingBreakdowns, fetchBreakdown } =
            useNoAuthScorecardBreakdowns({
                scorecardLinkId,
            })

        return {
            scorecard,
            scorecardHistory,
            download,
            isDownloading,
            isDownloadFailed,
            viewers,
            isScorecardAvailable,
            isDeleted,
            goToScorecards,
            fetchBreakdown,
            scorecardBreakdowns,
            scorecardLoadingBreakdowns,
        }
    },
}
</script>

<style scoped lang="scss">
// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .page-wrapper {
// //     padding: 5rem 3rem 0 3rem;
// //     max-width: 1148px;
// //     margin: 0 auto;
// // }

// // // Empty states
// // .scorecard-empty-state-container {
// //     @include w-100;
// //     @include container;
// //     border-radius: 2.75rem;
// //     @include pa-40;
// //     height: 34.375rem;
// //     @include flex-center;
// // }
// // .empty-state {
// //     @include flex;
// //     flex-direction: column;
// //     @include items-center;
// // }

// // // Responsive
// // @media screen and (max-width: $mq-767-max) {
// //     .page-wrapper {
// //         padding: unset;
// //     }
// // }
</style>
