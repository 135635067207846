<template>
    <LinkedAccountsSkeletonTable v-if="teamAccountsLoading" />
    <oTable
        v-else-if="filteredUnlinkedAccounts.length > 0"
        ref="unlinkedAccountsTable"
        :headers="unlinkedTableHeaders"
        :items="filteredUnlinkedAccounts"
        :per-page="24"
        pagination-method="intersection"
        :fixed-layout="true"
        :border-radius="24"
        sidebarAdjust
        :order-by="unlinkedOrderBy"
        order="DESC"
    >
        <template #header.name>
            <Spacer width="1.875rem" />
            <Tooltip v-if="aboveMobile" content="Select All" placement="top">
                <div
                    v-if="updatingLinkedAccounts && allUnlinkedAccountsSelected"
                    class="spinner-container"
                >
                    <Spinner />
                </div>
                <Checkbox
                    v-else
                    :model-value="allUnlinkedAccountsSelected"
                    :inderminate="someUnlinkedAccountsSelected"
                    @click="selectAllAccounts(false)"
                    :disabled="!hasLinkableUnlinkedAccounts"
                />
            </Tooltip>
            <Spacer width="1.25rem" />
            <Text size="f-8" weight="500">Account Name</Text>
        </template>
        <template #header.availableConnections>
            <Text size="f-8" weight="500">
                Changelog Email
                <Tooltip
                    :content="`The email selected in this column will be shown in Change History in ${platformWord} Ads whenever a change is made in Opteo.`"
                    :max-width="260"
                    :delay="[400, 0]"
                >
                    <InfoIcon
                        class="tooltip-info-icon"
                        style="margin-left: 0.375rem; transform: translateY(0.125rem)"
                    />
                </Tooltip>
            </Text>
        </template>
        <template #column.name="items">
            <LinkedAccountsAccountName :account="items.row" :linked="false" />
        </template>
        <template #column.spend30d="{ value, row }">
            <LinkedAccountsSpendCell :value="value" :account="row" :linked="false" />
        </template>
        <template #column.connectedToOpteoTs="{ value, row }">
            <LinkedAccountsTimeDistanceCell :value="value" :account="row" :linked="false" />
        </template>
        <template #column.availableConnections="{ value, row }">
            <div class="changelog-email-cell">
                <LinkedAccountsChangelogSelect
                    :available-connections="row.availableConnections"
                    :account-id="row.accountId"
                />
            </div>
        </template>
        <template #column.teamMembers="items">
            <LinkedAccountsTeamMembers :account="items.row" :is-linked="false" />
        </template>
    </oTable>

    <!-- All accounts are linked -->
    <div v-else-if="unlinkedAccountsCount === 0" class="empty-state-container">
        <div class="empty-state-text" v-if="platform === Platform.Platform.GoogleAds">
            You currently have no accounts available to link to Opteo. You can import an MCC using
            the <b>Import Google Ads MCC</b> button above, or head over to the <b>Linked</b> tab to
            see your linked accounts.
        </div>
        <div class="empty-state-text" v-if="platform === Platform.Platform.MicrosoftAds">
            You currently have no accounts available to link to Opteo. You can import an MCC using
            the <b>Import Microsoft Ads MCC</b> button, or head over to the <b>Linked</b> tab to see
            your linked accounts.
        </div>
        <div class="empty-state-text" v-if="platform === Platform.Platform.MetaAds">
            You currently have no accounts available to link to Opteo. You can import an MCC using
            the <b>Import Meta Ads MCC</b> button, or head over to the <b>Linked</b> tab to see your
            linked accounts.
        </div>
        <div class="empty-state-text" v-if="platform === Platform.Platform.LinkedInAds">
            You currently have no accounts available to link to Opteo. You can import an MCC using
            the <b>Import Linkedin Ads Accounts</b> button, or head over to the <b>Linked</b> tab to
            see your linked accounts.
        </div>
    </div>

    <!-- There are linked accounts but the search query doesn't match any accounts -->
    <div v-else class="empty-state-container">
        <Text as="h2" size="f-2">
            {{ searchedUnlinkedAccounts }}
        </Text>
        <div class="empty-state-text">No accounts matching search query.</div>
    </div>
</template>

<script setup lang="ts">
// import { oTable, Checkbox, Spacer, Text, Tooltip, Spinner } from '@opteo/components-next'
import InfoIcon from '@/components/global/InfoIcon.vue'
import LinkedAccountsTeamMembers from '@/components/linkedAccounts/LinkedAccountsTeamMembers.vue'
import LinkedAccountsChangelogSelect from '@/components/linkedAccounts/LinkedAccountsChangelogSelect.vue'
import LinkedAccountsAccountName from '@/components/linkedAccounts/LinkedAccountsAccountName.vue'
import LinkedAccountsSpendCell from '@/components/linkedAccounts/LinkedAccountsSpendCell.vue'
import LinkedAccountsTimeDistanceCell from '@/components/linkedAccounts/LinkedAccountsTimeDistanceCell.vue'
import LinkedAccountsConnectionStatusCell from '@/components/linkedAccounts/LinkedAccountsConnectionStatusCell.vue'

import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import useMediaQuery from '@/composition/global/useMediaQuery'
import LinkedAccountsSkeletonTable from '@/components/linkedAccounts/LinkedAccountsSkeletonTable.vue'
import { onUnmounted } from 'vue'
import { Platform } from '@opteo/types'

const {
    unlinkedTableHeaders,
    allUnlinkedAccountsSelected,
    someUnlinkedAccountsSelected,
    selectAllAccounts,
    filteredUnlinkedAccounts,
    teamAccountsLoading,
    unlinkedAccountsCount,
    unlinkedVisitedTS,
    unlinkedVisitedUnmountTS,
    unlinkedOrderBy,
    searchedUnlinkedAccounts,
    updatingLinkedAccounts,
    platform,
    platformWord,
    hasLinkableUnlinkedAccounts,
} = useLinkedAccounts()

unlinkedVisitedTS.value = new Date()

onUnmounted(() => {
    unlinkedVisitedUnmountTS.value = new Date()
})

const { aboveMobile } = useMediaQuery()
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // // Empty States
// // .empty-state-container {
// //     @include container;
// //     @include br-24;
// //     @include flex-center;
// //     flex-direction: column;
// //     gap: 1.5rem;
// //     width: 100%;
// //     padding: 8rem 0;
// // }

// // .empty-state-text {
// //     @include f-8;
// //     @include lh-150;
// //     @include tc;
// //     width: 26rem;
// // }


</style>
