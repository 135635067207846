<template>
    <div
        ref="elementToTransform"
        class="account-limits-container"
        :data-position="isFloatingMode ? 'floating' : 'collapsed'"
    >
        <div class="account-limits">
            <div class="account-limit">
                <Text size="f-6">
                    <b><Number :value="linkedAccountsCount" /></b> Linked
                    {{ linkedAccountsCount === 1 ? 'Account' : 'Accounts' }}
                </Text>
                <Spacer height="0.375rem" />
                <ProgressBar
                    :progress="linkedAccountProgress"
                    :projected-progress="
                        linkingAccounts ? projectedLinkedAccountProgress : linkedAccountProgress
                    "
                    :bar-color="linkedAccountProgressColor"
                />
                <Spacer height="0.5rem" />
                <Text color="gray" size="f-9">
                    <b>
                        <Number
                            :value="
                                linkingAccounts
                                    ? projectedAccountsCountRemaining
                                    : accountsCountRemaining
                            "
                        />
                    </b>
                    {{ accountsCountRemaining === 1 ? 'Account' : 'Accounts' }} Remaining
                </Text>
            </div>
            <div class="account-limit-separator" />
            <div class="account-limit">
                <Text size="f-6">
                    <b><Money currency="USD" :value="linkedAccounts30dSpend" /></b>
                    Spend/mo
                </Text>
                <Spacer height="0.375rem" />

                <ProgressBar
                    :progress="spendProgress"
                    :projected-progress="linkingAccounts ? projectedSpendProgress : spendProgress"
                    :bar-color="spendProgressColor"
                />
                <Spacer height="0.5rem" />
                <Text color="gray" size="f-9">
                    <b>
                        <Money
                            currency="USD"
                            :value="
                                linkingAccounts
                                    ? projectedAccounts30dSpendRemaining
                                    : accounts30dSpendRemaining
                            "
                        />
                    </b>
                    Spend/mo Remaining
                </Text>
            </div>
        </div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
// import { Text, Money, Spacer, Number, ProgressBar } from '@opteo/components-next'

import { useWindowSize, useElementSize, useScroll } from '@vueuse/core'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'

const LEFT_NAVBAR_WIDTH = 94 // pixels
const NAVBAR_TRANSITION_HEIGHT = 220 // pixels, distance from the top of the page to the top of the .account-limits container
const NAVBAR_TOP = 24 // pixels
const CONTAINER_VERTICAL_SCALE = 0.8

const navBarTop = computed(() => `${NAVBAR_TOP}px`)

const elementToTransform = ref()

const { width: windowWidth } = useWindowSize()
const { width: elementWidth, height: elementHeight } = useElementSize(elementToTransform)

const { y } = useScroll(document)

const isFloatingMode = computed(() => {
    return y.value < NAVBAR_TRANSITION_HEIGHT
})

const elementTransformY = computed(() => {
    const scaleDifference = 1 - CONTAINER_VERTICAL_SCALE
    return -Math.ceil(NAVBAR_TOP + (scaleDifference * elementHeight.value) / 2)
})

const containerTransformRatio = computed(() => {
    return `translateY(${elementTransformY.value}px) scale(1, ${CONTAINER_VERTICAL_SCALE}) perspective(1px)`
})

const backgroundTransformRatio = computed(() => {
    const horizontalScale = (windowWidth.value - LEFT_NAVBAR_WIDTH) / elementWidth.value
    return `translateY(0px) scale(${horizontalScale}, 1) perspective(1px)`
})

const invertedTransformRatio = computed(() => {
    const verticalScale = 1 / CONTAINER_VERTICAL_SCALE
    return `scale(1, ${verticalScale}) perspective(1px)`
})

const {
    linkedAccountsCount,
    accountsCountRemaining,
    linkedAccounts30dSpend,
    accounts30dSpendRemaining,
    currentPlan,
    projectedLinkedAccountsCount,
    projectedLinkedAccounts30dSpend,
    linkingAccounts,
    teamAccountsLoading,
} = useLinkedAccounts()

const linkedAccountProgress = computed(() => {
    if (!currentPlan.value) return 0

    if (!currentPlan.value?.account_limit) return Infinity
    if (!linkedAccountsCount.value) return 0

    return currentPlan.value?.account_limit &&
        linkedAccountsCount.value > currentPlan.value?.account_limit
        ? 1
        : linkedAccountsCount.value / currentPlan.value?.account_limit
})

const projectedAccountsCountRemaining = computed(() => {
    if (projectedLinkedAccountsCount.value == null) return 0
    if (currentPlan.value?.account_limit == null) return Infinity

    return currentPlan.value?.account_limit &&
        projectedLinkedAccountsCount.value > currentPlan.value?.account_limit
        ? 0
        : currentPlan.value?.account_limit - projectedLinkedAccountsCount.value
})

const projectedLinkedAccountProgress = computed(() => {
    if (!currentPlan.value?.account_limit || !projectedLinkedAccountsCount.value) return 0

    return currentPlan.value?.account_limit &&
        projectedLinkedAccountsCount.value > currentPlan.value?.account_limit
        ? 1
        : projectedLinkedAccountsCount.value / currentPlan.value?.account_limit
})

const spendProgress = computed(() => {
    if (!currentPlan.value) return 0
    if (!currentPlan.value?.spend_limit) return Infinity
    if (!linkedAccounts30dSpend.value) return 0

    return currentPlan.value?.spend_limit &&
        linkedAccounts30dSpend.value > currentPlan.value?.spend_limit
        ? 1
        : linkedAccounts30dSpend.value / currentPlan.value?.spend_limit
})

const projectedAccounts30dSpendRemaining = computed(() => {
    if (projectedLinkedAccounts30dSpend.value == null) return 0
    if (currentPlan.value?.spend_limit == null) return Infinity

    return currentPlan.value?.spend_limit &&
        projectedLinkedAccounts30dSpend.value > currentPlan.value?.spend_limit
        ? 0
        : currentPlan.value?.spend_limit - projectedLinkedAccounts30dSpend.value
})

const projectedSpendProgress = computed(() => {
    if (!currentPlan.value?.spend_limit || !projectedLinkedAccounts30dSpend.value) return 0

    return currentPlan.value?.spend_limit &&
        projectedLinkedAccounts30dSpend.value > currentPlan.value?.spend_limit
        ? 1
        : projectedLinkedAccounts30dSpend.value / currentPlan.value?.spend_limit
})

function determineColor(progressBarType: 'spend' | 'count', loading: boolean) {
    const limit =
        progressBarType === 'spend'
            ? currentPlan.value?.spend_limit
            : currentPlan.value?.account_limit

    const currentValue =
        progressBarType === 'spend'
            ? projectedLinkedAccounts30dSpend.value
            : projectedLinkedAccountsCount.value

    if (loading || limit === null) return 'opteo-gray'

    if (currentValue > (limit ?? 0)) return 'opteo-red'

    return 'client-blue'
}

const linkedAccountProgressColor = computed(() =>
    determineColor('count', teamAccountsLoading.value)
)

const spendProgressColor = computed(() => determineColor('spend', teamAccountsLoading.value))
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .account-limits-container {
// //     position: sticky;
// //     top: v-bind(navBarTop);
// //     z-index: 10;
// //     will-change: transform;
// //     transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
// // }

// // .account-limits-container[data-position='collapsed'] {
// //     transform: v-bind(containerTransformRatio);
// //     border-radius: 0px;
// // }
// // .account-limits-container::after {
// //     content: '';
// //     @include opteo-background;
// //     @include br-32;
// //     width: 100%;
// //     height: 100%;
// //     position: absolute;
// //     z-index: -1;
// //     left: 0;
// //     top: 0rem;
// //     will-change: transform;
// //     transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
// // }

// // .account-limits-container[data-position='collapsed']::after {
// //     transform: v-bind(backgroundTransformRatio);
// //     border-radius: 0px;
// // }
// // .account-limits {
// //     display: grid;
// //     grid-template-columns: 1fr auto 1fr;
// // }
// // .account-limit {
// //     padding: 1.5rem 2.5rem 1.875rem 2.5rem;
// //     transition: 0.6s cubic-bezier(0.19, 1, 0.22, 1);
// //     will-change: transform;
// // }
// // .account-limit-separator {
// //     border-right: 1px solid;
// //     @include opteo-border;
// // }
// // .account-limits-container[data-position='collapsed'] .account-limit {
// //     transform: v-bind(invertedTransformRatio);
// // }


</style>
