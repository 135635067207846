<template>
    <div class="form-card">
        <!-- Account Name -->
        <ImageUploader
            :disabled="imageUploadDisabled"
            location="domain"
            :url="accountLogoUrl ?? ''"
            @uploaded="refreshAccountLogo"
            @afterRemove="refreshAccountLogo"
            noShadow
            border-radius="0"
            class="image-uploader-container"
        >
            <div>
                <div v-if="!accountLogoUrl" class="avatar-no-image">
                    <Avatar
                        :color="accountColor"
                        :width="40"
                        :height="40"
                        :fontSize="12"
                        class="sender-settings-avatar"
                    >
                        {{ accountInitials }}
                    </Avatar>
                    <Text as="span" :style="{ color: accountColor }" class="modal-domain-name">
                        {{ realTimeAccountName }}
                    </Text>
                </div>
                <div class="relative" v-else>
                    <!-- go for 48 x 180 *1.25 = 225 x 60 -->
                    <!-- background-size: contain; means we don't need to maintain a ratio here, have instead used reasonable maximums for each scale based on the container size. this means square logos also look nice and reasonably sized -->
                    <div
                        :style="{
                            backgroundImage: `url(${accountLogoUrl})`,
                            width: `${200}px`,
                            height: `${126}px`,
                        }"
                        style="
                            background-size: contain;
                            background-repeat: no-repeat;
                            border-radius: 12px;
                            background-position: center center;
                        "
                    />
                </div>
            </div>
        </ImageUploader>

        <!-- Inputs -->
        <div class="inner">
            <oForm ref="formRef" class="account-info-form">
                <oInput
                    id="account-name"
                    name="accountName"
                    required
                    :placeholder="'Account Name'"
                    :modelValue="realTimeAccountName"
                    type="text"
                    label="Account Name"
                    :autofocus="autofocusInput"
                    @updated="updateRealTimeAccountName"
                />
                <oInput
                    id="scorecard-website"
                    name="website"
                    :placeholder="'website.com'"
                    :modelValue="website"
                    type="text"
                    label="Website"
                />
                <oInput
                    id="scorecard-industry"
                    name="industry"
                    :placeholder="'Industry'"
                    :modelValue="industry"
                    type="text"
                    label="Industry"
                />
                <oInput
                    id="scorecard-location"
                    name="location"
                    :placeholder="'City, State'"
                    :modelValue="location"
                    type="text"
                    label="Location"
                />
            </oForm>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue'

// import { oForm, oInput, Text, Avatar } from '@opteo/components-next'
import ImageUploader from '@/components/global/ImageUploader.vue'
import { useAccount } from '@/composition/account/useAccount'
import { useDomain } from '@/composition/domain/useDomain'
import { getAccountInitials } from '../utils'

const { accountColor } = useAccount()
const { domainName, mutateDomainLogo } = useDomain()

const props = defineProps<{
    registerSubmitFunction: (submitFunction: () => {}) => void
    onAccountLogoUrlUpdate?: (imageUrl: string | null) => void
    imageUploadDisabled?: boolean
    accountLogoUrl?: string
    autofocusInput?: boolean
    accountName: string
    website: string | null
    industry: string | null
    location: string | null
}>()

const formRef = ref()
const { registerSubmitFunction, onAccountLogoUrlUpdate } = props
onMounted(() => registerSubmitFunction(formRef.value.submit))

const refreshAccountLogo = (url: string | null) => {
    mutateDomainLogo()
    onAccountLogoUrlUpdate && onAccountLogoUrlUpdate(url)
}

const realTimeAccountName = ref(props.accountName || domainName.value)
const accountInitials = computed(() => getAccountInitials(realTimeAccountName.value))

const updateRealTimeAccountName = (updated: string) => {
    realTimeAccountName.value = updated
}
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .form-card {
// //     @include container;
// //     @include br-28;
// //     overflow: hidden;
// // }

// // .avatar-no-image {
// //     @include flex-center;
// // }

// // .image-uploader-container {
// //     min-height: 14rem;
// //     @include flex-center;
// //     border-bottom: 1px solid $opteo-light-gray;
// // }

// // .modal-domain-name {
// //     margin-left: 0.875rem;
// //     font-size: 1.625rem;
// //     letter-spacing: -0.05rem;
// //     font-weight: 700;
// //     white-space: nowrap;
// //     max-width: 19rem;
// //     overflow: hidden;
// //     text-overflow: ellipsis;
// //     line-height: 2rem;
// // }

// // .inner {
// //     max-width: 20rem;
// //     margin: 2rem auto 2.25rem auto;
// // }
// // .account-info-form {
// //     @include flex;
// //     flex-direction: column;
// //     gap: 1.5rem;
// // }


</style>
