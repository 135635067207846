import { computed, ref, watch } from 'vue'
import { useRoute } from 'vue-router'
import { Improvement } from '@opteo/types'
import groupBy from 'lodash-es/groupBy'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'
import differenceInMonths from 'date-fns/differenceInMonths'
import isThisYear from 'date-fns/isThisYear'
import isThisMonth from 'date-fns/isThisMonth'
import getYear from 'date-fns/getYear'

import { Endpoint, useAPI } from '../api/useAPI'
import { useAccount } from '../account/useAccount'

export function useCompletedImprovements() {
    const { accountId, accountInfo } = useAccount()

    const route = useRoute()
    const improvementOpen = computed(() => !!route.params.improvementId)

    const limit = ref(50)
    const {
        data: completedImprovements,
        error,
        isValidating: moreLoading,
        loading,
        mutate,
    } = useAPI<Improvement.CompletedObject[]>(Endpoint.ListCompletedImprovements, {
        body: () => {
            return {
                limit: limit.value,
            }
        },
        uniqueId: () => `${accountId.value}`,
        waitFor: () => accountId.value,
    })

    watch(limit, () => mutate())

    const improvements = computed(() => {
        // Group improvements
        const grouped = groupBy(completedImprovements.value, i => {
            const completedDate = parseISO(i.completed_timestamp as unknown as string)
            const currentYear = getYear(new Date())

            if (isThisMonth(completedDate)) {
                return 'This Month'
            } else if (differenceInMonths(new Date(), completedDate) <= 3) {
                return 'Last 3 Months'
            } else if (isThisYear(completedDate)) {
                return currentYear.toString()
            } else {
                return format(completedDate, 'yyyy')
            }
        })

        // Sort groups
        const sortedKeys = Object.keys(grouped).sort((a, b) => {
            const parseGroup = (key: string) => {
                if (key === 'This Month') return new Date().getTime()
                if (key === 'Last 3 Months') return new Date().setMonth(new Date().getMonth() - 3)
                return new Date(key).getTime() // Parse year or date string
            }
            return parseGroup(b) - parseGroup(a) // Descending order
        })

        // Return sorted array
        return sortedKeys.map(key => ({
            label: key,
            items: grouped[key],
        }))
    })

    const moreAvailable = computed(() => (accountInfo.value?.tasksCompleted ?? 0) >= limit.value)

    return {
        improvements,
        loading,
        error,
        improvementOpen,
        limit,
        moreAvailable,
        moreLoading,
    }
}
