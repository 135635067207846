import { onMounted, onUnmounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { Routes } from '@/router/routes'

type Layout = 'default-layout' | 'full-screen' | 'external'

export const layout = ref<Layout>('default-layout')

const showIntercomDespiteFullscreen: Routes[] = [
    // Add routes here that should still show the Intercom bubble when in full-screen mode
]

export function useLayouts(newLayout: Layout) {
    const { showBubble, hideBubble } = useIntercom()
    const { currentRoute } = useRouter()
    onMounted(() => {
        layout.value = newLayout

        if (
            newLayout === 'full-screen' &&
            !showIntercomDespiteFullscreen.includes((currentRoute.value.name as Routes) ?? '')
        ) {
            hideBubble()
        }
    })

    onUnmounted(() => {
        layout.value = 'default-layout'
        showBubble()
    })

    return {
        layout,
    }
}
