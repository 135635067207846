<template>
    <PageWrapper v-if="!improvementOpen">
        <!-- Skeletons -->
        <ImprovementGroup v-if="loading">
            <template #titlePrefix>
                <Skeleton :height="16" :width="128" />
            </template>
            <template #content>
                <template v-for="skid in 20" :key="skid">
                    <ImprovementRowSkeleton />
                </template>
            </template>
        </ImprovementGroup>

        <!-- EmptyState -->
        <EmptyState
            v-else-if="Object.keys(improvements).length === 0"
            icon="SparklesExtraLarge"
            headline="No Completed Improvements"
            message="All of your completed improvements will appear here. Switch to the Active tab to check and complete your first improvement."
            hideContainer
            :padding="0"
            :contentMaxWidth="376"
        >
            <Button iconAfter="ArrowUpRight" color="secondary" @click="goToActive">
                Go to Active
            </Button>
        </EmptyState>

        <!-- Completed Improvements -->
        <Stack v-else direction="column" :gap="0">
            <ImprovementGroup
                v-for="(group, index) in improvements"
                :title="group.label"
                :key="group.label"
                :first="index === 0"
                :last="index === improvements.length - 1"
                :style="{ zIndex: improvements.length - index + 1 }"
            >
                <template #titleSuffix>
                    <IndicatorTag color="green">
                        {{ group.items.length }}
                        Improvement<span v-if="group.items.length !== 1">s</span>
                    </IndicatorTag>
                </template>
                <template #content>
                    <template
                        v-for="(improvement, index) in group.items"
                        :key="improvement.improvement_id"
                    >
                        <ImprovementRowCompleted :improvement="improvement" :completed="true" />
                    </template>
                </template>
            </ImprovementGroup>
            <Stack v-if="moreAvailable" :padding="20" width="100%" justify="center">
                <Button
                    @click="limit = limit + 100"
                    :loading="moreLoading"
                    color="secondary"
                    size="small"
                >
                    Show More
                </Button>
            </Stack>
        </Stack>
    </PageWrapper>

    <!-- Improvement Template -->
    <router-view v-else />
</template>

<script setup lang="ts">
// Imports
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useCompletedImprovements } from '@/composition/improvement/useCompletedImprovements'
import { useDomain } from '@/composition/domain/useDomain'

// Components
import { Button, EmptyState, Stack, Skeleton } from '@opteo/components-next'
import ImprovementGroup from '@/components/improvement/ImprovementGroup.vue'
import ImprovementRowSkeleton from '@/components/improvement/ImprovementRowSkeleton.vue'
import ImprovementRowCompleted from '@/components/improvement/ImprovementRowCompleted.vue'
import IndicatorTag from '@/components/global/IndicatorTag.vue'

// Setup
const { improvements, loading, moreAvailable, moreLoading, limit, improvementOpen } =
    useCompletedImprovements()
const router = useRouter()

// Functions
function goToActive() {
    router.push({ name: Routes.ImprovementsActive })
}
</script>

<style scoped lang="scss"></style>
