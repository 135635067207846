import { parseEnvBool, parseEnvString, parseEnvNumber } from './env_utils'

const env = import.meta.env // will load from .env.* file

export const NODE_ENV: AppEnvironment = env.VITE_NODE_ENV

// The different environments the application can run in
export enum AppEnvironment {
    Development = 'development', // Local development & running unit tests
    Production = 'production', // app.opteo.com (main branch)
    Demo = 'demo', // demo.opteo.com (main branch)
    Staging = 'staging', // staging.opteo.com (dev branch) & deploy previews
}

// Useful vars for checking the current app environment
export const IS_DEVELOPMENT = NODE_ENV === AppEnvironment.Development
export const IS_PRODUCTION = NODE_ENV === AppEnvironment.Production
export const IS_DEMO = NODE_ENV === AppEnvironment.Demo
export const IS_STAGING = NODE_ENV === AppEnvironment.Staging

//
// Behaviour flags set per-environment in env.* files
//

// Urls
export const API_URL = parseEnvString(env.VITE_API_URL)

// Tokens & keys
export const INTERCOM_APP_ID = parseEnvString(env.VITE_INTERCOM_APP_ID)
export const CANNY_BOARD_TOKEN = parseEnvString(env.VITE_CANNY_BOARD_TOKEN)
export const SLACK_CLIENT_ID = parseEnvString(env.VITE_SLACK_CLIENT_ID)
export const STRIPE_PUBLISHABLE_KEY = parseEnvString(env.VITE_STRIPE_PUBLISHABLE_KEY)

// Toggling 3rd party scripts
export const LOAD_INTERCOM = parseEnvBool(env.VITE_LOAD_INTERCOM)
export const LOAD_GTM = parseEnvBool(env.VITE_LOAD_GTM)
export const LOAD_PROFITWELL = parseEnvBool(env.VITE_LOAD_PROFITWELL)
export const LOAD_SENTRY = parseEnvBool(env.VITE_LOAD_SENTRY)
export const SENTRY_ENV = parseEnvString(env.VITE_SENTRY_ENV)

// App behaviour flags
export const ALWAYS_SHOW_ONBOARDING = parseEnvBool(env.VITE_ALWAYS_SHOW_ONBOARDING)
export const SKIP_ONBOARDING_DISMISSAL = parseEnvBool(env.VITE_SKIP_ONBOARDING_DISMISSAL)
export const ENFORCEMENT = parseEnvBool(env.VITE_ENFORCEMENT)
export const MOCK_IMP_DISMISSALS = parseEnvBool(env.VITE_MOCK_IMP_DISMISSALS)
export const IMP_PUSH_MODE = parseEnvString(env.VITE_IMP_PUSH_MODE)
export const ENABLE_PERSISTENT_SWRV_CACHE = parseEnvBool(env.VITE_ENABLE_PERSISTENT_SWRV_CACHE)
export const OBFUSCATE_ENTITY_PILLS = parseEnvBool(env.VITE_OBFUSCATE_ENTITY_PILLS, false)

//Stytch
export const STYTCH_PUBLIC_TOKEN = parseEnvString(env.VITE_STYTCH_PUBLIC_TOKEN)

if (!IS_PRODUCTION) {
    console.debug('Environment variables:', {
        NODE_ENV,
        API_URL,
        INTERCOM_APP_ID,
        CANNY_BOARD_TOKEN,
        SLACK_CLIENT_ID,
        STRIPE_PUBLISHABLE_KEY,
        LOAD_INTERCOM,
        LOAD_GTM,
        LOAD_PROFITWELL,
        LOAD_SENTRY,
        SENTRY_ENV,
        ALWAYS_SHOW_ONBOARDING,
        SKIP_ONBOARDING_DISMISSAL,
        ENFORCEMENT,
        MOCK_IMP_DISMISSALS,
        IMP_PUSH_MODE,
        ENABLE_PERSISTENT_SWRV_CACHE,
        OBFUSCATE_ENTITY_PILLS,
    })
}
