<template>
    <PageHeader title="Alerts">
        <template #titleSuffix>
            <Button color="secondary" size="extra-small" @click="markAllAsRead">
                Mark All Read
            </Button>
        </template>
        <template #right>
            <Button color="secondary" size="small" iconBefore="Settings">Settings</Button>
        </template>
    </PageHeader>
    <PageContent>
        <!-- Skeletons -->
        <Stack v-if="loading" direction="column" width="100%" :gap="0" :padding="0">
            <StickyHeader :padding="above480 ? [0, 20] : [0, 14]">
                <template #titlePrefix>
                    <Skeleton :height="16" :width="128" />
                </template>
                <template #titleSuffix>
                    <Button color="secondary" size="extra-small">Mark as Read</Button>
                </template>
            </StickyHeader>
            <AlertRow v-for="skid in 20" :key="skid" />
        </Stack>
        <!-- Groups -->
        <template v-else-if="groupedAlerts.length">
            <Stack
                v-for="(group, i) in groupedAlerts"
                :key="group.label"
                direction="column"
                width="100%"
                :gap="0"
            >
                <!-- Header -->
                <StickyHeader :padding="above480 ? [0, 20] : [0, 14]" :title="group.label">
                    <template #titleSuffix>
                        <Button
                            color="secondary"
                            size="extra-small"
                            @click="markGroupAsRead(group.label)"
                        >
                            Mark as Read
                        </Button>
                    </template>
                </StickyHeader>

                <!-- Alerts -->
                <Stack direction="column" width="100%" :gap="0" :padding="0" borderBottom>
                    <AlertRow v-for="alert in group.items" :key="alert.id" :alert="alert" />
                </Stack>

                <!-- Show more -->
                <Stack
                    v-if="group.totalCount > group.items.length"
                    width="100%"
                    justify="center"
                    borderBottom
                    :padding="20"
                >
                    <Button @click="showMore(group.label)" color="secondary" size="small">
                        Show More
                    </Button>
                </Stack>
            </Stack>
        </template>
        <!-- Empty State -->
        <EmptyState
            v-else
            icon="SparklesExtraLarge"
            message="Your alerts will appear here. Opteo continuously monitors your accounts for budget issues, spend anomalies, and much more. You'll see a red notification bubble in the sidebar whenever a new alert is generated."
            hideContainer
            :padding="0"
            :contentMaxWidth="340"
        >
            <Button iconAfter="ArrowUpRight" color="secondary" @click="goToAccounts">
                Go to Accounts
            </Button>
        </EmptyState>
    </PageContent>
    <router-view />
</template>

<script setup lang="ts">
// Imports
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useAlerts } from '@/composition/alerts/useAlerts'

// Components
import {
    Button,
    Stack,
    EmptyState,
    StickyHeader,
    Skeleton,
    useMediaQuery,
} from '@opteo/components-next'
import AlertRow from '@/components/alerts/AlertRow.vue'

// Setup
const { markAllAsRead, loading, showMore, groupedAlerts, markGroupAsRead } = useAlerts()
const router = useRouter()
const { above480 } = useMediaQuery()

// Functions
function goToAccounts() {
    router.push({ name: Routes.AccountCentre })
}
</script>

<style scoped lang="scss"></style>
