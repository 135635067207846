<template>
    <Text as="h5" size="f-5" weight="600" style="line-height: 1.75rem">
        {{ alert?.title }}
    </Text>
    <Spacer height="1.5rem" />
    <EntityPillList
        v-if="campaignGroupName.length > 40"
        :items="[{ type: 'campaign-group', content: campaignGroupName }]"
    />
    <EntityPill v-else type="campaign-group" :content="campaignGroupName" />
    <Spacer height="1.5rem" />
    <Text as="p" size="f-8" v-if="isConversion">
        Last week, the above campaign group earned a record
        <b><Number :value="metricTotal" /></b> conversions. That roughly equates to a
        <b><Percent :value="percent" /></b> improvement when compared with an average week.
        Congratulations!
    </Text>
    <Text as="p" size="f-8" v-else>
        Last week, the above campaign group achieved a record CPA of
        <b><Money :value="metricTotal" :currency="currencyCode" /></b>. That roughly equates to a
        <b><Percent :value="percent" /></b> improvement when compared with an average week.
        Congratulations!
        <div v-if="hasMultiConversions">
            <Spacer height="1rem" />
            <Text as="p">
                CPA was calculated by combining all conversions from the
                {{ conversionsString }} conversion types currently enabled in this campaign group.
            </Text>
        </div>
    </Text>
    <Spacer height="2rem" />
    <div class="chart-container">
        <AreaChart
            :series="chartData"
            :metric="chartMetric"
            :horizontal-markers="avgConversionsMarker"
            :show-zero="true"
            :chart-height="180"
            disable-fallback
        />
    </div>
    <Spacer height="2rem" />
    <AlertNotes :alert-date="isoDate" :account-id="alert?.accountId ?? ('' as Account.ID)" />
</template>

<script lang="ts">
import { computed, defineComponent } from 'vue'
// import {
//     Text,
//     Spacer,
//     Percent,
//     Money,
//     Number,
//     AreaChart,
//     AreaChartTypes,
//} from '@opteo/components-next'
import parseISO from 'date-fns/parseISO'
import { Account, ConversionAlert } from '@opteo/types'
import sumBy from 'lodash-es/sumBy'

import { EntityPillLink, EntityPillLinkList } from '@/components/global/Entity'
import AlertNotes from '@/components/notes/AlertNotes.vue'
import Ent from '@/components/util/Ent.vue'
import { useAlert } from '@/composition/alerts/useAlert'

export default defineComponent({
    name: 'Flatline',
    components: {

//         AlertNotes,
//         Text,
//         Spacer,
//         Number,
//         Percent,
//         Money,
//         Ent,
//         AreaChart,
//         EntityPillLinkList,
//         EntityPillLink,
    
},
    setup() {
        const { alert, currencyCode } = useAlert<
            ConversionAlert.Body,
            ConversionAlert.WeekConversions[]
        >()
        if (!alert.value) {
            throw new Error('An alert ID must be set before initializing this alert.')
        }

        const isoDate = parseISO(alert.value.ts)

        const isConversion = alert.value?.body.is_conversion
        const campaignGroupName = alert.value?.body.group.name
        const avgOfMetric = alert.value.body.average

        // Either Conversions or CPA
        const metricTotal = alert.value.body.previous_week_total
        const percent = alert.value.body.delta / 100

        const conversionActions = computed(() => alert.value?.body.group.conversion_types ?? [])

        const hasMultiConversions = conversionActions.value.length > 1
        // Either "x and y" or "14" conversions
        const conversionsString =
            conversionActions.value.length === 2
                ? conversionActions.value.join(' and ')
                : conversionActions.value.length

        let chartData: AreaChartTypes.AreaChartSeries[] = []

        if (isConversion) {
            chartData = conversionActions.value.map(conversionAction => {
                return {
                    name: conversionAction,
                    items: alert.value!.chart.map(row => {
                        const weekConvTotal = sumBy(row.conversions, convAction =>
                            convAction.conversion_name === conversionAction ? convAction.total : 0
                        )

                        const x = new Date(row.day)
                        const y = weekConvTotal

                        return { x, y }
                    }),
                }
            })
        } else {
            chartData = [
                {
                    name: 'CPA',
                    items: alert.value.chart.map(row => {
                        const weekConvTotal = sumBy(row.conversions, convAction =>
                            conversionActions.value.includes(convAction.conversion_name)
                                ? convAction.total
                                : 0
                        )

                        const x = new Date(row.day)
                        const y = weekConvTotal !== 0 ? row.cost / weekConvTotal : 0

                        return { x, y }
                    }),
                },
            ]
        }

        const avgConversionsMarker = [
            { label: isConversion ? 'Average Conversions' : 'Average CPA', value: avgOfMetric },
        ]

        const chartMetric = computed(() => {
            return isConversion
                ? {
                      label: 'Conversions',
                      dataType: 'number',
                  }
                : {
                      label: 'CPA',
                      currency: currencyCode.value,
                      dataType: 'money',
                  }
        })

        return {
            alert,
            isConversion,
            campaignGroupName,
            metricTotal,
            percent,
            hasMultiConversions,
            conversionsString,
            chartData,
            isoDate,
            avgConversionsMarker,
            currencyCode,
            chartMetric,
        }
    },
})
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .chart-container {
// //     @include container;
// //     @include pa-24;
// // }


</style>
