<template>
    <TransitionGroup
        name="groups"
        key="groups"
        tag="div"
        :css="shouldEnableTransition"
        @before-leave="handleBeforeLeaveGroup"
    >
        <slot />
    </TransitionGroup>
</template>

<script setup lang="ts">
// Props
const props = defineProps({
    shouldEnableTransition: {
        type: Boolean,
        required: true,
        default: false,
    },
})

// Emits
const emit = defineEmits(['disable-move', 'enable-move'])

// handleBeforeLeaveGroup
const handleBeforeLeaveGroup = (element: Element) => {
    const el = element as HTMLElement
    const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

    element.setAttribute(
        'style',
        `left: ${el.offsetLeft - parseInt(marginLeft)}px;
        top: ${el.offsetTop - parseInt(marginTop)}px;
        width: ${width};
        height: ${height};`
    )

    // Disable child `move` transition
    emit('disable-move')
    setTimeout(() => {
        emit('enable-move')
    }, 320)
}
</script>

<style scoped lang="scss">
div {
    @include w-100;
}
</style>
