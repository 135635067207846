<template>
    <svg width="28" height="30" viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M21.691 3.00255C19.7312 1.12884 17.2645-.00145961 13.9996.00000141 10.7253.00146942 8.25766 1.17617 6.30534 3.0615c-1.93684 1.87039-3.22388 4.41603-3.3049 7.1396L3 10.216v2.7839c0 1.6811-.60274 2.5892-1.28087 3.4368-.07589.0949-.15741.1932-.2422.2953l-.00009.0002C.833046 17.508 0 18.512 0 19.9998c0 1.0037.515491 1.8262 1.26801 2.4501.73614.6103 1.7508 1.0811 2.93982 1.4443 1.31762.4026 2.93315.6978 4.79706.8814-.00325.0739-.00489.1483-.00489.223 0 2.7614 2.2386 5 5 5s5-2.2386 5-5c0-.0747-.0016-.1491-.0049-.223 1.8639-.1836 3.4795-.4788 4.7971-.8814 1.189-.3632 2.2037-.834 2.9398-1.4443.7525-.6239 1.268-1.4464 1.268-2.4501 0-1.4878-.833-2.4918-1.4768-3.2676l-.0001-.0001c-.0848-.1022-.1663-.2005-.2422-.2954C25.6027 15.5891 25 14.681 25 12.9999V10.1229l-.0005-.0157c-.085-2.71281-1.3663-5.24792-3.3085-7.10465ZM16.9991 24.9246C16.0486 24.9747 15.0472 25 14 25s-2.0486-.0253-2.9991-.0754c-.0006.0246-.0009.0493-.0009.074 0 1.6569 1.3431 3 3 3s3-1.3431 3-3c0-.0247-.0003-.0494-.0009-.074ZM14.0004 2c2.7351-.00123 4.7103.92023 6.3086 2.44818 1.6121 1.54126 2.6202 3.5919 2.691 5.70652v2.8452c0 2.3176.8973 3.6589 1.7191 4.6862.0912.114.1776.2207.2591.3215l.0003.0004C25.6552 18.8444 26 19.2705 26 19.9998c0 .247-.1095.5498-.5445.9104-.4514.3742-1.1867.7472-2.2477 1.0713C21.0934 22.6275 17.9554 23 14 23c-3.9554 0-7.09336-.3725-9.20783-1.0185-1.06098-.3241-1.79632-.6971-2.24768-1.0713C2.10951 20.5496 2 20.2468 2 19.9998c0-.7293.34476-1.1554 1.0215-1.9918l.0001-.0001c.08161-.1009.16804-.2077.25927-.3218C4.10274 16.6588 5 15.3175 5 12.9999v-2.7537c.06745-2.12362 1.0807-4.18744 2.69466-5.74602C9.29679 2.95302 11.2747 2.00122 14.0004 2Z"
            fill="#373744"
        />
    </svg>
</template>
