<template>
    <div class="keyword-destination-entity">
        <div
            role="button"
            :class="['parent-entity', { expanded }, { uncheckable: entity?.uncheckable }]"
            @click="handleParentClick(entity)"
        >
            <div class="entity-item">
                <Checkbox v-if="!entity.uncheckable" v-model="entity.checked" />
                <Spacer v-if="!entity.uncheckable" width="0.75rem" />
                <EntityPill :type="entity.type" :content="entity.label" :color="color" />
            </div>
            <div v-if="entity.children?.length" class="expand-button-container">
                <oButton
                    @clicked="handleExpandClick(entity, $event)"
                    :circle="true"
                    size="x-small"
                    color="white"
                >
                    <template #icon>
                        <DownCaretSmall :class="{ 'rotate-arrow': !expanded }" />
                    </template>
                </oButton>
            </div>
        </div>
        <perfect-scrollbar v-if="expanded" ref="childrenList" class="child-entities">
            <div
                role="button"
                v-for="(child, index) in entity.children"
                :key="'child-' + index"
                :class="[
                    'child-entity',
                    { last: entity.children && index === entity.children.length - 1 },
                ]"
                @click="handleChildClick(child)"
            >
                <div :id="child.source ? 'selectedChild' : ''" class="entity-item">
                    <Checkbox v-model="child.checked" />
                    <Spacer width="1rem" />
                    <EntityPill :type="child.type" :content="child.label" />
                    <div class="source-tag">
                        <ColorTag v-if="child.source" color="blue" title="Source" />
                    </div>
                </div>
            </div>
        </perfect-scrollbar>
    </div>
</template>

<script setup lang="ts">
// import { Checkbox, EntityPill, Spacer, oButton, ColorTag } from '@opteo/components-next'
import DownCaretSmall from '@/components/global/DownCaretSmall.vue'
import { updateCheckedValues } from './utils'
// maybe there's a better place to put this type like @opteo/types
import { Entity } from './keyword-destination-types'
import { computed, inject, Ref, ref, onMounted } from 'vue'

export interface Props {
    entity: Entity
    color?: string
}

const props = withDefaults(defineProps<Props>(), {})

const emit = defineEmits<{
    (e: 'update:entity', entity: Entity): void
}>()

const childrenList = ref()

type SelectorAPI = {
    expandedIds: Ref<string[]>
    toggleExpanded: (id: string) => void
}

const { expandedIds, toggleExpanded } = inject('selector') as SelectorAPI

const expanded = computed(() => expandedIds.value.includes(props.entity.id))

onMounted(() => {
    if (expanded?.value && props?.entity?.type === 'campaign') {
        setTimeout(() => {
            const selectedChild = document.getElementById('selectedChild')
            const scrollPositionY = selectedChild?.getBoundingClientRect().top
            const parentPositionY = childrenList.value.$el.getBoundingClientRect().top

            if (!scrollPositionY) {
                return
            }

            childrenList.value.$el.scrollTo({
                top: scrollPositionY - parentPositionY - 16,
                left: 0,
                behavior: 'auto',
            })
        }, 120)
    }
})

function handleParentClick(entity: Entity) {
    if (entity.uncheckable && entity.children?.length) {
        toggleExpanded(entity.id)
    } else {
        const updatedEntity = { ...entity, checked: !entity.checked }
        emit('update:entity', updatedEntity)
    }
}

function handleChildClick(childEntity: Entity) {
    const updatedChildren = updateCheckedValues(props.entity.children, childEntity)
    const updatedEntity = { ...props.entity, children: updatedChildren }

    emit('update:entity', updatedEntity)
}

function handleExpandClick(entity: Entity, event: Event) {
    event.stopPropagation()
    toggleExpanded(entity.id)
}
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .keyword-destination-entity {
// //     @include container;
// //     @include br-20;
// //     overflow: hidden;
// // }
// // .keyword-destination-entity:not(:last-child) {
// //     @include mb-16;
// // }

// // .parent-entity {
// //     @include flex;
// //     @include items-center;
// //     @include justify-between;
// //     padding: 1rem 1.5rem;
// //     cursor: pointer;
// //     position: relative;
// //     // @include opteo-focus;
// // }

// // .parent-entity.expanded {
// //     border-bottom: 1px solid;
// //     @include opteo-border;
// // }

// // .expand-button-container {
// //     position: absolute;
// //     top: 0;
// //     right: 0;
// //     bottom: 0;
// //     z-index: 3;
// //     background-color: white;
// //     @include flex-center;
// //     padding-right: 1.5rem;
// //     padding-left: 0rem;
// // }

// // .expand-button-container:before {
// //     content: '';
// //     background: #fff;
// //     background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgb(255, 255, 255) 80%);
// //     width: 6rem;
// //     left: -6rem;
// //     height: calc(100% - 8px);
// //     top: 4px;
// //     position: absolute;
// //     pointer-events: none;
// // }

// // .child-entities {
// //     max-height: 304px;
// // }
// // .child-entity {
// //     padding: 1rem 1.5rem;
// //     cursor: pointer;
// //     @include relative;
// //     // @include opteo-focus;
// // }
// // .child-entity:not(.last) {
// //     border-bottom: 1px solid;
// //     @include opteo-border;
// // }

// // .entity-item {
// //     @include flex;
// //     @include items-center;
// // }
// // .source-tag {
// //     @include absolute;
// //     right: 0rem;
// //     background: rgb(255, 255, 255);
// //     background: linear-gradient(270deg, rgba(255, 255, 255, 1) 80%, rgba(255, 255, 255, 0) 100%);
// //     height: 100%;
// //     @include flex-center;
// //     @include pr-24;
// //     @include pl-48;
// // }


</style>
