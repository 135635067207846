import { computed, ref, watch } from 'vue'
import uniqBy from 'lodash-es/uniqBy'
import orderBy from 'lodash-es/orderBy'
import { Account, UserSettings } from '@opteo/types'
import { Button, showSnackbar } from '@opteo/components-next'

import { Endpoint, useAPI, authRequest } from '@/composition/api/useAPI'
import { useDeepLinking } from '@/composition/global/useDeepLinking'
import { openInNewTab } from '@/lib/globalUtils'
import { useDomain } from '../domain/useDomain'
import { useUser } from '../user/useUser'
import { useAccount } from '../account/useAccount'

export function useAccountSettings() {
    const { accountId } = useAccount()
    const { domainId, loading: domainLoading } = useDomain()
    const { groupId } = useUser()

    /* 
        Deep linking section 
    */

    const { ocid: currentOcid, isOcidLoading: isCurrentOcidLoading, mutateOcid } = useDeepLinking()

    const googleAdsOcid = ref(currentOcid ?? '')
    const saveOcidErrorMessage = ref<string | null>(null)

    // TODO(deepLinking): oInput is not generating a declaration file. Fix this and add this type: <InstanceType<typeof oInput> | null>
    const saveGoogleAdsOcidInput = ref()
    const saveGoogleAdsOcidButton = ref<InstanceType<typeof Button> | null>(null)

    const saveOcid = async () => {
        if (!groupId.value) {
            handleSaveOcidError('No Group ID found')
            return
        }

        try {
            await authRequest(Endpoint.SaveOcid, {
                body: {
                    ocid: googleAdsOcid.value,
                    accountId: accountId.value,
                    groupId: groupId.value,
                },
            })

            mutateOcid(Number(googleAdsOcid.value))
            showSnackbar({
                message: `Google Ads OCID successfully set to <b>${googleAdsOcid.value}</b>`,
            })
        } catch (err) {
            const errorMessage = err instanceof Error ? err.message : 'Unknown error'

            handleSaveOcidError(errorMessage)
        }
    }

    const handleSaveOcidError = (errorMessage: string) => {
        saveOcidErrorMessage.value = errorMessage

        saveGoogleAdsOcidInput.value.setError(saveOcidErrorMessage.value)

        showSnackbar({
            message: `Error: ${errorMessage}`,
        })

        throw new Error(errorMessage)
    }

    // NOTE(deepLinking): idea, we could add more states for OCID testing, such as valid or invalid and add an additional step for the user to confirm
    // after opening link in a new tab: if valid, show "Save" button, else ask them to try again
    const isOcidTested = ref(false)

    const testIsOcidValid = () => {
        openInNewTab(`https://ads.google.com/aw/overview?ocid=${googleAdsOcid.value}`)

        isOcidTested.value = true
    }

    /*
        Alerts section
    */

    const saveAlertSettingsButton = ref()
    const saveAlertSettingsButtonLoading = ref(false)
    const { data: accountEmailMuted } = useAPI<boolean>(Endpoint.GetAccountEmailSettings, {
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const { data: slackSettings } = useAPI<UserSettings.SlackAlertSettings>(
        Endpoint.GetSlackSettings
    )

    const muteSettings = ref({
        email: accountEmailMuted.value,
        slack: slackSettings.value?.muted_accounts?.includes(accountId.value as Account.ID),
    })

    const updateMuteSettingsValues = () => {
        muteSettings.value.email = accountEmailMuted.value
        muteSettings.value.slack = slackSettings.value?.muted_accounts?.includes(
            accountId.value as Account.ID
        )
    }

    watch(accountEmailMuted, updateMuteSettingsValues)
    watch(slackSettings, updateMuteSettingsValues)
    watch(accountId, updateMuteSettingsValues)

    const saveAlertSettings = async () => {
        if (!accountId.value) {
            throw new Error('Account ID is not defined')
        }
        const mutedSlackAccounts = (slackSettings.value?.muted_accounts ?? []).filter(
            mutedAccountId => mutedAccountId !== accountId.value
        )
        if (muteSettings.value.slack) {
            mutedSlackAccounts.push(accountId.value)
        }

        saveAlertSettingsButtonLoading.value = true

        await Promise.all([
            authRequest(Endpoint.SaveSlackSettings, {
                body: {
                    new_settings: { muted_accounts: mutedSlackAccounts },
                    get_muted_accounts: false,
                },
            }),
            authRequest(Endpoint.MuteAccountEmails, {
                body: { mute: muteSettings.value.email },
            }),
        ])

        saveAlertSettingsButtonLoading.value = false

        showSnackbar({
            message: `Alert Settings updated successfully`,
        })
    }

    /*
       Country section
    */
    const saveCountryButton = ref()
    const saveCountryButtonLoading = ref(false)
    const { data: countryRaw, mutate: refreshCountryFromServer } = useAPI<string>(
        Endpoint.GetCountry,
        { uniqueId: () => accountId.value, waitFor: () => accountId.value }
    )
    const { data: timezone } = useAPI<string>(Endpoint.GetTimezone, {
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })
    const { data: countries } = useAPI<
        {
            country: string
            country_code: string
            tld: string
            international_dialing: string
        }[]
    >(Endpoint.GetCountryTld, { uniqueId: () => accountId.value, waitFor: () => accountId.value })

    const countryDropdownItems = computed(() =>
        orderBy(uniqBy(countries.value ?? [], 'country'), 'country', 'asc').map(row => {
            return {
                label: row.country,
                value: row.country,
            }
        })
    )

    watch(countryRaw, newVal => {
        if (newVal) {
            countryMutable.value = newVal
        }
    })

    const countryMutable = ref<string>()

    const saveCountry = async () => {
        saveCountryButtonLoading.value = true

        await authRequest(Endpoint.SaveCountry, {
            body: { country: countryMutable.value },
        })

        refreshCountryFromServer()
        saveCountryButtonLoading.value = false

        if (countryMutable.value) {
            showSnackbar({
                message: `SERP Country successfully set to <b>${countryMutable.value}</b>`,
            })
        } else {
            showSnackbar({
                message: `Please select a country to override this setting`,
            })
        }
    }

    /*
       Connection section
    */

    const saveConnectionButton = ref()
    const saveConnectionButtonLoading = ref(false)
    const { data: connections } = useAPI<
        {
            connectionId: number
            loginCustomerId: string
            email: string
            isCurrent: boolean
        }[]
    >(Endpoint.GetPossibleConnectionsForAccount, {
        body() {
            return { account_id: accountId.value }
        },
        uniqueId: () => accountId.value,
        waitFor: () => accountId.value,
    })

    const connectionDropdownItems = computed(() =>
        (connections.value ?? []).map(connection => {
            return {
                label: connection.email,
                value: connection.connectionId,
            }
        })
    )

    const currentConnection = computed(() =>
        (connections.value ?? []).find(connection => connection.isCurrent)
    )

    const saveConnection = async ({ connectionId }: { connectionId: number }) => {
        connectionId = +connectionId

        saveConnectionButtonLoading.value = true

        const loginCustomerId = (connections.value ?? []).find(
            c => c.connectionId === connectionId
        )?.loginCustomerId

        await authRequest(Endpoint.SetAccountConnection, {
            body: {
                group_id: groupId.value,
                account_id: accountId.value,
                connection_id: connectionId,
                login_customer_id: loginCustomerId,
            },
        })

        saveConnectionButtonLoading.value = false
        showSnackbar({
            message: `Change History Email successfully set to <b>${
                connections.value?.find(c => c.connectionId === connectionId)?.email
            }</b>`,
        })
    }

    /*
       CPI override section. Hopefully to be removed in favor of improvement algo settings
    */

    const saveCpiButtonLoading = ref(false)
    const { data: cpiSetting } = useAPI<boolean>(Endpoint.GetCpiOverride, {
        uniqueId: () => domainId.value,
        waitFor: () => domainId.value,
    })

    const cpiSettingLocal = ref(!!cpiSetting.value)

    watch(cpiSetting, newVal => {
        cpiSettingLocal.value = newVal
    })

    const updateCpiSetting = (newSetting: boolean) => {
        cpiSettingLocal.value = newSetting
    }

    const saveCpiSetting = async () => {
        saveCpiButtonLoading.value = true

        await authRequest(Endpoint.SaveCpiOverride, {
            body: { domain_id: domainId.value, cpi_override: cpiSettingLocal },
        })

        saveCpiButtonLoading.value = false

        showSnackbar({
            message: `CPI settings updated successfully.`,
        })
    }

    return {
        muteSettings,
        slackSettings,
        saveAlertSettingsButton,
        saveAlertSettingsButtonLoading,
        saveAlertSettings,
        countryMutable,
        timezone,
        countries,
        countryRaw,
        countryDropdownItems,
        saveCountryButton,
        saveCountryButtonLoading,
        saveCountry,
        connections,
        connectionDropdownItems,
        currentConnection,
        saveConnection,
        saveConnectionButton,
        saveConnectionButtonLoading,
        cpiSetting,
        cpiSettingLocal,
        saveCpiSetting,
        saveCpiButtonLoading,
        updateCpiSetting,
        domainColours: Account.Colours,
        domainLoading,
        currentOcid,
        isCurrentOcidLoading,
        googleAdsOcid,
        isOcidTested,
        saveOcid,
        saveGoogleAdsOcidButton,
        saveGoogleAdsOcidInput,
        testIsOcidValid,
    }
}
