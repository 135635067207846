<template>
    <BatchBar
        v-model="isOpen"
        :count="batchedImprovementsLength"
        :showShield="true"
        :showClearSelectedButton="false"
        label="Improvement"
        sticky
    >
        <!-- Icon -->
        <template #icon>
            <IconSlot :width="22" :height="22" class="dark">
                <!-- Default -->
                <CheckIcon v-if="!currentImpBatchPushing && !batchDone" color="blue" animated />
                <!-- Done -->
                <CheckIcon
                    v-else-if="batchDone && countPerState.pushed && !countPerState.failed"
                    color="green"
                    animated
                />
                <!-- Warn -->
                <WarnIcon
                    v-else-if="
                        (batchDone && countPerState.failed) || (batchDone && countPerState.skipped)
                    "
                    color="gray"
                    animated
                />
                <!-- Dismissed -->
                <DismissIcon
                    v-else-if="batchDone && countPerState.dismissed"
                    color="gray"
                    animated
                />
                <!-- Loading -->
                <Spinner monochrome v-else />
            </IconSlot>
        </template>
        <!-- Text -->
        <template #text>
            <Transition name="blur-fade" mode="out-in">
                <!-- Default -->
                <Text v-if="!currentImpBatchPushing && !batchDone" as="span" color="white">
                    {{ batchedImprovementsLength }}
                    Improvement{{ batchedImprovementsLength === 1 ? '' : 's' }}
                    Selected
                </Text>
                <!-- Done -->
                <Text v-else-if="batchDone">
                    <Text v-if="countPerState.dismissed" as="span" color="white">
                        {{ countPerState.dismissed }}
                        Improvement{{ countPerState.dismissed === 1 ? '' : 's' }} Dismissed
                    </Text>
                    <Text v-if="countPerState.pushed" as="span" color="white">
                        {{ countPerState.pushed }}
                        Improvement{{ countPerState.pushed === 1 ? '' : 's' }} Pushed{{
                            countPerState.skipped || countPerState.failed ? ', ' : ''
                        }}
                    </Text>
                    <Text v-if="countPerState.skipped" as="span" color="white">
                        {{ countPerState.skipped }}
                        <span v-if="!countPerState.pushed">
                            Improvement{{ countPerState.skipped === 1 ? '' : 's' }}
                        </span>
                        Skipped{{ countPerState.failed ? ', ' : '' }}
                    </Text>
                    <Text v-if="countPerState.failed" as="span" color="white">
                        {{ countPerState.failed }}
                        <span v-if="!countPerState.pushed && !countPerState.skipped">
                            Improvement{{ countPerState.failed === 1 ? '' : 's' }}
                        </span>
                        Failed
                    </Text>
                </Text>
                <!-- Loading -->
                <Text v-else as="span" color="white">
                    Pushing
                    {{ batchedImprovementsLength }}
                    Improvement{{ batchedImprovementsLength === 1 ? '' : 's' }}
                </Text>
            </Transition>
        </template>

        <!-- Buttons -->
        <template #buttons>
            <Transition name="blur-fade" mode="out-in">
                <Stack v-if="!batchDone" direction="row" :gap="8">
                    <!-- Clear -->
                    <Button
                        color="batch-bar"
                        fullWidth
                        :disabled="batchQueueRunning"
                        @click="clearBatch"
                    >
                        Clear <span v-if="above768">Selected</span>
                    </Button>
                    <!-- Dismiss -->
                    <Menu
                        buttonColor="batch-bar"
                        containerColor="batch-bar-popout"
                        buttonModeColor="batch-bar-popout"
                        buttonLabel="Dismiss"
                        :buttonIconAfter="above480 ? 'CaretDown' : ''"
                        :disabled="batchQueueRunning"
                        :items="[
                            {
                                value: 'dismissWeek',
                                label: 'Dismiss for 1 Week',
                                icon: 'Circle',
                                action: () => dismiss('week'),
                            },
                            {
                                value: 'dismissMonth',
                                label: 'Dismiss for 1 Month',
                                icon: 'Calendar',
                                action: () => dismiss('month'),
                            },
                            {
                                value: 'dismissIndefinitely',
                                label: 'Dismiss Indefinitely',
                                icon: 'Error',
                                action: () => dismiss('forever'),
                            },
                        ]"
                    />
                    <!-- Push -->
                    <Button
                        color="green"
                        :iconAfter="above480 ? 'ArrowUpRight' : undefined"
                        :loading="batchQueueRunning"
                        fullWidth
                        @click="pushBatch"
                    >
                        Push
                        <span v-if="above480">
                            {{ batchedImprovementsLength }} Improvement<span
                                v-if="batchedImprovementsLength != 1"
                                >s
                            </span>
                        </span>
                    </Button>
                </Stack>
                <!-- cleanupBatch -->
                <Button
                    v-else
                    color="batch-bar"
                    iconAfter="ArrowUpRight"
                    @click="cleanupBatch"
                    fullWidth
                >
                    Continue
                </Button>
            </Transition>
        </template>
    </BatchBar>
</template>

<script setup lang="ts">
// Imports
import { computed, ref, PropType } from 'vue'
import countBy from 'lodash-es/countBy'

// Composition
import { useBatchBox } from '@/composition/improvement/useBatchBox'
import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import type { DismissDuration } from '@/composition/improvement/types'

// Components
import {
    BatchBar,
    Button,
    CheckIcon,
    WarnIcon,
    DismissIcon,
    Spinner,
    IconSlot,
    Stack,
    Text,
    Menu,
    useMediaQuery,
} from '@opteo/components-next'

const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    batchedImprovements: {
        type: Array as PropType<QueuedImprovement[]>,
        required: true,
    },
    batchedImprovementsLength: {
        type: Number as PropType<number>,
        required: true,
    },
    batchQueueRunning: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const dismissPopoutOpen = ref(false)
const bar = ref()
const { above480, above768 } = useMediaQuery()
const mobileDismissRangeVisible = ref(false)
const { currentImpBatchPushing, batchDone, pushBatch, dismissBatch, clearBatch, cleanupBatch } =
    useBatchBox()

// Computed
const isOpen = computed({
    get: () => props.modelValue,
    set: value => {
        emit('update:modelValue', value)
    },
})
const countPerState = computed(() => {
    return countBy(props.batchedImprovements, i => i.state)
})
const progressColor = computed(() => {
    if (!currentImpBatchPushing.value) {
        return 'opteo-gray'
    }
    if (
        currentImpBatchPushing.value.progress >= 1 &&
        currentImpBatchPushing.value.state !== 'failed'
    ) {
        return 'client-green'
    }
    if (currentImpBatchPushing.value.state === 'failed') {
        return 'opteo-red'
    }

    return 'client-blue'
})
const progressBarSpeed = computed(() => {
    const progress = currentImpBatchPushing.value?.progress
    if (!progress || progress === 0) {
        return 0
    }
    if (progress === 1) {
        return 1
    }

    return 10
})

// Functions
const dismiss = (duration: DismissDuration) => {
    dismissBatch(duration)
}
const mobileDismissedClicked = () => {
    mobileDismissRangeVisible.value = true
}
const backToActions = () => {
    mobileDismissRangeVisible.value = false
}
</script>

<style scoped lang="scss"></style>
