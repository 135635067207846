<template>
    <Container
        color="background-plus-one"
        :borderRadius="999"
        :padding="[6, 12, 6, 11]"
        clickable
        @click="emit('click')"
    >
        <Stack direction="row" :gap="8">
            <Dot :size="10" :color="color" />
            <Text as="span" size="button-small" color="foreground" :wrap="false">
                <slot></slot>
            </Text>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { PropType } from 'vue'

// Components
import { Container, Stack, Text, Dot } from '@opteo/components-next'

// Props
const props = defineProps({
    color: {
        type: String as PropType<string>,
        required: true,
        default: 'green',
    },
})

// Emits
const emit = defineEmits(['click'])
</script>

<style scoped lang="scss"></style>
