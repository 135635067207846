<template>
    <Modal v-model="alertModalOpen" title="Alert" @closed="onModalClosed" @opened="onModalOpened">
        <template #content>
            <Container
                width="100%"
                :minWidth="320"
                :maxWidth="500"
                :borderRadius="20"
                :padding="20"
            >
                <Stack direction="column" align="start" :gap="20">
                    <Alert />
                    <Button color="secondary" size="small" @click="openFullPage">
                        Open in Full Page
                    </Button>
                </Stack>
            </Container>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import router from '@/router'
import { Routes } from '@/router/routes'

// Composition
import { provideAlert, setFloatingAlertId } from '@/composition/alerts/useAlert'

// Components
import { Modal, Button, Container, Stack } from '@opteo/components-next'
import Alert from '@/components/alerts/Alert.vue'

// Setup
const { alert, markAsRead, alertModalOpen } = provideAlert()

const { currentRoute } = useRouter()
const isFullScreenAlert = computed(() => {
    return !!currentRoute.value.params.alertId
})

watch(alert, newVal => {
    if (newVal && !isFullScreenAlert.value) {
        alertModalOpen.value = true
    } else {
        alertModalOpen.value = false
    }
})

const onModalOpened = () => {
    markAsRead()
}

const onModalClosed = () => {
    setFloatingAlertId(undefined)
}

const fullPageAlertRoute = computed(() => {
    return {
        name: Routes.Alert,
        params: {
            alertId: alert.value?.hashId,
        },
    }
})
const openFullPage = () => {
    router.push(fullPageAlertRoute.value)
    alertModalOpen.value = false
}
</script>

<style scoped lang="scss"></style>
