import { computed, Ref, ref, watch } from 'vue'
import { Domain, Account, Tracking } from '@opteo/types'

import { useIntersectionObserver } from '@vueuse/core'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { useAccountList } from './useAccountList'

// TODO(types): Move these to @opteo/types
export interface AccountRowData {
    sparkline_data: {
        cost_sparkline: SingleSparklineData
        conversion_sparkline: SingleSparklineData
    }
    budget_bar: BudgetBar
}

// TODO(types): Move these to @opteo/types
export interface SingleSparklineData {
    points: { x: number; y: number }[]
    thirty_day_total: number
    delta: number
}

// TODO(types): Move these to @opteo/types
export interface BudgetBar {
    budget: number
    budget_variance: number
    actual_spend: number
    has_budget: boolean
    has_budget_auto_pause: boolean
    display_difference: number
    month_progress_percentage: number
    month_spent_percentage: number
    status: BudgetStatus
}

// TODO(types): Move these to @opteo/types
export enum BudgetStatus {
    FAR_OVER = 'far_over',
    OVER = 'over',
    GOOD = 'good',
    UNDER = 'under',
    FAR_UNDER = 'far_under',
    TOO_EARLY = 'too_early',
    INVALID = 'invalid',
}

const DELTA_MIN = 10

export function useDomainListRow(accountId: Ref<Account.ID>) {
    const accountCentreRow = ref(null)
    const rowIsVisible = ref(false)
    const { mutateDomainList } = useAccountList()

    useIntersectionObserver(accountCentreRow, ([{ isIntersecting }]) => {
        if (isIntersecting) {
            rowIsVisible.value = true
        }
    })
    // This is rare in vueture, but here we want to cover the server error case to show a special message
    const {
        data: rowMetrics,
        mutate: mutateRowMetrics,
        error: rowMetricsError,
    } = useAPI<Domain.ListMetrics>(Endpoint.GetUserAccountsMetrics, {
        body: () => {
            return { account_id: accountId.value }
        },
        // If the mutate function is called from any other it should still work if the row is not visible
        waitFor: () => (!accountCentreRow.value || rowIsVisible.value) && accountId.value,
        uniqueId: () => accountId.value,
    })

    const getDeltaColour = (delta: number): string => {
        let deltaColour = '#D0D0D3'
        if (delta >= DELTA_MIN) deltaColour = '#00A861'
        if (delta <= -DELTA_MIN) deltaColour = '#FF2828'
        return deltaColour
    }

    const domainListMetricsHasError = ref(false)

    watch(rowMetricsError, () => {
        domainListMetricsHasError.value = true
    })

    const spendChart = computed(() => rowMetrics.value?.sparkline_data.cost_sparkline)
    const convChart = computed(() => {
        if (domainListMetricsHasError.value) return undefined
        return rowMetrics.value?.sparkline_data.conversion_sparkline
    })

    const budgetBar = computed(() => {
        if (domainListMetricsHasError.value) {
            return
        }
        return rowMetrics.value?.budget_bar!
    })
    const loadingMetrics = computed(() => !rowMetrics.value)

    const isError = computed(() => domainListMetricsHasError.value)

    const updateClient = async (domainName: string, color: Account.ColourHexCode) => {
        // Emit to domainList to update domain name & color by refreshing entire list
        mutateDomainList(accountId.value, {
            name: domainName,
            color,
        })

        await Promise.all([
            authRequest(Endpoint.SaveAccountColorPreference, {
                body: { account_id: accountId.value, color_preference: color },
            }),
            authRequest(Endpoint.SaveAccountDisplayName, {
                body: { account_id: accountId.value, display_name: domainName },
            }),
        ])

        mutateDomainList()
    }

    return {
        isError,
        loadingMetrics,
        spendChart,
        convChart,
        budgetBar,
        rowIsVisible,
        accountCentreRow,
        getDeltaColour,
        updateClient,
        mutateRowMetrics,
        rowMetrics,
    }
}
