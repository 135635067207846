<template>
    <PageHeader title="Linked Accounts" ref="pageHeader">
        <template #right-content>
            <Popout
                v-model="showPlatformSwitcher"
                :externalToggleButton="true"
                :trapFocus="true"
                :initialFocus="initialFocus"
                :offset="[0, -2]"
                :zIndex="9999999999999"
                placement="bottom"
            >
                <oButton
                    color="white"
                    @clicked="showPlatformSwitcher = !showPlatformSwitcher"
                    :icon-before="true"
                >
                    <template #icon>
                        <GoogleAdsLogo
                            v-if="platform === Platform.Platform.GoogleAds"
                            :width="18"
                        />

                        <MetaAdsLogo
                            v-else-if="
                                betaFeatures?.metaBeta && platform === Platform.Platform.MetaAds
                            "
                            :width="22"
                        />

                        <LinkedInLogo
                            v-else-if="
                                betaFeatures?.linkedInBeta &&
                                platform === Platform.Platform.LinkedInAds
                            "
                            :width="22"
                        />

                        <MicrosoftAdsLogo v-else-if="betaFeatures?.microsoftGamma" :width="18" />
                        <MicrosoftAdsLogoDisabled v-else :width="18" />
                    </template>
                    <div class="button-inner">
                        {{ platformCopy[platform] }}
                        <Spacer width="0.75rem" height="0.25rem" />
                        <SmallDownCaret style="transform: translateY(0.0625rem)" />
                    </div>
                </oButton>
                <template #content>
                    <div class="platform-switcher-content">
                        <button
                            class="platform"
                            ref="googleAdsButton"
                            @click="switchPlatform(Platform.Platform.GoogleAds)"
                        >
                            <GoogleAdsLogo :width="18" />
                            <Text as="span" size="f-9" weight="500">Google Ads Accounts</Text>
                        </button>

                        <button
                            :disabled="!betaFeatures?.microsoftGamma"
                            class="platform"
                            @click="switchPlatform(Platform.Platform.MicrosoftAds)"
                        >
                            <MicrosoftAdsLogo v-if="betaFeatures?.microsoftGamma" :width="18" />
                            <MicrosoftAdsLogoDisabled v-else :width="18" />
                            <Text as="span" size="f-9" weight="500">Microsoft Ads Accounts</Text>
                        </button>

                        <button
                            v-if="betaFeatures?.metaBeta"
                            class="platform"
                            @click="switchPlatform(Platform.Platform.MetaAds)"
                        >
                            <MetaAdsLogo :width="22" />

                            <Text as="span" size="f-9" weight="500">Meta Ads Accounts</Text>
                        </button>

                        <button
                            v-if="betaFeatures?.linkedInBeta"
                            class="platform"
                            @click="switchPlatform(Platform.Platform.LinkedInAds)"
                        >
                            <LinkedInLogo :width="22" />

                            <Text as="span" size="f-9" weight="500">LinkedIn Accounts</Text>
                        </button>
                    </div>
                </template>
            </Popout>
        </template>
    </PageHeader>

    <PageContent>
        <LinkedAccountsLimits v-if="platform === Platform.Platform.GoogleAds" />
        <Spacer height="2.5rem" v-if="platform === Platform.Platform.GoogleAds" />
        <router-view />
    </PageContent>

    <Modal
        title="Assign Team Members"
        width="640px"
        v-model="assignTeamModalOpen"
        :noPadding="true"
        container-class="modal-z-max"
        contentMaxHeight="30rem"
    >
        <template #content>
            <div
                role="table"
                aria-label="Assign Team Members"
                aria-describedby="team-members-table-description"
                :aria-rowcount="teamMembers.length"
            >
                <div id="team-members-table-description">
                    Assign Team Members for the selected accounts
                </div>
                <div class="team-members-table-header" role="rowgroup">
                    <div role="row">
                        <Tooltip :content="aboveMobile ? 'Select All' : undefined" placement="top">
                            <Checkbox
                                @click="assignAllTeamMembers"
                                :model-value="allTeamMembersAssigned"
                            />
                        </Tooltip>
                        <Spacer width="1.25rem" />
                        <Text
                            as="span"
                            size="f-8"
                            weight="500"
                            role="columnheader"
                            aria-sort="none"
                        >
                            Team Member
                        </Text>
                        <Spacer width="1.25rem" />
                        <div class="team-members-search">
                            <SmallSearchIcon class="search-icon" />
                            <oInput
                                v-model="searchedTeamMember"
                                class="team-members-search-input"
                                name="searchTeamMembers"
                            />
                        </div>
                    </div>
                </div>
                <perfect-scrollbar role="rowgroup">
                    <div
                        v-if="filteredTeamMembers.length > 0"
                        v-for="(user, index) in filteredTeamMembers"
                        :aria-rowindex="index"
                        :class="[
                            filteredTeamMembers.length === index + 1 ? 'last' : '',
                            'team-members-table-item',
                        ]"
                        role="row"
                        :data-user-id="user.userId"
                    >
                        <div role="cell" @click="assignTeamMember(user.userId)">
                            <Checkbox :model-value="assignedTeamMembers.includes(user.userId)" />
                            <Spacer width="1.25rem" />
                            <ProfileImage
                                :width="36"
                                :height="36"
                                :isAdmin="user.isAdmin"
                                :image-url="user.profileUrl ?? undefined"
                                :avatar-url="getAvatarUrl(user.userId)"
                                :hide-role="true"
                            />
                            <Spacer width="1rem" />
                            <div>
                                <Text size="f-8" weight="500">{{ user.name }}</Text>
                                <Text color="gray" size="f-9">{{ user.email }}</Text>
                            </div>
                        </div>
                    </div>
                    <div v-else-if="searchedTeamMember.length && filteredTeamMembers.length === 0">
                        <div role="cell">
                            <div class="team-members-empty-state">
                                <Text as="h2" size="f-2">
                                    {{ searchedTeamMember }}
                                </Text>

                                <Text size="f-8" align="center"
                                    >No team members match search query.</Text
                                >
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div role="cell">
                            <div class="team-members-empty-state">
                                <Text size="f-8" align="center">No team members.</Text>
                            </div>
                        </div>
                    </div>
                </perfect-scrollbar>
            </div>
        </template>
        <template #buttons>
            <template v-if="linkingAccounts">
                <oButton @clicked="assignTeamModalOpen = false" color="white"> Cancel </oButton>
                <Spacer width="0.5rem" />
                <oButton
                    @clicked="updateLinkedAccountStatusWithTeamMembers"
                    :loading="updatingLinkedAccounts"
                    icon-before
                >
                    <template #icon>
                        <UserPlusIcon />
                    </template>
                    Confirm and Link
                    {{ selectedUnlinkedAccountsCount === 1 ? 'Account' : 'Accounts' }}
                </oButton>
            </template>
            <template v-else>
                <oButton
                    ref="removeTeamMembersButton"
                    @clicked="assignTeamMembersToAccounts(false)"
                    :loading="removingTeamMembers"
                    icon-before
                >
                    <template #icon>
                        <UserMinusIcon />
                    </template>
                    Remove Team {{ assignedTeamMembers.length <= 1 ? 'Member' : 'Members' }}
                </oButton>
                <Spacer width="0.5rem" />
                <oButton
                    ref="addTeamMembersButton"
                    @clicked="assignTeamMembersToAccounts(true)"
                    :loading="addingTeamMembers"
                    icon-before
                >
                    <template #icon>
                        <UserPlusIcon />
                    </template>
                    Add Team {{ assignedTeamMembers.length <= 1 ? 'Member' : 'Members' }}
                </oButton>
            </template>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { provideLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useUser } from '@/composition/user/useUser'

// Types
import { Platform } from '@opteo/types'

// Components
// import {
//     oButton,
//     Text,
//     Spacer,
//     Modal,
//     Checkbox,
//     ProfileImage,
//     Tooltip,
//     oInput,
//     Link,
//     GoogleAdsLogo,
//     Popout,
//     MicrosoftAdsLogo,
//     MetaAdsLogo,
//     LinkedInLogo,
//} from '@opteo/components-next'
import LinkedAccountsLimits from '@/components/linkedAccounts/LinkedAccountsLimits.vue'
import UserPlusIcon from '@/components/linkedAccounts/icons/UserPlusIcon.vue'
import SmallSearchIcon from '@/components/linkedAccounts/icons/SmallSearchIcon.vue'
import SmallDownCaret from '@/components/linkedAccounts/icons/SmallDownCaret.vue'
import MicrosoftAdsLogoDisabled from '@/components/linkedAccounts/icons/MicrosoftAdsLogoDisabled.vue'

// Setup
const {
    linkingAccounts,
    assignTeamModalOpen,
    teamMembers,
    allTeamMembersAssigned,
    assignedTeamMembers,
    searchedTeamMember,
    filteredTeamMembers,
    assignTeamMember,
    assignAllTeamMembers,
    updateLinkedAccountStatusWithTeamMembers,
    selectedUnlinkedAccountsCount,
    updatingLinkedAccounts,
    removeTeamMembersButton,
    addTeamMembersButton,
    removingTeamMembers,
    addingTeamMembers,
    assignTeamMembersToAccounts,
    platform,
} = provideLinkedAccounts()
const { betaFeatures } = useUser()
const { push } = useRouter()
const { getAvatarUrl } = useBoringAvatar()
const { aboveMobile } = useMediaQuery()

const showPlatformSwitcher = ref(false)
const googleAdsButton = ref()
const initialFocus = () => googleAdsButton.value

function switchPlatform(selectedPlatform: Platform.Platform) {
    if (selectedPlatform === Platform.Platform.GoogleAds) {
        push({ name: Routes.LinkedAccountsGoogle })
    } else if (selectedPlatform === Platform.Platform.MicrosoftAds) {
        if (betaFeatures.value?.microsoftGamma) {
            push({ name: Routes.LinkedAccountsMicrosoft })
        } else {
            console.warn('Microsoft Ads Gamma is not enabled')
        }
    } else if (selectedPlatform === Platform.Platform.MetaAds) {
        if (betaFeatures.value?.metaBeta) {
            push({ name: Routes.LinkedAccountsMeta })
        } else {
            console.warn('Meta Ads Beta is not enabled')
        }
    } else if (selectedPlatform === Platform.Platform.LinkedInAds) {
        if (betaFeatures.value?.linkedInBeta) {
            push({ name: Routes.LinkedAccountsLinkedIn })
        } else {
            console.warn('LinkedIn Ads Beta is not enabled')
        }
    }
}

const platformCopy = {
    [Platform.Platform.GoogleAds]: 'Google Ads Accounts',
    [Platform.Platform.MicrosoftAds]: 'Microsoft Ads Accounts',
    [Platform.Platform.MetaAds]: 'Meta Ads Accounts',
    [Platform.Platform.LinkedInAds]: 'LinkedIn Ads Accounts',
} as const satisfies Record<Platform.Platform, string>

watch(assignTeamModalOpen, open => {
    if (!open && searchedTeamMember.value.length > 0) {
        searchedTeamMember.value = ''
    }
})
</script>

<style scoped lang="scss">
// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // // common styles for linked and unlinked routes

// // :deep(.account-name-cell) {
// //     display: inline-flex;
// //     align-items: center;
// //     padding-left: 1.875rem;
// //     width: 100%;
// //     cursor: pointer;
// //     overflow: hidden;
// // }

// // :deep(.changelog-email-cell) {
// //     display: flex;
// //     align-items: center;
// //     justify-content: flex-start;
// // }

// // :deep(.tooltip-info-icon) {
// //     display: inline-block;
// //     width: 14px;
// // }

// // // Linked Account Name
// // :deep(.linked-accounts-row-name) {
// //     display: flex;
// //     align-items: center;
// // }

// // // Linked Account Avatars
// // :deep(.linked-accounts-team-avatars) {
// //     display: flex;
// //     align-items: center;
// //     position: relative;
// //     z-index: 1;
// // }

// // :deep(.profile-image-item) {
// //     border-radius: 100%;
// //     border: 2px solid $opteo-white;
// // }
// // :deep(.profile-image-item:not(:first-child)) {
// //     margin-left: -0.5rem;
// // }

// // :deep(.button-inner) {
// //     @include flex;
// //     @include items-center;
// //     height: 13px;
// // }

// // // Platform Switcher
// // .platform-switcher-content {
// //     width: 14.375rem;
// //     @include pa-12;
// //     @include flex;
// //     flex-direction: column;
// //     gap: 0.5rem;
// // }
// // .platform-switcher-content .platform {
// //     all: unset;
// //     border-radius: 0.875rem;
// //     padding: 0.8125rem 1rem;
// //     @include flex;
// //     @include items-center;
// //     width: 100%;
// //     box-sizing: border-box;
// //     gap: 0.625rem;
// //     transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
// //     cursor: pointer;
// // }
// // .platform-switcher-content .platform[disabled] {
// //     box-shadow: none !important;
// //     cursor: default;
// //     transform: none !important;
// //     user-select: none;
// // }
// // .platform-switcher-content .platform[disabled] span {
// //     @include opteo-medium-gray;
// // }
// // .platform-switcher-content .platform:hover {
// //     box-shadow: $opteo-shadow;
// // }
// // .platform-switcher-content .platform:focus {
// //     outline: none;
// //     box-shadow: $opteo-shadow-focus;
// // }

// // .platform-switcher-content .platform:active {
// //     outline: none;
// //     box-shadow: $opteo-shadow-focus;
// //     transform: translate3d(0, 1px, 0);
// // }

// // // Tabs
// // .notification-fade-enter-active {
// //     transition:
// //         transform 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6),
// //         opacity 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6);
// // }
// // .notification-fade-leave-active {
// //     transition:
// //         transform 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6),
// //         opacity 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6);
// // }
// // .notification-fade-enter-from,
// // .notification-fade-leave-to {
// //     transform: scale(20%);
// //     opacity: 0;
// // }

// // // styles for modal
// // .team-members-table-header {
// //     @include opteo-foreground;
// //     @include bg-opteo-white;
// //     border-bottom: 1px solid;
// //     @include opteo-border;
// // }
// // .team-members-table-header div[role='row'] {
// //     padding: 0.875rem 1.75rem;
// // }

// // #team-members-table-description {
// //     display: none;
// // }
// // div[role='row'],
// // div[role='cell'] {
// //     width: 100%;
// //     display: flex;
// //     align-items: center;
// //     cursor: pointer;
// // }
// // div[role='cell'] {
// //     padding: 1.375rem 1.75rem;
// //     @include flex;
// //     @include items-center;
// // }
// // div[role='rowgroup'] {
// //     max-height: 21.1875rem;
// // }
// // div[role='cell'] p {
// //     line-height: 1.25rem;
// // }
// // .team-members-table-item:not(.last) div[role='cell'] {
// //     border-bottom: 1px solid;
// //     @include opteo-border;
// // }

// // .modal-z-max {
// //     z-index: 99999999999999999999999;
// // }
// // .team-members-search {
// //     max-width: 12.5rem;
// //     @include relative;
// // }
// // .team-members-search .search-icon {
// //     @include absolute;
// //     top: 50%;
// //     transform: translateY(-50%);
// //     left: 0.875rem;
// // }
// // :deep(.team-members-search-input input) {
// //     padding: 12px 14px 12px 38px;
// // }

// // .team-members-empty-state {
// //     @include flex-center;
// //     flex-direction: column;
// //     gap: 1.125rem;
// //     width: 100%;
// //     padding: 2.75rem 0;
// // }
</style>
