import { ref, Ref, computed, nextTick } from 'vue'
import { useColorMode } from '@vueuse/core'
import { useDevice } from '@opteo/components-next'
import { Billing } from '@opteo/types'
import {
    loadStripe,
    Stripe,
    PaymentMethod,
    StripeElements,
    StripePaymentElement,
    StripePaymentElementOptions,
    StripeError,
} from '@stripe/stripe-js'
import { useAPI, Endpoint } from '@/composition/api/useAPI'
import { API_URL, STRIPE_PUBLISHABLE_KEY } from '@/lib/env'
import { useSubscription } from './useSubscription'

// Composition
const mode = useColorMode({ disableTransition: false })
const { isPaintCompatible } = useDevice()

// Appearance configuration
const appearanceLight = {
    theme: 'flat',
    variables: {
        fontFamily: '"Inter", sans-serif',
        fontSizeBase: '14px',
        fontSizeXs: '13.74px',
        spacingUnit: '5px',
        fontLineHeight: '1.5',
        borderRadius: '13px',
        colorPrimary: '#0007D6',
        colorText: '#0B0B0D',
        colorDanger: '#FF1414',
        colorBackground: '#F5F5FA',
        colorTextSecondary: '#6A6A75',
        iconColor: '#B6B6C2',
        colorTextPlaceholder: '#B6B6C2',
    },
    rules: {
        '.AccordionItem': {
            border: 'none',
            boxShadow: '0 0 0 1px rgba(0, 2, 82, 0.03)',
            borderRadius: '0px',
        },
        '.AccordionItem:focus-visible': {
            boxShadow: '0 0 0 1px rgba(0, 2, 82, 0.03)',
            color: 'var(--colorPrimary)',
        },
        '.PickerItem': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#ffffff',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.PickerItem:hover': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#ffffff',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
            color: 'var(--colorPrimary)',
        },
        '.PickerItem--selected': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#ffffff',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
            color: 'var(--colorPrimary)',
        },
        '.PickerItem--selected:hover': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#ffffff',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
            color: 'var(--colorPrimary)',
        },
        '.PickerAction': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#ffffff',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.PickerAction:hover': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#ffffff',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
            color: 'var(--colorPrimary)',
        },
        '.Block': {
            border: 'none',
            borderRadius: '18px',
            boxShadow:
                '0px 16px 16px -8px rgba(0, 0, 0, 0.03), 0px 8px 8px -4px rgba(0, 0, 0, 0.05), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.04)',
        },
        '.BlockDivider': {
            backgroundColor: 'rgba(0, 2, 82, 0.04)',
        },
        '.Label': {
            color: '#93939E',
            fontSize: '11px',
            lineHeight: '12px',
            fontWeight: '500',
            marginBottom: '12px',
        },
        '.Error': {
            fontSize: '11px',
            fontWeight: '500',
            marginTop: '10px',
        },
        '.Input': {
            fontSize: '12.37px',
            fontWeight: '500',
            padding: isPaintCompatible ? '10px 13px 10px 13px' : '10px 14px 10px 15px',
            borderRadius: isPaintCompatible ? '13px' : '999px',
            lineHeight: '16px',
            transition: 'none',
        },
        '.Input:focus': {
            boxShadow: '0 0 0 2px #0007D6',
        },
    },
}
const appearanceDark = {
    theme: 'flat',
    variables: {
        fontFamily: '"Inter", sans-serif',
        fontSizeBase: '14px',
        fontSizeXs: '13.74px',
        spacingUnit: '5px',
        fontLineHeight: '1.5',
        borderRadius: '13px',
        colorPrimary: '#ffffff',
        colorText: '#ffffff',
        colorDanger: '#FF2929',
        colorBackground: '#000000',
        colorTextSecondary: '#6A6A75',
        iconColor: '#6A6A75',
        colorTextPlaceholder: '#51515C',
    },
    rules: {
        '.AccordionItem': {
            border: 'none',
            boxShadow: '0 0 0 1px rgba(204, 205, 255, 0.015)',
            backgroundColor: '#131316',
            borderRadius: '0px',
        },
        '.AccordionItem:focus-visible': {
            boxShadow: '0 0 0 1px rgba(204, 205, 255, 0.015)',
            color: 'var(--colorPrimary)',
        },
        '.PickerItem': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#18181C',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.PickerItem:hover': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#232329',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.PickerItem--selected': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#232329',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.PickerItem--selected:hover': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#232329',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.PickerAction': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#18181C',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.PickerAction:hover': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#232329',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.03)',
        },
        '.Block': {
            border: 'none',
            borderRadius: '18px',
            backgroundColor: '#18181C',
            boxShadow:
                '0px 5px 5px -2.5px rgba(0, 0, 0, 0.02), 0px 4px 4px -2px rgba(0, 0, 0, 0.03), 0px 2px 2px -1px rgba(0, 0, 0, 0.04), 0px 1px 1px -0.5px rgba(0, 0, 0, 0.05), 0 0 0 1px rgba(0, 2, 82, 0.04)',
        },
        '.BlockDivider': {
            backgroundColor: 'rgba(204, 205, 255, 0.03)',
        },
        '.Label': {
            color: '#6A6A75',
            fontSize: '11px',
            lineHeight: '12px',
            fontWeight: '500',
            marginBottom: '12px',
        },
        '.Error': {
            fontSize: '11px',
            fontWeight: '500',
            marginTop: '10px',
        },
        '.Input': {
            fontSize: '12.37px',
            fontWeight: '500',
            lineHeight: '16px',
            padding: isPaintCompatible ? '10px 13px 10px 13px' : '10px 14px 10px 15px',
            borderRadius: isPaintCompatible ? '13px' : '999px',
            transition: 'none',
        },
        '.Input:focus': {
            boxShadow: '0 0 0 2px #0064EB',
        },
    },
}

interface UsePaymentMethod {
    title: string
    addPaymentMethodLoading: Ref<boolean>
    stripeElementsLoading: Ref<boolean>
    addPaymentMethodButton: Ref<any>
    paymentEntryError: Ref<string | null>
    addPaymentMethod(): Promise<void>
    init(): Promise<void>
}

type EmittedEvent = (
    event: 'success' | 'error',
    payload: {
        paymentMethod: string | PaymentMethod | StripeError
        paymentMethodType: Billing.AllowedPaymentMethod
    }
) => void

export function usePaymentElement({
    updatingCard,
    emit,
    user,
}: {
    updatingCard?: boolean
    emit: EmittedEvent
    user: Object
}): UsePaymentMethod {
    const { data: setupIntent } = useAPI<{ client_secret: string }>(
        Endpoint.CreateStripeSetupIntent
    )

    const title = updatingCard ? 'Update Payment Method' : 'Add Payment Method'
    let stripe: Stripe | null = null
    let elements: StripeElements | null = null
    const addPaymentMethodButton = ref()
    const stripeElementsLoading = ref(false)
    const addPaymentMethodLoading = ref(false)
    const paymentElement = ref<StripePaymentElement | null>(null)
    const paymentEntryError = ref<string | null>(null)
    const appearance = computed(() =>
        mode.value === 'dark' ? appearanceDark : appearanceLight
    ).value

    // Compute clientSecret from SetupIntent
    const clientSecret = computed(() => setupIntent.value?.client_secret)

    // init
    async function init() {
        stripeElementsLoading.value = true
        addPaymentMethodLoading.value = false

        stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
        if (!stripe) {
            console.error('Stripe failed to load')
            return
        }

        if (!clientSecret.value) {
            console.error('Client secret is not available')
            return
        }

        // Destroy previous elements if they exist (re-initializing)
        if (elements) {
            elements = null
        }

        elements = stripe.elements({
            clientSecret: clientSecret.value,
            fonts: [
                {
                    cssSrc: 'https://fonts.googleapis.com/css2?family=Inter:opsz,wght@14..32,100..900&display=swap',
                },
            ],
            appearance,
        })

        const paymentElementOptions: StripePaymentElementOptions = {
            defaultValues: {
                billingDetails: {
                    name: user.value?.profile.name,
                    email: user.value?.profile.username,
                },
            },
            layout: {
                type: 'accordion',
                radios: false,
                defaultCollapsed: false,
                // spacedAccordionItems: true,
                visibleAccordionItemsCount: 8,
            },
            paymentMethodOrder: ['apple_pay', 'google_pay', 'card', 'paypal'],
            wallets: {
                applePay: 'auto',
                googlePay: 'auto',
            },
        }

        // Create and mount the unified Payment Element
        paymentElement.value = elements.create(
            'payment',
            paymentElementOptions
        ) as StripePaymentElement
        paymentElement.value.mount('#payment-element')

        paymentElement.value.on('ready', async () => {
            stripeElementsLoading.value = false
            await nextTick()
        })
    }

    // addPaymentMethod
    async function addPaymentMethod() {
        addPaymentMethodLoading.value = true
        paymentEntryError.value = null

        if (clientSecret.value && elements && stripe) {
            const { error } = await stripe.confirmSetup({
                elements,
                confirmParams: {
                    return_url: `${API_URL}/webhooks/stripe/payments?opteoUserId=${user?.value?.profile?.user_id}`,
                    // return_url: window.location.href,
                },
                redirect: 'always',
                // redirect: 'if_required',
            })

            if (error) {
                paymentEntryError.value = error.message || null
                addPaymentMethodLoading.value = false
                emit('error', error)
            }
            // else if (confirmedSetupIntent) {
            // const paymentMethod = confirmedSetupIntent.payment_method
            // if (paymentMethod) {
            //     emit('success', paymentMethod)
            // }
            // }
        }
    }

    return {
        title,
        stripeElementsLoading,
        addPaymentMethodLoading,
        addPaymentMethodButton,
        paymentEntryError,
        addPaymentMethod,
        init,
    }
}
