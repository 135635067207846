import { ref, computed } from 'vue'
import { isToday, isThisWeek, isThisMonth, isThisYear, parseISO, getYear, format } from 'date-fns'
import { Api, Alert } from '@opteo/types'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'

const alertModalOpen = ref(false)

export function useAlerts() {
    const { data: alerts, mutate } = useAPI<Api.ListAlerts.Response>(Endpoint.ListAlerts)

    const loading = computed(() => !alerts.value)
    const unreadCount = computed(() => (alerts.value ?? []).filter(alert => !alert.checked).length)
    const groupLimits = ref<Record<string, number>>({})

    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ]

    // Helper to determine group limits
    function getGroupLimit(label: string): number {
        // Fixed groups have no limit.
        if (['Today', 'This Week', 'This Month'].includes(label)) {
            return Infinity
        }
        // For month groups, determine ordering to set the limit.
        if (monthNames.includes(label)) {
            // Ensure alerts are available.
            if (!alerts.value) return 25
            // Filter alerts in the current year but not in the current month.
            const alertsInCurrentYear = alerts.value.filter(alert => {
                const date = parseISO(alert.ts)
                return isThisYear(date) && !isThisMonth(date)
            })
            // Create a unique set of month names from those alerts.
            const monthGroupSet = new Set(
                alertsInCurrentYear.map(alert => format(parseISO(alert.ts), 'MMMM'))
            )
            // Sort month groups in descending order so the most recent month comes first.
            const monthGroupLabels = Array.from(monthGroupSet).sort(
                (a, b) => monthNames.indexOf(b) - monthNames.indexOf(a)
            )
            // If this label is the first in the ordering, return Infinity; otherwise, cap at 25.
            return monthGroupLabels[0] === label ? Infinity : 25
        }
        // For year groups, limit to 25 alerts.
        return 25
    }

    function showMore(groupLabel: string) {
        const currentLimit = getGroupLimit(groupLabel)
        groupLimits.value[groupLabel] = currentLimit + 20
    }

    const groupedAlerts = computed(() => {
        if (!alerts.value) return []

        // Map each alert to its group label based on its timestamp
        const mapToGroup = (alert: Alert.Object) => {
            const date = parseISO(alert.ts)
            if (isToday(date)) {
                return 'Today'
            } else if (isThisWeek(date)) {
                return 'This Week'
            } else if (isThisMonth(date)) {
                // Alerts in the current month that aren’t caught by the above are labeled as “This Month”
                return 'This Month'
            } else if (isThisYear(date)) {
                // For the current year but not in the current month, group by month name (e.g. “February”)
                return format(date, 'MMMM')
            } else {
                // For alerts not in the current year, group by year
                return String(getYear(date))
            }
        }

        // Build the groups object
        const groupsObj: Record<string, Alert.Object[]> = {}
        for (const alert of alerts.value) {
            const groupLabel = mapToGroup(alert)
            if (!groupsObj[groupLabel]) groupsObj[groupLabel] = []
            groupsObj[groupLabel].push(alert)
        }

        // Fixed groups for the current month
        const fixedGroups = ['Today', 'This Week', 'This Month']
        const existingFixedGroups = fixedGroups.filter(label => groupsObj[label])

        // Other groups: could be month names (from earlier months of the current year) or year numbers.
        const otherLabels = Object.keys(groupsObj).filter(label => !fixedGroups.includes(label))

        // Separate month names from year labels:
        const monthGroupLabels = otherLabels.filter(label => monthNames.includes(label))
        // Sort month groups descending (so that the most recent month comes first, e.g. “February” before “January”)
        monthGroupLabels.sort((a, b) => monthNames.indexOf(b) - monthNames.indexOf(a))

        // Year groups (labels that aren’t month names)
        const yearGroupLabels = otherLabels.filter(label => !monthNames.includes(label))
        // Sort year groups descending numerically
        yearGroupLabels.sort((a, b) => Number(b) - Number(a))

        // The overall order is: fixed groups, then month groups, then year groups.
        const orderedLabels = [...existingFixedGroups, ...monthGroupLabels, ...yearGroupLabels]

        return orderedLabels.map(label => {
            const allItems = groupsObj[label] ?? []
            const limitForGroup = getGroupLimit(label)
            const truncatedItems = allItems.slice(0, limitForGroup)

            return {
                label,
                items: truncatedItems,
                totalCount: allItems.length,
            }
        })
    })

    // Mark all as read
    async function markAllAsRead() {
        const ids = alerts.value?.map(alert => alert.id)
        mutate(() =>
            (alerts.value ?? []).map(a => ({
                ...a,
                checked: true,
            }))
        )
        await authRequest(Endpoint.SetAlertsAllRead, {
            body: { alert_ids: ids },
        })
    }

    // Mark group as read
    async function markGroupAsRead(groupLabel: string) {
        const group = groupedAlerts.value.find(g => g.label === groupLabel)
        if (!group) return

        const ids = group.items.map(a => a.id)
        mutate(() =>
            (alerts.value ?? []).map(a => (ids.includes(a.id) ? { ...a, checked: true } : a))
        )
        await authRequest(Endpoint.SetAlertsAllRead, {
            body: { alert_ids: ids },
        })
    }

    // Single alert update
    function mutateAlert(alertId: number, updatedAlert: Partial<Alert.Object>) {
        mutate(() =>
            (alerts.value ?? []).map(a => (a.id === alertId ? { ...a, ...updatedAlert } : a))
        )
    }

    return {
        alerts,
        loading,
        unreadCount,
        mutateAlert,
        mutateAlerts: mutate,
        alertModalOpen,
        groupLimits,
        groupedAlerts,
        showMore,
        markAllAsRead,
        markGroupAsRead,
    }
}
