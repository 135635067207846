<template>
    <PageWrapper>
        <PageHeader title="Settings">
            <template #right>
                <Menu
                    buttonLabel="Sections"
                    :items="sectionItems"
                    :offset="[4, 6]"
                    placement="bottom-end"
                    @item-clicked="scrollToSection"
                    :showKeyboardShortcuts="false"
                    buttonSize="small"
                />
            </template>
        </PageHeader>
        <PageContent>
            <!-- Account Profile -->
            <section id="account-profile">
                <StickyHeader title="Account Profile" />
                <Stack direction="column" align="center" :padding="20" :gap="0">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="300"
                    >
                        <AccountProfile showUpdateButton />
                    </Container>
                </Stack>
            </section>

            <!-- Budget -->
            <section id="update-budget">
                <StickyHeader title="Monthly Budget" />
                <Stack direction="column" align="center" width="100%" :padding="20">
                    <AccountMonthlyBudget
                        :accountId="accountId"
                        :currencyCode="currencyCode"
                        :currencySymbol="currencySymbol"
                        :accountPlatform="accountPlatform"
                        showUpdateButton
                    />
                </Stack>
            </section>

            <!-- Performance Mode -->
            <section id="performance-mode">
                <StickyHeader title="Performance Mode" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="300"
                    >
                        <AccountPerformanceMode showUpdateButton />
                    </Container>
                </Stack>
            </section>

            <!-- Conversion Actions. Linkedin does not support conversion action selection. -->
            <section
                v-if="accountPlatform !== Platform.Platform.LinkedInAds"
                id="conversion-actions"
            >
                <StickyHeader title="Conversion Actions" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="322"
                    >
                        <AccountConversionActions />
                    </Container>
                </Stack>
            </section>

            <!-- Performance Goals -->
            <section id="performance-goals">
                <StickyHeader title="Performance Goals" />
                <Stack direction="column" align="center" :padding="20">
                    <AccountPerformanceGoals showUpdateButton />
                </Stack>
            </section>

            <!-- Google Analytics -->
            <section v-if="accountPlatform === Platform.Platform.GoogleAds" id="analytics">
                <StickyHeader title="Connect Google Analytics" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :maxWidth="288"
                    >
                        <Analytics view="domain-settings" />
                    </Container>
                </Stack>
            </section>

            <!-- Connect Slack -->
            <section id="connect-slack">
                <StickyHeader title="Connect Slack Channel" />
                <Stack direction="column" align="center" :padding="20">
                    <SlackConnectWrapper :isDomainSpecificConnection="true" />
                </Stack>
            </section>

            <!-- Enable Deep Linking -->
            <section id="enable-deep-linking">
                <StickyHeader title="Enable Deep Linking" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :maxWidth="404"
                    >
                        <Stack
                            direction="column"
                            :gap="20"
                            align="start"
                            :padding="[22, 22, 0, 22]"
                            width="100%"
                        >
                            <Container color="background-plus-two" :borderRadius="12" hideOverflow>
                                <Image
                                    :src="
                                        isDark
                                            ? `/src/assets/img/enable-deep-linking-dark.png`
                                            : `/src/assets/img/enable-deep-linking.png`
                                    "
                                    :aspectRatio="712 / 476"
                                    :borderRadius="0"
                                    :width="356"
                                    :height="238"
                                    skeletonColor="background-plus-two"
                                    alt="An illustration of floating mountains in front of a blue sky."
                                />
                            </Container>

                            <Text as="p" size="body-small">
                                Copy the Google Ads OCID for <b>this account</b> and paste it into
                                the field below to enable deep linking. This will make entities in
                                this account clickable — when clicked a given entity will open
                                automatically in Google Ads in a new tab.
                            </Text>
                        </Stack>

                        <Form :validateOnSubmit="true" @submitted="saveOcid">
                            <Stack
                                direction="column"
                                :gap="14"
                                align="start"
                                borderBottom
                                :padding="[20, 22, 22, 22]"
                            >
                                <NumberInput
                                    ref="saveGoogleAdsOcidInput"
                                    v-model="googleAdsOcid"
                                    id="google-ads-ocid"
                                    name="google-ads-ocid"
                                    formatting="plain"
                                    label="Google Ads OCID"
                                    placeholder="188062348"
                                    nestedButton
                                    type="number"
                                >
                                    <template #nestedButton>
                                        <Button
                                            size="extra-small"
                                            color="secondary"
                                            @click="testIsOcidValid"
                                        >
                                            Test OCID
                                        </Button>
                                    </template>
                                </NumberInput>
                                <Text as="p" color="foreground-minus-one" size="body-extra-small">
                                    You can find the OCID for a Google Ads account by signing in to
                                    Google Ads and copying it from the URL bar in your browser.
                                </Text>
                                <Button
                                    size="extra-small"
                                    color="secondary"
                                    iconAfter="ArrowUpRight"
                                    @click="goToDeepLinkingDocs"
                                >
                                    Learn More
                                </Button>
                            </Stack>

                            <Stack align="center" justify="center" :padding="[22, 0]">
                                <Button
                                    ref="saveGoogleAdsOcidButton"
                                    size="small"
                                    color="secondary-highlight"
                                    submit
                                >
                                    Save Google Ads OCID
                                </Button>
                            </Stack>
                        </Form>
                    </Container>
                </Stack>
            </section>

            <!-- Alert Settings -->
            <section id="alert-settings">
                <StickyHeader title="Alert Settings" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :maxWidth="312"
                    >
                        <Form @submitted="saveAlertSettings">
                            <Stack direction="column" align="start" borderBottom :gap="0">
                                <Stack
                                    direction="column"
                                    align="start"
                                    width="100%"
                                    :padding="[22]"
                                    borderBottom
                                >
                                    <Checkbox
                                        name="domainEmailMuted"
                                        v-model="muteSettings.email"
                                        label="Mute email alerts for this account"
                                        :fullWidth="true"
                                    />
                                </Stack>

                                <Stack
                                    direction="column"
                                    align="start"
                                    :padding="[22, 22, 18, 22]"
                                    :gap="14"
                                    width="100%"
                                >
                                    <Checkbox
                                        name="domainSlackMuted"
                                        v-model="muteSettings.slack"
                                        label="Mute Slack alerts for this account"
                                        :fullWidth="true"
                                    />
                                    <Text
                                        as="p"
                                        size="body-extra-small"
                                        color="foreground-minus-one"
                                    >
                                        Please note, selecting this option will mute Slack alerts
                                        for this account for everyone on your team.
                                    </Text>
                                </Stack>
                            </Stack>
                            <Stack align="center" justify="center" :padding="[22, 0]">
                                <Button
                                    :loading="saveAlertSettingsButtonLoading"
                                    ref="saveAlertSettingsButton"
                                    size="small"
                                    color="secondary-highlight"
                                    submit
                                >
                                    Update Alert Settings
                                </Button>
                            </Stack>
                        </Form>
                    </Container>
                </Stack>
            </section>

            <!-- Active Improvement Types -->
            <section
                v-if="
                    accountPlatform === Platform.Platform.MicrosoftAds ||
                    accountPlatform === Platform.Platform.GoogleAds
                "
                id="active-imp-types"
            >
                <StickyHeader title="Active Improvement Types" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="392"
                    >
                        <Stack :padding="[18, 22, 18, 22]" borderBottom>
                            <Text as="p" size="body-small">
                                Some improvement types may not be appropriate for your account. You
                                can disable those that are not relevant here. Please note, this will
                                not affect your teammates' task list.
                            </Text>
                        </Stack>
                        <Stack
                            v-for="(category, index) of recActionPrefrerenceCategories"
                            :key="category.categoryLabel"
                            direction="column"
                            align="start"
                            :gap="20"
                            :padding="[20, 22, 22, 22]"
                            :borderBottom="index !== recActionPrefrerenceCategories.length - 1"
                        >
                            <Text as="h4" size="label" color="foreground-minus-one">
                                {{ category.categoryLabel }}
                            </Text>
                            <Stack direction="column" align="start">
                                <Stack
                                    v-if="category.preferences?.length"
                                    direction="column"
                                    align="start"
                                    :gap="14"
                                    width="100%"
                                >
                                    <Checkbox
                                        v-for="pref of category.preferences"
                                        :key="pref.static_title"
                                        v-model="pref.selected"
                                        :label="pref.static_title"
                                        @click="setActiveImpType()"
                                        fullWidth
                                    />
                                </Stack>
                                <div v-else>
                                    <div
                                        v-for="i of new Array(6)"
                                        :key="i"
                                        class="checkbox-container"
                                    >
                                        <Skeleton :height="20" :width="20" />
                                        <Skeleton
                                            class="ml-12"
                                            :height="12"
                                            :width="Math.random() * 80 + 100"
                                        />
                                    </div>
                                </div>
                            </Stack>
                        </Stack>
                    </Container>
                </Stack>
            </section>

            <!-- Timezone -->
            <section
                v-if="
                    accountPlatform === Platform.Platform.MicrosoftAds ||
                    accountPlatform === Platform.Platform.GoogleAds
                "
                id="update-country"
            >
                <StickyHeader title="SERP Country" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="336"
                    >
                        <Stack direction="column" align="center" justify="center" :gap="0">
                            <Stack
                                :padding="[18, 22, 18, 22]"
                                direction="column"
                                justify="center"
                                :gap="24"
                                borderBottom
                            >
                                <Text as="p" size="body-small">
                                    Search Engine Results Page (SERP) previews are based on the
                                    native timezone in your marketing account. To override this,
                                    please select one of the countries from the dropdown menu below.
                                </Text>
                            </Stack>
                            <Stack
                                direction="column"
                                align="center"
                                :gap="6"
                                width="100%"
                                borderBottom
                                :padding="28"
                            >
                                <Text as="span" size="label" color="foreground-minus-one">
                                    Native SERP Country
                                </Text>
                                <Text v-if="timezone" as="span" size="body" weight="semibold">
                                    {{ computedTimezone }}
                                </Text>
                                <Skeleton v-else :width="120" />
                            </Stack>
                            <Form @submitted="saveCountry">
                                <Stack :padding="[20, 22, 22, 22]" borderBottom>
                                    <Select
                                        v-if="countryDropdownItems.length > 0"
                                        :model-value="countryMutable || countryRaw"
                                        @update:model-value="countryMutable = $event"
                                        placeholder="Select Country"
                                        name="country"
                                        :options="countryDropdownItems"
                                        label="SERP Country Override"
                                    />
                                </Stack>

                                <Stack align="center" justify="center" :padding="[22, 0]">
                                    <Button
                                        ref="saveCountryButton"
                                        :loading="saveCountryButtonLoading"
                                        size="small"
                                        color="secondary-highlight"
                                        submit
                                    >
                                        Update SERP Country
                                    </Button>
                                </Stack>
                            </Form>
                        </Stack>
                    </Container>
                </Stack>
            </section>

            <!-- Change History Settings -->
            <section id="update-domain-connection">
                <StickyHeader title="Change History Email" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="320"
                    >
                        <Stack
                            :padding="[18, 22, 18, 22]"
                            direction="column"
                            justify="center"
                            :gap="24"
                            borderBottom
                        >
                            <Text as="p" size="body-small">
                                When Opteo pushes a change to this account, the email listed below
                                will be displayed in the native platform
                                <b>Change History</b> section.
                                <Spacer :height="10" />
                                If this account has more than one connection to Opteo, you can
                                select an alternative email address using the menu below.
                            </Text>
                        </Stack>
                        <Form @submitted="saveConnection">
                            <Stack :padding="[20, 22, 22, 22]" borderBottom>
                                <Select
                                    :model-value="currentConnection?.connectionId"
                                    :options="connectionDropdownItems"
                                    name="connectionId"
                                    type="select"
                                    label="Change History Email"
                                />
                            </Stack>
                            <Stack align="center" justify="center" :padding="[22, 0]">
                                <Button
                                    ref="saveConnectionButton"
                                    :loading="saveConnectionButtonLoading"
                                    size="small"
                                    color="secondary-highlight"
                                    submit
                                >
                                    Update Change History Email
                                </Button>
                            </Stack>
                        </Form>
                    </Container>
                </Stack>
            </section>
        </PageContent>
    </PageWrapper>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useColorMode } from '@vueuse/core'

// Composition
import { useImprovementPreferences } from '@/composition/improvement/useImprovementPreferences'
import { useAccountSettings } from '@/composition/account/useAccountSettings'
import { useAccount } from '@/composition/account/useAccount'

// Types
import { Platform } from '@opteo/types'

// Components
import {
    Menu,
    StickyHeader,
    Stack,
    Container,
    showSnackbar,
    NumberInput,
    Button,
    Text,
    Checkbox,
    Select,
    Form,
    Image,
    Skeleton,
    Spacer,
} from '@opteo/components-next'

import Analytics from '@/components/connectors/analytics/Analytics.vue'
import SlackConnectWrapper from '@/components/connectors/slack/SlackConnectWrapper.vue'
import AccountProfile from '@/components/account/settings/AccountProfile.vue'
import AccountMonthlyBudget from '@/components/account/settings/AccountMonthlyBudget.vue'
import AccountPerformanceGoals from '@/components/account/settings/AccountPerformanceGoals.vue'
import AccountPerformanceMode from '@/components/account/settings/AccountPerformanceMode.vue'
import AccountConversionActions from '@/components/account/settings/AccountConversionActions.vue'

import { useCountryFromTimezone } from '@/composition/utils/utils'

import { scrollToSection } from '@/lib/globalUtils'

// Setup
const route = useRoute()
const { accountPlatform, accountId, currencySymbol, currencyCode } = useAccount()
const scrollPosition = route?.params?.scroll_position
const { updateActiveImpTypes, recActionPrefrerenceCategories } = useImprovementPreferences()

const {
    saveAlertSettings,
    muteSettings,
    saveAlertSettingsButton,
    saveAlertSettingsButtonLoading,
    timezone,
    saveCountry,
    countryDropdownItems,
    countryMutable,
    countryRaw,
    saveCountryButton,
    saveCountryButtonLoading,
    saveConnection,
    currentConnection,
    connectionDropdownItems,
    saveConnectionButton,
    saveConnectionButtonLoading,
    saveCpiSetting,
    cpiSettingLocal,
    updateCpiSetting,
    saveCpiButtonLoading,
    googleAdsOcid,
    isOcidTested,
    saveOcid,
    saveGoogleAdsOcidButton,
    saveGoogleAdsOcidInput,
    testIsOcidValid,
} = useAccountSettings()

const computedTimezone = computed(() => {
    return useCountryFromTimezone(timezone)
})

const sectionItems = [
    {
        label: 'Account Profile',
        value: 'account-profile',
    },
    {
        label: 'Monthly Budget',
        value: 'update-budget',
    },
    {
        label: 'Performance Mode',
        value: 'performance-mode',
    },
    {
        label: 'Conversion Actions',
        value: 'conversion-actions',
    },
    {
        label: 'Performance Goals',
        value: 'performance-goals',
    },
    {
        label: 'Connect Google Analytics',
        value: 'analytics',
    },
    {
        label: 'Connect Slack Channel',
        value: 'connect-slack',
    },
    {
        label: 'Enable Deep Linking',
        value: 'enable-deep-linking',
    },
    {
        label: 'Alert Settings',
        value: 'alert-settings',
    },
    {
        label: 'Active Improvement Types',
        value: 'active-imp-types',
    },
    {
        label: 'SERP Country',
        value: 'update-country',
    },
    {
        label: 'Change History Email',
        value: 'update-domain-connection',
    },
]

const mode = useColorMode()
const isDark = computed(() => {
    if (mode.value === 'dark') {
        return true
    } else {
        return false
    }
})

// Functions
const setActiveImpType = () => {
    updateActiveImpTypes()
    showSnackbar({
        message: `Improvement Preferences Saved.`,
        timeout: 5000,
    })
}

// goToDocs
function goToDeepLinkingDocs() {
    window.open('https://opteo.com/docs/en/articles/10177961-deep-linking', '_blank')
}

// Scroll
if (scrollPosition) {
    // @ts-expect-error
    scrollToSection(scrollPosition)
}
</script>

<style scoped lang="scss">
section {
    @include w-100;
    @include bb;
    @include border-background;
}
section:last-child {
    border-bottom: none;
}
section:nth-child(1) {
    @include z-1;
}
section:nth-child(2) {
    @include z-2;
}
section:nth-child(3) {
    @include z-3;
}
section:nth-child(4) {
    @include z-4;
}
section:nth-child(5) {
    @include z-5;
}
section:nth-child(6) {
    @include z-6;
}
section:nth-child(7) {
    @include z-7;
}
section:nth-child(9) {
    @include z-9;
}
section:nth-child(9) {
    @include z-9;
}
</style>
