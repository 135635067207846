<template>
    <div class="improvement-group" :class="[{ hidden }, { first }]">
        <StickyHeader :title="title">
            <template #titlePrefix v-if="$slots.titlePrefix">
                <slot name="titlePrefix"></slot>
            </template>
            <template #titleSuffix v-if="$slots.titleSuffix">
                <slot name="titleSuffix"></slot>
            </template>
            <template #right v-if="$slots.right"><slot name="right"></slot></template>
        </StickyHeader>
        <Stack v-if="$slots.content" direction="column" :gap="0" :padding="0" borderBottom>
            <TransitionGroup
                tag="div"
                style="width: 100%"
                name="list"
                @before-leave="handleBeforeLeave"
                @after-leave="handleAfterLeave"
            >
                <slot name="content"></slot>
            </TransitionGroup>
        </Stack>
    </div>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, onMounted, onUpdated } from 'vue'

// Components
import { StickyHeader, Stack, useMediaQuery } from '@opteo/components-next'

// Props
const props = defineProps({
    title: {
        type: String as PropType<string>,
        required: false,
    },
    first: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
    },
    last: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
    },
    hidden: {
        type: Boolean as PropType<boolean>,
        required: false,
        default: false,
    },
})

// Emits
const emit = defineEmits(['before-item-leave', 'after-item-leave'])

// Setup
const { above480 } = useMediaQuery()

// Functions
const elementPositions = ref(new Map<Element, { top: number; left: number }>())

const precapturePositions = () => {
    document.querySelectorAll('.improvement-row-wrapper').forEach(el => {
        const element = el as HTMLElement
        elementPositions.value.set(element, {
            top: element.offsetTop,
            left: element.offsetLeft,
        })
    })
    document.querySelectorAll('.empty-state-wrapper').forEach(el => {
        const element = el as HTMLElement
        elementPositions.value.set(element, {
            top: element.offsetTop,
            left: element.offsetLeft,
        })
    })
}

const handleBeforeLeave = (element: Element) => {
    const el = element as HTMLElement
    const precomputed = elementPositions.value.get(el)

    if (precomputed) {
        const { marginLeft, marginTop, width, height } = window.getComputedStyle(el)

        element.setAttribute(
            'style',
            `left: ${precomputed.left - parseInt(marginLeft)}px;
            top: ${precomputed.top - parseInt(marginTop)}px;
            width: ${width};
            height: ${height};`
        )

        emit('before-item-leave')
    } else {
        console.warn('Position not precomputed for element:', el)
    }
}

onMounted(() => {
    precapturePositions()
})
onUpdated(() => {
    precapturePositions()
})

const handleAfterLeave = () => {
    emit('after-item-leave')
}
</script>

<style scoped lang="scss">
.improvement-group {
    @include w-100;
    @include relative;
    transition:
        opacity $timing-standard $curve-ease-in-out,
        transform $timing-standard $curve-ease-in-out;
    opacity: 1;
    transform: scale(1);
    // @include bt;
    // @include border-background;
}
.improvement-group.first {
    border-top: none;
}
.improvement-group.hidden {
    opacity: 0;
    transform: scale(0.96);
    @include pointer-events-none;
    @include absolute;
}
</style>
