<template>
    <Stack direction="column" :padding="20" gap="0.75rem" borderBottom>
        <Button
            color="secondary"
            iconAfter="ArrowUpRight"
            fullWidth
            @click="emit('google-clicked')"
        >
            <template #before>
                <IconSlot :width="14" :height="14">
                    <GoogleGLogo :size="12" />
                </IconSlot>
            </template>
            Continue with Google
        </Button>
        <Button
            color="secondary"
            iconAfter="ArrowUpRight"
            fullWidth
            @click="emit('microsoft-clicked')"
        >
            <template #before>
                <IconSlot :width="14" :height="14">
                    <MicrosoftAdsLogo :size="12" />
                </IconSlot>
            </template>
            Continue with Microsoft
        </Button>
        <Button
            color="secondary"
            iconAfter="ArrowUpRight"
            fullWidth
            @click="emit('linkedin-clicked')"
        >
            <template #before>
                <IconSlot :width="14" :height="14">
                    <LinkedInAdsLogo :size="12" />
                </IconSlot>
            </template>
            Continue with LinkedIn
        </Button>
    </Stack>
</template>

<script setup lang="ts">
// Components
import {
    Stack,
    Button,
    IconSlot,
    GoogleGLogo,
    MicrosoftAdsLogo,
    MetaAdsLogo,
    LinkedInAdsLogo,
} from '@opteo/components-next'

// Emits
const emit = defineEmits(['google-clicked', 'microsoft-clicked', 'linkedin-clicked'])
</script>
