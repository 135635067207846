<template>
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M16 0c.8284 0 1.5.671573 1.5 1.5v2c0 .82843-.6716 1.5-1.5 1.5s-1.5-.67157-1.5-1.5v-2c0-.828427.6716-1.5 1.5-1.5Zm8 16c0 4.4183-3.5817 8-8 8s-8-3.5817-8-8 3.5817-8 8-8 8 3.5817 8 8Zm3.3107-9.18934c.5857-.58579.5857-1.53553 0-2.12132-.5858-.58579-1.5356-.58579-2.1214 0l-1.4142 1.41421c-.5858.58579-.5858 1.53554 0 2.12132.5858.58579 1.5356.58579 2.1213 0l1.4143-1.41421ZM4.68934 4.68934c-.58579.58579-.58579 1.53553 0 2.12132l1.41421 1.41421c.58579.58579 1.53554.58579 2.12132 0 .58579-.58578.58579-1.53553 0-2.12132L6.81066 4.68934c-.58579-.58579-1.53553-.58579-2.12132 0Zm0 20.49996c-.58579.5858-.58579 1.5356 0 2.1214.58579.5857 1.53553.5857 2.12132 0l1.41421-1.4143c.58579-.5857.58579-1.5355 0-2.1213-.58578-.5858-1.53553-.5858-2.12132 0l-1.41421 1.4142Zm22.62136 2.1214c.5857-.5858.5857-1.5356 0-2.1214l-1.4143-1.4142c-.5857-.5858-1.5355-.5858-2.1213 0-.5858.5858-.5858 1.5356 0 2.1213l1.4142 1.4143c.5858.5857 1.5356.5857 2.1214 0ZM17.5 28.5c0-.8284-.6716-1.5-1.5-1.5s-1.5.6716-1.5 1.5v2c0 .8284.6716 1.5 1.5 1.5s1.5-.6716 1.5-1.5v-2ZM0 16c0-.8284.671573-1.5 1.5-1.5h2c.82843 0 1.5.6716 1.5 1.5s-.67157 1.5-1.5 1.5h-2C.671573 17.5 0 16.8284 0 16Zm28.5-1.5c-.8284 0-1.5.6716-1.5 1.5s.6716 1.5 1.5 1.5h2c.8284 0 1.5-.6716 1.5-1.5s-.6716-1.5-1.5-1.5h-2Z"
            fill="#373744"
        />
    </svg>
</template>
