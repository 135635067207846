<template>
    <ExternalPageWrapper :maxWidth="280">
        <Container :borderRadius="22" width="100%" hideOverflow>
            <!-- Explainer -->
            <Stack direction="column" align="start" :padding="[16, 20]" gap="0.75rem" borderBottom>
                <Text as="h6" size="headline-six" color="foreground">Reset Password</Text>
                <Text v-if="showForm" as="p" size="body-extra-small" color="foreground-minus-one">
                    Set and confirm your new password using the form below. Click the button to
                    approve.
                </Text>
                <Text
                    v-else-if="invalidToken"
                    as="p"
                    size="body-extra-small"
                    color="foreground-minus-one"
                >
                    This password reset link is no longer valid.
                </Text>
                <Text v-else as="p" size="body-extra-small" color="foreground-minus-one">
                    Password updated successfully. Click the button below to return to the login
                    page.
                </Text>
            </Stack>
            <Form ref="updatePasswordForm" @submitted="resetPassword" validateOnSubmit>
                <Stack :padding="showForm ? [18, 20, 20, 20] : 20" direction="column" gap="1.25rem">
                    <Stack v-if="showForm" direction="column" gap="1.125rem">
                        <TextInput
                            v-model="newPassword"
                            :validator="newPasswordValidator"
                            type="password"
                            name="newPassword"
                            ref="newPasswordInput"
                            label="New Password"
                            placeholder="••••••••••"
                            autocomplete="new-password"
                            autofocus
                            required
                        />
                        <TextInput
                            v-model="confirmNewPassword"
                            :validator="confirmNewPasswordValidator"
                            type="password"
                            name="confirmNewPassword"
                            ref="confirmNewPasswordInput"
                            label="Confirm New Password"
                            placeholder="••••••••••"
                            autocomplete="new-password"
                            required
                        />
                    </Stack>
                    <Button
                        v-if="showForm"
                        submit
                        color="primary"
                        iconAfter="ArrowUpRight"
                        :loading="loading"
                        fullWidth
                    >
                        Save New Password
                    </Button>
                    <Button
                        v-else-if="invalidToken"
                        color="primary"
                        iconAfter="ArrowUpRight"
                        fullWidth
                        @click="goToLogin"
                    >
                        Go to Login
                    </Button>
                    <Button
                        v-else
                        color="primary"
                        iconAfter="ArrowUpRight"
                        fullWidth
                        @click="goToLogin"
                    >
                        Go to Login
                    </Button>
                </Stack>
            </Form>
        </Container>
        <Stack :padding="[20, 0, 0, 0]" direction="column" gap="0.375rem">
            <Text size="body-extra-small" align="center" as="p">
                <router-link to="/login" data-cy="login-link">
                    <Text as="span" color="foreground-minus-three">
                        Click here to return to the Login page.
                    </Text>
                </router-link>
            </Text>
        </Stack>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { useResetPasswordLink } from '@/composition/auth/useResetPasswordLink'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Components
import { Form, Container, Button, TextInput, Stack, Text } from '@opteo/components-next'

// Setup
const router = useRouter()
const {
    showForm,
    loading,
    invalidToken,
    email,
    updatePasswordForm,
    resetPassword,
    newPassword,
    newPasswordValidator,
    newPasswordInput,
    confirmNewPassword,
    confirmNewPasswordInput,
    confirmNewPasswordValidator,
} = useResetPasswordLink()

// Functions
function goToLogin() {
    router.push({ name: Routes.Login })
}
</script>

<style scoped lang="scss"></style>
