<template>
    <Modal v-model="open" :title="modalTitle" :maxContentHeight="486">
        <template #content>
            <PaymentMethodForm
                ref="paymentMethodForm"
                :width="452"
                :updatingCard="!!creditCard"
                @success="onSuccess"
                @error="onError"
            />
        </template>
        <template #buttons>
            <Button color="secondary" @click="open = false">Cancel</Button>
            <Button :loading="loading" iconAfter="ArrowUpRight" @click="submit">
                {{ title }}
            </Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed, watch } from 'vue'

// Composition
import { useSubscription } from '@/composition/billing/useSubscription'
import { usePaymentElement } from '@/composition/billing/usePaymentElement'
import { useUser } from '@/composition/user/useUser'
import { Endpoint, authRequest } from '@/composition/api/useAPI'

// Components
import { Modal, showSnackbar, hideSnackbar, Button } from '@opteo/components-next'
import PaymentMethodForm from '@/components/billing/PaymentMethodForm.vue'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue', 'success', 'error'])

// Setup
const open = ref(false)
const loading = ref(false)
const paymentMethodForm = ref()
const { user } = useUser()
const { mutate, creditCard } = useSubscription()
const { title } = usePaymentElement({
    updatingCard: !!creditCard,
    emit,
    user,
})

// Computed
const modalTitle = computed(() =>
    creditCard.value ? 'Update Payment Method' : 'Add Payment Method'
)

// Functions
function submit() {
    paymentMethodForm.value.submit()
    loading.value = true
}

// onSuccess
async function onSuccess(paymentMethod: string) {
    await authRequest(Endpoint.UpdateCustomerCc, {
        body: { token: paymentMethod },
    })

    await mutate()

    open.value = false
    loading.value = false

    showSnackbar({
        message: `Payment method updated succesfully`,
        timeout: 3000,
        actionText: 'Dismiss',
        actionHandler: () => hideSnackbar(),
    })
}

// onError
function onError(error: any) {
    loading.value = false
    console.error(error.message)
    showSnackbar({
        message: error.message,
        timeout: 5000,
        actionText: 'Dismiss',
        actionHandler: () => hideSnackbar(),
    })
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
