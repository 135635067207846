<template>
    <div ref="searchTermTable">
        <oTable
            :headers="searchTermTableHeaders"
            :items="formattedSearchTerms as unknown as Record<string, string | number>[]"
            order-by="cost"
            order="DESC"
            fixed-layout
            :border-radius="24"
            paginationMethod="intersection"
            :intersectionOptions="{
                rootMargin: '0px',
                threshold: 1,
            }"
        >
            <template #header.searchTerm>
                <Tooltip content="Select All" placement="top">
                    <Checkbox :model-value="allSearchTermsSelected" @click="toggleAllSearchTerms" />
                </Tooltip>
                <Spacer width="1.25rem" />
                <Text size="f-8" weight="500">Search Term</Text>
            </template>
            <template
                #column.searchTerm="{
                    value: searchTermText,
                    row,
                }: {
                    value: string
                    row: SearchTermItem
                }"
            >
                <div class="search-term-cell" @click="toggleSelectedSearchTerm(row)">
                    <div v-if="addingNgramsToNegative" class="spinner-container">
                        <Spinner />
                    </div>
                    <Checkbox v-else :model-value="isSearchTermSelected(row.uniqueKey)" />
                    <Spacer width="1.25rem" />

                    <EntityPill
                        v-if="searchTermText"
                        :type="Improvement.LocationEntity.SearchTerm"
                        :content="searchTermText"
                    />
                    <EntityPill v-else :type="Improvement.LocationEntity.SearchTerm">
                        <Skeleton
                            width="6rem"
                            color="hsla(174, 100%, 22%, 0.2)"
                            shimmer-color="hsla(174, 100%, 22%, 0.06)"
                        />
                    </EntityPill>
                </div>
            </template>

            <template #column.cost="{ value }">
                <Money v-if="typeof value === 'number'" :value="value" :currency="currencyCode" />
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.impressions="{ value }">
                <Number v-if="typeof value === 'number'" :value="value" />
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.conversions="{ value }">
                <Number v-if="typeof value === 'number'" :value="value" />
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.conversionValue="{ value }">
                <Money v-if="typeof value === 'number'" :value="value" :currency="currencyCode" />
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.cpa="{ value }">
                <Money v-if="typeof value === 'number'" :value="value" :currency="currencyCode" />
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.roas="{ value }">
                <Percent v-if="typeof value === 'number'" :value="value" />
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.cpm="{ value }">
                <span v-if="typeof value === 'number'">
                    {{ formatCpm(value) }}
                </span>
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.vpm="{ value }">
                <span v-if="typeof value === 'number'">
                    {{ formatVpm(value) }}
                </span>
                <Skeleton v-else width="3rem" />
            </template>
            <template #column.vsAvg="{ value, row }">
                <Text
                    v-if="typeof value === 'number'"
                    size="f-8"
                    weight="500"
                    :color="row.vsAverageColor"
                >
                    <Percent :value="value" :decimal-places="2" include-sign />
                </Text>
                <Skeleton v-else width="3rem" />
            </template>

            <template #[`column.${entitySource}`]="{ row }">
                <NGramEntitySource :search-term-item="row" />
            </template>
        </oTable>
    </div>
    <BatchBar
        :title="pluralise(selectedSearchTerms.length, 'Search Term', 'Search Terms')"
        :model-value="showBatchBar"
        @clear-selected="clearSelectedSearchTerms"
        :count="selectedSearchTerms.length"
        :full-screen="true"
        :z-index="9999999999999999"
        class="search-terms-batch-bar"
    >
        <template #buttons>
            <oButton color="blue" @clicked="handleAddSearchTermsAsNegatives">
                Add as Negative&nbsp;{{
                    pluralise(selectedSearchTerms.length, 'Keyword', 'Keywords')
                }}
                <template #icon><RightArrowIcon hex="#fff" /></template>
            </oButton>
        </template>
    </BatchBar>
</template>

<script lang="ts" setup>
// import {
//     oTable,
//     Text,
//     EntityPill,
//     Percent,
//     Money,
//     Number,
//     Checkbox,
//     Tooltip,
//     Spacer,
//     Spinner,
//     BatchBar,
//     oButton,
//     RightArrowIcon,
//} from '@opteo/components-next'

import { Improvement, NgramTool } from '@opteo/types'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'
import { computed, ref } from 'vue'
import { Endpoint, useAPI } from '@/composition/api/useAPI'
import Skeleton from '@/components/util/Skeleton.vue'
import { useNGramFilters } from '@/composition/toolkit/nGramTool/useNGramFilters'
import NGramEntitySource from './NGramEntitySource.vue'
import { useAccount } from '@/composition/account/useAccount'
import { useNGramHeaders } from '@/composition/toolkit/nGramTool/useNGramHeaders'
import { useNgramPanels } from '@/composition/toolkit/nGramTool/useNgramPanels'
import { pluralise } from '@/lib/globalUtils'
import { useElementBounding } from '@vueuse/core'

import type {
    PanelItem,
    SearchTermItem,
    SearchTermRes,
} from '@/composition/toolkit/nGramTool/types/index'

const {
    currencyCode,
    formatCpm,
    formatVpm,
    formatItemsForPanels,
    addingNgramsToNegative,
    goToNegativeDestination,
    shoppingActive,
    performanceMaxActive,
} = useNGramTool()

const { lookbackWindow } = useNGramFilters()
const { entitySource, searchTermTableHeaders } = useNGramHeaders()
const { closePanel } = useNgramPanels()

const props = defineProps<{
    searchTerms: string[]
}>()

const emit = defineEmits<{
    (e: 'add-search-terms-as-negatives', searchTerms: SearchTermItem[]): void
}>()

const { accountId } = useAccount()

const channel = computed<NgramTool.CampaignChannelType>(() =>
    performanceMaxActive.value ? 'pmax' : shoppingActive.value ? 'shopping' : 'search'
)

const { data, isValidating: isSearchTermsLoading } = useAPI<SearchTermRes[]>(
    Endpoint.GetSearchTermMetrics,
    {
        body: () => ({
            accountId: accountId.value,
            searchTermResourceNames: props.searchTerms,
            lookbackWindow: lookbackWindow.value.value,
            channel: channel.value,
        }),
        uniqueId: () => `${JSON.stringify(props.searchTerms)}`,
    }
)

const formattedSearchTerms = computed(() => {
    if (isSearchTermsLoading.value) {
        const skeletons = new Array(15).fill({})

        return skeletons as PanelItem<SearchTermRes>[]
    }

    if (!data.value) {
        return []
    }

    return formatItemsForPanels(data.value)
})

// Batch Bar Functionality

const searchTermTable = ref<HTMLElement>()
// @ts-expect-error this is exactly what you need so stop complaining
const { width, left, right } = useElementBounding(searchTermTable)

const batchBarWidth = computed(() => `${width.value}px`)
const batchBarLeft = computed(() => `${left.value + 24}px`)
const batchBarRight = computed(() => `${right.value}px`)

const showBatchBar = computed(() => {
    return selectedSearchTerms.value.length > 0
})

// Select Single Search Term functionality

const selectedSearchTerms = ref<SearchTermItem[]>([])

const isSearchTermSelected = (uniqueKey: string) =>
    selectedSearchTerms.value.some(selectedSearchTerm => selectedSearchTerm.uniqueKey === uniqueKey)

const toggleSelectedSearchTerm = (searchTermItem: SearchTermItem) => {
    if (isSearchTermSelected(searchTermItem.uniqueKey)) {
        selectedSearchTerms.value = selectedSearchTerms.value.filter(
            selectedSearchTerm => selectedSearchTerm.uniqueKey !== searchTermItem.uniqueKey
        )
    } else {
        selectedSearchTerms.value = [...selectedSearchTerms.value, searchTermItem]
    }
}

// Select All Search Terms functionality

const allSearchTermsSelected = computed(() => {
    return selectedSearchTerms.value.length === props.searchTerms.length
})

const clearSelectedSearchTerms = () => {
    selectedSearchTerms.value = []
}

const toggleAllSearchTerms = () => {
    if (allSearchTermsSelected.value) {
        clearSelectedSearchTerms()
    } else {
        selectedSearchTerms.value = formattedSearchTerms.value
    }
}

// Add Negative Search Terms functionality

const handleAddSearchTermsAsNegatives = () => {
    emit('add-search-terms-as-negatives', selectedSearchTerms.value)
    closePanel()
    goToNegativeDestination()
}
</script>

<style scoped lang="scss">

// @import '@/assets/css/theme.scss';
// @import '@/assets/css/variables.scss';

// .search-term-cell {
//     display: flex;
//     align-items: center;
//     cursor: pointer;
// }

// :deep(table td[data-label='Search Term']),
// :deep(table td[data-label='Ad Group Source']),
// :deep(table td[data-label='Campaign Source']) {
//     overflow: hidden;
//     position: relative;
// }
// :deep(table td[data-label='Search Term']::after) {
//     content: '';
//     background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
//     @include absolute;
//     top: 0;
//     right: -1rem;
//     width: 6rem;
//     bottom: 0;
// }

// :deep(table td[data-label='Ad Group Source']::after),
// :deep(table td[data-label='Campaign Source']::after) {
//     content: '';
//     background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
//     @include absolute;
//     top: 0;
//     right: -0.5rem;
//     width: 5rem;
//     bottom: 0;
// }

// .info-circle-icon {
//     width: 1rem;
// }

// .batch-bar {
//     &.search-terms-batch-bar {
//         width: calc(v-bind(batchBarWidth) - 3rem);
//         left: v-bind(batchBarLeft);
//         right: v-bind(batchBarRight);
//         bottom: 1.75rem;
//     }
// }

</style>
