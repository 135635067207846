<template>
    <div class="match-types-table-wrapper">
        <oTable
            :headers="[
                { key: 'text', text: 'Negative Keyword', sortable: true },
                { key: 'matchType', text: 'Match Type', width: 160 },
            ]"
            :items="negativeKeywords"
            :border-radius="24"
            fixed-layout
        >
            <template #column.text="{ row }">
                <div class="negative-list-cell">
                    <EntityPill
                        :type="Improvement.LocationEntity.NegativeKeyword"
                        :content="formatKeyword(row)"
                    />
                </div>
            </template>

            <template #column.matchType="{ value, row }">
                <Select
                    :model-value="formatMatchTypeOption(value)"
                    :options="matchTypeOptions"
                    @update:model-value="handleMatchTypeUpdate($event, row)"
                />
            </template>
        </oTable>
    </div>
</template>

<script setup lang="ts">
// import { oTable, EntityPill, Select } from '@opteo/components-next'
import { Improvement, Gads } from '@opteo/types'

import { useNGramAddNegatives } from '@/composition/toolkit/nGramTool/useNGramAddNegatives'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

type ValidMatchType =
    | Gads.enums.KeywordMatchType.BROAD
    | Gads.enums.KeywordMatchType.EXACT
    | Gads.enums.KeywordMatchType.PHRASE

const { negativeKeywords } = useNGramAddNegatives()
const { formatKeyword } = useNGramTool()

const matchTypeOptions = [
    { label: 'Broad', value: Gads.enums.KeywordMatchType.BROAD },
    { label: 'Exact', value: Gads.enums.KeywordMatchType.EXACT },
    { label: 'Phrase', value: Gads.enums.KeywordMatchType.PHRASE },
] as const satisfies Array<{ label: string; value: ValidMatchType }>

function formatMatchTypeOption(matchType: Gads.enums.KeywordMatchType) {
    return matchTypeOptions.find(option => option.value === matchType)!
}

function handleMatchTypeUpdate(
    { value }: { value: Gads.enums.KeywordMatchType },
    { keyword }: { keyword: string }
) {
    const negativeKeyword = negativeKeywords.value.find(
        negativeKeyword => negativeKeyword.keyword === keyword
    )

    if (!negativeKeyword) return

    negativeKeyword.matchType = value
}
</script>

<style scoped lang="scss">

// @import '@/assets/css/theme.scss';
// @import '@/assets/css/variables.scss';

// .match-types-table-wrapper {
//     width: 100%;
//     max-width: 520px;
//     margin: 0 auto;
// }

// .negative-list-cell {
//     overflow: hidden;
//     position: relative;
//     width: 100%;
// }
// .negative-list-cell::after {
//     content: '';
//     background: linear-gradient(270deg, #fff 1.5rem, rgba(255, 255, 255, 0) 100%);
//     @include absolute;
//     top: 0;
//     right: -1rem;
//     width: 6rem;
//     bottom: 0;
// }

</style>
