<template>
    <ExternalPageWrapper :maxWidth="280">
        <Container :borderRadius="22" width="100%" hideOverflow>
            <Stack direction="column" align="start" :padding="[16, 20]" gap="0.75rem" borderBottom>
                <Text as="h6" size="headline-six" color="foreground">Welcome to Opteo</Text>
                <Text v-if="showForm" as="p" size="body-extra-small" color="foreground-minus-one">
                    Your account is almost ready. To complete the process, create a secure password:
                </Text>
                <Text v-else as="p" size="body-extra-small" color="foreground-minus-one">
                    Your account is ready. Click below to login.
                </Text>
            </Stack>
            <Form @submitted="createPassword">
                <!-- Error -->
                <Stack v-if="showError" :padding="[16, 20]" borderBottom>
                    <ErrorMessage>
                        {{ errorMessage }}
                    </ErrorMessage>
                </Stack>
                <!-- Spinner -->
                <Stack v-show="loading" :padding="20" justify="center">
                    <Spinner />
                </Stack>
                <!-- Password form -->
                <Stack
                    :padding="showForm ? [18, 20, 20, 20] : 20"
                    direction="column"
                    gap="1.25rem"
                    v-show="!unrecoverable && !loading"
                >
                    <Stack v-if="showForm" direction="column" gap="1.125rem">
                        <TextInput
                            name="newPassword"
                            ref="newPasswordInput"
                            v-model="password"
                            type="password"
                            :validator="newPasswordValidator"
                            autocomplete="new-password"
                            label="New Password"
                            placeholder="••••••••••"
                            autofocus
                            required
                        />
                        <TextInput
                            name="confirmPassword"
                            ref="confirmPasswordInput"
                            v-model="password2"
                            type="password"
                            :validator="newPasswordValidator"
                            autocomplete="new-password"
                            label="Confirm New Password"
                            placeholder="••••••••••"
                            required
                        />
                    </Stack>
                    <Button
                        v-if="showForm"
                        :loading="formLoading"
                        color="primary"
                        iconAfter="ArrowUpRight"
                        fullWidth
                        submit
                    >
                        Create Account
                    </Button>
                    <Button
                        v-else
                        color="primary"
                        iconAfter="ArrowUpRight"
                        fullWidth
                        @click="goToLogin"
                    >
                        Go to Login
                    </Button>
                </Stack>
            </Form>
        </Container>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useInviteWelcome } from '@/composition/misc/useInviteWelcome'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Components
import {
    Form,
    Stack,
    TextInput,
    Text,
    Button,
    Container,
    Spinner,
    ErrorMessage,
} from '@opteo/components-next'

// Setup
const {
    showForm,
    showError,
    errorMessage,
    loading,
    unrecoverable,
    password,
    password2,
    formLoading,
    createPassword,
    newPasswordInput,
    newPasswordValidator,
    confirmPasswordInput,
} = useInviteWelcome()
const router = useRouter()

// Functions
function goToLogin() {
    router.push({ name: Routes.Login })
}
</script>

<style scoped lang="scss"></style>
