import { ref } from 'vue'
import { Endpoint, noAuthRequest } from '@/composition/api/useAPI'
import { useRouter, useRoute } from 'vue-router'
import { Routes } from '@/router/routes'

export function useAfterCreate() {
    const route = useRoute()
    const router = useRouter()

    const selectedType = ref<string[]>([]) // Array required for `Choicebox`
    const loading = ref(false)
    const error = ref<string | null>(null)

    async function updateAccountType() {
        if (!selectedType.value.length) {
            error.value = 'Please select an account type.'
            return
        }

        loading.value = true
        error.value = null

        // Get userId from `params`
        const userId = route.params.id as string

        // Payload
        const avatarType = selectedType.value[0] === '1' ? 'agency' : 'business'
        const payload = {
            meta: {
                function: 'auth:updateUserInfo',
                args: {
                    user_id: userId,
                    avatar: avatarType,
                },
            },
        }

        // Request
        try {
            // TODO(components-updates): Create endpoint for updating `avatar`
            // Temporary example, new endpoint required.

            // const response = await noAuthRequest('/api/' + Endpoint.UpdateUserInfo, payload)

            // if (!response.success) {
            //     throw new Error(response.error || 'Failed to update account type.')
            // }

            // Redirect
            router.push({ name: Routes.ConnectGoogleAds, params: { id: userId } })
        } catch (err: any) {
            error.value = err.message || 'An unexpected error occurred.'
        } finally {
            loading.value = false
        }
    }

    return {
        selectedType,
        loading,
        error,
        updateAccountType,
    }
}
