<template>
    <PageWrapper v-if="!improvementOpen">
        <!-- Skeletons -->
        <ImprovementGroup v-if="pageLoading">
            <template #titlePrefix>
                <Skeleton :height="16" :width="128" />
            </template>
            <template #content>
                <template v-for="skid in 20" :key="skid">
                    <ImprovementRowSkeleton />
                </template>
            </template>
        </ImprovementGroup>

        <!-- Improvements -->
        <Stack v-else direction="column" width="100%" :gap="0" grow>
            <!-- Improvements Loop -->
            <Stack direction="column" width="100%" :gap="0" grow v-if="!mockEmptyState">
                <ImprovementGroups :shouldEnableTransition="shouldEnableParentTransition">
                    <div
                        v-for="(improvementGroup, index) in improvementsGrouped"
                        :key="improvementGroup.label"
                        :style="[{ zIndex: improvementsGrouped.length + index }, { width: '100%' }]"
                    >
                        <ImprovementGroup
                            :title="improvementGroup.label"
                            :first="index === 0"
                            :last="index === improvementsGrouped.length - 1"
                            @before-item-leave="() => toggleParentTransition(true)"
                            @after-item-leave="() => toggleParentTransition(false)"
                        >
                            <template #titleSuffix>
                                <IndicatorTag
                                    color="blue"
                                    @click="toggleImprovementGroup(improvementGroup)"
                                >
                                    {{ improvementGroup.improvementsInGroup.length }}
                                    Improvement<span
                                        v-if="improvementGroup.improvementsInGroup.length != 1"
                                        >s</span
                                    >
                                </IndicatorTag>
                            </template>
                            <template #right>
                                <ImprovementGroupChecks :checks="exampleChecks" />
                            </template>
                            <template #content>
                                <template
                                    v-for="(
                                        improvement, index
                                    ) in improvementGroup.improvementsInGroup"
                                    :key="improvement.improvement_id"
                                    class="improvement-row-wrapper"
                                >
                                    <ImprovementRowActive
                                        :improvement="improvement"
                                        :queued-improvement="findQueuedImprovement(improvement)"
                                        :queue-running="queueRunning"
                                        :batch-queue-running="batchQueueRunning"
                                    />
                                </template>
                                <Stack v-if="improvementGroup.improvementsInGroup.length === 0">
                                    <EmptyState
                                        icon="SparklesExtraLarge"
                                        hideContainer
                                        :contentMaxWidth="320"
                                        :padding="above480 ? 48 : [48, 24]"
                                        message="All improvements in this category have been pushed, or there aren't currently any improvements for Opteo to recommend. Opteo will continue to monitor your account for any potential improvements."
                                    />
                                </Stack>
                            </template>
                        </ImprovementGroup>
                    </div>
                </ImprovementGroups>
            </Stack>
            <!-- Empty States -->
            <template v-else>
                <!-- Empty State (All Completed) -->
                <EmptyState
                    v-if="emptyState?.status === EmptyStates.CompletedImps"
                    icon="SparklesExtraLarge"
                    headline="All Improvements Completed"
                    message="Congratulations! All of your active improvements have been completed. Opteo will continue to analyse your account for potential improvements, please check back tomorrow."
                    hideContainer
                    :padding="0"
                    :contentMaxWidth="380"
                >
                    <Button iconAfter="ArrowUpRight" color="secondary" @click="goToCompleted">
                        Go to Completed
                    </Button>
                </EmptyState>
                <!-- Empty State (First Improvements) -->
                <EmptyState
                    v-if="emptyState?.status === EmptyStates.FirstImps"
                    icon="SparklesExtraLarge"
                    headline="Improvements Incoming"
                    message="Opteo is gathering performance data and analysing your account for improvements. Check back in a few minutes."
                    hideContainer
                    :padding="0"
                    :contentMaxWidth="340"
                >
                    <Button iconAfter="ArrowUpRight" color="secondary" @click="openMessenger()">
                        Message Support
                    </Button>
                </EmptyState>
                <!-- Empty State (Zero Spend) -->
                <EmptyState
                    v-if="emptyState?.status === EmptyStates.ZeroSpend"
                    icon="ExclamationExtraLarge"
                    headline="No Active Campaigns"
                    message="For Opteo to generate improvements, your account must have at least one active campaign with spend. If you are seeing this message in error, or need assistance, please message support."
                    hideContainer
                    :padding="0"
                    :contentMaxWidth="376"
                >
                    <Button iconAfter="ArrowUpRight" color="secondary" @click="openMessenger()">
                        Message Support
                    </Button>
                </EmptyState>
                <!-- Empty State (Campaigns Paused) -->
                <EmptyState
                    v-if="emptyState?.status === EmptyStates.CampaignsPaused"
                    icon="ExclamationExtraLarge"
                    headline="All Campaigns Paused"
                    :message="`All active campaigns in this account have been paused because your current monthly spend has exceeded your monthly budget. Campaigns are scheduled to resume spending on ${resumeSpending}. To reactivate your campaigns now, or adjust your monthly budget, click below.`"
                    hideContainer
                    :padding="0"
                    :contentMaxWidth="428"
                >
                    <Stack direction="column" :gap="24">
                        <Stack direction="row" :gap="10" width="100%" justify="center">
                            <Button color="secondary" @click="goToBudgetSettings()">
                                Adjust Budget
                            </Button>
                            <Popout
                                :modelValue="showReactivatePopout"
                                :maxWidth="352"
                                :offset="[0, 7]"
                                trigger="mouseenter"
                                placement="top"
                            >
                                <Button
                                    color="green"
                                    iconAfter="ArrowUpRight"
                                    :loading="reactivatingCampaignsLoader"
                                    @click="reactivateCampaigns()"
                                >
                                    Reactivate Campaigns
                                </Button>
                                <template #content>
                                    <Container
                                        :width="352"
                                        :padding="[16, 20]"
                                        :borderRadius="18"
                                        color="background-plus-two"
                                        shadow="large"
                                    >
                                        <Text as="p" size="body-small" color="foreground">
                                            Clicking <b>Reactivate Campaigns</b> will reactivate
                                            your campaigns immediately. Adjust your budget settings
                                            to prevent your campaigns from being automatically
                                            paused again. Budgets are checked <b>once per hour</b>.
                                        </Text>
                                    </Container>
                                </template>
                            </Popout>
                        </Stack>
                    </Stack>
                </EmptyState>
                <!-- Empty State (Lost Write Access) -->
                <EmptyState
                    v-if="emptyState?.status === EmptyStates.LostWriteAccess"
                    icon="ExclamationExtraLarge"
                    headline="Write Access Denied"
                    message="Opteo no longer has write access to your account. This means you can no longer push changes via Opteo. To continue using Opteo, please update your account access permissions. If you need further assistance, get in touch with our support team."
                    hideContainer
                    :padding="0"
                    :contentMaxWidth="380"
                >
                    <Button iconAfter="ArrowUpRight" color="secondary" @click="openMessenger()">
                        Message Support
                    </Button>
                </EmptyState>
            </template>
        </Stack>

        <!-- Batch Bar -->
        <ImprovementBatchBar
            v-model="batchBarOpen"
            :batched-improvements="batchedImprovements"
            :batched-improvements-length="batchedImprovementsLength"
            :batch-queue-running="batchQueueRunning"
        />
    </PageWrapper>

    <!-- Improvement Template -->
    <router-view v-else />
</template>

<script setup lang="ts">
// Imports
import { computed, ref, watch, onMounted, onUnmounted, onBeforeUnmount, nextTick } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { useIntercom } from '@/lib/intercom/useIntercom'

// Types
import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import type { EnhancedImprovement } from '@/composition/improvement/types'

// Composition
import { useActiveImprovements } from '@/composition/improvement/useActiveImprovements'
import { useImprovementQueue } from '@/composition/improvement/useImprovementQueue'
import { useBatchBox } from '@/composition/improvement/useBatchBox'
import { useImprovementEmptyStates } from '@/composition/improvement/useImprovementEmptyStates'
import { useDomain } from '@/composition/domain/useDomain'

// Components
import {
    Button,
    Stack,
    Skeleton,
    EmptyState,
    Text,
    Popout,
    Container,
    useMediaQuery,
} from '@opteo/components-next'
import ImprovementRowSkeleton from '@/components/improvement/ImprovementRowSkeleton.vue'
import ImprovementRowActive from '@/components/improvement/ImprovementRowActive.vue'
import ImprovementBatchBar from '@/components/improvement/ImprovementBatchBar.vue'
import ImprovementGroups from '@/components/improvement/ImprovementGroups.vue'
import ImprovementGroup from '@/components/improvement/ImprovementGroup.vue'
import ImprovementGroupChecks from '@/components/improvement/ImprovementGroupChecks.vue'
import IndicatorTag from '@/components/global/IndicatorTag.vue'

// Setup
const { improvementsGrouped, loading: improvementsLoading } = useActiveImprovements()
const { queuedImprovements, queueRunning, cleanupQueue } = useImprovementQueue()
const { batchedImprovements, batchQueueRunning, toggleImprovementGroup, cleanupBatch } =
    useBatchBox()
const {
    emptyState,
    loadingEmptyState,
    formattedReEnableDate: resumeSpending,
    reactivatePausedCampaigns,
    reactivatingCampaignsLoader,
    EmptyStates,
    mockEmptyState,
} = useImprovementEmptyStates()
const { domainId } = useDomain()
const { openMessenger } = useIntercom()
const route = useRoute()
const router = useRouter()
const { above480 } = useMediaQuery()

// Reactivate Popout
const showReactivatePopout = ref(false)

// Functions
async function reactivateCampaigns() {
    await reactivatePausedCampaigns()
}

// Loading
const pageLoading = ref(true)
watch(
    [improvementsLoading, loadingEmptyState],
    ([newImp, newEmpty]) => {
        // as soon as both are false, set `pageLoading` to false
        if (!newImp && !newEmpty) {
            pageLoading.value = false
        }
    },
    { immediate: true }
)

// Computed
const improvementOpen = computed(() => !!route.params.improvementId)

// automatically open or close batchbar
const batchBarOpen = ref(false)
const batchedImprovementsLength = computed(() => batchedImprovements.value.length)

watch(batchedImprovementsLength, (newVal, oldVal) => {
    if (newVal && !oldVal) {
        batchBarOpen.value = true
    }
    if (!newVal && oldVal) {
        batchBarOpen.value = false
    }
})

const findQueuedImprovement = (improvement: EnhancedImprovement) => {
    const queuedImprovement: QueuedImprovement | undefined = queuedImprovements.value.find(
        i => i.id === improvement.improvement_id
    )

    return queuedImprovement
}

// Transition state
const shouldEnableParentTransition = ref(false)
const toggleParentTransition = async (value: boolean) => {
    await nextTick()
    shouldEnableParentTransition.value = value
}

// Example `checks`
const exampleChecks = ref({
    lastChecked: new Date(Date.now() - 4 * 60 * 60 * 1000),
    checks: [
        {
            name: 'Age Demographics',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 892, label: 'instances checked' },
                { value: 3, label: 'improvements generated' },
            ],
        },
        {
            name: 'Gender Demographics',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 785, label: 'instances checked' },
                { value: 2, label: 'improvements generated' },
            ],
        },
        {
            name: 'Parental Status Demographics',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 584, label: 'instances checked' },
                { value: 0, label: 'improvements generated' },
            ],
        },
        {
            name: 'Income Demographics',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 2348, label: 'instances checked' },
                { value: 2, label: 'improvements generated' },
            ],
        },
        {
            name: 'Location Demographics',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 843, label: 'instances checked' },
                { value: 4, label: 'improvements generated' },
            ],
        },
        {
            name: 'Device Demographics',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 892, label: 'instances checked' },
                { value: 3, label: 'improvements generated' },
            ],
        },
        {
            name: 'Locations of Interest',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 785, label: 'instances checked' },
                { value: 2, label: 'improvements generated' },
            ],
        },
        {
            name: 'Partner Sites',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 584, label: 'instances checked' },
                { value: 0, label: 'improvements generated' },
            ],
        },
        {
            name: 'YouTube Placements',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 2348, label: 'instances checked' },
                { value: 2, label: 'improvements generated' },
            ],
        },
        {
            name: 'Gmail Placements',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 840, label: 'instances checked' },
                { value: 2, label: 'improvements generated' },
            ],
        },
        {
            name: 'Website Placements',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 578, label: 'instances checked' },
                { value: 4, label: 'improvements generated' },
            ],
        },
        {
            name: 'Mobile App Placements',
            subChecks: [
                { value: 38, label: 'campaigns checked' },
                { value: 286, label: 'ad groups checked' },
                { value: 823, label: 'instances checked' },
                { value: 0, label: 'improvements generated' },
            ],
        },
    ],
})

// Router links
function goToCompleted() {
    router.push({ name: Routes.ImprovementsCompleted })
}
function goToBudgetSettings() {
    router.push({ name: Routes.DomainSettings, params: { scroll_position: 'update-budget' } })
}

// Lifecycle
onMounted(() => {
    window.addEventListener('beforeunload', cleanupBatch)
    window.addEventListener('beforeunload', cleanupQueue)
})

onBeforeUnmount(() => {
    // Cleanup batch queue and standard queue also on refresh or back/forward click
    window.removeEventListener('beforeunload', cleanupBatch)
    window.removeEventListener('beforeunload', cleanupQueue)
})

onUnmounted(() => cleanupBatch())
</script>

<style scoped lang="scss"></style>
