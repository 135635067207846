<template>
    <ImprovementRowContainer :improvement="improvement" @improvement-opened="openImprovement">
        <template #icon>
            <DismissIcon small color="gray" />
        </template>
        <template #buttons>
            <Menu
                buttonSize="small"
                placement="bottom-end"
                :offset="[4, 6]"
                :items="[
                    {
                        value: 'openImprovement',
                        label: 'Open Improvement',
                        icon: 'Overlays',
                        action: () => openImprovement(),
                    },
                    {
                        value: 'shareImprovement',
                        label: 'Share Improvement',
                        icon: 'Link',
                        action: () => console.log('Shared Improvement'),
                    },
                    {
                        value: 'revertDismissal',
                        label: 'Revert Dismissal',
                        icon: 'Lightning',
                        action: () => revertDismissImprovement(improvement.improvement_id),
                    },
                    {
                        value: 'shareFeedback',
                        label: 'Share Feedback',
                        icon: 'Pencil',
                        action: () => shareFeedback(),
                    },
                ]"
            />
        </template>
    </ImprovementRowContainer>
</template>

<script setup lang="ts">
// Imports
import { PropType } from 'vue'
import { Improvement } from '@opteo/types'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useDismissImprovement } from '@/composition/improvement/useDismissImprovement'
import { useDismissFeedbackModal } from '@/composition/improvement/useDismissFeedbackModal'

// Components
import { Menu, DismissIcon } from '@opteo/components-next'
import ImprovementRowContainer from '@/components/improvement/ImprovementRowContainer.vue'

// Props
const props = defineProps({
    improvement: {
        required: true,
        type: Object as PropType<Improvement.Object>,
    },
    isDeferred: {
        // should be false if the deferred_until date is far into the future
        required: false,
        type: Boolean,
        default: false,
    },
})

// Setup
const router = useRouter()
const { openDismissFeedbackModal } = useDismissFeedbackModal()
const { revertDismissImprovement } = useDismissImprovement()

// Functions
const shareFeedback = () => {
    return openDismissFeedbackModal({
        improvementId: props.improvement.improvement_id,
        improvementTitle: props.improvement.title,
        improvementLocation: props.improvement.location,
        improvementRecAction: props.improvement.rec_action,
    })
}

const openImprovement = () => {
    router.push({
        name: Routes.DismissedImprovement,
        params: { improvementId: props.improvement.improvement_id },
    })
}
</script>

<style scoped lang="scss"></style>
