<template>
    <PageWrapper>
        <PageHeader title="Toolkit">
            <template #titleSuffix>
                <PageSwitcher :pages="pageSwitcherPages" />
            </template>
            <template #right>
                <Button color="secondary" iconBefore="Lightning" iconAfter="CaretDown" size="small">
                    48 Improvement Types
                </Button>
                <Button color="secondary" iconBefore="Loaders" size="small">Targets</Button>
            </template>
        </PageHeader>
        <router-view></router-view>
    </PageWrapper>
</template>

<script setup lang="ts">
// Imports
import { ref } from 'vue'
import { Routes } from '@/router/routes'

// Components
import { Button } from '@opteo/components-next'
import PageSwitcher from '@/components/global/PageSwitcher.vue'

// PageSwitcher
const pageSwitcherPages = ref([
    { label: 'Keyword Finder', route: Routes.ToolkitKeywordFinder },
    { label: 'N-Gram Finder', route: Routes.ToolkitNGramFinderSearchSelect },
    { label: 'Account Manager', route: Routes.ToolkitAccountManager },
    { label: 'Product Manager', route: Routes.ToolkitProductManager },
])
</script>

<style scoped lang="scss"></style>
