<template>
    <Container :borderRadius="20" :width="480">
        <Stack direction="column" align="start" :padding="isEditing ? 16 : [16, 20]" width="100%">
            <Stack v-if="loading" :padding="[4, 0]">
                <Skeleton :height="12" :width="128" />
            </Stack>
            <Text v-else-if="!isEditing" as="p" size="body-small">{{ note.text }}</Text>
            <Textarea
                v-else-if="isEditing"
                ref="inputField"
                v-model="localText"
                v-on:keyup.enter.exact="save"
                autofocus
            />
        </Stack>
        <!-- Footer -->
        <Stack direction="row" justify="between" :padding="[13, 20]" width="100%" borderTop>
            <Stack v-if="loading" direction="row" :gap="12" width="100%" align="center">
                <Skeleton :height="24" :width="24" />
                <Stack direction="column" :padding="[3, 0, 0, 0]" :gap="4" align="start">
                    <Stack :padding="[2, 0]">
                        <Skeleton :height="11" :width="88" />
                    </Stack>
                    <Stack :padding="[5, 0]">
                        <Skeleton :height="8" :width="76" />
                    </Stack>
                </Stack>
            </Stack>
            <Stack
                v-else-if="note.type === NoteType.Text"
                direction="row"
                :gap="16"
                width="100%"
                align="center"
            >
                <ProfileImage
                    :size="24"
                    :imageUrl="note.author_avatar_filename"
                    :boringAvatar="avatarUrl"
                />
                <Stack direction="column" :padding="[3, 0, 0, 0]" :gap="4" align="start">
                    <Text as="h6" size="headline-seven" color="foreground">
                        {{ note.author_name }}
                    </Text>
                    <Text as="span" size="body-extra-small" color="foreground-minus-one">
                        {{ note.author_email }}
                    </Text>
                </Stack>
            </Stack>
            <Stack v-else direction="row" :gap="16" width="100%" align="center">
                <OpteoLogo :size="24" />
                <Stack direction="column" :padding="[3, 0, 0, 0]" :gap="4" align="start">
                    <Text as="h6" size="headline-seven" color="foreground">Auto Generated</Text>
                    <Text as="span" size="body-extra-small" color="foreground-minus-one">
                        support@opteo.com
                    </Text>
                </Stack>
            </Stack>
            <Stack
                v-if="note.type === NoteType.Text"
                direction="row"
                :gap="8"
                align="center"
                width="auto"
            >
                <Button @click="remove" size="extra-small" color="secondary" :disabled="loading">
                    Delete
                </Button>
                <Button
                    v-if="isEditing"
                    @click="save"
                    :disabled="loading"
                    size="extra-small"
                    color="secondary"
                >
                    Save
                </Button>
                <Button
                    v-if="!isEditing"
                    @click="edit"
                    :disabled="loading"
                    size="extra-small"
                    color="secondary"
                >
                    Edit
                </Button>
            </Stack>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { computed, PropType, ref } from 'vue'

// Composition
import { useNotes, NormalisedNote, NoteType } from '@/composition/notes/useNotes'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

// Types
import { Account } from '@opteo/types'

// Components
import {
    Button,
    ProfileImage,
    Text,
    Stack,
    Container,
    Textarea,
    OpteoLogo,
    Skeleton,
} from '@opteo/components-next'

// Props
const props = defineProps({
    note: {
        type: Object as PropType<NormalisedNote>,
        required: true,
    },
    accountId: {
        type: String as PropType<Account.ID>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Setup
const { startNoteEdit, deleteNote, saveNote } = useNotes(ref(props.accountId))
const { getAvatarUrl } = useBoringAvatar()
const avatarUrl = computed(() => getAvatarUrl(props.note?.author_user_id))
const inputField = ref(null)
const localText = ref(props.note.text)
const isEditing = ref(props.note.currentlyEditing)

// Functions
const remove = () => {
    deleteNote(props.note.id, props.note.day)
}
const edit = () => {
    startNoteEdit(props.note.id)
    isEditing.value = true
}
const save = () => {
    saveNote(props.note.id, props.note.day, localText.value)
    isEditing.value = false
}
</script>

<style scoped lang="scss"></style>
