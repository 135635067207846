<template>
    <Stack ref="stackRef" :width="86">
        <Skeleton v-if="loading" :width="72" :height="32" color="secondary" />
        <Popout
            v-else
            v-model="showPopout"
            :trigger="loading ? undefined : 'mouseenter'"
            placement="top"
            :offset="[0, 10]"
            :delay="[200, 0]"
            :appendTo="stackEl"
        >
            <Pill :color="color" :label="`${count} Issues`"></Pill>
            <template #content>
                <Container
                    color="background-plus-two"
                    shadow="large"
                    :borderRadius="count > 0 ? 16 : 13"
                    :width="count > 0 ? 232 : 'auto'"
                    hideOverflow
                >
                    <Scroller v-if="count > 0" nested :padding="[16]" maxHeight="388px">
                        <Stack :gap="12" direction="column" width="100%">
                            <Stack v-for="(issue, index) in issues" :gap="10">
                                <!-- <Count :value="issue.count" size="medium" color="tertiary" /> -->
                                <Container
                                    :width="20"
                                    :height="20"
                                    align="center"
                                    justify="center"
                                    color="background-plus-three"
                                    :borderRadius="999"
                                    hideOverflow
                                >
                                    <Text
                                        as="span"
                                        size="label-small"
                                        weight="bold"
                                        :color="
                                            issue.count === 0
                                                ? 'foreground-minus-two'
                                                : 'foreground'
                                        "
                                    >
                                        {{ issue.count }}
                                    </Text>
                                </Container>
                                <Text
                                    as="span"
                                    size="headline-seven"
                                    :color="
                                        issue.count === 0 ? 'foreground-minus-three' : 'foreground'
                                    "
                                >
                                    {{ issue.label }}
                                </Text>
                            </Stack>
                        </Stack>
                    </Scroller>
                    <Stack
                        :padding="count > 0 ? [12, 20, 14, 20] : [11, 18, 11, 18]"
                        :gap="0"
                        :borderTop="count > 0"
                        align="center"
                        justify="center"
                        width="100%"
                    >
                        <Text
                            as="p"
                            size="body-extra-small"
                            align="center"
                            color="foreground-minus-one"
                            :wrap="false"
                        >
                            Last checked <b>{{ formatLastChecked }}</b>
                        </Text>
                    </Stack>
                </Container>
            </template>
        </Popout>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, computed, ref } from 'vue'
import formatDistanceToNow from 'date-fns/formatDistanceToNow'

// Components
import {
    Popout,
    Container,
    Scroller,
    Stack,
    Pill,
    Skeleton,
    Text,
    Count,
} from '@opteo/components-next'

// Props
const props = defineProps({
    issues: {
        type: Object as PropType<object[]>,
        required: true,
    },
    lastChecked: {
        type: Date as PropType<Date>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Setup
const showPopout = ref(false)

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})

// formatLastChecked
const formatLastChecked = computed(() => {
    if (!props.lastChecked) return ''

    return formatDistanceToNow(props.lastChecked, { addSuffix: true }).replace(/^about\s/, '')
})

// Count
const count = computed(() => props.issues.reduce((sum, issue) => sum + issue.count, 0))

// Computed
const color = computed(() => {
    if (count.value === 0) return 'gray'
    if (count.value <= 5) return 'green'
    if (count.value <= 10) return 'amber'
    return 'red'
})
</script>
