<template>
    <ExternalPageWrapper :maxWidth="280">
        <Container :borderRadius="22" width="100%" hideOverflow>
            <!-- SSO -->
            <ssoButtons
                @google-clicked="stytchSSO('login', 'google')"
                @microsoft-clicked="stytchSSO('login', 'microsoft')"
                @linkedin-clicked="stytchSSO('login', 'linkedin')"
            />

            <!-- Form -->
            <Form id="login" @submitted="login" validateOnSubmit>
                <Stack :padding="[18, 20, 20, 20]" direction="column" gap="1.25rem">
                    <Stack direction="column" gap="1.125rem">
                        <TextInput
                            ref="emailInput"
                            type="email"
                            name="email"
                            label="Email Address"
                            placeholder="johnny@appleseed.com"
                            autocomplete="email"
                            autofocus
                            required
                            @updated="validatePassword"
                        />

                        <TextInput
                            ref="passwordInput"
                            type="password"
                            name="password"
                            label="Password"
                            placeholder="••••••••••"
                            autocomplete="password"
                            required
                        />
                    </Stack>
                    <Button submit :loading="loading" fullWidth iconAfter="ArrowUpRight">
                        Login with Email
                    </Button>
                </Stack>
            </Form>
        </Container>
        <Stack :padding="[20, 0, 0, 0]" direction="column" gap="0.375rem">
            <Text size="body-extra-small" align="center" as="p">
                <router-link to="/createaccount" data-cy="create-link">
                    <Text as="span" color="foreground-minus-three">
                        Don't have an account? Create one here.
                    </Text>
                </router-link>
            </Text>
            <Text size="body-extra-small" align="center" as="p">
                <router-link to="/reset" data-cy="reset-link">
                    <Text as="span" color="foreground-minus-three">
                        Forgot password? Reset it here.
                    </Text>
                </router-link>
            </Text>
        </Stack>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useLogin } from '@/composition/auth/useLogin'
import { useStytchSignIn } from '@/composition/auth/useStytchSignIn'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'
import ssoButtons from '@/components/connectors/sso/ssoButtons.vue'

// Components
import { Form, Stack, TextInput, Text, Button, Container } from '@opteo/components-next'

// Setup
const { login, validatePassword, loading, emailInput, passwordInput } = useLogin()

const { stytchSSO } = useStytchSignIn()
</script>

<style scoped lang="scss"></style>
