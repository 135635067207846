<template>
    <Container
        @click="handleClick"
        clickable
        clickAnimation
        tabindex="0"
        :width="60"
        :height="60"
        :borderRadius="16"
        :outline="false"
        :color="color"
        :hoverColor="hoverColor"
        align="center"
        justify="center"
        :class="[{ active }, 'app-sidebar-button']"
    >
        <slot></slot>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { PropType, computed } from 'vue'
import { useColorMode } from '@vueuse/core'

// Components
import { Container } from '@opteo/components-next'

// Props
const props = defineProps({
    active: {
        type: Boolean as PropType<boolean>,
        default: false,
        required: false,
    },
})

// Emits
const emit = defineEmits(['click'])

// Setup
const mode = useColorMode()

// Computed
const color = computed(() => {
    if (mode.value === 'light') {
        if (props.active) {
            return 'primary-translucent'
        } else {
            return 'transparent'
        }
    } else if (mode.value === 'dark') {
        if (props.active) {
            return 'background-plus-one'
        } else {
            return 'transparent'
        }
    }
})
const hoverColor = computed(() => {
    if (mode.value === 'light') {
        if (props.active) {
            return 'primary-translucent'
        } else {
            return 'sidebar-item-default'
        }
    } else return 'background-plus-one'
})

// Functions
const handleClick = () => {
    emit('click')
}
</script>

<style scoped lang="scss">
.light .app-sidebar-button :deep(svg:not(.opteo-logo) path) {
    fill: $light-foreground-minus-three;
}
.dark .app-sidebar-button :deep(svg:not(.opteo-logo) path) {
    fill: $dark-foreground-minus-three;
}

.light .app-sidebar-button.active :deep(svg:not(.opteo-logo) path) {
    fill: $dark-blue;
}
.dark .app-sidebar-button.active :deep(svg:not(.opteo-logo) path) {
    fill: $dark-foreground;
}
</style>
