<template>
    <svg width="28" height="22" viewBox="0 0 28 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M0 4.79933c0-1.68016 0-2.52024.32698-3.16197.287621-.56449.74656-1.023431 1.31105-1.311051C2.27976-.00067139 3.11984-.00067139 4.8-.00067139h18.4c1.6802 0 2.5202 0 3.162.32698039.5645.28762 1.0234.746561 1.311 1.311051C28 2.27909 28 3.11917 28 4.79933V17.1993c0 1.6802 0 2.5203-.327 3.162-.2876.5645-.7465 1.0234-1.311 1.311-.6418.327-1.4818.327-3.162.327H4.8c-1.68016 0-2.52024 0-3.16197-.327-.56449-.2876-1.023429-.7465-1.31105-1.311C0 19.7196 0 18.8795 0 17.1993V4.79933Zm26 1.2H2V16.7993c0 1.1201 0 1.6802.21799 2.108.19174.3763.4977.6823.87403.874.42782.218.98788.218 2.10798.218h17.6c1.1201 0 1.6802 0 2.108-.218.3763-.1917.6823-.4977.874-.874.218-.4278.218-.9879.218-2.108V5.99933Zm-23-2c.55228 0 1-.44772 1-1 0-.55229-.44772-1-1-1s-1 .44771-1 1c0 .55228.44772 1 1 1Zm4-1c0 .55228-.44772 1-1 1s-1-.44772-1-1c0-.55229.44772-1 1-1s1 .44771 1 1Zm2 1c.55229 0 1-.44772 1-1 0-.55229-.44771-1-1-1s-1 .44771-1 1c0 .55228.44771 1 1 1Z"
            fill="#373744"
        />
    </svg>
</template>
