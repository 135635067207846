<template>
    <ExternalPageWrapper :maxWidth="280" showLogoutButton>
        <Container :borderRadius="22" width="100%" hideOverflow>
            <Stack direction="column" align="start" :padding="[16, 20]" gap="0.75rem" borderBottom>
                <Text as="h6" size="headline-six" color="foreground">Select Account Type</Text>
                <Text as="p" size="body-extra-small" color="foreground-minus-one">
                    Please select the account type that most accurately describes your business.
                </Text>
            </Stack>

            <Form @submitted="updateAccountType" validateOnSubmit>
                <!-- Error -->
                <Stack v-if="error" :padding="[16, 20]" borderBottom>
                    <ErrorMessage color="red">{{ error }}</ErrorMessage>
                </Stack>
                <!-- Form -->
                <Stack>
                    <Choicebox
                        v-model="selectedType"
                        name="accountType"
                        type="radio"
                        color="transparent"
                        :padding="[14, 20, 16, 20]"
                        :items="[
                            {
                                label: 'Agency',
                                value: '1',
                                description:
                                    'I manage a number of marketing accounts on behalf of my clients.',
                            },
                            {
                                label: 'Business',
                                value: '2',
                                description:
                                    'I manage marketing accounts on behalf of an individual business.',
                            },
                        ]"
                    />
                </Stack>
                <!-- Button -->
                <Stack :padding="20">
                    <Button submit iconAfter="ArrowUpRight" fullWidth :loading="loading">
                        Continue
                    </Button>
                </Stack>
            </Form>
        </Container>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useAfterCreate } from '@/composition/auth/useAfterCreate'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Components
import {
    Form,
    Stack,
    Text,
    Button,
    Container,
    ErrorMessage,
    Choicebox,
} from '@opteo/components-next'

// Setup
const { selectedType, loading, error, updateAccountType } = useAfterCreate()
</script>
