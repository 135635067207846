<template>
    <OnboardingModal
        mode="large-modal"
        :modelValue="modalVisible"
        :buttonText="buttonText"
        :contentMaxWidth="contentMaxWidth"
        :offsetBottom="offsetBottom"
        @update:modelValue="modalClosed"
    >
        <template #header>
            <slot name="header" />
        </template>
        <template #copy>
            <slot name="copy" />
        </template>
    </OnboardingModal>
</template>

<script setup lang="ts">
import { PropType } from 'vue'

// Components
import { OnboardingModal } from '@opteo/components-next'

import { useOnboardingTour } from '@/composition/user/useOnboardingTour'

// Props
const props = defineProps({
    stepName: {
        type: String as PropType<string>, // Must match field in `users` table
        required: true,
    },
    buttonText: {
        type: String as PropType<string>,
        required: true,
    },
    contentMaxWidth: {
        type: String as PropType<string>,
        required: false,
        default: '25rem',
    },
    offsetBottom: {
        type: Number as PropType<number>,
        required: false,
    },
})

// Setup
const { modalVisible, dismiss } = useOnboardingTour(props)

// Functions
const modalClosed = (isOpen: boolean) => {
    if (isOpen === false) {
        dismiss()
    }
}
</script>
