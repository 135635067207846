<template>
    <Modal title="Change History" v-model="changeHistoryModalOpen" width="852px" :no-padding="true">
        <template #content>
            <div class="change-history-items">
                <div v-for="change in changeHistory" class="history-item">
                    <div class="history-item-content">
                        <EntityPill
                            :type="Improvement.LocationEntity.NegativeKeyword"
                            :content="
                                formatKeyword({
                                    keyword: change.keyword,
                                    matchType: +change.matchType,
                                })
                            "
                        />
                        <Tooltip
                            :content="'Added by ' + change.userName"
                            :offset="[0, 6]"
                            :delay="[200, 0]"
                        >
                            <ProfileImage
                                :image-url="change.profileUrl"
                                :user-id="change.userId"
                                :width="28"
                                :hide-role="true"
                            />
                        </Tooltip>
                    </div>
                    <ChangeHistoryModalFooter
                        :match-type="change.matchType"
                        :negative-keywords-destinations="change.negativeKeywordsDestinations"
                        :date="change.date"
                    />
                </div>
            </div>
        </template>
    </Modal>
</template>

<script lang="ts" setup>
// import { Modal, EntityPill, ProfileImage, Tooltip } from '@opteo/components-next'
import { useNGramChangeHistory } from '@/composition/toolkit/nGramTool/useNGramChangeHistory'
import { Improvement } from '@opteo/types'

import ChangeHistoryModalFooter from './ChangeHistoryModalFooter.vue'
import { useNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

const { changeHistoryModalOpen } = useNGramChangeHistory()

const { changeHistory, formatKeyword } = useNGramTool()
</script>

<style scoped lang="scss">

// @import '@/assets/css/theme.scss';
// @import '@/assets/css/variables.scss';

// .change-history-items {
//     @include pv-48;
//     min-height: calc(100vh - 168px);
// }

// .history-item {
//     @include container;
//     border-radius: 1.375rem;
//     max-width: 480px;
//     margin: 0 auto;
// }

// .history-item:not(:last-child) {
//     @include mb-24;
// }

// .history-item-content {
//     @include ph-20;
//     @include pv-16;
//     display: flex;
//     justify-content: space-between;
// }

</style>
