<template>
    <Stack direction="column" :gap="0" :padding="22" align="start" width="100%">
        <!-- Description -->
        <GoogleAnalyticsLogo :size="24" />
        <Spacer :height="18" />
        <Stack align="start" width="100%" :gap="0" direction="column">
            <Text as="h6" size="headline-six" width="100%">Connect Google Analytics</Text>
            <Spacer :height="12" />
            <Text as="p" size="body-extra-small" color="foreground-minus-one">
                <slot v-if="$slots['no-connection-copy']" name="no-connection-copy" />
                <template v-else>
                    Connect Google Analytics to <b>this account</b> to get access to supplementary
                    data and usage metrics inside your Opteo account.
                    <Spacer :height="10" />
                    Once connected, your Analytics data will be available in Performance, Reports
                    and more.
                </template>
            </Text>
        </Stack>

        <!-- Loading -->
        <Stack
            v-if="connectionStatusLoading"
            width="100%"
            align="center"
            justify="center"
            :padding="[22, 22, 8, 22]"
        >
            <Spinner />
        </Stack>

        <!-- Not Connected -->
        <Stack v-if="!connectionStatusLoading" :padding="[16, 0, 0]">
            <Button
                v-if="!hasConnection"
                @click="linkGoogleAnalytics()"
                size="small"
                color="secondary"
                iconAfter="ArrowUpRight"
                :loading="linkGaButtonLoading"
                fullWidth
            >
                <template #before>
                    <GoogleAnalyticsLogo :size="12" />
                </template>
                Connect Google Analytics
            </Button>
            <Button v-else @click="linkGoogleAnalytics()" size="small" color="secondary" fullWidth>
                <template #before>
                    <GoogleAnalyticsLogo :size="12" />
                </template>
                Google Analytics Connected
                <template #after>
                    <Dot :size="10" />
                </template>
            </Button>
        </Stack>

        <!-- Connected -->
        <Stack
            v-if="hasConnection && !connectionStatusLoading"
            align="start"
            width="100%"
            :gap="0"
            direction="column"
            :padding="[14, 0, 0]"
        >
            <Text v-if="accountProfile" as="p" size="body-extra-small" color="foreground-minus-one">
                Connected on <b>{{ formattedLinkingDate }}</b>
            </Text>
            <Text
                v-if="accountProfile && accountProfile.name"
                as="p"
                size="body-extra-small"
                color="foreground-minus-one"
            >
                to <b>{{ accountProfile?.name }}</b> ({{ accountProfile?.id }})
            </Text>

            <Stack v-else :padding="[14, 0, 0]">
                <Button
                    :loading="savingChanges"
                    size="small"
                    color="secondary-highlight"
                    iconAfter="ArrowUpRight"
                    @click="openViewSelector()"
                >
                    Connect Analytics View
                </Button>
            </Stack>

            <!-- Button -->
            <Stack
                v-if="hasConnection && accountProfile && accountProfile?.name"
                align="center"
                justify="start"
                :padding="[14, 0, 0, 0]"
                width="100%"
            >
                <Button
                    :loading="savingChanges"
                    size="extra-small"
                    color="secondary"
                    @click="openViewSelector()"
                >
                    Edit Connection
                </Button>
            </Stack>
        </Stack>
    </Stack>

    <!-- Modal -->
    <AnalyticsModal
        v-model="actuallyShowModal"
        :loading="availableProfilesLoading"
        :accounts="availableProfiles?.accounts"
        :selected="selectedEntities"
        :filtered="filteredEntities"
        :selectEntity="selectAnalyticsEntity"
        :searchEntities="searchAnalyticsEntities"
        :hasConnection="hasConnection"
        :accountProfile="accountProfile"
        @close="cancelViewSelection"
        @linkView="linkView"
        @unlinkAccount="unlinkGoogleAnalytics"
    />
</template>

<script setup lang="ts">
// Composition
import { useAnalytics } from '@/composition/misc/useAnalytics'

// Components
import {
    Stack,
    Button,
    Text,
    Spinner,
    Spacer,
    GoogleAnalyticsLogo,
    Dot,
} from '@opteo/components-next'
import AnalyticsModal from '@/components/connectors/analytics/components/AnalyticsModal.vue'

// Props
const props = defineProps<{
    view?: string
    buttonColor?: string
}>()

// Emits
const emit = defineEmits(['before-oauth-redirect'])

// Setup
const {
    connectionStatusLoading,
    hasConnection,
    formattedLinkingDate,
    accountProfile,
    availableProfiles,
    availableProfilesLoading,
    actuallyShowModal,
    openViewSelector,
    cancelViewSelection,
    savingChanges,
    linkView,
    unlinkGoogleAnalytics,
    linkGoogleAnalytics,
    linkGaButtonLoading,
    selectAnalyticsEntity,
    selectedEntities,
    filteredEntities,
    searchAnalyticsEntities,
} = useAnalytics(async () => emit('before-oauth-redirect'))
</script>

<style scoped lang="scss"></style>
