<template>
    <div v-if="!createError">
        <GeneratingScorecardLoader
            :progress-percent="createProgress"
            :has-timed-out="createHasTimedOut"
            @give-up-on-waiting="backToToolkitTools()"
        />
    </div>
    <div v-else class="scorecard-empty-state-container">
        <marquee-text :duration="50" :repeat="4">
            <div class="slides">
                <OverviewLoaderSlide />
                <SearchTermCoverageLoaderSlide />
                <QualityScoreLoaderSlide />
                <ProjectedSpendLoaderSlide />
            </div>
        </marquee-text>
        <div class="empty-state-content">
            <Text as="p" size="f-8" align="center">
                Unfortunately, Opteo could not gather the required data to generate your scorecard.
                If you continue to have problems, please send support a message.
            </Text>
            <oButton size="medium" color="blue" @clicked="backToList()">
                Return to Scorecard
            </oButton>
        </div>
    </div>
</template>

<script lang="ts" setup>
import { ref, watch, onUnmounted } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { Endpoint, authRequestWithProgress } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'
// import { Text, oButton, showSnackbar } from '@opteo/components-next'
import GeneratingScorecardLoader from '@/components/scorecard/loader/GeneratingScorecardLoader.vue'
import MarqueeText from 'vue-marquee-text-component'
import OverviewLoaderSlide from '@/components/scorecard/loader/OverviewLoaderSlide.vue'
import SearchTermCoverageLoaderSlide from '@/components/scorecard/loader/SearchTermCoverageLoaderSlide.vue'
import QualityScoreLoaderSlide from '@/components/scorecard/loader/QualityScoreLoaderSlide.vue'
import ProjectedSpendLoaderSlide from '@/components/scorecard/loader/ProjectedSpendLoaderSlide.vue'
import { useScorecardList } from '@/composition/toolkit/scorecard/useScorecardList'

const SCORECARD_GENERATION_TIMEOUT = 90_000 // 90 seconds

const { domainId } = useDomain()

const router = useRouter()
const { mutateScorecardList } = useScorecardList()

/**
 * New Scorecard creation:
 * - call createNewScorecard in backend using polling mode to sidestep server timeouts
 * - wait for response
 * - get UUID from response
 * - redirect to new scorecard using UUID (or show snackbar with link)
 *
 * Behaviours to test:
    [x]  Scorecard succeeds before timeout ⇒ should redirect to new scorecard
    [x]  Scorecard succeeds after timeout (still viewing loader) ⇒ should redirect to new scorecard
    [x]  Scorecard succeeds after timeout (navigated away from loader) ⇒ should show snackbar with link

    [x]  Scorecard errors before timeout ⇒ should show error page
    [x]  Scorecard errors after timeout (still viewing loader) ⇒ should show error page
    [x]  scorecard errors after timeout (navigated away from loader) ⇒ Should show failure snackbar

 */

const createError = ref(false)
const createHasTimedOut = ref(false)
const createProgress = ref(0)
const cancelCurrentRequest = ref<() => void>()

async function createNewScorecard() {
    try {
        createProgress.value = 0
        createError.value = false
        createHasTimedOut.value = false

        const { dataPromise, progressStep, cancel } = authRequestWithProgress<string>(
            Endpoint.GenerateScorecard,
            {}
        )

        cancelCurrentRequest.value = cancel

        setTimeout(() => (createHasTimedOut.value = true), SCORECARD_GENERATION_TIMEOUT)

        watch(progressStep, value => (createProgress.value = value))

        const localDomainId = await domainId.value
        const scorecardId = await dataPromise

        // Only auto-redirect if we're on the scorecard loading page.
        // Otherwise provide a snackbar with a link to the new scorecard.
        if (router.currentRoute.value.name === Routes.PerformanceScorecardCreate) {
            router.push({
                name: Routes.PerformanceScorecardSingle,
                params: { scorecardId },
            })
        } else {
            showSnackbar({
                message: 'Scorecard is ready to view',
                actionText: 'View Scorecard',
                actionHandler: async () => {
                    router.push({
                        name: Routes.PerformanceScorecardSingle,
                        params: { scorecardId, accountId: localDomainId },
                    })
                },
                timeout: 600_000, // 10 minutes
            })
        }

        mutateScorecardList()
    } catch {
        // Only flip to error if the user is still on the scorecard loading page
        if (router.currentRoute.value.name === Routes.PerformanceScorecardCreate) {
            createError.value = true
        } else {
            showSnackbar({
                message: 'Unable to generate scorecard. If this persists, please contact support.',
                timeout: 10_000,
            })
        }
    }
}

function backToList() {
    router.push({ name: Routes.PerformanceScorecardList })
    // Note that this will also trigger the unmounted hook to cancel the request,
    // just as pressing `esc` would.
}

function backToToolkitTools() {
    router.push({ name: Routes.ToolkitTools })
    showSnackbar({
        message: 'Generating Scorecard..',
        spinner: true,
        timeout: 600_000, // 10 minutes, hopefully should be enough to be replaced by a success or error snackbar
    })
}

onUnmounted(() => {
    /**
     * If we're going back to the scorecard list, cancel the current request.
     * Otherwise, keep it going in the background, (the progress snackbar should be shown)
     */
    if (
        router.currentRoute.value.name === Routes.PerformanceScorecardList &&
        cancelCurrentRequest.value
    ) {
        cancelCurrentRequest.value()
    }
})

createNewScorecard()
</script>

<style scoped lang="scss">
// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // // Empty states
// // .scorecard-empty-state-container :deep(.marquee-text-wrap) {
// //     @include pv-56;
// // }
// // .scorecard-empty-state-container :deep(.marquee-text-content .slides svg) {
// //     margin-right: 2rem;
// // }
// // .scorecard-empty-state-container {
// //     @include w-100;
// //     @include container;
// //     border-radius: 2.75rem;
// //     @include pa-0;
// //     @include flex;
// //     flex-direction: column;
// //     overflow: hidden;
// // }

// // .scorecard-empty-state-container .empty-state-content {
// //     max-width: 20rem;
// //     @include flex;
// //     @include items-center;
// //     flex-direction: column;
// //     gap: 1.5rem;
// //     margin: 0 auto;
// //     @include pb-56;
// // }
</style>
