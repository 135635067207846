<template>
    <ExternalPageWrapper :maxWidth="280">
        <Container :borderRadius="22" width="100%" hideOverflow>
            <!-- Explainer -->
            <Stack direction="column" align="start" :padding="[16, 20]" gap="0.75rem" borderBottom>
                <Text as="h6" size="headline-six" color="foreground">Reset Password</Text>
                <Text v-if="showForm" as="p" size="body-extra-small" color="foreground-minus-one">
                    Enter your email address and click the button below to reset your password. You
                    may need to check your spam folder or search for mail sent by
                    <b>support@opteo.com</b>.
                </Text>
                <Text v-else as="p" size="body-extra-small" color="foreground-minus-one">
                    An email with a password reset link has been sent to <b>{{ emailAddress }}</b
                    >.
                </Text>
            </Stack>

            <!-- Email form -->
            <Form @submitted="sendResetLink" validateOnSubmit>
                <Stack v-if="showForm" :padding="[18, 20, 20, 20]" direction="column" gap="1.25rem">
                    <TextInput
                        ref="userEmailInput"
                        :prefill="true"
                        label="Email Address"
                        v-model="emailAddress"
                        type="email"
                        name="emailAddress"
                        placeholder="johnny@appleseed.com"
                        autofocus
                        required
                    />

                    <Button submit :loading="loading" fullWidth iconAfter="ArrowUpRight">
                        Send Reset Link
                    </Button>
                </Stack>

                <Stack v-else :padding="[18, 20, 20, 20]">
                    <Button
                        :loading="loading"
                        fullWidth
                        iconAfter="ArrowUpRight"
                        @click="goToLogin"
                    >
                        Go to Login
                    </Button>
                </Stack>
            </Form>
        </Container>
        <Stack :padding="[20, 0, 0, 0]" direction="column" gap="0.375rem">
            <Text size="body-extra-small" align="center" as="p">
                <router-link to="/login" data-cy="login-link">
                    <Text as="span" color="foreground-minus-three">
                        Click here to return to the Login page.
                    </Text>
                </router-link>
            </Text>
        </Stack>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useResetPassword } from '@/composition/auth/useResetPassword'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Components
import { Form, Stack, TextInput, Text, Button, Container } from '@opteo/components-next'

// Setup
const { showForm, sendResetLink, emailAddress, loading, userEmailInput } = useResetPassword()
const router = useRouter()

// Functions
function goToLogin() {
    router.push({ name: Routes.Login })
}
</script>

<style scoped lang="scss"></style>
