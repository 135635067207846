<template>
    <Stack direction="column" width="auto" :gap="20">
        <SubscriptionUpdateCard
            v-if="userType === 'update_cc'"
            @update-payment-method="showPaymentMethodModal = !showPaymentMethodModal"
        />
        <SubscriptionOverview v-if="customerIsActive || loading" />
        <SubscriptionRenew
            v-else-if="!customerIsActive && !loading"
            @resubscribe="showRenewSubscriptionModal = !showRenewSubscriptionModal"
            @changePlan="toggleSelectPlanModal"
        />
    </Stack>
    <SubscriptionStatus
        v-if="userType"
        @updatePaymentMethod="showPaymentMethodModal = !showPaymentMethodModal"
        @addPaymentMethod="showPaymentMethodModal = !showPaymentMethodModal"
    />
    <!-- Modals -->
    <SelectPlanModal v-model="showSelectPlanModal" />
    <RenewSubscriptionModal
        v-model="showRenewSubscriptionModal"
        @updatePaymentMethod="showPaymentMethodModal = !showPaymentMethodModal"
    />
    <PaymentMethodModal v-model="showPaymentMethodModal" />
</template>

<script setup lang="ts">
// Imports
import { ref, computed } from 'vue'

// Composition
import { useSubscription } from '@/composition/billing/useSubscription'
import { usePlanSelectionModal } from '@/composition/billing/usePlanSelectionModal'

// Components
import { Stack } from '@opteo/components-next'

import SubscriptionUpdateCard from '@/components/billing/Subscription/SubscriptionUpdateCard.vue'
import SubscriptionOverview from '@/components/billing/Subscription/SubscriptionOverview.vue'
import SubscriptionRenew from '@/components/billing/Subscription/SubscriptionRenew.vue'
import SubscriptionStatus from '@/components/billing/Subscription/SubscriptionStatus.vue'

import SelectPlanModal from '@/components/billing/Modals/SelectPlanModal.vue'
import RenewSubscriptionModal from '@/components/billing/Modals/RenewSubscriptionModal.vue'
import PaymentMethodModal from '@/components/billing/Modals/PaymentMethodModal.vue'

// Setup
const { userType, latestSubscription, loading } = useSubscription()
const { showSelectPlanModal, toggleSelectPlanModal } = usePlanSelectionModal()
const showRenewSubscriptionModal = ref(false)
const showPaymentMethodModal = ref(false)

// Computed
const customerIsActive = computed<boolean>(() => {
    if (latestSubscription.value) {
        return (
            !latestSubscription.value?.cancel_at_period_end &&
            userType.value !== 'cancelled' &&
            userType.value !== 'incomplete'
        )
    }
    return false
})
</script>

<style scoped lang="scss"></style>
