<template>
    <Stack ref="stackRef" :justify="above480 ? 'start' : 'end'" :width="64">
        <Skeleton v-if="loading || !budget" :width="56" :height="32" color="secondary" />
        <Popout
            v-else
            v-model="showPopout"
            :trigger="loading ? undefined : above480 ? 'mouseenter' : 'click'"
            :placement="above480 ? 'top' : 'bottom-end'"
            :maxWidth="containerWidth"
            :externalToggleButton="above480 ? false : false"
            :offset="[0, 10]"
            :delay="[200, 0]"
            :zIndex="4001"
            :appendTo="stackEl"
            :disabled="!above480"
            trapFocus
        >
            <Pill v-if="budget.status === 'invalid'" color="gray" label="n/a" />
            <Pill v-else :label="label" :color="color" />
            <template #content>
                <Container
                    color="background-plus-two"
                    shadow="large"
                    :borderRadius="16"
                    :width="containerWidth"
                    hideOverflow
                >
                    <Stack v-if="budget.status != 'invalid'" :padding="[16, 20]" borderBottom>
                        <Text as="p" size="body-small">
                            So far this month, this account has spent
                            <b>
                                <Money
                                    :value="budget.actual_spend"
                                    :currency="account.currencyCode"
                                />
                            </b>
                            or around <b>{{ monthSpentPercentage }}</b> of its monthly budget. If
                            spending continues at the current pace, you should spend around
                            <Text as="span" size="body-small" weight="bold" :color="textColor">
                                {{ label }}
                            </Text>
                            of your budget this month.
                        </Text>
                    </Stack>
                    <Stack :padding="18" :gap="24">
                        <Stack :gap="16">
                            <Avatar
                                :initials="accountInitials"
                                :color="mapHexToAvatarColor(account.color)"
                                :platform="account.platform"
                            />
                            <Stack direction="column" :gap="4" align="start">
                                <Stack :gap="6">
                                    <Text
                                        as="h6"
                                        size="button"
                                        :color="mapHexToTextColor(account.color)"
                                        :wrap="false"
                                        :maxWidth="112"
                                        truncate
                                    >
                                        {{ account.name }}
                                    </Text>
                                </Stack>
                                <Stack :padding="[0, 0, 0, 0]">
                                    <Text
                                        v-if="budget.status != 'invalid'"
                                        as="span"
                                        size="label-small"
                                        color="foreground-minus-one"
                                        :wrap="false"
                                    >
                                        <Money
                                            :value="budget.budget"
                                            :currency="account.currencyCode"
                                        />/month
                                    </Text>
                                    <Text
                                        v-else
                                        as="span"
                                        size="label-small"
                                        color="foreground-minus-one"
                                        :wrap="false"
                                    >
                                        No Budget Set
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Button
                            v-if="budget.status != 'invalid'"
                            size="small"
                            color="tertiary"
                            iconAfter="ArrowUpRight"
                            @click="goToSettings({ accountId: account.accountId })"
                        >
                            Update Budget
                        </Button>
                        <Button
                            v-else
                            size="small"
                            color="tertiary"
                            iconAfter="ArrowUpRight"
                            @click="goToSettings({ accountId: account.accountId })"
                        >
                            Set Budget
                        </Button>
                    </Stack>
                </Container>
            </template>
        </Popout>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed, watch } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Types
import { Account, Domain } from '@opteo/types'

// Composition
import { mapHexToAvatarColor, mapHexToTextColor } from '@/composition/utils/utils'
import { getDomainInitials } from '@/composition/domain/useDomain'

// Components
import {
    Popout,
    Stack,
    Pill,
    Container,
    usePercent,
    Money,
    Skeleton,
    Text,
    Avatar,
    Button,
    useMediaQuery,
} from '@opteo/components-next'

// Props
const props = defineProps({
    budget: {
        type: Object as PropType<Domain.BudgetBar>,
        required: true,
        default: () => ({}),
    },
    account: {
        type: Object as PropType<Account.Info>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Setup
const router = useRouter()
const showPopout = ref(false)
const { above480 } = useMediaQuery()

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})

// goToSettings
function goToSettings({ accountId }: { accountId: Account.ID }) {
    router.push({
        name: Routes.DomainSettings,
        params: { accountId, scroll_position: 'update-budget' },
    })
}

// Map
const BUDGET_COLOUR_MAP: Record<Domain.BudgetStatus, string> = {
    [Domain.BudgetStatus.GOOD]: 'green',
    [Domain.BudgetStatus.UNDER]: 'amber',
    [Domain.BudgetStatus.OVER]: 'amber',
    [Domain.BudgetStatus.FAR_UNDER]: 'red',
    [Domain.BudgetStatus.FAR_OVER]: 'red',
    [Domain.BudgetStatus.TOO_EARLY]: 'gray',
    [Domain.BudgetStatus.INVALID]: 'gray',
}

// Computed

// Label
const label = computed(() => {
    return usePercent({ value: props.budget.display_difference / 100, decimalPlaces: 0 })
        .displayValue.value
})

// containerWidth
const containerWidth = computed(() => {
    if (props.budget.status === 'invalid') {
        return 'auto'
    } else if (props.budget.actual_spend < 9999) {
        return 342
    } else if (props.budget.actual_spend < 99999) {
        return 346
    } else {
        return 350
    }
})

// accountInitials
const accountInitials = computed(() => getDomainInitials(props.account.name))

// monthSpentPercentage
const monthSpentPercentage = computed(() => {
    return usePercent({ value: props.budget.month_spent_percentage / 100, decimalPlaces: 0 })
        .displayValue.value
})

// Color
const color = computed(() => BUDGET_COLOUR_MAP[props.budget.status])

// textColor
const textColor = computed(() => {
    if (color.value === 'gray') {
        return 'foreground'
    } else return color.value
})
</script>

<style scoped lang="scss"></style>
