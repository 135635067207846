<template>
    <Sidebar
        :items="sidebarItems"
        :footerItems="footerItems"
        :route="route"
        :router="router"
        :activeColor="activeColor"
        :height="height"
        :width="width"
        :resizeable="height && width ? false : true"
        :border="height && width ? false : true"
        :gap="4"
        @item-clicked="emit('item-clicked')"
    >
        <template #header>
            <Stack :padding="16">
                <AccountSwitcher />
            </Stack>
        </template>
    </Sidebar>
</template>

<script setup lang="ts">
// Imports
import { computed, ref, PropType } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useAccount } from '@/composition/account/useAccount'
import { mapHexToAvatarColor } from '@/composition/utils/utils'

// Types
import { Platform } from '@opteo/types'
import {
    SidebarItemObject,
    SidebarGroupObject,
} from '@opteo/components-next/dist/components/navigation/Sidebar/types'

// Components
import { Stack, Sidebar } from '@opteo/components-next'
import AccountSwitcher from '@/components/account/AccountSwitcher.vue'

// Props
const props = defineProps({
    height: {
        type: String as PropType<string>,
        required: false,
    },
    width: {
        type: String as PropType<string>,
        required: false,
    },
})

// Emits
const emit = defineEmits(['item-clicked'])

// Setup
const { accountInfo, accountColor, accountPlatform } = useAccount()
const router = useRouter()
const route = useRoute()

// activeColor
const activeColor = computed(() => {
    if (accountColor.value) {
        return mapHexToAvatarColor(accountColor.value)
    } else return 'skeleton'
})

// sidebarItems
const sidebarItems = ref<(SidebarGroupObject | SidebarItemObject)[]>([
    {
        type: 'Group',
        key: 'improvements',
        name: 'Improvements',
        icon: 'Lightning',
        items: [
            {
                type: 'Item',
                name: 'Active',
                key: 'improvements-active',
                routerLink: Routes.ImprovementsActive,
                count:
                    accountInfo.value?.tasksAvailable > 0
                        ? accountInfo.value?.tasksAvailable
                        : undefined,
            },
            {
                type: 'Item',
                name: 'Completed',
                key: 'improvements-completed',
                routerLink: Routes.ImprovementsCompleted,
                count:
                    accountInfo.value?.tasksCompleted > 0
                        ? accountInfo.value?.tasksCompleted
                        : undefined,
            },
            {
                type: 'Item',
                name: 'Dismissed',
                key: 'improvements-dismissed',
                routerLink: Routes.ImprovementsDismissed,
                count:
                    accountInfo.value?.tasksDismissed > 0
                        ? accountInfo.value?.tasksDismissed
                        : undefined,
            },
        ],
    },
    {
        type: 'Item',
        key: 'issues',
        name: 'Issues',
        icon: 'Error',
        routerLink: Routes.Issues,
    },
    {
        type: 'Group',
        key: 'performance',
        name: 'Performance',
        icon: 'Performance',
        items: [
            {
                type: 'Item',
                name: 'Dashboard',
                key: 'performance-dashboard',
                routerLink: Routes.PerformanceGraphs,
            },
            {
                type: 'Item',
                name: 'Scorecard',
                key: 'performance-scorecard',
                routerLink: Routes.PerformanceScorecardList,
            },
            {
                type: 'Item',
                name: 'Segments',
                key: 'performance-segments',
                routerLink: Routes.PerformanceSegments,
            },
            {
                type: 'Item',
                name: 'Trends',
                key: 'performance-trends',
                routerLink: Routes.PerformanceTable,
            },
        ],
    },
    {
        type: 'Group',
        key: 'toolkit',
        name: 'Toolkit',
        icon: 'Toolkit',
        items: [
            {
                type: 'Item',
                name: 'Keyword Finder',
                key: 'toolkit-keyword-finder',
                routerLink: Routes.ToolkitKeywordFinder,
            },
            {
                type: 'Item',
                name: 'N-Gram Finder',
                key: 'toolkit-ngram-finder',
                routerLink: Routes.ToolkitNGramFinderSearchSelect,
            },
            {
                type: 'Item',
                name: 'Account Manager',
                key: 'toolkit-account-manager',
                routerLink: Routes.ToolkitAccountManager,
            },
            {
                type: 'Item',
                name: 'Product Manager',
                key: 'toolkit-product-manager',
                routerLink: Routes.ToolkitProductManager,
            },
        ],
    },
    {
        type: 'Item',
        key: 'notes',
        name: 'Notes',
        icon: 'Pencil',
        routerLink: Routes.Notes,
    },
])

// footerItems
const footerItems = ref<SidebarItemObject[]>([
    {
        type: 'Item',
        key: 'targets',
        name: 'Targets',
        icon: 'Loaders',
        routerLink: Routes.Targets,
    },
    {
        type: 'Item',
        key: 'settings',
        name: 'Settings',
        icon: 'Settings',
        routerLink: Routes.DomainSettings,
    },
])

// platformHelpers
const isGads = computed(() => accountPlatform.value === Platform.Platform.GoogleAds)
const isMeta = computed(() => accountPlatform.value === Platform.Platform.MetaAds)
const isMicrosoft = computed(() => accountPlatform.value === Platform.Platform.MicrosoftAds)
</script>

<style scoped lang="scss"></style>
