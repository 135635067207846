<template>
    <section class="chat-query-wrapper">
        <div class="chat-query">
            <div class="icon" v-if="icon">
                <ProfileImage
                    :width="40"
                    :image-url="profileImageUrl"
                    :avatar-url="currentUserAvatarUrl"
                />
            </div>
            <p class="query" v-html="HTML" />
        </div>
    </section>
</template>

<script lang="ts">
// Vue
import { defineComponent, computed } from 'vue'

// @opteo/components-next
// import { ProfileImage } from '@opteo/components-next'

// Composition
import { useBoringAvatar, getAvatarUrl } from '@/composition/user/useBoringAvatar'
import { useChatEntities } from '@/composition/chat/useChatEntities'

export default defineComponent({
    name: 'ChatQuery',
    // components: { ProfileImage },
    props: {
        entities: {
            type: Object,
            default: {},
        },
        content: {
            type: String,
            required: true,
        },
        icon: {
            type: Boolean,
            required: false,
            default: true,
        },
        profileImageUrl: {
            type: String,
            required: false,
            default: '',
        },
        userId: {
            type: Number,
            required: false,
        },
    },
    setup(props) {
        const { HTML } = useChatEntities(props.content, props.entities)

        const currentUserAvatarUrl = computed(() =>
            props.userId ? getAvatarUrl(props.userId) : useBoringAvatar().currentUserAvatarUrl.value
        )

        return {
            currentUserAvatarUrl,
            HTML,
        }
    },
})
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .chat-query-wrapper {
// //     max-width: 40rem;
// //     margin-left: auto;
// //     margin-right: auto;
// // }
// // .chat-query {
// //     @include inline-flex;
// //     @include items-start;
// //     @include container;
// //     @include br-24;
// //     @include relative;
// //     gap: 1.625rem;
// //     min-height: 4.5rem;
// //     padding: 1.5rem 1.875rem;
// // }
// // .icon {
// //     flex-shrink: 0;
// //     top: 1.0625rem;
// //     position: absolute;
// //     left: calc(-2.5rem - 1.5rem);
// // }
// // .query {
// //     width: 100%;
// //     word-break: break-word;
// //     letter-spacing: -0.005rem;
// //     font-size: 1rem !important;
// //     line-height: 1.625rem;
// //     white-space: pre-wrap;
// // }

// // @media (max-width: 768px) {
// //     .icon {
// //         display: none;
// //     }
// // }


</style>
