import { noAuthRequest } from '@/composition/api/useAPI'
import { ref } from 'vue'

interface ResetPasswordResponse {
    data: {
        username_found: boolean
        error: 'No registered email found' | 'Registered email found'
        username: string
    }
}

export function useResetPassword() {
    const userEmailInput = ref()
    const emailAddress = ref<string>()
    const showForm = ref(true)
    const loading = ref(false)

    async function sendResetLink(formData: Record<string, any>) {
        const { emailAddress: submittedEmail } = formData

        // Validate the email format
        if (!submittedEmail || typeof submittedEmail !== 'string') {
            console.warn('Validation failed: Invalid or missing email address.')
            userEmailInput.value?.setError('Please provide a valid email address')
            return
        }

        emailAddress.value = submittedEmail

        await userEmailInput.value?.validate()

        // Check validity
        if (!userEmailInput.value?.state || !userEmailInput.value?.state.valid) {
            console.warn('Validation failed: Input is invalid.')
            userEmailInput.value?.setError('Please provide a valid email address')
            return
        }

        loading.value = true
        let response

        try {
            const { data } = await noAuthRequest<ResetPasswordResponse>('/api/auth/resetPassword', {
                meta: {
                    function: 'auth:resetPassword',
                    args: {
                        username: emailAddress.value,
                    },
                },
            })

            response = data
        } catch (error) {
            console.error('Reset password request failed:', error)
        }

        loading.value = false

        if (!response) {
            console.warn('No response received from reset password request.')
            userEmailInput.value?.setError(
                'Something went wrong, please contact support for assistance with this issue.'
            )
            return
        }

        if (!response.username_found) {
            console.warn('Username not found in reset password response.')
            userEmailInput.value?.setError('Email address not found')
            return
        }

        showForm.value = false
    }

    return { sendResetLink, showForm, emailAddress, loading, userEmailInput }
}
