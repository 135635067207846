<template>
    <Container color="background-plus-one" :borderRadius="22" width="100%" :maxWidth="356">
        <Form
            ref="goalsForm"
            @submitted="updatePerformanceGoals"
            class="account-performance-goals-form"
        >
            <Stack
                direction="column"
                align="start"
                :padding="[18, 22, 18, 22]"
                :gap="16"
                borderBottom
            >
                <Text as="p" size="body-small" color="foreground">
                    Set an account-level goal to track your progress. Please note, performance goals
                    use the conversion actions selected above to track/count conversions.
                </Text>
            </Stack>

            <Stack direction="column" align="start" :padding="[20, 22, 22]" :gap="18" borderBottom>
                <Select
                    v-model="computedPrimaryGoal"
                    :options="primaryGoalItems"
                    name="primaryGoal"
                    label="Primary Goal"
                    placeholder="Select Primary Goal"
                />
                <Select
                    v-model="computedGoalCycle"
                    :options="goalCycleItems"
                    name="goalCycle"
                    label="Goal Cycle"
                    placeholder="Select Goal Cycle"
                    @update:model-value="handleGoalCycleChange"
                />
                <Select
                    v-if="showCycleStart"
                    v-model="computedCycleStart"
                    :options="cycleStartItems"
                    name="cycleStart"
                    :label="cycleStartLabel"
                    :placeholder="`Select ${cycleStartLabel}`"
                />
                <NumberInput
                    ref="goalAmountInput"
                    v-model="computedGoalAmount"
                    name="goalAmount"
                    label="Goal Amount"
                    :prefix="computedPrimaryGoal === 1 ? goalMetric : null"
                    :suffix="computedPrimaryGoal === 0 ? goalMetric : null"
                    :min="0"
                    :step="1"
                    autocomplete="off"
                />
            </Stack>
            <Stack
                direction="column"
                align="start"
                :padding="[20, 22, 22, 22]"
                :gap="16"
                :borderBottom="showUpdateButton"
            >
                <Select
                    v-model="computedSecondaryGoal"
                    :options="secondaryGoalItems"
                    name="secondaryGoal"
                    label="Secondary Goal"
                    placeholder="Select Secondary Goal"
                />
            </Stack>
            <Stack v-if="showUpdateButton" align="center" justify="center" :padding="[22, 0]">
                <Button
                    ref="updatePerformanceGoalsButton"
                    :loading="updatePerformanceGoalsLoading"
                    size="small"
                    color="secondary-highlight"
                    submit
                >
                    Update Performance Goals
                </Button>
            </Stack>
        </Form>
    </Container>
</template>

<script lang="ts" setup>
import {
    Stack,
    Text,
    NumberInput,
    Select,
    Form,
    Button,
    Container,
    Target,
} from '@opteo/components-next'
import {
    usePerformanceGoals,
    CycleStartDay,
    CycleStartDate,
} from '@/composition/account/usePerformanceGoals'
import { Targets } from '@opteo/types'

defineProps<{
    showUpdateButton?: boolean
}>()

const {
    // Refs
    goalAmountInput,
    updatePerformanceGoalsButton,
    goalsForm,
    updatePerformanceGoalsLoading,

    // Computed
    computedPrimaryGoal,
    computedGoalCycle,
    computedGoalAmount,
    computedSecondaryGoal,
    computedCycleStart,
    goalMetric,
    primaryGoalItems,
    secondaryGoalItems,
    goalCycleItems,
    cycleStartItems,
    cycleStartLabel,
    showCycleStart,

    // Functions
    updatePerformanceGoals,
} = usePerformanceGoals()

// Handle cycle change to ensure the UI updates immediately
const handleGoalCycleChange = (newValue: Targets.PerformanceGoalCycle) => {
    // Set a default cycle start value based on the selected cycle
    if (newValue === Targets.PerformanceGoalCycle.WEEK && !computedCycleStart.value) {
        // Default to Monday for weekly cycles
        computedCycleStart.value = CycleStartDay.MONDAY
    } else if (newValue === Targets.PerformanceGoalCycle.MONTH && !computedCycleStart.value) {
        // Default to the 1st of the month for monthly cycles
        computedCycleStart.value = CycleStartDate.DAY_1
    }
}

defineExpose({
    updatePerformanceGoals,
})
</script>
