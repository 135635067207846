<template>
    <PageWrapper>
        <!-- Header -->
        <PageHeader title="Notes">
            <template #right>
                <Button size="small" color="secondary" @click="exportNotes">Export Notes</Button>
            </template>
        </PageHeader>

        <!-- Content -->
        <PageContent>
            <Stack
                v-for="month in notesByMonth"
                :key="month.month"
                :gap="0"
                direction="column"
                align="start"
            >
                <!-- Month -->
                <Stack direction="column" :gap="0" width="100%" align="start">
                    <!-- Days -->
                    <Stack
                        width="100%"
                        direction="column"
                        align="center"
                        v-for="(day, index) in month.days"
                        :key="day.date"
                        :id="`day-${day.date}`"
                        :style="{ zIndex: month.days.length - index }"
                    >
                        <!-- Block -->
                        <NoteDay :day="day" :loading="loading" />
                    </Stack>
                </Stack>
            </Stack>
        </PageContent>
    </PageWrapper>
</template>

<script setup lang="ts">
// Composition
import { useNotes } from '@/composition/notes/useNotes'
import { useAccount } from '@/composition/account/useAccount'

// Components
import { Button, Stack } from '@opteo/components-next'
import NoteDay from '@/components/notes/NoteDay.vue'

// Setup
const { accountId } = useAccount()
const { notesByMonth, loading, exportNotes } = useNotes(accountId)
</script>

<style scoped lang="scss"></style>
