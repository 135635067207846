<template>
    <Stack :maxWidth="600" justify="center">
        <Table
            v-if="invoiceTableRows && invoiceTableRows.length > 0"
            :columns="invoiceTableColumns"
            :columnWidths="invoiceTableColumnWidths"
            layout="fixed"
            :rowHeight="48"
            :rows="invoiceTableRows"
            :perPage="8"
        />
        <EmptyState
            v-else
            icon="SparklesExtraLarge"
            width="100%"
            :maxWidth="456"
            :contentMaxWidth="328"
            :borderRadius="22"
            :message="`When your first invoice is generated, you'll receive a copy by email and a record will be stored here. Your first invoice is scheduled for ${nextChargeDate}.`"
        />
    </Stack>
    <InvoiceSettingsModal v-model="showInvoiceSettingsModal" />
</template>

<script setup lang="ts">
// Composition
import { useInvoices } from '@/composition/billing/invoices/useInvoices'
import { useSubscription } from '@/composition/billing/useSubscription'

// Components
import { Stack, Table, EmptyState } from '@opteo/components-next'
import InvoiceSettingsModal from '@/components/billing/Modals/InvoiceSettingsModal/InvoiceSettingsModal.vue'

// Setup
const { invoiceTableRows, showInvoiceSettingsModal } = useInvoices()
const { nextChargeDate } = useSubscription()
const invoiceTableColumns = [
    {
        key: 'invoice',
        label: 'Invoice Date',
        sortable: true,
        type: 'text',
    },
    {
        key: 'total',
        label: 'Total',
        sortable: true,
        type: 'money',
    },
    {
        key: 'type',
        label: 'Type',
        sortable: true,
        type: 'text',
    },
    {
        key: 'download',
        label: 'Download',
        sortable: false,
        type: 'button',
    },
]
const invoiceTableColumnWidths = {
    invoice: '100%',
    total: '104px',
    type: '120px',
    download: '138px',
}
</script>

<style scoped lang="scss"></style>
