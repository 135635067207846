import filter from 'lodash-es/filter'

import { Billing } from '@opteo/types'
import { computed, onMounted, ref } from 'vue'
import { Endpoint, useAPI } from '../api/useAPI'
import { useUser } from '../user/useUser'

interface PricingPlans {
    [pricingPlans: string]: Billing.CurrentPlan
}

const showSelectPlanModal = ref(false)
const toggleSelectPlanModal = () => {
    console.log('toggled showSelectPlanModal to', !showSelectPlanModal.value)
    showSelectPlanModal.value = !showSelectPlanModal.value
}

export function usePlanSelectionModal() {
    const { data: pricingPlans } = useAPI<PricingPlans>(Endpoint.GetPricingPlans)

    const { currentPlan } = useUser()

    const monthlyPlansVisible = ref(true)

    const monthlyPlans = computed(() =>
        filter(pricingPlans.value, plan =>
            [
                Billing.StandardPlan.Basic,
                Billing.StandardPlan.Professional,
                Billing.StandardPlan.Agency,
                Billing.StandardPlan.Enterprise,
            ].some(monthlyPlan => monthlyPlan === plan.id)
        )
    )

    const yearlyPlans = computed(() =>
        filter(pricingPlans.value, plan =>
            [
                Billing.StandardPlan.YearlyBasic,
                Billing.StandardPlan.YearlyProfessional,
                Billing.StandardPlan.YearlyAgency,
                Billing.StandardPlan.YearlyEnterprise,
            ].some(yearlyPlan => yearlyPlan === plan.id)
        )
    )

    const setActiveBillingDuration = (duration: 'monthly' | 'yearly') => {
        monthlyPlansVisible.value = duration === 'monthly' ? true : false
    }

    onMounted(() => (monthlyPlansVisible.value = currentPlan.value?.period_interval !== 'year'))

    return {
        yearlyPlans,
        monthlyPlans,
        monthlyPlansVisible,
        setActiveBillingDuration,
        currentPlan,
        pricingPlans,
        showSelectPlanModal,
        toggleSelectPlanModal,
    }
}
