<template>
    <div :class="[layout, 'app']" drawer-wrapper>
        <router-view />
    </div>
    <!-- Global Connection Status -->
    <!-- <ConnectionStatus /> -->
    <!-- Global Snackbar -->
    <Snackbar />
    <!-- Global Async Push -->
    <!-- <AsyncPush /> -->
    <!-- Global Ad Block Detector -->
    <AdBlockDetector />
</template>

<script setup lang="ts">
// Import
import { provide, watch } from 'vue'
import { OBFUSCATE_ENTITY_PILLS } from '@/lib/env'
import { useHead } from '@unhead/vue'
import { useColorMode } from '@vueuse/core'

// Composition
import { layout } from '@/composition/layouts/useLayouts'
import { useApp } from '@/composition/useApp'
import { ProvideKeys } from './composition/useProvide'

// Components
import { Snackbar, useDevice } from '@opteo/components-next'
import AdBlockDetector from '@/components/util/AdBlockDetector.vue'

// Instantiate colorMode
const mode = useColorMode({ disableTransition: false })

// Watch for colorMode change and suppress transitions
watch(mode, () => {
    applyTransitionSuppression()
})
function applyTransitionSuppression() {
    const htmlEl = document.documentElement
    htmlEl.classList.add('suppress-transitions')
    setTimeout(() => {
        htmlEl.classList.remove('suppress-transitions')
    }, 1000)
}

// Detect device
const { isMobileOrTablet } = useDevice()

// Dynamically set viewport meta based on device type
useHead({
    meta: [
        {
            name: 'viewport',
            content: isMobileOrTablet.value
                ? 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
                : 'width=device-width, initial-scale=1.0, maximum-scale=5.0, user-scalable=yes',
        },
    ],
})

// useApp (load plugins like Intercom, etc.)
useApp()

// provide
provide(ProvideKeys.ObfuscateEntityPills, OBFUSCATE_ENTITY_PILLS)
</script>

<style lang="scss">
.app {
    @include flex;
}

.suppress-transitions *,
.suppress-transitions *::before,
.suppress-transitions *::after {
    -webkit-transition: none !important;
    -moz-transition: none !important;
    -o-transition: none !important;
    -ms-transition: none !important;
    transition: none !important;
}
</style>
