<template>
    <oTable
        :headers="tableHeaders"
        :items="items"
        :per-page="20"
        :fixed-layout="true"
        :border-radius="24"
    >
        <template #header.name>
            <Spacer width="1.875rem" />
            <Checkbox />
            <Spacer width="1.25rem" />
            <Text size="f-8" weight="500">Account Name</Text>
        </template>
        <template #header.availableConnections>
            <Text size="f-8" weight="500">
                Changelog Email
                <Tooltip
                    :content="`The email selected in this column will be shown in Change History in ${platformWord} Ads whenever a change is made in Opteo.`"
                    :max-width="260"
                    :delay="[400, 0]"
                >
                    <InfoIcon
                        class="tooltip-info-icon"
                        style="margin-left: 0.375rem; transform: translateY(0.125rem)"
                    />
                </Tooltip>
            </Text>
        </template>

        <template #column.name>
            <div class="account-name-cell">
                <Checkbox />
                <Spacer width="1.25rem" />
                <div class="linked-accounts-row-name">
                    <Skeleton :width="36" :height="36" />
                    <Spacer width="1.125rem" />
                    <Skeleton :width="Math.random() * 120 + 100" height="1rem" />
                </div>
            </div>
        </template>
        <template #column.spend30d>
            <Skeleton width="5rem" height="1rem" />
        </template>
        <template #column.connectionStatus>
            <div class="changelog-select-button">
                <span class="changelog-select-label">
                    <Skeleton width="7.5rem" height="1rem" />
                </span>
                <SmallDownCaret class="select-arrow" aria-hidden="true" />
            </div>
        </template>
        <template #column.availableConnections>
            <div class="changelog-select-button">
                <span class="changelog-select-label">
                    <Skeleton width="5rem" height="1rem" />
                </span>
                <SmallDownCaret class="select-arrow" aria-hidden="true" />
            </div>
        </template>

        <template #column.connectedToOpteoTs="{ value }">
            <Skeleton width="5rem" height="1rem" />
        </template>
        <template #column.lastLogin>
            <Skeleton width="5rem" height="1rem" />
        </template>
        <template #column.teamMembers>
            <div class="linked-accounts-team-avatars">
                <Skeleton
                    v-for="index in 3"
                    :key="index"
                    :style="{ zIndex: 3 - (index - 1) }"
                    class="profile-image-item"
                    :width="28"
                    :height="28"
                />
                <oButton size="x-small" color="white" :circle="true" style="margin-left: 0.75rem">
                    <template #icon>
                        <TableEdit />
                    </template>
                </oButton>
            </div>
        </template>
    </oTable>
</template>

<script setup lang="ts">
// import { oTable, Checkbox, Spacer, Text, Tooltip, TableEdit, oButton } from '@opteo/components-next'
import InfoIcon from '@/components/global/InfoIcon.vue'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import SmallDownCaret from '@/components/linkedAccounts/icons/SmallDownCaret.vue'

import Skeleton from '@/components/util/Skeleton.vue'
import { computed } from 'vue'

const { currentView, linkedTableHeaders, unlinkedTableHeaders, platformWord } = useLinkedAccounts()

const tableHeaders = computed(() =>
    currentView.value === 'linked' ? linkedTableHeaders : unlinkedTableHeaders
)

const items: {}[] = new Array(20).fill({})
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .changelog-select-button {
// //     display: flex;
// //     align-items: center;
// //     justify-content: space-between;
// //     appearance: none;
// //     width: auto;
// //     -moz-appearance: none;
// //     -webkit-appearance: none;
// //     padding: 0.8125rem 0.875rem;
// //     border-radius: 0.75rem;
// //     border: none;
// //     box-shadow: $opteo-shadow;
// //     color: $opteo-black;
// //     transition: box-shadow cubic-bezier(0.19, 1, 0.22, 1) 0.25s;
// //     background-color: white;
// //     height: 2.75rem;
// //     position: relative;
// //     user-select: none;
// //     -webkit-user-select: none;
// //     cursor: pointer;
// //     text-indent: 0.875rem;
// //     white-space: nowrap;
// //     overflow: hidden;
// //     text-overflow: ellipsis;
// // }
// // .profile-image-item {
// //     border: 2px solid white;
// // }


</style>
