<template>
    <PageContainer>
        <!-- AccountSidebar -->
        <AccountSidebar v-if="above1024 && layout === 'default-layout'" ref="AccountSidebarRef" />
        <!-- AccountContainer -->
        <Stack
            :gap="0"
            direction="column"
            :height="containerHeight"
            maxHeight="100%"
            :width="pageWidth"
        >
            <router-view />
        </Stack>
        <!-- AccountBottomBar -->
        <AccountBottomBar
            v-if="!above1024 && layout === 'default-layout'"
            @menu-clicked="toggleSidebar"
        />
        <!-- ToggleSidebar -->
        <ToggleSidebar
            position="right"
            :width="300"
            :modelValue="showSidebar"
            :zIndex="4002"
            :borderRadius="24"
            @close="toggleSidebar"
        >
            <AccountSidebar width="100%" height="100%" @item-clicked="toggleSidebar" />
        </ToggleSidebar>
        <!-- AccountSetupBar -->
        <AccountSetupBar />
    </PageContainer>
</template>

<script setup lang="ts">
// Imports
import { onMounted, watch, ref, computed } from 'vue'
import { useElementSize, useWindowSize } from '@vueuse/core'

// Composition
import { provideDomain } from '@/composition/domain/useDomain'
import { useAccount } from '@/composition/account/useAccount'
import { layout } from '@/composition/layouts/useLayouts'

// Components
import PageContainer from '@/components/layout/internalPages/PageContainer.vue'
import AccountSidebar from '@/components/account/AccountSidebar.vue'
import AccountBottomBar from '@/components/account/AccountBottomBar.vue'
import AccountSetupBar from '@/components/account/setup/AccountSetupBar.vue'
import { Stack, useMediaQuery, ToggleSidebar } from '@opteo/components-next'

// Setup
const { above1024 } = useMediaQuery()
const { trackAccountVisit, accountId } = useAccount()
const AccountSidebarRef = ref(null)
const showSidebar = ref(false)

// pageWidth
const { width: windowWidth } = useWindowSize()
const { width: AccountSidebarWidth } = useElementSize(AccountSidebarRef)
const pageWidth = computed(() => {
    if (above1024.value === true) {
        if (layout.value === 'full-screen') {
            return windowWidth.value
        } else {
            return windowWidth.value - AccountSidebarWidth.value - 82
        }
    } else return windowWidth.value
})

// containerHeight
const containerHeight = computed(() => {
    if (above1024.value === true) {
        return '100%'
    } else {
        return 'calc(100dvh - 168px)'
    }
})

// toggleSidebar
const toggleSidebar = () => {
    showSidebar.value = !showSidebar.value
}

// TODO(ms): remove the need for provideDomain entirely
provideDomain()

// trackVisit
const trackVisit = () => {
    if (!accountId.value) return
    trackAccountVisit()
}

// Track visit when first mounted and when account changes via quick-switcher
// TODO: avoid needing the trackVisit calls at all, and extract that information from Endpoint.TrackPageLoad instead.
onMounted(trackVisit)
watch(accountId, trackVisit)
</script>

<style scoped lang="scss"></style>
