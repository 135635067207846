<template>
    <Popout
        v-model="popoutOpen"
        :offset="[0, 18]"
        :interactive="false"
        :borderRadius="28"
        trigger="mouseenter"
        placement="top"
        maxWidth="22rem"
        :popperOptions="{
            strategy: 'fixed',
        }"
    >
        <ScorecardDonut
            :width="20"
            :strokeWidth="2"
            hideScore
            :score="section?.noData || isLoading ? 100 : section.score"
            :customColor="section?.noData || isLoading ? '#ECECEF' : ''"
        />
        <template v-slot:content v-if="!isLoading">
            <div class="mini-score-popout">
                <ScorecardDonut
                    :score="section?.noData ? 100 : section.score"
                    :customColor="section?.noData ? '#ECECEF' : ''"
                    :strokeWidth="3"
                    :width="56"
                    :hideScore="section?.noData"
                    compact
                    compact-text-size="1.25rem"
                />
                <div class="content">
                    <Text as="h5" size="f-7" weight="600">
                        {{ section.title }}
                    </Text>
                    <Spacer height="0.5625rem" />
                    <Text as="p" size="f-9">
                        {{ section.copy[0] }}
                    </Text>
                </div>
            </div>
        </template>
    </Popout>
</template>

<script lang="ts">
// Vue
import { ref, computed } from 'vue'

// Local components
import ScorecardDonut from './ScorecardDonut.vue'

// components-next
// import { Popout, Text, Spacer } from '@opteo/components-next'

// Export
export default {
    name: 'ScorecardListItemMiniScore',
    props: {
        section: {
            type: Object,
            required: true,
        },
        isLoading: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: {

//         ScorecardDonut,
//         Popout,
//         Text,
//         Spacer,
    
},
    setup() {
        const popoutOpen = ref(false)
        return { popoutOpen }
    },
}
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';
// // .mini-score-popout {
// //     @include ph-24;
// //     @include pv-20;
// //     @include flex;
// //     @include items-center;
// //     gap: 1.25rem;
// // }
// // .mini-score-popout .content :deep(p) {
// //     overflow: hidden;
// //     display: -webkit-box;
// //     line-clamp: 3;
// //     -webkit-line-clamp: 3;
// //     -webkit-box-orient: vertical;
// // }


</style>
