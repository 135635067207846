<template>
    <PageHeader title="Accounts">
        <template #titleSuffix>
            <!-- View Switcher -->
            <Menu
                :items="viewItems"
                :buttonLabel="currentView.label"
                :showKeyboardShortcuts="false"
                buttonSize="extra-small"
                placement="bottom-start"
            />
        </template>
        <template #right>
            <!-- Sort -->
            <SelectableList
                v-if="above480"
                type="sort"
                v-model="sortedBy"
                :items="sortItems"
                :popoutWidth="160"
                buttonSize="small"
                placement="bottom-end"
            />
            <!-- Date Range -->
            <Menu
                v-if="above960"
                :items="dateRangeItems"
                :buttonLabel="currentDateRange.label"
                :popoutWidth="148"
                :offset="[4, 6]"
                :showKeyboardShortcuts="false"
                buttonIconBefore="Calendar"
                buttonSize="small"
                placement="bottom-end"
            />
            <!-- Settings -->
            <Button
                color="secondary"
                iconBefore="Settings"
                size="small"
                @click="toggleAccountsSettingsModal"
            >
                Settings
            </Button>
        </template>
    </PageHeader>

    <PageContent>
        <!-- /init -->
        <AccountsInit v-if="isInit" />

        <!-- Skeleton Rows -->
        <Stack
            v-else-if="loading"
            direction="column"
            align="start"
            :gap="0"
            :padding="[0, 0, 12, 0]"
            grow
        >
            <AccountGroup loading>
                <AccountsRow
                    v-for="skeletonId in 20"
                    :key="skeletonId"
                    :account="{}"
                    :hoverable="false"
                />
            </AccountGroup>
        </Stack>

        <!-- Account Groups -->
        <Stack
            v-else-if="truncatedDomainList?.length"
            direction="column"
            align="start"
            :gap="0"
            :padding="[0, 0, 12, 0]"
            :firstOnTop="false"
            grow
        >
            <AccountGroup
                :mode="currentView.value === 'accountGroups' ? 'account' : 'user'"
                :name="currentView.value === 'accountGroups' ? 'Ungrouped' : 'Unassigned'"
                color="gray"
                @createGroup="toggleCreateGroupModal"
            >
                <AccountsRow
                    v-for="account in truncatedDomainList"
                    :key="account.accountId"
                    :account="account"
                />
            </AccountGroup>
        </Stack>

        <!-- No Accounts -->
        <EmptyState
            v-else-if="userHasNoAccounts"
            icon="ExclamationExtraLarge"
            headline="No Linked Accounts"
            message="You currently have no accounts linked to Opteo. Click the button below to link an existing account or import a new one from a supported platform."
            :contentMaxWidth="296"
            :padding="[48, 0]"
            hideContainer
        >
            <Button color="secondary" iconAfter="ArrowUpRight" @click="goToLinkedAccounts">
                Linked Accounts
            </Button>
        </EmptyState>

        <!-- No Search Results -->
        <EmptyState
            v-else
            icon="SearchExtraLarge"
            :headline="searchedDomain"
            message="No accounts matching your search query. Click the button below to clear your search."
            :contentMaxWidth="272"
            hideContainer
        >
            <Button color="secondary" @click="handleResetClick">Clear Search Query</Button>
        </EmptyState>

        <!-- FloatingFooter -->
        <FloatingFooter
            v-model="showFooter"
            :showShield="true"
            :width="720"
            :padding="[12, 14, 12, 16]"
            sticky
        >
            <Stack align="center" :gap="12" width="100%">
                <IconButton
                    :size="32"
                    icon="Search"
                    color="floating-footer"
                    circle
                    @click="accountSearchInput.focus()"
                />
                <input
                    ref="accountSearchInput"
                    v-model="searchedDomain"
                    placeholder="Search accounts"
                    class="search-input"
                    @keydown.enter.prevent="gotoFirstSearchedDomain"
                    :autofocus="isDesktop ? true : false"
                />
            </Stack>
            <template #right>
                <Button color="floating-footer" iconBefore="Plus" @click="toggleCreateGroupModal">
                    Create Group
                </Button>
                <Spacer :width="8" />
                <Button
                    color="floating-footer"
                    iconAfter="ArrowUpRight"
                    @click="goToLinkedAccounts"
                >
                    Linked Accounts
                </Button>
            </template>
        </FloatingFooter>
    </PageContent>

    <!-- Onboarding -->
    <OnboardingTour
        step-name="tour_domainlist"
        :buttonText="'Continue to Accounts'"
        contentMaxWidth="382px"
        :offsetBottom="84"
    >
        <template v-slot:header>Accounts</template>
        <template v-slot:copy>
            Accounts is the home for all your marketing accounts in Opteo. Group accounts using the
            Create Group button. Filter using the options at the top of the page. To open an account
            and start pushing improvements, click on one of the account rows.
        </template>
    </OnboardingTour>

    <!-- Modals -->
    <AccountsSettingsModal v-model="showAccountsSettingsModal" />
    <CreateGroupModal v-model="showCreateGroupModal" />
</template>

<script setup lang="ts">
// Imports
import { ref, computed, onMounted } from 'vue'
import { useInfiniteScroll } from '@vueuse/core'
import { pushToDatalayer } from '@/lib/datalayer'
import { SortBy } from '@/lib/accountList'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useRouter, useRoute } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useAccountList } from '@/composition/user/useAccountList'

// Components
import {
    Button,
    SelectableList,
    FloatingFooter,
    IconButton,
    Spacer,
    Stack,
    EmptyState,
    Menu,
    useDevice,
    useMediaQuery,
} from '@opteo/components-next'
import AccountsSettingsModal from '@/components/accounts/Modals/AccountsSettingsModal.vue'
import CreateGroupModal from '@/components/accounts/Modals/CreateGroupModal.vue'
import AccountGroup from '@/components/accounts/AccountGroup.vue'
import AccountsInit from '@/components/accounts/AccountsInit.vue'
import AccountsRow from '@/components/accounts/AccountsRow/AccountsRow.vue'

import OnboardingTour from '@/components/user/OnboardingTour.vue'

// Setup
const route = useRoute()
const { user } = useUser()
const { isDesktop } = useDevice()
const {
    searchedDomain,
    gotoFirstSearchedDomain,
    userHasNoAccounts,
    sortedBy,
    filteredDomainList,
    resetSearch,
    domainListLoading,
} = useAccountList()

const { above480, above960 } = useMediaQuery()

const loading = computed(() => !user.value || domainListLoading.value)

const { push, currentRoute } = useRouter()

const isInit = computed(() => currentRoute.value.name === Routes.AccountCentreInit)

const goToLinkedAccounts = () => push({ name: Routes.LinkedAccounts })

const intercom = useIntercom()

// ——————————— Temporary

const showFooter = ref(true)
const accountSearchInput = ref()
const sortItems = [
    { label: 'Account Name', value: SortBy.Name },
    { label: 'Spend', value: SortBy.Spend },
    { label: 'Conversions', value: SortBy.Conversions },
    { label: 'Issues', value: SortBy.Issues },
    { label: 'Budget Status', value: SortBy.Budget },
    { label: 'Goal Status', value: SortBy.Goal },
    { label: 'Last Active', value: SortBy.LastActive },
]
const handleResetClick = () => {
    searchedDomain.value = ''
    accountSearchInput.value.focus()
}

const currentView = ref({
    label: 'Account Groups',
    value: 'accountGroups',
})
const viewItems = [
    {
        label: 'Account Groups',
        value: 'accountGroups',
        action: () =>
            (currentView.value = {
                label: 'Account Groups',
                value: 'accountGroups',
            }),
    },
    {
        label: 'Team Members',
        value: 'teamMembers',
        action: () =>
            (currentView.value = {
                label: 'Team Members',
                value: 'teamMembers',
            }),
    },
]

const currentDateRange = ref({
    label: 'Last 30 Days',
    value: '30d',
})
const dateRangeItems = [
    {
        label: 'Last 30 Days',
        value: '30d',
        action: () =>
            (currentDateRange.value = {
                label: 'Last 30 Days',
                value: '30d',
            }),
    },
    {
        label: 'Last 90 Days',
        value: '90d',
        action: () =>
            (currentDateRange.value = {
                label: 'Last 90 Days',
                value: '90d',
            }),
    },
    {
        label: 'Last 180 Days',
        value: '180d',
        action: () =>
            (currentDateRange.value = {
                label: 'Last 180 Days',
                value: '180d',
            }),
    },
    {
        label: 'Last Year',
        value: 'year',
        action: () =>
            (currentDateRange.value = {
                label: 'Last Year',
                value: 'year',
            }),
    },
]

// Modals
const showAccountsSettingsModal = ref(false)
const toggleAccountsSettingsModal = () => {
    showAccountsSettingsModal.value = !showAccountsSettingsModal.value
}

const showCreateGroupModal = ref(false)
const toggleCreateGroupModal = () => {
    showCreateGroupModal.value = !showCreateGroupModal.value
}

// ——————————— End Temporary

// autofocusSearch
const autofocusSearch = () => {
    if (isDesktop.value === true) {
        accountSearchInput.value.focus()
    } else return
}

onMounted(() => {
    intercom.trackEvent('60_saw_client_list')
    pushToDatalayer('gtm_saw_client_centre')
    resetSearch()
    autofocusSearch()
})

// Infinite Scroll
const listTruncationCount = ref(25)
const truncatedDomainList = computed(() => {
    return filteredDomainList.value.slice(0, listTruncationCount.value)
})
useInfiniteScroll(
    document,
    () => {
        listTruncationCount.value = listTruncationCount.value + 25
    },
    { distance: 500 } // 500px from bottom, allow more rows to be shown
)

// Temporary AccountGroup Data
const chimeExampleGroup = ref({
    name: 'Chime',
    color: 'teal',
    id: 'zq387qxi',
    accounts: [
        {
            id: 641486,
            accountId: 'ga_3ffUaTYDFWkhnmW4oFHits',
            idOnPlatform: '741-052-4457',
            name: 'Chime',
            color: '#00a897',
            platform: 0,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 45,
            tasksCompleted: 3,
            tasksDismissed: 0,
            active: true,
            lastVisitedAt: '2025-02-22T13:59:15.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 8000000,
                budget_variance: 0,
                has_budget_auto_pause: 0,
                actual_spend: 5958741.925880998,
                has_budget: true,
                display_difference: 96.63511949149105,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 74.48427407351248,
                status: 'good',
                budget_sort_order_value: 16,
            },
            connectionEmail: 'adwords@opteo.com',
            costUSD30days: 8906366.839,
            performanceMode: 2,
            accountSetupSeen: true,
            budget_sort_order_value: 16,
            accountManagerUserId: 75585, // new
        },
        {
            id: 3584867336,
            accountId: 'ga_kufb3ZcdjcsWpThGten4QB',
            idOnPlatform: '799-851-0368',
            name: 'Chime',
            color: '#00a897',
            platform: 2,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 49,
            tasksCompleted: 0,
            tasksDismissed: 1,
            active: true,
            lastVisitedAt: '2025-02-06T22:11:59.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 725000,
                budget_variance: 62500,
                has_budget_auto_pause: 0,
                actual_spend: 1167137.2559099998,
                has_budget: true,
                display_difference: 206.43316496429554,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 160.98444909103446,
                status: 'far_over',
                budget_sort_order_value: 2,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 2017887.7591,
            performanceMode: 2,
            accountSetupSeen: true,
            budget_sort_order_value: 2,
            accountManagerUserId: 75585, // new
        },
        {
            id: 11454143,
            accountId: 'ga_8YpdA88LYMLw7gr4DKRk7D',
            idOnPlatform: '770-724-0331',
            name: 'Chime',
            color: '#00a897',
            platform: 1,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 86,
            tasksCompleted: 0,
            tasksDismissed: 0,
            active: true,
            lastVisitedAt: '2025-02-22T13:59:33.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 675000,
                budget_variance: 0,
                has_budget_auto_pause: 0,
                actual_spend: 481307.89382299996,
                has_budget: true,
                display_difference: 90.88775865420105,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 71.30487315896296,
                status: 'good',
                budget_sort_order_value: 14,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 736049.1299,
            performanceMode: 1,
            accountSetupSeen: true,
            budget_sort_order_value: 14,
            accountManagerUserId: 75585, // new
        },
        {
            id: 4249317797,
            accountId: 'ga_dnAkBY6SUF5xRVAgaBKxDw',
            idOnPlatform: '441-164-6097',
            name: 'Chime',
            color: '#00a897',
            platform: 3,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 41,
            tasksCompleted: 0,
            tasksDismissed: 0,
            active: true,
            lastVisitedAt: '2025-02-22T15:07:03.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 50000,
                budget_variance: 50000,
                has_budget_auto_pause: 0,
                actual_spend: 39011.575886000006,
                has_budget: true,
                display_difference: 100.15828794628572,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 78.023151772,
                status: 'good',
                budget_sort_order_value: 18,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 52828.6576,
            performanceMode: 1,
            accountSetupSeen: true,
            budget_sort_order_value: 18,
            accountManagerUserId: 75585, // new
        },
    ],
})
const discordExampleGroup = ref({
    name: 'Discord',
    color: 'purple',
    id: 'rv5p69mu',
    accounts: [
        {
            id: 3584867336,
            accountId: 'ga_kufb3ZcdjcsWpThGten4QB',
            idOnPlatform: '799-851-0368',
            name: 'Discord',
            color: '#6e3aff',
            platform: 0,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 49,
            tasksCompleted: 0,
            tasksDismissed: 1,
            active: true,
            lastVisitedAt: '2025-02-06T22:11:59.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 725000,
                budget_variance: 62500,
                has_budget_auto_pause: 0,
                actual_spend: 1167137.2559099998,
                has_budget: true,
                display_difference: 206.43316496429554,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 160.98444909103446,
                status: 'far_over',
                budget_sort_order_value: 2,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 2017887.7591,
            performanceMode: 2,
            accountSetupSeen: true,
            budget_sort_order_value: 2,
            accountManagerUserId: 54685, // new
        },
        {
            id: 15538374,
            accountId: 'ga_iJfmaqW7A9CHFwDPNtNyYB',
            idOnPlatform: '795-523-3787',
            name: 'Discord',
            color: '#6e3aff',
            platform: 2,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 57,
            tasksCompleted: 0,
            tasksDismissed: 2,
            active: true,
            lastVisitedAt: '2025-02-11T14:36:50.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 600000,
                budget_variance: 0,
                has_budget_auto_pause: 0,
                actual_spend: 613832.447924,
                has_budget: true,
                display_difference: 132.6903386327381,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 102.30540798733333,
                status: 'far_over',
                budget_sort_order_value: 4,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 795633.7841,
            performanceMode: 1,
            accountSetupSeen: true,
            budget_sort_order_value: 4,
            accountManagerUserId: 54685, // new
        },
        {
            id: 11454143,
            accountId: 'ga_8YpdA88LYMLw7gr4DKRk7D',
            idOnPlatform: '770-724-0331',
            name: 'Discord',
            color: '#6e3aff',
            platform: 1,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 86,
            tasksCompleted: 0,
            tasksDismissed: 0,
            active: true,
            lastVisitedAt: '2025-02-22T13:59:33.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 675000,
                budget_variance: 0,
                has_budget_auto_pause: 0,
                actual_spend: 481307.89382299996,
                has_budget: true,
                display_difference: 90.88775865420105,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 71.30487315896296,
                status: 'good',
                budget_sort_order_value: 14,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 736049.1299,
            performanceMode: 1,
            accountSetupSeen: true,
            budget_sort_order_value: 14,
            accountManagerUserId: 54685, // new
        },
    ],
})
const pelotonExampleGroup = ref({
    name: 'Peloton',
    color: 'pink',
    id: 'f430f9kf',
    accounts: [
        {
            id: 6852317772,
            accountId: 'ga_3vkcCw2YiqDPkRtm9CCSKx',
            idOnPlatform: '338-996-6146',
            name: 'Peloton',
            color: '#ff365c',
            platform: 2,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 36,
            tasksCompleted: 0,
            tasksDismissed: 0,
            active: true,
            lastVisitedAt: '2025-02-22T13:59:21.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 80000,
                budget_variance: 0,
                has_budget_auto_pause: 0,
                actual_spend: 195631.49669,
                has_budget: true,
                display_difference: 316.78397295999997,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 244.53937086250002,
                status: 'far_over',
                budget_sort_order_value: 1,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 257811.5605,
            performanceMode: 1,
            accountSetupSeen: true,
            budget_sort_order_value: 1,
            accountManagerUserId: 75793, // new
        },
        {
            id: 11256212,
            accountId: 'ga_1aAmEaBVUMzEXQHi8sdqPZ',
            idOnPlatform: '819-139-6401',
            name: 'Peloton',
            color: '#ff365c',
            platform: 1,
            currencySymbol: '$',
            currencyCode: 'USD',
            timezone: 'America/Los_Angeles',
            tasksAvailable: 42,
            tasksCompleted: 0,
            tasksDismissed: 0,
            active: true,
            lastVisitedAt: '2025-02-22T13:58:51.000Z',
            metadata: null,
            legacy: true,
            budgetBar: {
                budget: 75000,
                budget_variance: 0,
                has_budget_auto_pause: 0,
                actual_spend: 57265.640132,
                has_budget: true,
                display_difference: 97.35573183580952,
                month_progress_percentage: 78.57142857142857,
                month_spent_percentage: 76.35418684266668,
                status: 'good',
                budget_sort_order_value: 18,
            },
            connectionEmail: 'nya@opteo.com',
            costUSD30days: 78642.2276,
            performanceMode: 1,
            accountSetupSeen: true,
            budget_sort_order_value: 18,
            accountManagerUserId: 75793, // new
        },
    ],
})
</script>

<style scoped lang="scss">
.search-input {
    all: unset;
    @include w-100;
    @include body;
    @include foreground;
}
.search-input::placeholder {
    @include foreground-minus-three;
}
</style>
