import { pushToDatalayer } from '@/lib/datalayer'
import { delay } from '@/lib/globalUtils'
import { API_URL } from '@/lib/env'
import { onMounted, ref } from 'vue'
import { useRoute } from 'vue-router'
import { authRequest, Endpoint } from '../api/useAPI'
import { useLogin } from '../auth/useLogin'
import { useLayouts } from '../layouts/useLayouts'
import { useUser } from './useUser'
import { Api } from '@opteo/types'

export function useConnectAdwords() {
    const route = useRoute()
    const { logout } = useLogin()
    const { userId, isAgency, isAdvertiser, loading: userLoading } = useUser()

    const loading = ref(false)
    const noProfilesDetected = ref(false)
    const oauthError = ref(false)

    const connectGoogleAds = async () => {
        loading.value = true

        const redirectUrl = `${document.location.origin}`

        const errorUrl = `${document.location.origin}/user/${userId.value}/connectgoogleads/no-profiles-detected`

        pushToDatalayer('gtm_clicked_connect_gads_button')

        const [googleAdsUrl] = await Promise.all([
            authRequest<string>(Endpoint.GetGoogleAdsOauthUrl),
            delay(2000), // wait a minimum of 2s to allow any datalayer GTM events to fire
        ])

        if (!userId.value) {
            throw new Error('User ID not found')
        }

        const state: Api.OAuth.State = {
            userId: userId.value,
            returnUrl: redirectUrl,
            errorUrl: errorUrl,
        }

        const connectUrl = new URL(googleAdsUrl)
        connectUrl.searchParams.append('state', JSON.stringify(state))

        window.location.href = connectUrl.toString()
    }

    onMounted(() => {
        if (route.params.error) {
            noProfilesDetected.value = true
        }
        if (route.query.from && route.query.err) {
            oauthError.value = true
        }
    })

    return {
        connectGoogleAds,
        logout,
        noProfilesDetected,
        oauthError,
        isAgency,
        isAdvertiser,
        loading,
        userLoading,
    }
}
