<template>
    <Stack :gap="14" align="center" :width="width">
        <!-- Label -->
        <Stack v-if="loading || !chart" :height="12" width="auto">
            <Skeleton :width="labelSkeletonWidth" :height="8" />
        </Stack>
        <Text v-else as="span" size="label" color="foreground-minus-one">{{ label }}</Text>
        <!-- Sparkline -->
        <Sparkline
            :items="sparklinePoints"
            :color="delta?.color"
            :loading="loading"
            :skeletonPath="1"
            :width="48"
        />
        <!-- Value -->
        <Stack v-if="loading" :height="14" :gap="0" width="auto" align="center">
            <Skeleton :height="10" :width="valueSkeletonWidth" />
        </Stack>
        <Text
            v-else
            as="span"
            size="delta"
            weight="bold"
            :color="delta?.color === 'gray' ? 'foreground-minus-one' : delta?.color"
        >
            <Money
                v-if="format === 'money'"
                :value="chart?.thirty_day_total ?? 0"
                :currency="currency"
            />
            <NumberWrapper v-else-if="format === 'number'" :value="chart?.thirty_day_total ?? 0" />
            <Percent v-else-if="format === 'percent'" :value="chart?.thirty_day_total ?? 0" />
        </Text>
        <!-- Delta -->
        <Delta
            ref="delta"
            :threshold="0.2"
            :delta="computedDelta"
            :decimal-places="computedDecimalPlaces"
            :loading="loading"
            small
        />
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'

// Types
type AccountsRowValueFormat = 'money' | 'percent' | 'number'

// Components
import {
    Stack,
    Delta,
    Text,
    Sparkline,
    Money,
    Number as NumberWrapper,
    Percent,
    Skeleton,
} from '@opteo/components-next'

// Props
const props = defineProps({
    chart: {
        type: Object as PropType<any>,
        required: true,
        default: () => ({}),
    },
    width: {
        type: Number as PropType<number>,
        required: true,
    },
    label: {
        type: String as PropType<string>,
        required: true,
    },
    labelSkeletonWidth: {
        type: Number as PropType<number>,
        required: true,
    },
    valueSkeletonWidth: {
        type: Number as PropType<number>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    format: {
        type: String as PropType<AccountsRowValueFormat>,
        required: true,
    },
    currency: {
        type: String as PropType<string>,
        required: false,
    },
})

// Setup
const delta = ref(null)
const sparklinePoints = computed(() => {
    // If there’s no array or it’s empty
    if (!props.chart?.points) {
        return fallbackPoints.value
    }

    // If any point has a `null` y-value
    const hasNullValues = props.chart.points.some(point => point.y === null)
    if (hasNullValues) {
        return fallbackPoints.value
    }

    // Otherwise, return the points as-is
    return props.chart.points
})
const fallbackPoints = ref([
    { x: 1, y: 0 },
    { x: 2, y: 0 },
    { x: 3, y: 0 },
    { x: 4, y: 0 },
    { x: 5, y: 0 },
    { x: 6, y: 0 },
    { x: 7, y: 0 },
    { x: 8, y: 0 },
])

// Delta
const computedDelta = computed(() => {
    return props.chart?.delta < 999 ? props.chart?.delta / 100 : 9.99
})
const computedDecimalPlaces = computed(() => {
    return props.chart?.delta > 500 ? 0 : 2
})
</script>
