<template>
    <ExternalPageWrapper :maxWidth="654" @logout="logout" showLogoutButton>
        <Stack
            :direction="above1024 ? 'row' : 'column'"
            :align="above1024 ? 'start' : 'center'"
            :gap="above1280 ? 32 : 24"
            :width="above1280 ? 'auto' : 654"
        >
            <!-- Payment Method Form -->
            <PaymentMethodForm
                :updatingCard="false"
                :width="272"
                :borderRadius="22"
                @success="onSuccess"
                @error="onError"
                @load="showInformation = true"
                showButton
            />

            <!-- Information -->
            <Transition :name="above1024 ? 'information-fade-right' : 'information-fade-up'">
                <Container
                    v-if="showInformation"
                    :width="336"
                    :borderRadius="22"
                    class="information"
                >
                    <!-- Description -->
                    <Stack
                        :padding="[18, 20, 17, 20]"
                        :gap="0"
                        direction="column"
                        align="start"
                        borderBottom
                    >
                        <Text as="h6" size="headline-six">Add Payment Method</Text>
                        <Spacer :height="12" />
                        <Text as="span" size="body-extra-small" color="foreground-minus-one">
                            Add a payment method to activate your subscription.
                            <b>Your card will not be charged until the end of your trial</b>.
                        </Text>
                        <Spacer :height="10" />
                        <Text as="span" size="body-extra-small" color="foreground-minus-one">
                            If you are unhappy with Opteo for any reason, you can cancel your
                            subscription at any time via the <b>Billing and Invoices</b> page. If
                            you have any questions, please don't hesitate to
                            <Text
                                as="span"
                                color="foreground-minus-one"
                                weight="bold"
                                @click="showNewMessage()"
                                style="cursor: pointer"
                                >send our support team a message</Text
                            >.
                        </Text>
                    </Stack>
                    <!-- Subscription -->
                    <Stack v-if="monthlyFee" :padding="[16, 20]" direction="column" :gap="11">
                        <Stack align="center" justify="between">
                            <Text size="body-small" color="foreground-minus-one" :wrap="false">
                                Pricing Plan
                            </Text>
                            <Text size="body-small" weight="semibold">{{ currentPlan?.name }}</Text>
                        </Stack>
                        <Stack align="center" justify="between">
                            <Text size="body-small" color="foreground-minus-one" :wrap="false">
                                Trial Ends
                            </Text>
                            <Text size="body-small" weight="semibold">
                                {{ trialEndDate }}
                            </Text>
                        </Stack>
                        <Stack align="center" justify="between">
                            <Text size="body-small" color="foreground-minus-one" :wrap="false">
                                Monthly Price
                            </Text>
                            <Text size="body-small" weight="semibold">
                                <Money :value="monthlyFee" currency="USD" />
                            </Text>
                        </Stack>
                        <Stack align="center" justify="between">
                            <Text size="body-small" color="foreground-minus-one" :wrap="false">
                                Due Today
                            </Text>
                            <Text size="body-small" weight="semibold" color="green">
                                <Money :value="0" currency="USD" />
                            </Text>
                        </Stack>
                    </Stack>
                    <!-- Loading -->
                    <Stack v-else :padding="[16, 20]" direction="column" :gap="11">
                        <Stack align="center" justify="between" :height="20">
                            <Skeleton :height="10" :width="88" />
                            <Skeleton :height="10" :width="48" />
                        </Stack>
                        <Stack align="center" justify="between" :height="20">
                            <Skeleton :height="10" :width="72" />
                            <Skeleton :height="10" :width="112" />
                        </Stack>
                        <Stack align="center" justify="between" :height="20">
                            <Skeleton :height="10" :width="90" />
                            <Skeleton :height="10" :width="56" />
                        </Stack>
                        <Stack align="center" justify="between" :height="20">
                            <Skeleton :height="10" :width="112" />
                            <Skeleton :height="10" :width="74" />
                        </Stack>
                    </Stack>
                </Container>
            </Transition>
        </Stack>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { ref } from 'vue'
// Composition
import { useNewPaymentMethod } from '@/composition/user/useNewPaymentMethod'

// Components
import {
    Money,
    Text,
    Stack,
    Container,
    Skeleton,
    Spacer,
    useMediaQuery,
    showSnackbar,
    hideSnackbar,
} from '@opteo/components-next'
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'
import PaymentMethodForm from '@/components/billing/PaymentMethodForm.vue'

// Setup
const { showNewMessage, monthlyFee, trialEndDate, onSuccess, logout, currentPlan } =
    useNewPaymentMethod()
const showInformation = ref(false)
const { above1280, above1024 } = useMediaQuery()

// Functions
function onError(error: any) {
    console.error(error.message)
    showSnackbar({
        message: error.message,
        timeout: 5000,
        actionText: 'Dismiss',
        actionHandler: () => hideSnackbar(),
    })
}
</script>

<style scoped lang="scss">
@media screen and (min-width: $mq-1280) {
    .information {
        @include absolute;
        right: calc(-336px - 24px);
    }
}

.information-fade-right-enter-active,
.information-fade-right-leave-active {
    transition: all 0.36s ease 0.56s;
}

.information-fade-right-enter-from,
.information-fade-right-leave-to {
    opacity: 0;
    transform: translateX(-0.5rem);
}

.information-fade-up-enter-active,
.information-fade-up-leave-active {
    transition: all 0.36s ease 0.56s;
}

.information-fade-up-enter-from,
.information-fade-up-leave-to {
    opacity: 0;
    transform: translateY(0.5rem);
}
</style>
