<template>
    <section class="w-100">
        <!-- Close Button -->
        <transition name="improvement-close-button-fade" appear>
            <CloseButton v-if="closeButton" :fixed="true" @clicked="closeImprovement" />
        </transition>
        <!-- Improvement Container -->
        <div class="improvement-container">
            <slot></slot>
        </div>
    </section>
</template>

<script lang="ts">
// import { CloseButton } from '@opteo/components-next'

export default {
    // components: { CloseButton },
    name: 'ImprovementContainer',
    props: {
        closeButton: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['close-improvement'],
    setup(_, { emit }) {
        function closeImprovement() {
            const openPopouts = Array.from(document.querySelectorAll('.popout')).length > 0

            if (openPopouts) {
                return
            }
            emit('close-improvement')
        }

        return { closeImprovement }
    },
}
</script>

<style lang="scss">

// @import '@/assets/css/theme.scss';

// // Base
// .improvement-container {
//     display: block;
//     margin-bottom: 6.5rem;
//     padding: 6rem 0 3rem 0;
// }

// .improvement-close-button-fade-enter-active {
//     transition:
//         transform 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6),
//         opacity 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6);
//     transition-delay: 0.25s;
// }
// .improvement-close-button-fade-leave-active {
//     transition:
//         transform 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6),
//         opacity 0.75s cubic-bezier(0.68, -0.6, 0.32, 1.6);
// }
// .improvement-close-button-fade-enter-from,
// .improvement-close-button-fade-leave-to {
//     transform: scale(20%);
//     opacity: 0;
// }

// @media screen and (min-width: 0px) and (max-width: 767px) {
//     .improvement-container {
//         padding: 1rem 0 0 0;
//         margin-bottom: 13rem;
//     }
// }
// @media screen and (min-width: 768px) and (max-width: 1024px) {
//     .improvement-container {
//         padding: 5rem 0;
//     }
// }
// @media screen and (min-width: 1025px) and (max-width: 1279px) {
// }
// @media screen and (min-width: 1280px) and (max-width: 1440px) {
// }
// @media screen and (min-width: 1441px) and (max-width: 1600px) {
// }
// @media screen and (min-width: 1601px) and (max-width: 1920px) {
// }
// @media screen and (min-width: 1921px) and (max-width: 2560px) {
// }
// @media screen and (min-width: 2561px) and (max-width: 9999px) {
// }

</style>
