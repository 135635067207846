<template>
    <ExternalPageWrapper :maxWidth="280">
        <Container :borderRadius="22" width="100%" hideOverflow>
            <!-- Explainer -->
            <Stack direction="column" align="start" :padding="[16, 20]" gap="0.75rem" borderBottom>
                <Text as="h6" size="headline-six" color="foreground">Welcome to Opteo</Text>
                <Text as="p" size="body-extra-small" color="foreground-minus-one">
                    Your account has been unlocked and your trial has been activated. To get
                    started, click the button below. If you have any questions, don't hesitate to
                    contact our support team.
                </Text>
            </Stack>
            <Stack :padding="20" direction="column">
                <Button fullWidth iconAfter="ArrowUpRight" @click="goToAccounts">
                    Continue to Accounts
                </Button>
            </Stack>
        </Container>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Components
import { Stack, Text, Button, Container } from '@opteo/components-next'

// Setup
const router = useRouter()

// Functions
function goToAccounts() {
    router.push({ name: Routes.Root })
}
</script>

<style scoped lang="scss"></style>
