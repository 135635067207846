<template>
    <div>
        <slot />
    </div>
</template>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // // Global
// // :deep(.entity-tag) {
// //     @include relative;
// //     // can't be `inline` as caret doesn't show
// //     @include inline-block;
// //     vertical-align: middle;
// //     max-width: 100%;
// //     @include f-8;
// //     @include fw-500;
// //     word-break: break-word;
// // }
// // :deep(.entity-tag[data-state='complete'][data-type='campaign']) {
// //     background: $opteo-dark-blue-translucent;
// //     @include opteo-dark-blue;
// // }
// // :deep(.entity-tag[data-state='complete'][data-type='adgroup']) {
// //     background: $opteo-dark-purple-translucent;
// //     @include opteo-dark-purple;
// // }
// // :deep(.entity-tag[data-state='complete'][data-type='keyword']) {
// //     background: $opteo-dark-teal-translucent;
// //     @include opteo-dark-teal;
// // }
// // :deep(.entity-tag[data-type='campaign']:not([data-state='incomplete'])) {
// //     @include opteo-dark-blue;
// // }
// // :deep(.entity-tag[data-type='adgroup']:not([data-state='incomplete'])) {
// //     @include opteo-dark-purple;
// // }
// // :deep(.entity-tag[data-type='keyword']:not([data-state='incomplete'])) {
// //     @include opteo-dark-teal;
// // }
// // :deep(.entity-tag[data-state='complete'] span.at),
// // :deep(.entity-tag[data-state='semi-complete'] span.at),
// // :deep(.entity-tag[data-state='complete'] span.separator),
// // :deep(.entity-tag[data-state='semi-complete'] span.semi-complete-start),
// // :deep(.entity-tag[data-state='semi-complete'] span.semi-complete-end) {
// //     display: inline-block;
// //     opacity: 0;
// //     position: absolute;
// //     pointer-events: none;
// // }
// // :deep(.entity-tag[data-state='semi-complete'] span.separator) {
// //     display: inline-block;
// //     opacity: 1;
// //     position: absolute;
// //     pointer-events: none;
// //     color: rgba(0, 0, 0, 0);
// // }

// // :deep(.entity-tag span.name) {
// //     display: inline-block;
// //     user-select: none;
// //     // safari prevents window.getSelection working in a textNode when the enclosing has user-select: none
// //     // https://stackoverflow.com/questions/35281283/safari-getselection-not-working
// //     -webkit-user-select: auto; /* Safari */
// //     -moz-user-select: none; /* Firefox */
// //     -ms-user-select: none; /* IE10+/Edge */
// //     user-select: none; /* Standard */
// // }

// // :deep(.entity-tag span.resource-name) {
// //     // font-size needs to be big enough so that user selection covers the width of the entity that's set by the text in span.name::before
// //     // they're actually selecting the text within span.resource-name but we need visually simulate that it's the entity
// //     // font-size sets the height of the caret too
// //     font-size: 30px;
// //     position: absolute;
// //     top: 0rem;
// //     right: 0.125rem;
// //     bottom: 0;
// //     padding-left: 30px;
// //     white-space: nowrap;
// //     width: 100%;
// //     overflow: hidden;
// //     text-align: right;
// //     opacity: 1;
// //     z-index: 4;
// //     color: rgba(0, 0, 0, 0);
// //     caret-color: #000;
// // }

// // :deep(.entity-tag[data-state='complete'] span.name::before) {
// //     content: attr(data-name);
// // }

// // // ChatQuery
// // :deep(.chat-query .entity-tag) {
// //     line-height: 1.3125rem;
// //     padding: 0 0.1875rem;
// //     margin: 0 0.1875rem;
// //     transform: translateY(-0.125rem);
// //     font-size: inherit;
// //     @include fw-400;
// //     background: transparent !important;
// // }
// // :deep(.chat-query .entity-tag::after) {
// //     content: '';
// //     width: calc(100% + 0.25rem);
// //     height: 1.5rem;
// //     position: absolute;
// //     top: -0.125rem;
// //     right: -0.125rem;
// //     border-radius: 0.375rem;
// // }
// // :deep(.chat-query .entity-tag[data-type='campaign']::after) {
// //     background: $opteo-dark-blue-translucent;
// // }
// // :deep(.chat-query .entity-tag[data-type='adgroup']::after) {
// //     background: $opteo-dark-purple-translucent;
// // }
// // :deep(.chat-query .entity-tag[data-type='keyword']::after) {
// //     background: $opteo-dark-teal-translucent;
// // }

// // @media screen and (max-width: 768px) {
// //     :deep(.chat-query .entity-tag::after) {
// //         height: calc(100% + 0.25rem);
// //     }
// // }

// // // ChatInput
// // :deep(.chat-input .entity-tag) {
// //     transform: translateY(-2px);
// //     padding-right: 0.375rem;
// //     line-height: 1.5rem;
// //     height: 2rem;
// //     @include br-8;
// // }
// // :deep(.chat-input .entity-tag::before) {
// //     content: '';
// //     position: absolute;
// //     left: 0.375rem;
// //     top: 0;
// //     bottom: 0;
// //     width: 1.5rem;
// //     z-index: 2;
// //     pointer-events: none;
// // }
// // :deep(.chat-input .entity-tag[data-type='campaign']:not([data-state='incomplete'])::before) {
// //     content: '';
// //     background: url('@/assets/img/icon-circle-campaign.svg') center / contain no-repeat;
// // }
// // :deep(.chat-input .entity-tag[data-type='adgroup']:not([data-state='incomplete'])::before) {
// //     content: '';
// //     background: url('@/assets/img/icon-circle-adgroup.svg') center / contain no-repeat;
// // }
// // :deep(.chat-input .entity-tag[data-type='keyword']:not([data-state='incomplete'])::before) {
// //     content: '';
// //     background: url('@/assets/img/icon-circle-keyword.svg') center / contain no-repeat;
// // }
// // :deep(.chat-input .entity-tag[data-state='incomplete']) {
// //     font-size: 1.25rem;
// //     line-height: 2.375rem;
// //     font-weight: 300;
// //     letter-spacing: -0.0075rem;
// //     transform: translateY(-5px);
// // }
// // :deep(.chat-input .entity-tag span.name) {
// //     padding-left: 2.25rem;
// //     line-height: 2rem;
// // }
// // :deep(.chat-input .entity-tag[data-state='complete'] span.name::before) {
// //     line-height: 1.9375rem;
// // }

// // // Chat Conversation
// // :deep(.conversation .entity-tag) {
// //     line-height: 1.3125rem;
// //     padding: 0 0.1875rem;
// //     margin: 0 0.1875rem;
// //     transform: translateY(-1px);
// //     font-size: inherit;
// //     @include fw-400;
// //     background: transparent !important;
// // }
// // :deep(.conversation .entity-tag::after) {
// //     content: '';
// //     width: calc(100% + 0.25rem);
// //     height: 1.5rem;
// //     position: absolute;
// //     top: -0.125rem;
// //     right: -0.125rem;
// //     border-radius: 0.375rem;
// // }
// // :deep(.conversation .entity-tag[data-type='campaign']::after) {
// //     background: $opteo-dark-blue-translucent;
// // }
// // :deep(.conversation .entity-tag[data-type='adgroup']::after) {
// //     background: $opteo-dark-purple-translucent;
// // }
// // :deep(.conversation .entity-tag[data-type='keyword']::after) {
// //     background: $opteo-dark-teal-translucent;
// // }

// // // Shared Chat Footer

// // :deep(.sharedchat-footer .entity-tag) {
// //     line-height: 1.3125rem;
// //     padding: 0 0.1875rem;
// //     margin: 0 0.1875rem;
// //     transform: translateY(-0.125rem);
// //     font-size: inherit;
// //     @include fw-400;
// //     background: transparent !important;
// // }
// // :deep(.sharedchat-footer .entity-tag::after) {
// //     content: '';
// //     width: calc(100% + 0.25rem);
// //     height: 1.5rem;
// //     position: absolute;
// //     top: -0.125rem;
// //     right: -0.125rem;
// //     border-radius: 0.375rem;
// // }
// // :deep(.sharedchat-footer .entity-tag[data-type='campaign']::after) {
// //     background: $opteo-dark-blue-translucent;
// // }
// // :deep(.sharedchat-footer .entity-tag[data-type='adgroup']::after) {
// //     background: $opteo-dark-purple-translucent;
// // }
// // :deep(.sharedchat-footer .entity-tag[data-type='keyword']::after) {
// //     background: $opteo-dark-teal-translucent;
// // }


</style>
