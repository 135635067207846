import { computed, Ref } from 'vue'
import { TextColor } from '@opteo/components-next'
import { AvatarColor } from '@opteo/components-next/dist/components/avatars/Avatar/types'

// TODO(components-updates): Need to update API to use color strings (e.g. `teal`, `blue`) rather than hex codes, need to replace all instances of `mapHexToAvatarColor`, etc.
export function mapHexToAvatarColor(hexColor?: string): AvatarColor {
    if (!hexColor) return 'gray'

    switch (hexColor.toLowerCase()) {
        case '#006dff':
            return 'blue'
        case '#6e3aff':
            return 'purple'
        case '#00a897':
            return 'teal'
        case '#ff365c':
            return 'pink'
        case '#ff471f':
            return 'orange'
        default:
            return 'gray'
    }
}

export function mapAvatarColorToHex(color?: AvatarColor): string {
    if (!color) return '#006dff' // Default to blue hex

    switch (color) {
        case 'blue':
            return '#006dff'
        case 'purple':
            return '#6e3aff'
        case 'teal':
            return '#00a897'
        case 'pink':
            return '#ff365c'
        case 'orange':
            return '#ff471f'
        default:
            return '#006dff'
    }
}

/**
 * Returns a computed property that formats an IANA timezone into a human-readable string
 *
 * @param timezone Ref<string | undefined> containing an IANA timezone like "America/Los_Angeles"
 * @param format "long" for full name (e.g. "Pacific Time") or "short" for abbreviation (e.g. "PT")
 * @returns Computed<string> with the formatted timezone or empty string if timezone is undefined
 */
export function useFormattedTimezone(
    timezone: Ref<string | undefined>,
    format: 'long' | 'short' = 'long'
) {
    return computed(() => {
        if (!timezone.value) return ''

        try {
            // Create a formatter with the specified timezone and format
            const formatter = new Intl.DateTimeFormat('en', {
                timeZone: timezone.value,
                timeZoneName: format,
            })

            // Extract the timezone part from the formatted date
            const parts = formatter.formatToParts(new Date())
            const timeZonePart = parts.find(part => part.type === 'timeZoneName')

            return timeZonePart ? timeZonePart.value : timezone.value
        } catch (error) {
            // If there's an error (like invalid timezone), return the original
            console.error(`Error formatting timezone ${timezone.value}:`, error)
            return timezone.value
        }
    })
}

/**
 * Maps common timezone identifiers to their corresponding countries
 */
const timezoneToCountryMap: Record<string, string> = {
    // North America
    'America/New_York': 'United States',
    'America/Chicago': 'United States',
    'America/Denver': 'United States',
    'America/Los_Angeles': 'United States',
    'America/Phoenix': 'United States',
    'America/Anchorage': 'United States',
    'America/Honolulu': 'United States',
    'America/Toronto': 'Canada',
    'America/Vancouver': 'Canada',
    'America/Montreal': 'Canada',
    'America/Mexico_City': 'Mexico',

    // Europe
    'Europe/London': 'United Kingdom',
    'Europe/Paris': 'France',
    'Europe/Berlin': 'Germany',
    'Europe/Rome': 'Italy',
    'Europe/Madrid': 'Spain',
    'Europe/Amsterdam': 'Netherlands',
    'Europe/Brussels': 'Belgium',
    'Europe/Vienna': 'Austria',
    'Europe/Dublin': 'Ireland',
    'Europe/Lisbon': 'Portugal',
    'Europe/Oslo': 'Norway',
    'Europe/Stockholm': 'Sweden',
    'Europe/Helsinki': 'Finland',
    'Europe/Copenhagen': 'Denmark',
    'Europe/Athens': 'Greece',
    'Europe/Istanbul': 'Turkey',
    'Europe/Moscow': 'Russia',
    'Europe/Zurich': 'Switzerland',

    // Asia
    'Asia/Tokyo': 'Japan',
    'Asia/Shanghai': 'China',
    'Asia/Hong_Kong': 'Hong Kong',
    'Asia/Singapore': 'Singapore',
    'Asia/Seoul': 'South Korea',
    'Asia/Dubai': 'United Arab Emirates',
    'Asia/Kolkata': 'India',
    'Asia/Bangkok': 'Thailand',
    'Asia/Tel_Aviv': 'Israel',
    'Asia/Riyadh': 'Saudi Arabia',

    // Australia & Oceania
    'Australia/Sydney': 'Australia',
    'Australia/Melbourne': 'Australia',
    'Australia/Perth': 'Australia',
    'Australia/Brisbane': 'Australia',
    'Pacific/Auckland': 'New Zealand',

    // Africa
    'Africa/Casablanca': 'Morocco',
    'Africa/Cairo': 'Egypt',
    'Africa/Johannesburg': 'South Africa',
    'Africa/Lagos': 'Nigeria',
    'Africa/Nairobi': 'Kenya',

    // South America
    'America/Sao_Paulo': 'Brazil',
    'America/Buenos_Aires': 'Argentina',
    'America/Bogota': 'Colombia',
    'America/Lima': 'Peru',
    'America/Santiago': 'Chile',
}

/**
 * Extract continent from timezone identifier
 * @param timezone Timezone identifier like "America/Los_Angeles"
 * @returns The continent part (e.g., "America")
 */
function extractContinent(timezone: string): string | null {
    const parts = timezone.split('/')
    return parts.length > 0 ? parts[0] : null
}

/**
 * Attempt to determine country from continent and city
 * @param timezone Timezone identifier
 * @returns Best guess for country or null if unknown
 */
function guessCountryFromTimezone(timezone: string): string | null {
    // First check the direct mapping
    if (timezoneToCountryMap[timezone]) {
        return timezoneToCountryMap[timezone]
    }

    // Try to guess based on the continent
    const continent = extractContinent(timezone)
    if (!continent) return null

    // Very basic continent-based guessing for unknown timezones
    switch (continent) {
        case 'America':
            if (timezone.includes('Argentina')) return 'Argentina'
            if (timezone.includes('Brazil')) return 'Brazil'
            if (timezone.includes('Canada')) return 'Canada'
            if (timezone.includes('Mexico')) return 'Mexico'
            return 'United States' // Default for America

        case 'Europe':
            return 'European Union' // Default for Europe

        case 'Asia':
            if (timezone.includes('China')) return 'China'
            if (timezone.includes('India')) return 'India'
            if (timezone.includes('Japan')) return 'Japan'
            return 'Asia' // Default for Asia

        case 'Africa':
            if (timezone.includes('South')) return 'South Africa'
            return 'Africa' // Default for Africa

        case 'Australia':
            return 'Australia'

        case 'Pacific':
            if (timezone.includes('Zealand')) return 'New Zealand'
            return 'Pacific' // Default for Pacific

        default:
            return null
    }
}

/**
 * Returns a computed property that extracts the country from an IANA timezone identifier
 *
 * @param timezone Ref<string | undefined> containing an IANA timezone like "America/Los_Angeles"
 * @returns Computed<string> with the country name or empty string if unable to determine
 */
export function useCountryFromTimezone(timezone: Ref<string | undefined>) {
    return computed(() => {
        if (!timezone.value) return ''

        try {
            return guessCountryFromTimezone(timezone.value) || ''
        } catch (error) {
            console.error(`Error determining country for timezone ${timezone.value}:`, error)
            return ''
        }
    })
}

export function mapHexToTextColor(hexColor: string): TextColor {
    switch (hexColor.toLowerCase()) {
        case '#006dff':
            return 'blue'
        case '#6e3aff':
            return 'purple'
        case '#00a897':
            return 'teal'
        case '#ff365c':
            return 'pink'
        case '#ff471f':
            return 'orange'
        default:
            return 'foreground-minus-one'
    }
}
