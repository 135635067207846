<template>
    <div class="improvement-batch-icon" role="button">
        <IconSlot :width="20" :height="20">
            <!-- Unselected, not pushable -->
            <div v-show="iconState === 'unselected_not_batchable'" @click.prevent="select">
                <Tooltip
                    :content="
                        aboveMobile
                            ? 'This improvement will be skipped when being pushed via the queue because it requires user input'
                            : ''
                    "
                    :offset="[0, 8]"
                    :delay="[320, 0]"
                    :maxWidth="222"
                >
                    <Checkbox :modelValue="false" readOnly />
                </Tooltip>
            </div>

            <!-- Unselected, pushable -->
            <div v-show="iconState === 'unselected_batchable'" @click.prevent="select">
                <Checkbox :modelValue="false" readOnly />
            </div>

            <!-- Selected -->
            <div v-show="iconState === 'selected'" @click.prevent="select">
                <Checkbox :modelValue="true" readOnly />
            </div>

            <!-- Pushing -->
            <div v-if="iconState === 'working'">
                <Spinner />
            </div>

            <!-- Success -->
            <div v-if="iconState === 'pushed'">
                <Tooltip
                    :content="aboveMobile ? 'Improvement pushed successfully' : ''"
                    :offset="[0, 8]"
                    :delay="[320, 0]"
                >
                    <CheckIcon animated small color="green" />
                </Tooltip>
            </div>

            <!-- Skipped -->
            <div v-if="iconState === 'skipped'">
                <Tooltip
                    :content="'This improvement was skipped because it requires additional user input to be completed'"
                    :max-width="206"
                    :offset="[0, 8]"
                    :delay="[320, 0]"
                >
                    <WarnIcon animated small color="gray" />
                </Tooltip>
            </div>

            <!-- Failed -->
            <div v-if="iconState === 'failed'">
                <Tooltip
                    :content="errorMessage"
                    :max-width="206"
                    :offset="[0, 8]"
                    :delay="[320, 0]"
                >
                    <WarnIcon animated small />
                </Tooltip>
            </div>

            <!-- Dismissed -->
            <div v-if="iconState === 'dismissed'">
                <Tooltip
                    :content="aboveMobile ? 'Improvement dismissed' : ''"
                    :offset="[0, 8]"
                    :delay="[320, 0]"
                >
                    <DismissIcon animated small color="gray" />
                </Tooltip>
            </div>
        </IconSlot>
    </div>
</template>

<script setup lang="ts">
import { computed } from 'vue'

import {
    Spinner,
    CheckIcon,
    WarnIcon,
    DismissIcon,
    Checkbox,
    Tooltip,
    IconSlot,
} from '@opteo/components-next'

import useMediaQuery from '@/composition/global/useMediaQuery'
import { useBatchBox } from '@/composition/improvement/useBatchBox'

import type { QueuedImprovement } from '@/composition/improvement/useImprovementQueue'
import type { EnhancedImprovement } from '@/composition/improvement/types'

type Props = {
    improvement: EnhancedImprovement
    queuedImprovement: QueuedImprovement | undefined
    batchQueueRunning: boolean
}

const props = defineProps<Props>()

const { toggleSelection } = useBatchBox()
const { aboveMobile } = useMediaQuery()

const iconState = computed(() => {
    if (!props.queuedImprovement) {
        return props.improvement.requires_adjust
            ? 'unselected_not_batchable'
            : 'unselected_batchable'
    }

    if (props.queuedImprovement.state === 'idle' || props.queuedImprovement.state === 'waiting') {
        return 'selected'
    }

    return props.queuedImprovement.state
})

const errorMessage = computed(() => props.queuedImprovement?.error ?? 'Unknown Error')

function select(event: { shiftKey: boolean }) {
    if (props.batchQueueRunning) {
        // Don't allow toggling while a queue is already running
        return
    }

    if (props.queuedImprovement && props.queuedImprovement.state !== 'idle') {
        // Don't allow toggling for non-idle rows
        return
    }

    const shouldSelect = iconState.value !== 'selected'

    toggleSelection(props.improvement.improvement_id, shouldSelect, event.shiftKey)
}
</script>

<style scoped lang="scss"></style>
