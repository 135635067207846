<template>
    <Container :maxWidth="512" :borderRadius="22">
        <Stack direction="column" align="start" :gap="16" :padding="[18, 22, 22]">
            <Text v-if="currentPlan" size="body-small" color="red">
                <span v-if="creditCard?.last4">
                    There seems to be an issue with your payment card ending in
                    <b>{{ creditCard?.last4 }}</b
                    >.
                </span>
                Please update your payment method to continue using Opteo. Because your account is
                past due, your payment method will be charged
                <b><Money :value="currentPlan.price" :currency="currentPlanCurrency" /></b> upon
                completion.
            </Text>
            <Stack direction="row" :gap="8">
                <Button @click="toggleSelectPlanModal" size="small" color="secondary">
                    Change Plan
                </Button>
                <Button @click="emit('update-payment-method')" size="small" color="red">
                    Update Payment Method
                </Button>
            </Stack>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Composition
import { useSubscription } from '@/composition/billing/useSubscription'
import { useUser } from '@/composition/user/useUser'
import { usePlanSelectionModal } from '@/composition/billing/usePlanSelectionModal'

// Components
import { Container, Stack, Text, Money, Button } from '@opteo/components-next'

// Emits
const emit = defineEmits(['update-payment-method'])

// Setup
const { creditCard } = useSubscription()
const { currentPlan, currentPlanCurrency } = useUser()
const { toggleSelectPlanModal } = usePlanSelectionModal()
</script>

<style scoped lang="scss"></style>
