<template>
    <Stack direction="column" align="center" justify="center" :gap="0">
        <!-- Preview -->
        <Stack :padding="[32]" direction="column" justify="center" :gap="24" borderBottom>
            <Stack v-if="newAccountName" :gap="12" align="center" justify="center">
                <Avatar
                    :color="selectedColor"
                    :initials="accountInitials"
                    :size="28"
                    :platform="accountPlatform"
                    show-account-type
                />
                <Text :color="selectedColor" :wrap="false" truncate as="span" size="headline-six">
                    {{ newAccountName }}
                </Text>
            </Stack>
            <Stack v-else :gap="12" align="center" justify="center">
                <Skeleton :width="28" :height="28" />
                <Skeleton :width="80" :height="12" />
            </Stack>
        </Stack>
        <!-- Form -->
        <Form @submitted="updateAccountProfile">
            <Stack
                width="100%"
                direction="column"
                align="center"
                :borderBottom="showUpdateButton"
                :gap="20"
                :padding="[20, 22, 22, 22]"
            >
                <TextInput
                    ref="accountNameInput"
                    :model-value="accountName"
                    label="Account Name"
                    name="accountName"
                    @update:modelValue="newAccountName = $event"
                    :validator="nameValidator"
                    :spellcheck="false"
                    autocomplete="off"
                />
                <!-- Color -->
                <SelectableList
                    type="color"
                    v-model="selectedColor"
                    name="account-color"
                    fullWidth
                    label="Account Colour"
                />
            </Stack>
            <Stack v-if="showUpdateButton" align="center" justify="center" :padding="[22, 0]">
                <Button
                    ref="updateAccountProfileButton"
                    :loading="updatingAccountProfile"
                    size="small"
                    color="secondary-highlight"
                    submit
                >
                    Update Account Profile
                </Button>
            </Stack>
        </Form>
    </Stack>
</template>

<script lang="ts" setup>
// Imports
import { computed, ref, watch } from 'vue'

// Composition
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { useAccountList } from '@/composition/user/useAccountList'
import { useAccount } from '@/composition/account/useAccount'
import { mapHexToAvatarColor, mapAvatarColorToHex } from '@/composition/utils/utils'

// Components
import {
    Stack,
    Text,
    TextInput,
    Avatar,
    Button,
    Form,
    SelectableList,
    showSnackbar,
    Skeleton,
} from '@opteo/components-next'

// Props
defineProps<{ showUpdateButton?: boolean }>()

// Setup
const { mutateAccount, accountColor, accountName, accountInitials, accountPlatform } = useAccount()
const { mutateDomainList } = useAccountList()
const accountNameInput = ref()
const updateAccountProfileButton = ref()

// Set default values
const newAccountName = ref(accountName.value)

// selectedColor
const selectedColor = ref(mapHexToAvatarColor(accountColor.value))
// const updateSelectedColor = (color: string[]) => {
//     selectedColor.value = color
// }
watch(accountColor, newColor => {
    selectedColor.value = mapHexToAvatarColor(newColor)
})

// loading
const updatingAccountProfile = ref(false)

// Computed
const accountProfileInvalid = computed(() => {
    return !accountNameInput.value?.state.valid || !selectedColor.value || !accountName.value
})

// Functions
function nameValidator(name: string) {
    const strippedName = name.trim()
    if (!strippedName) return 'Account name is required'
}

async function updateAccountProfile() {
    if (accountProfileInvalid.value) {
        showSnackbar({
            message: 'Please ensure the account name is valid and a color has been selected.',
        })
        throw new Error('Invalid account name or colour')
    }

    if (
        newAccountName.value === accountName.value &&
        mapAvatarColorToHex(selectedColor.value) === accountColor.value
    ) {
        showSnackbar({
            message: 'Account Profile updated successfully',
        })
        return
    }

    updatingAccountProfile.value = true

    await Promise.all([
        newAccountName.value && newAccountName.value !== accountName.value
            ? authRequest(Endpoint.SaveAccountDisplayName, {
                  body: { display_name: newAccountName.value.trim() },
              })
            : undefined,
        mapAvatarColorToHex(selectedColor.value) !== accountColor.value
            ? authRequest(Endpoint.SaveAccountColorPreference, {
                  body: { color_preference: mapAvatarColorToHex(selectedColor.value) },
              })
            : undefined,
    ])

    await mutateAccount()
    updatingAccountProfile.value = false

    showSnackbar({
        message: 'Account Profile updated successfully',
    })
    // Refresh account list so it includes the new name
    mutateDomainList()
}

// This is needed to trigger the function from the parent component
defineExpose({
    updateAccountProfile,
})
</script>

<style scoped lang="scss"></style>
