<template>
    <aside class="user-sidebar">
        <Stack direction="column" align="start" :gap="8">
            <!-- Accounts -->
            <UserSidebarButton @click="goToAccounts" :active="isActive(Routes.AccountCentre).value">
                <OpteoLogo :width="36" :height="36" />
            </UserSidebarButton>

            <!-- Chat -->
            <UserSidebarButton @click="goToChat" :active="isActive(Routes.Chat).value">
                <UserSidebarIconChat />
            </UserSidebarButton>

            <!-- Reports -->
            <UserSidebarButton @click="goToReports" :active="isActive(Routes.Reports).value">
                <UserSidebarIconReports />
            </UserSidebarButton>

            <!-- Basecamp -->
            <UserSidebarButton @click="goToBasecamp" :active="isActive(Routes.Basecamp).value">
                <UserSidebarIconBasecamp />
            </UserSidebarButton>
        </Stack>
        <Stack direction="column" align="start" :gap="8">
            <!-- Color Mode -->
            <UserSidebarButton @click="next()" :active="false">
                <UserSidebarIconLight v-if="mode === 'dark'" />
                <UserSidebarIconDark v-else />
            </UserSidebarButton>

            <!-- LinkedAccounts -->
            <UserSidebarButton
                @click="goToLinkedAccounts"
                :active="isActive(Routes.LinkedAccounts).value"
            >
                <UserSidebarIconLinkedAccounts />
            </UserSidebarButton>

            <!-- Alerts -->
            <UserSidebarButton @click="goToAlerts" :active="isActive(Routes.Alerts).value">
                <Count
                    v-if="unreadCount > 0"
                    :value="unreadCount"
                    :size="unreadCount > 99 ? 'small' : 'medium'"
                    :class="[{ wide: unreadCount > 99 }, 'alerts-count']"
                    animated
                />
                <UserSidebarIconAlerts />
            </UserSidebarButton>

            <!-- Account -->
            <UserSidebarButton
                @click="goToUserSettings"
                :active="isActive(Routes.UserSettings).value"
            >
                <ProfileImage
                    :size="32"
                    :imageUrl="profileImageUrl"
                    :boringAvatar="currentUserAvatarUrl"
                />
            </UserSidebarButton>
        </Stack>
    </aside>
</template>

<script setup lang="ts">
// Imports
import { computed, watchEffect } from 'vue'
import { useColorMode, useCycleList } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { useAlerts } from '@/composition/alerts/useAlerts'

// Composition
import { layout } from '@/composition/layouts/useLayouts'
import { useUser } from '@/composition/user/useUser'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { useSubscription } from '@/composition/billing/useSubscription'

// Components
import { OpteoLogo, Stack, ProfileImage, Count } from '@opteo/components-next'
import UserSidebarButton from './components/UserSidebarButton.vue'
import UserSidebarIconChat from './components/UserSidebarIconChat.vue'
import UserSidebarIconReports from './components/UserSidebarIconReports.vue'
import UserSidebarIconBasecamp from './components/UserSidebarIconBasecamp.vue'
import UserSidebarIconLinkedAccounts from './components/UserSidebarIconLinkedAccounts.vue'
import UserSidebarIconAlerts from './components/UserSidebarIconAlerts.vue'

import UserSidebarIconLight from './components/UserSidebarIconLight.vue'
import UserSidebarIconDark from './components/UserSidebarIconDark.vue'

// Setup
const { isBillingLocked } = useSubscription()
const { profileImageUrl } = useUser()
const { currentUserAvatarUrl } = useBoringAvatar()
const router = useRouter()
const { unreadCount } = useAlerts()

// colorMode
const mode = useColorMode({ disableTransition: false })
const { state, next } = useCycleList(['dark', 'light'] as const, { initialValue: mode })
watchEffect(() => (mode.value = state.value))

// Functions
function isActive(routeName: string) {
    return computed(() => {
        const currentRoute = router.currentRoute.value
        return (
            currentRoute.name === routeName ||
            currentRoute.path.includes(`/${routeName.toLowerCase()}`)
        )
    })
}
function goToAccounts() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.AccountCentre })
    }
}
function goToChat() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Chat })
    }
}
function goToReports() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Reports })
    }
}
function goToBasecamp() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Basecamp })
    }
}
function goToLinkedAccounts() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.LinkedAccounts })
    }
}
function goToAlerts() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Alerts })
    }
}
function goToUserSettings() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.UserSettings })
    }
}
</script>

<style scoped lang="scss">
.user-sidebar {
    padding: 0.625rem;
    @include br;
    height: 100dvh;
    flex-shrink: 0;
    @include flex;
    @include direction-column;
    @include justify-between;
    gap: 0.5rem;
    @include overflow-hidden;
    width: 5.0625rem;
    @include border-background;
    @include bg-background;
}
.alerts-count {
    @include absolute;
    top: 0.625rem;
    right: 0.625rem;
}
.alerts-count.wide {
    right: 0.375rem;
}
</style>
