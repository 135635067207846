import { computed, ComputedRef } from 'vue'
import addDates from 'date-fns/add'
import formatDate from 'date-fns/format'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { useUser } from '@/composition/user/useUser'
import { useLogin } from '@/composition/auth/useLogin'
import { showSnackbar, hideSnackbar } from '@opteo/components-next'
import { Routes } from '@/router/routes'
import { useRouter } from 'vue-router'
import { Endpoint, authRequest } from '@/composition/api/useAPI'
import { pushToDatalayer } from '@/lib/datalayer'
import { Billing } from '@opteo/types'

function generateTrialEndDate(): string {
    const trialEndDate = addDates(new Date(), { days: Billing.FreeTrialLengthInDays ?? 14 })
    const month = formatDate(trialEndDate, 'MMMM')
    const day = formatDate(trialEndDate, 'do')
    const year = formatDate(trialEndDate, 'yyyy')

    return `${month} ${day} ${year}`
}

interface UseNewPaymentMethod {
    trialEndDate: string
    monthlyFee: ComputedRef<number>
    showNewMessage: (message?: string | undefined) => any
    onSuccess: (paymentMethod: string) => Promise<void>
    logout: () => void
    currentPlan: ComputedRef<Billing.CurrentPlan | undefined>
}

export function useNewPaymentMethod(): UseNewPaymentMethod {
    const trialEndDate = generateTrialEndDate()

    const { showNewMessage } = useIntercom()
    const { currentPlan, userId, mutateUserInfo } = useUser()
    const { logout } = useLogin()
    const router = useRouter()

    const monthlyFee = computed(() => currentPlan.value?.price ?? 0)

    async function onSuccess(paymentMethod: string) {
        await authRequest(Endpoint.UpdateCustomerCc, {
            body: { token: paymentMethod },
        })
        // await authRequest(Endpoint.PushBackTrialEnd) // Seemingly deprecated?
        await mutateUserInfo()
        pushToDatalayer('gtm_added_cc')
        router.push({ name: Routes.AccountCentreInit, params: { id: userId.value } })
    }

    return { trialEndDate, monthlyFee, showNewMessage, onSuccess, logout, currentPlan }
}
