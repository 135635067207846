<template>
    <FloatingFooter v-model="setupActive" :width="560" :padding="[12, 14, 12, 16]">
        <!-- Onboarding -->
        <OnboardingModal
            :modelValue="showOnboarding"
            :style="onboardingStyles"
            :maxWidth="506"
            mode="bar"
            buttonText="Okay, thanks"
            @update:modelValue="handleUpdate"
        >
            <template #copy>
                <b>Account Setup</b> is a quick and easy way to set up your marketing accounts with
                Opteo. Fill out the form on this page and click <b>Continue to Targets</b> to
                continue.
            </template>
        </OnboardingModal>
        <!-- Avatar -->
        <Stack :gap="12" align="center" width="100%">
            <Avatar
                v-if="accountColor"
                :color="mapHexToAvatarColor(accountColor)"
                :width="36"
                :height="36"
                show-account-type
                :platform="accountPlatform"
            >
                {{ accountInitials }}
            </Avatar>
            <Skeleton v-else :width="28" :height="28" />
            <!-- Skip -->
            <Button color="floating-footer" size="extra-small" @click="skipAccountSetup">
                Skip Account Setup
            </Button>
        </Stack>
        <!-- <Stack width="100%" justify="center">
            <Steps v-model="activeStepKey" :items="steps" />
        </Stack> -->
        <!-- Buttons -->
        <Stack :gap="8" align="center" width="auto">
            <Button
                ref="nextStepButton"
                v-if="currentStep?.key === 'account-setup'"
                @click="goToCampaignGroups"
                iconAfter="ArrowUpRight"
                :loading="stepValidators[currentStep.key]?.loading"
            >
                Continue to Targets
            </Button>
            <Button
                v-if="currentStep?.key === 'campaign-groups'"
                @click="completeAccountSetup"
                iconAfter="ArrowUpRight"
            >
                Complete Account Setup
            </Button>
        </Stack>
    </FloatingFooter>
</template>

<script setup lang="ts">
// Imports
import { ref, onMounted } from 'vue'
import { useLocalStorage } from '@vueuse/core'
import { ALWAYS_SHOW_ONBOARDING } from '@/lib/env'

// Composition
import { useAccountSetupBar } from '@/composition/account/useAccountSetupBar'
import { mapHexToAvatarColor } from '@/composition/utils/utils'

// Components
import {
    Button,
    Avatar,
    Skeleton,
    FloatingFooter,
    Stack,
    OnboardingModal,
    // Steps,
} from '@opteo/components-next'

// Setup
const {
    setupActive,
    currentStep,
    skipAccountSetup,
    completeAccountSetup,
    goToCampaignGroups,
    // steps,
    // activeStepKey,
    stepValidators,
    nextStepButton,
    accountInitials,
    accountColor,
    accountPlatform,
} = useAccountSetupBar()

// onboardingStyles
const onboardingStyles = ref({
    position: 'absolute',
    top: '-0.375rem',
    left: '50%',
    transform: 'translate(-50%, -100%)',
})

// Only show Account Setup onboarding once
const showAccountSetupOnboarding = useLocalStorage<boolean>('showAccountSetupOnboarding', true)

// Delay onboardingModal
const showOnboarding = ref(false)
onMounted(() => {
    if (showAccountSetupOnboarding.value) {
        setTimeout(() => {
            showOnboarding.value = true
        }, 500)
    }
})

// handleUpdate
const handleUpdate = () => {
    if (ALWAYS_SHOW_ONBOARDING) {
        // Do not trigger `localStorage`, hide `onboardingModal`
        showAccountSetupOnboarding.value = true
        showOnboarding.value = false
    } else {
        // Set `localStorage` to false, hide `onboardingModal`
        showAccountSetupOnboarding.value = false
        showOnboarding.value = false
    }
}
</script>

<style scoped lang="scss"></style>
