<template>
    <ClickableRow
        :padding="above480 ? [15, 24, 15, 24] : [15, 18]"
        @click="goToAccount"
        :hoverable="hoverable"
    >
        <!-- Account -->
        <AccountsRowAccount :account="account" />

        <!-- Right -->
        <Stack v-if="!isError" :gap="12" width="auto">
            <!-- Spend Sparkline -->
            <AccountsRowSparkline
                v-if="above960"
                label="Spend"
                format="money"
                :chart="spendChart"
                :loading="loadingMetrics"
                :width="284"
                :labelSkeletonWidth="32"
                :valueSkeletonWidth="48"
                :currency="account.currencyCode"
            />
            <!-- Conv. Sparkline -->
            <AccountsRowSparkline
                v-if="above1280"
                label="Conv."
                format="number"
                :chart="convChart"
                :loading="loadingMetrics"
                :width="262"
                :labelSkeletonWidth="30"
                :valueSkeletonWidth="36"
            />
            <!-- Issues -->
            <AccountsRowIssues
                v-if="above640"
                :issues="issues"
                :lastChecked="new Date(Date.now() - 4 * 60 * 60 * 1000)"
                :loading="loadingMetrics"
            />
            <!-- Budget -->
            <AccountsRowBudget :budget="budgetBar" :account="account" :loading="loadingMetrics" />
            <!-- Goal -->
            <AccountsRowGoal
                v-if="above640"
                :goalProgress="0.32"
                :goalDifference="0.2"
                :goalStatus="`ahead`"
                :currentValue="3034950.34"
                :primaryGoal="1"
                :goalCycle="2"
                :goalAmount="8500000"
                :account="account"
                :currency="account.currencyCode"
                :loading="loadingMetrics"
            />
            <!-- Account Group, Account Manager -->
            <Stack v-if="above480" :gap="10" width="auto">
                <AccountsRowGroup
                    :accountGroups="accountGroups"
                    :selectedGroup="groupId"
                    :loading="loadingMetrics"
                    :account="account"
                />
                <AccountsRowManager
                    :teamMembers="teamMembers"
                    :selectedManager="selectedTeamMember"
                    :loading="loadingMetrics"
                    :account="account"
                />
            </Stack>
        </Stack>

        <!-- Error -->
        <Stack v-else :gap="0" width="auto">
            <Text
                v-if="above480"
                as="span"
                color="foreground-minus-three"
                size="body-small"
                :wrap="false"
            >
                Unable to fetch data for this account
            </Text>
            <WarnIcon v-else color="gray" translucent />
        </Stack>
    </ClickableRow>
</template>

<script setup lang="ts">
// Imports
import { computed, PropType, ref } from 'vue'
import { ALWAYS_SHOW_ONBOARDING } from '@/lib/env'
import { getPlatformFromId } from '@/lib/globalUtils'
import { Routes } from '@/router/routes'
import { useRouter } from 'vue-router'

// Composition
import { useDomainListRow } from '@/composition/user/useDomainListRow'
import { useTeam } from '@/composition/user/useTeam'

// Types
import { Account, Platform } from '@opteo/types'

// Components
import { Stack, ClickableRow, useMediaQuery, Text, WarnIcon } from '@opteo/components-next'
import AccountsRowAccount from '@/components/accounts/AccountsRow/AccountsRowAccount.vue'
import AccountsRowSparkline from '@/components/accounts/AccountsRow/AccountsRowSparkline.vue'
import AccountsRowIssues from '@/components/accounts/AccountsRow/AccountsRowIssues.vue'
import AccountsRowBudget from '@/components/accounts/AccountsRow/AccountsRowBudget.vue'
import AccountsRowGoal from '@/components/accounts/AccountsRow/AccountsRowGoal.vue'
import AccountsRowGroup from '@/components/accounts/AccountsRow/AccountsRowGroup.vue'
import AccountsRowManager from '@/components/accounts/AccountsRow/AccountsRowManager.vue'

// Props
const props = defineProps({
    account: {
        type: Object as PropType<any>,
        // type: Object as PropType<Account.Info>,
        required: true,
    },
    groupId: {
        type: String as PropType<string>,
        required: false,
        default: 'ungrouped',
    },
    hoverable: {
        type: Boolean,
        required: false,
        default: true,
    },
})

// Setup
const { push } = useRouter()
const { above480, above640, above960, above1280 } = useMediaQuery()
const {
    loadingMetrics,
    spendChart,
    convChart,
    budgetBar,
    rowIsVisible,
    accountCentreRow,
    isError,
} = useDomainListRow(ref(props.account.accountId))
const { team } = useTeam()

// Computed
const platform = computed(() => {
    return getPlatformFromId(props?.account?.accountId)
})

// Functions
const goToAccount = () => {
    // 1) No accountId?
    if (!props.account?.accountId) {
        console.warn('No accountId')
        return
    }

    // 2) LinkedIn + Meta route
    if (
        platform.value &&
        [Platform.Platform.MetaAds, Platform.Platform.LinkedInAds].includes(platform.value)
    ) {
        return push({
            name: Routes.Performance,
            params: {
                accountId: props.account.accountId,
            },
        })
    }

    // 3) Account Setup route
    if (ALWAYS_SHOW_ONBOARDING || !props.account.accountSetupSeen) {
        return push({
            name: Routes.Setup,
            params: {
                accountId: props.account.accountId,
            },
        })
    }

    // 4) Otherwise, Account route
    return push({
        name: Routes.Account,
        params: {
            accountId: props.account.accountId,
        },
    })
}

// Temporary
const issues = ref([
    { label: 'Spend Flatlines', value: 'flatline', count: 0 },
    { label: 'Conversion Action Issues', value: 'actions', count: 0 },
    { label: 'Broken Links', value: 'brokenLink', count: 0 },
    { label: 'Disapprovals', value: 'disapproval', count: 0 },
    { label: 'Empty Campaigns', value: 'emptyCampaign', count: 0 },
    { label: 'Empty Ad Groups', value: 'emptyAdGroup', count: 0 },
    { label: 'Spelling Mistakes', value: 'spelling', count: 0 },
])

// accountGroups (Temporary)
const accountGroups = [
    { label: 'Ungrouped', value: 'ungrouped', color: 'gray' },
    { label: 'Chime', value: 'zq387qxi', color: 'teal' },
    { label: 'Discord', value: 'rv5p69mu', color: 'purple' },
    { label: 'Peloton', value: 'f430f9kf', color: 'pink' },
    { label: 'Intercom', value: 'p54938t3', color: 'blue' },
    { label: 'Mastercard', value: '4po32giv', color: 'orange' },
]

// teamMembers
const selectedTeamMember = ref(String(props.account.accountManagerUserId))
const teamMembers = computed(() => {
    if (!team.value) return [{ label: 'Unassigned', value: 'unassigned', noImage: true }]

    return [
        {
            label: 'Unassigned',
            value: 'unassigned',
            noImage: true,
        },
        ...team.value.map(({ user_id, name, avatar_filename }) => ({
            label: name,
            value: `${user_id}`,
            boringAvatar: `https://api.opteo.io/render/avatar.svg?userId=${user_id}`,
            imageUrl: avatar_filename || undefined,
        })),
    ]
})
</script>

<style scoped lang="scss"></style>
