<template>
    <Stack direction="column" align="start" :padding="[20, 22, 18, 22]" :gap="14">
        <Stack direction="column" :gap="0" align="start">
            <Label>Conversion Actions</Label>
            <Button
                class="conversion-actions-button"
                @click="showConversionActionPanel = true"
                color="secondary"
                iconAfter="Pencil"
                :fullWidth="true"
            >
                {{ enabledConversionActions?.length ?? 0 }} Selected
            </Button>
        </Stack>
        <Text as="p" size="body-extra-small" color="foreground-minus-one">
            Opteo will optimise towards the conversion actions you select here. Please select the
            actions that most accurately represent success for your account as a whole, excluding
            any unused or deprecated actions.
        </Text>
    </Stack>

    <!-- Conversion Actions Side Panel -->
    <teleport to="body">
        <Panel v-model="showConversionActionPanel" title="Conversion Actions" :width="768">
            <template #titleSuffix>
                <Container :padding="[7, 12]" :gap="0" :borderRadius="10" hideOverflow>
                    <Text as="p" color="foreground-minus-one" size="body-extra-small">
                        All Conversion Actions data taken from the past 90 days
                    </Text>
                </Container>
            </template>

            <template #content>
                <OnboardingModal
                    v-model="panelDescription"
                    expandable
                    :animated="false"
                    :visibleLines="3"
                >
                    <template #copy>
                        <b>Select your primary conversion actions.</b> Primary conversion actions
                        represent the most important outcomes for your business and are usually
                        directly responsible for revenue. Opteo will optimise towards the conversion
                        actions selected below, attempting to generate more of these conversions at
                        a below-target rate.
                        <Spacer :height="16" />
                        Secondary conversion actions represent goals for your business that are
                        <b>not</b> directly responsible for revenue. An example of a secondary
                        conversion action might be a website visitor adding their email address to
                        your marketing list. If you intend to generate email signups, you may want
                        to select these actions.
                    </template>
                </OnboardingModal>
                <Spacer :height="24" />
                <Table
                    :columns="conversionActionTableHeaders"
                    :columnWidths="{
                        action: '100%',
                        conversions: '98px',
                        conversionValue: '106px',
                        type: '112px',
                    }"
                    :rows="
                        conversionActionTableItems.length === 0
                            ? conversionActionTableLoadingItems
                            : conversionActionTableItems
                    "
                    :perPage="9"
                    :rowHeight="56"
                    :loading="conversionActionTableItems.length === 0"
                    selectionMode="toggle"
                    @select="handleSelectRow"
                    @select:all="handleSelectAll"
                    :borderRadius="20"
                    layout="fixed"
                />
            </template>
            <template #buttons>
                <Button color="secondary" @click="showConversionActionPanel = false">
                    Cancel
                </Button>
                <Button
                    ref="updateConversionActionsButton"
                    :loading="updatingConversionActions"
                    @click="updateAccountConversionActions"
                >
                    Save Conversion Actions
                    <template #icon>
                        <svg width="12" height="12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M1 11 11 1M11 1H3M11 1v8"
                                stroke="#fff"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                    </template>
                </Button>
            </template>
        </Panel>
    </teleport>
</template>

<script lang="ts" setup>
import { computed, ref, watch } from 'vue'

import {
    Stack,
    Button,
    Text,
    Panel,
    Table,
    OnboardingModal,
    Spacer,
    Label,
    Container,
    showSnackbar,
} from '@opteo/components-next'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { Targets } from '@opteo/types'
import { useAccountList } from '@/composition/user/useAccountList'
import { delay } from '@opteo/promise'
import { useAccount } from '@/composition/account/useAccount'

const { mutateDomainList } = useAccountList()
const { accountId, currencyCode } = useAccount()

const {
    data: accountConversionActions,
    mutate: mutateAccountConversionActions,
    loading: loadingConversionActions,
} = useAPI<Targets.AccountConversionAction[]>(Endpoint.GetAccountConversionActions, {
    uniqueId: () => accountId.value,
    waitFor: () => accountId.value,
})

const updatingConversionActions = ref(false)
const updateConversionActionsButton = ref()

function handleSelectRow(selection) {
    updateConversionAction(selection.actionId, selection.checked)
}
function handleSelectAll(checked: boolean) {
    conversionActionTableItems.value?.forEach(conv => {
        updateConversionAction(conv.actionId, checked)
    })
}

async function updateAccountConversionActions() {
    const conversionActionsNeedUpdating = conversionActionTableItems.value?.filter(ca => {
        const originalCa = accountConversionActions.value?.find(
            oca => oca.conversionActionId === ca.actionId
        )
        return originalCa && originalCa.enabled !== ca.checked
    })

    if (!conversionActionsNeedUpdating?.length) {
        showSnackbar({
            message: `No changes made`,
        })
        await delay(1000)

        showConversionActionPanel.value = false
        return
    }

    updatingConversionActions.value = true

    const enabledConversionIds = conversionActionsNeedUpdating
        .filter(ca => ca.checked)
        .map(ca => ca.actionId)

    const disabledConversionIds = conversionActionsNeedUpdating
        .filter(ca => !ca.checked)
        .map(ca => ca.actionId)

    await Promise.all([
        enabledConversionIds.length
            ? authRequest(Endpoint.UpdateAccountConversionActions, {
                  body: {
                      conversion_action_ids: enabledConversionIds,
                      enabled: true,
                  },
              })
            : undefined,
        disabledConversionIds.length
            ? authRequest(Endpoint.UpdateAccountConversionActions, {
                  body: {
                      conversion_action_ids: disabledConversionIds,
                      enabled: false,
                  },
              })
            : undefined,
    ])

    if (conversionActionsNeedUpdating?.length) {
        await mutateAccountConversionActions()
        await mutateDomainList()
    }

    updatingConversionActions.value = false

    showSnackbar({
        message: `${conversionActionsNeedUpdating?.length} conversion actions updated.`,
    })

    await delay(1000)

    showConversionActionPanel.value = false
}

// Conversion Actions
const showConversionActionPanel = ref(false)

type ConversionActionTableItem = {
    action: { label: string; type: string }
    actionId: string
    checked: boolean
    category: string
    conversions: { value: number }
    conversionValue: { currency: string; value: number }
    type: { label: 'Primary' | 'Secondary' }
}

const conversionActionTableItems = ref<ConversionActionTableItem[]>([])

watch(
    [accountConversionActions, currencyCode],
    ([actions, currCode]) => {
        conversionActionTableItems.value =
            actions?.map(row => {
                const type: 'Primary' | 'Secondary' = row.isPrimary ? 'Primary' : 'Secondary'
                return {
                    action: { label: row.conversionActionName, type: 'ConversionAction' },
                    actionId: row.conversionActionId,
                    checked: !!row.enabled,
                    category: row.conversionActionCategory,
                    conversions: { value: row.conversions ?? 0 },
                    conversionValue: {
                        currency: currCode ?? 'JPY',
                        value: row.conversionsValue ?? 0,
                    },
                    type: { label: type },
                }
            }) ?? []
    },
    { immediate: true }
)

const conversionActionTableLoadingItems = ref<any>([
    ...Array(9)
        .fill(0)
        .map(() => ({
            action: {
                label: 'Conversion Action',
                type: 'ConversionAction',
                skeletonWidth: [200, 256],
            },
            actionId: 345,
            checked: false,
            category: 'string',
            conversions: 45,
            conversionValue: 45,
            type: { skeletonWidth: [56, 62] },
        })),
])

const enabledConversionActions = computed(() =>
    accountConversionActions.value?.filter(item => item.enabled)
)

const conversionActionTableHeaders = [
    {
        key: 'action',
        label: 'Conversion Action',
        type: 'entity',
        sortable: false,
        constrainGradient: true,
    },
    {
        key: 'conversions',
        label: 'Conv.',
        type: 'number',
        sortable: true,
    },
    {
        key: 'conversionValue',
        label: 'Value',
        type: 'money',
        sortable: true,
    },
    {
        key: 'type',
        label: 'Type',
        type: 'text',
        sortable: true,
    },
]

const panelDescription = ref(true)

const updateConversionAction = (conversionActionId: number, value: boolean) => {
    const conversionAction = conversionActionTableItems.value?.find(
        ca => ca.actionId === conversionActionId
    )

    if (!conversionAction) throw new Error('Invalid conversion action')

    conversionAction.checked = value
}
</script>
