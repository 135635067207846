<template>
    <EntityPill :label="label" :type="entityType" size="small" />
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'

// Types
import { EntityType } from '@opteo/components-next/dist/components/labels/EntityPill/types'

// Components
import { EntityPill } from '@opteo/components-next'

const validEntityTypes = [
    'AccountLevel',
    'AdGroup',
    'Ad',
    'AssetGroup',
    'Campaign',
    'CampaignGroup',
    'ConversionAction',
    'Demographic',
    'Device',
    'Experiment',
    'Extension',
    'Keyword',
    'Location',
    'NegativeKeyword',
    'NegativeKeywordList',
    'Ngram',
    'Pause',
    'Placement',
    'PlacementExclusionList',
    'Product',
    'ProductGroup',
    'SearchTerm',
    'Url',
    'Word',
] as const

// Map to normalize API responses
const entityNormalizationMap: Record<string, string> = {
    adgroup: 'ad-group',
    'n-gram': 'ngram',
    'negative-list': 'negative-keyword-list',
}

// Props
const props = defineProps({
    entity: {
        type: String,
        required: true,
        validator: (value: string) => {
            return [
                'account-level',
                'campaign-group',
                'campaign',
                'adgroup',
                'product-group',
                'experiment',
                'keyword',
                'placement',
                'search-term',
                'location',
                'url',
                'word',
                'n-gram',
                'conversion-action',
                'negative-keyword',
                'negative-list',
                'placement-exclusion-list',
            ].includes(value)
        },
    },
    label: {
        type: String,
        required: true,
    },
    charLimit: {
        type: Number,
        required: false,
    },
})

// Utility to normalize and convert snake-case to PascalCase
function snakeToPascalCase(snake: string): EntityType {
    const normalizedSnake = entityNormalizationMap[snake] || snake // Normalize if needed
    const pascal = normalizedSnake
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join('')

    if (validEntityTypes.includes(pascal as EntityType)) {
        return pascal as EntityType
    }

    throw new Error(`Invalid entity: ${snake}`)
}

// Convert the `entity` prop to `EntityType`
const entityType = computed(() => snakeToPascalCase(props.entity))
</script>

<style scoped lang="scss"></style>
