<template>
    <Modal
        v-model="isUserBlocked"
        :closeButton="false"
        :drawer="false"
        :enableTransition="false"
        title="Plan Limit Exceeded"
        :width="512"
    >
        <template #content>
            <Container :borderRadius="20" :minWidth="360" :padding="[18, 22]">
                <Text v-if="isAdmin" as="p" size="body-small">
                    Your total monthly spend increased recently, exceeding your plan limit. You are
                    currently subscribed to the <b>{{ currentPlan?.name }}</b> plan, which includes
                    up to
                    <span v-if="currentPlan?.account_limit">
                        <b>{{ currentPlan?.account_limit }}</b>
                        linked accounts and
                    </span>
                    <b><Money :value="currentPlan?.spend_limit" currency="USD" /></b>
                    total spend per month.
                    <Spacer height="1rem" />
                    You currently have
                    <Text
                        as="span"
                        weight="bold"
                        :color="
                            totalActiveAccounts > currentPlan?.account_limit ? 'red' : 'foreground'
                        "
                    >
                        {{ totalActiveAccounts }}
                    </Text>
                    linked accounts and
                    <Text
                        as="span"
                        weight="bold"
                        :color="totalSpend30d > currentPlan?.spend_limit ? 'red' : 'foreground'"
                    >
                        <Money :value="totalSpend30d" currency="USD" />
                    </Text>
                    total spend per month. Upgrade your plan or unlink some accounts to continue.
                </Text>
                <Text v-else as="p" size="body-small">
                    Your total monthly spend increased recently, exceeding your plan limit. You are
                    subscribed to the
                    <b>{{ currentPlan?.name }}</b> plan, which includes up to
                    <span v-if="currentPlan?.account_limit">
                        <b>{{ currentPlan?.account_limit }}</b> accounts and
                    </span>
                    <b><Money :value="currentPlan?.spend_limit" currency="USD" /></b> total spend.
                    You currently have <b>{{ totalActiveAccounts }}</b> accounts linked to Opteo
                    with a total spend of <b><Money :value="totalSpend30d" currency="USD" /></b>.
                    <br /><br />
                    Contact your administrator ({{ adminEmail }}) to upgrade your pricing plan, or
                    unlink some accounts in Linked Accounts.
                </Text>
            </Container>
        </template>
        <template #buttons>
            <Button :color="isAdmin ? 'secondary' : 'primary'" @click="goToLinkedAccounts">
                Go to Linked Accounts
            </Button>
            <Button v-if="isAdmin" color="primary" @click="goToBilling">
                Go to Billing and Invoices
            </Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { computed, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { StorageSerializers, useLocalStorage } from '@vueuse/core'
import { LS_OVER_LIMIT } from '@/lib/cookies'
import { ENFORCEMENT } from '@/lib/env'

// Composition
import { useUser } from '@/composition/user/useUser'

// Components
import { Modal, Container, Text, Spacer, Money, Button } from '@opteo/components-next'

// enforcementVars
const enforcementVar = ENFORCEMENT
if (typeof enforcementVar === 'undefined') throw new Error('No env variable')
const enforcement = +enforcementVar

// Setup
const route = useRoute()
const router = useRouter()
const { user, isAdmin, currentPlan, totalActiveAccounts, totalSpend30d, userInfo } = useUser()

// Computed
const adminEmail = computed(() => user.value?.profile.admin_email)
const isUserBlocked = computed(() => {
    if (
        route.path.includes('linkedaccounts') ||
        route.path.includes('billing') ||
        route.path.includes('newpaymentmethod') ||
        route.path.includes('init')
    ) {
        return false
    }
    return !!enforcement && user.value?.profile.over_limit
})

// Routing
if (isUserBlocked.value && !route.path.includes('accountcentre')) {
    goToAccounts()
}

// overLimit
const overLimit = useLocalStorage<number>(LS_OVER_LIMIT, enforcement, {
    serializer: StorageSerializers.number,
})
overLimit.value = isUserBlocked.value ? 1 : 0
watch([overLimit, isUserBlocked], () => {
    if (!enforcement) return
    overLimit.value = isUserBlocked.value ? 1 : 0
})

// Functions
function goToLinkedAccounts() {
    router.push({ name: Routes.LinkedAccounts })
}
function goToBilling() {
    router.push({ name: Routes.Billing })
}
function goToAccounts() {
    router.push({ name: Routes.AccountCentre })
}
</script>

<style scoped lang="scss"></style>
