<template>
    <Stack width="100%" :padding="[16, 0, 0, 0]">
        <Stack v-if="slackSettings" direction="column" align="start" :gap="0">
            <Button @click="connectSlack()" size="small" color="secondary" fullWidth>
                <template #before>
                    <SlackLogo :size="12" />
                </template>
                Slack Connected
                <template #after>
                    <Dot :size="10" />
                </template>
            </Button>
            <Spacer :height="14" />
            <Stack direction="column" align="start" :gap="0">
                <Text as="p" size="body-extra-small" color="foreground-minus-one">
                    Connected to <b>{{ slackSettings.channel_name }}</b>
                </Text>
                <Text as="p" size="body-extra-small" color="foreground-minus-one">
                    in the <b>{{ slackSettings.workspace_name ?? 'Opteo' }}</b> workspace.
                </Text>
            </Stack>
            <Spacer :height="14" />
            <Button @click="removeSlack()" size="extra-small" color="secondary">
                Disconnect
            </Button>
        </Stack>
        <Button
            v-else
            @click="connectSlack()"
            size="small"
            color="secondary"
            iconAfter="ArrowUpRight"
            fullWidth
        >
            <template #before>
                <SlackLogo :size="12" />
            </template>
            {{ isDomainSpecificConnection ? 'Connect Slack Channel' : 'Connect Slack' }}
        </Button>
    </Stack>
</template>

<script setup lang="ts">
import { Button, Stack, Text, SlackLogo, Spacer, Dot } from '@opteo/components-next'

import { useSlack } from '@/composition/connectors/useSlack'

interface Props {
    isPopup?: boolean
    isDomainSpecificConnection: boolean
}

const { isPopup = false, isDomainSpecificConnection } = defineProps<Props>()

const { connectSlack, removeSlack, slackSettings } = useSlack({
    isPopup,
    isDomainSpecificConnection,
})
</script>

<style scoped lang="scss"></style>
