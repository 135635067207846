<template>
    <aside v-if="layout === 'default-layout'" class="settings-bottom-bar">
        <div class="inner-wrapper">
            <Container
                :padding="[10, 13, 10, 15]"
                direction="row"
                align="center"
                justify="between"
                :borderRadius="16"
                width="100%"
            >
                <Stack :gap="14" direction="row" align="center">
                    <ProfileImage
                        :size="28"
                        :imageUrl="profileImageUrl"
                        :boringAvatar="currentUserAvatarUrl"
                    />
                    <Text as="h6" size="headline-six" color="foreground">
                        {{ user?.profile.name }}
                    </Text>
                </Stack>
                <Container
                    clickable
                    @click="emit('menu-clicked')"
                    :width="34"
                    :height="34"
                    :borderRadius="10"
                    :color="['primary-translucent', 'background-plus-two']"
                    align="center"
                    justify="center"
                    class="menu-button"
                >
                    <ThreeLines />
                </Container>
            </Container>
        </div>
    </aside>
</template>

<script setup lang="ts">
// Composition
import { layout } from '@/composition/layouts/useLayouts'
import { useUser } from '@/composition/user/useUser'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'

// Components
import { Container, ProfileImage, ThreeLines, Text, Stack } from '@opteo/components-next'

// Emits
const emit = defineEmits(['menu-clicked'])

// Setup
const { profileImageUrl, user } = useUser()
const { currentUserAvatarUrl } = useBoringAvatar()
</script>

<style scoped lang="scss">
.settings-bottom-bar {
    @include w-100;
    padding: 1rem;
    flex-shrink: 0;
    z-index: 1000;
    @include flex;
    @include items-center;
    @include justify-center;
    @include bg-background;
    @include bottom-bar-shadow;
}
.settings-bottom-bar .inner-wrapper {
    @include w-100;
    max-width: 42rem;
    @include flex;
    @include items-center;
    @include justify-between;
}

.light .settings-bottom-bar .menu-button :deep(svg path) {
    fill: $dark-blue;
}
.dark .settings-bottom-bar .menu-button :deep(svg path) {
    fill: $light-0;
}
</style>
