<template>
    <FullScreenOverlay>
        <component v-if="ready" :is="componentName" :key="improvement?.improvement_id">
            <!-- Will show when componentName does not exist -->
            <DefaultTemplate />
        </component>

        <div v-if="impNotFound">
            <ImprovementContainer @close-improvement="handleClose">
                <div class="improvement-empty-state-container">
                    <img src="@/assets/img/improvement-error-state.png" style="height: 155px" />
                    <Spacer height="2rem" />
                    <div class="center" style="max-width: 362px">
                        <Text as="h5" size="f-5" weight="600" align="center">
                            Improvement could not be found
                        </Text>
                        <Spacer height="0.875rem" />
                        <Text as="p" size="f-8" align="center">
                            Your improvement could not be located in our database. Our product team
                            has been notified and will try to resolve the issue. Please close this
                            page and refresh to continue.
                        </Text>
                    </div>
                </div>
            </ImprovementContainer>
        </div>
    </FullScreenOverlay>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'
import camelCase from 'lodash-es/camelCase'
import upperFirst from 'lodash-es/upperFirst'

// Composition
import { useImprovement } from '@/composition/improvement/useImprovement'
import { useLayouts } from '@/composition/layouts/useLayouts'
import { useAccount } from '@/composition/account/useAccount'

// Types
import { Platform } from '@opteo/types'

// Components
import { Text, Spacer } from '@opteo/components-next'
import ImprovementContainer from '@/components/improvement/ImprovementContainer.vue'
import DefaultTemplate from '@/components/improvement/DefaultTemplate.vue'

// Google Ads Improvements
import PauseAd from '@/components/improvement/types/ad-comparison/PauseAd.vue'
import PauseAdV2 from '@/components/improvement/types/ad-comparison-v-2/PauseAdV2.vue'
import WriteAd from '@/components/improvement/types/ad-comparison/WriteAd.vue'
import WriteAdV2 from '@/components/improvement/types/ad-comparison-v-2/WriteAdV2.vue'
import AdjustAdScheduleBids from '@/components/improvement/types/ad-schedule/AdjustAdScheduleBids.vue'
import AdjustAttributionModel from '@/components/improvement/types/attribution-model/AdjustAttributionModel.vue'
import FixBelowFirstPageBid from '@/components/improvement/types/bids-below-first-page/FixBelowFirstPageBid.vue'
import CreateBrandCampaign from '@/components/improvement/types/branding/CreateBrandCampaign.vue'
import RemoveBroadKeywords from '@/components/improvement/types/broad-keywords/RemoveBroadKeywords.vue'
import RemoveBroadKeywordsV2 from '@/components/improvement/types/broad-keywords-v2/RemoveBroadKeywordsV2.vue'
import FixBrokenLink from '@/components/improvement/types/broken-link/FixBrokenLink.vue'
import FixBrokenSitelink from '@/components/improvement/types/broken-link/FixBrokenSitelink.vue'
import LiftCampaignBudgetCap from '@/components/improvement/types/budget-cap/LiftCampaignBudgetCap.vue'
import ReduceCampaignBids from '@/components/improvement/types/budget-cap/ReduceCampaignBids.vue'
import AddCallExtension from '@/components/improvement/types/account-level-extensions/AddCallExtension.vue'
import AddCalloutExtension from '@/components/improvement/types/account-level-extensions/AddCalloutExtension.vue'
import AddSitelinkExtension from '@/components/improvement/types/account-level-extensions/AddSitelinkExtension.vue'
import AddStructuredSnippetsExtension from '@/components/improvement/types/account-level-extensions/AddStructuredSnippetsExtension.vue'
import AddCampaignLevelCalloutExtension from '@/components/improvement/types/campaign-level-extensions/AddCampaignLevelCalloutExtension.vue'
import AddCampaignLevelSitelinkExtension from '@/components/improvement/types/campaign-level-extensions/AddCampaignLevelSitelinkExtension.vue'
import AddCampaignLevelSnippetExtension from '@/components/improvement/types/campaign-level-extensions/AddCampaignLevelSnippetExtension.vue'
import FixNegativesBlockingKeywords from '@/components/improvement/types/check-negatives-blocking-keywords/FixNegativesBlockingKeywords.vue'
import AdjustProductGroupBid from '@/components/improvement/types/check-product-groups/AdjustProductGroupBid.vue'
import ResyncProductGroups from '@/components/improvement/types/check-product-groups/ResyncProductGroups.vue'
import DemoImprovement from '@/components/improvement/types/demo/DemoImprovement.vue'
import AdjustAgeBid from '@/components/improvement/types/demographic-age-mod/AdjustAgeBid.vue'
import AdjustGenderBid from '@/components/improvement/types/demographic-gender-mod/AdjustGenderBid.vue'
import AdjustParentalBid from '@/components/improvement/types/demographic-parental-mod/AdjustParentalBid.vue'
import AdjustDeviceBid from '@/components/improvement/types/device-bids/AdjustDeviceBid.vue'
import AdjustSingleDeviceBid from '@/components/improvement/types/device-bids-v-2/AdjustSingleDeviceBid.vue'
import AdjustMultipleDeviceBids from '@/components/improvement/types/device-bids-v-2/AdjustMultipleDeviceBids.vue'
import AddFrequencyCapping from '@/components/improvement/types/frequency-capping/AddFrequencyCapping.vue'
import AdjustGeoBids from '@/components/improvement/types/geo-bids/AdjustGeoBids.vue'
import AdjustLocationBids from '@/components/improvement/types/location-bids/AdjustLocationBids.vue'
import AdjustKeywordBid from '@/components/improvement/types/keyword-bids/AdjustKeywordBid.vue'
import PauseKeyword from '@/components/improvement/types/keyword-cutoff/PauseKeyword.vue'
import DisableInterestLocations from '@/components/improvement/types/location-interests/DisableInterestLocations.vue'
import DisableInterestLocationsV2 from '@/components/improvement/types/campaign-settings/DisableInterestLocationsV2.vue'
import ExcludeMobileApps from '@/components/improvement/types/mobile-apps-exclusion/ExcludeMobileApps.vue'
import AddNegativeGeoKeyword from '@/components/improvement/types/negative-geo-keywords/AddNegativeGeoKeyword.vue'
import PauseDuplicateKeywords from '@/components/improvement/types/pause-duplicate-keywords/PauseDuplicateKeywords.vue'
import PauseDuplicateKeywordsV2 from '@/components/improvement/types/pause-duplicate-keywords-v2/PauseDuplicateKeywordsV2.vue'
import ExcludePlacement from '@/components/improvement/types/placement-exclusion/ExcludePlacement.vue'
import ExcludeContent from '@/components/improvement/types/content-exclusions/ExcludeContent.vue'
import CreateRemarketingCampaign from '@/components/improvement/types/remarketing/CreateRemarketingCampaign.vue'
import RemovePlussedNegatives from '@/components/improvement/types/remove-plussed-negatives/RemovePlussedNegatives.vue'
import ResyncExclusionLists from '@/components/improvement/types/resync-exclusion-lists/ResyncExclusionLists.vue'
import AddMissingCampaignsToSharedSet from '@/components/improvement/types/resync-shared-set-negative-lists/AddMissingCampaignsToSharedSet.vue'
import AddRobotNegative from '@/components/improvement/types/robot-negatives/AddRobotNegative.vue'
import SearchPartnersSettings from '@/components/improvement/types/search-partners-settings/SearchPartnersSettings.vue'
import DisableSearchPartners from '@/components/improvement/types/search-partners/DisableSearchPartners.vue'
import AddSiteExclusions from '@/components/improvement/types/site-category-exclusions/AddSiteExclusions.vue'
import AddSkagV2 from '@/components/improvement/types/skag/AddSkagV2.vue'
import CheckAdSpelling from '@/components/improvement/types/spell-checker/CheckAdSpelling.vue'
import CheckQueryRelevance from '@/components/improvement/types/sqr/CheckQueryRelevance.vue'
import AddNegativeListShopping from '@/components/improvement/types/sqr-shopping/AddNegativeListShopping.vue'
import AdjustSinglePlacementDemoBid from '@/components/improvement/types/demo-bids/AdjustSinglePlacementDemoBid.vue'
import ExcludeSingleDevice from '@/components/improvement/types/device-bids-v-2/ExcludeSingleDevice.vue'
import ExcludeSingleDemographic from '@/components/improvement/types/demo-bids/ExcludeSingleDemographic.vue'
import AdjustMultiplePlacementDemoBids from '@/components/improvement/types/demo-bids/AdjustMultiplePlacementDemoBids.vue'
import AdjustKeywordBidV2 from '@/components/improvement/types/keyword-bids-v2/AdjustKeywordBidV2.vue'
import IncreaseKeywordBidV2 from '@/components/improvement/types/keyword-bids-v2/IncreaseKeywordBidV2.vue'
import PauseKeywordV2 from '@/components/improvement/types/keyword-bids-v2/PauseKeywordV2.vue'
import AddNegativeNgram from '@/components/improvement/types/ngram/AddNegativeNgram.vue'
import ReduceNgramBids from '@/components/improvement/types/ngram/ReduceNgramBids.vue'
import AdjustAdGroupMultiTargets from '@/components/improvement/types/target-adjustments/AdjustAdGroupMultiTargets.vue'
import ExcludeLocation from '@/components/improvement/types/exclude-location/ExcludeLocation.vue'

// Microsoft Ads Improvements
import CheckQueryRelevanceMs from '@/components/improvement/types/sqr/CheckQueryRelevance.ms.vue'
import PauseKeywordMs from '@/components/improvement/types/keyword-bids-v2/PauseKeywordV2.ms.vue'
import AdjustKeywordBidMs from '@/components/improvement/types/keyword-bids-v2/AdjustKeywordBidV2.ms.vue'
import IncreaseKeywordBidMs from '@/components/improvement/types/keyword-bids-v2/IncreaseKeywordBidV2.ms.vue'
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'

// Setup
const { improvement, impNotFound, handleClose } = useImprovement()
const { currencyCode, accountPlatform } = useAccount()

const componentName = computed(() => {
    const componentName = upperFirst(camelCase(improvement.value?.rec_action))

    return componentName
})

const ready = computed(() => improvement.value?.rec_action && currencyCode.value)
</script>

<style scoped lang="scss"></style>
