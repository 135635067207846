<template>
    <PageHeader title="Performance">
        <template #titleSuffix>
            <PageSwitcher :pages="pageSwitcherPages" />
        </template>
        <template #right>
            <Button color="secondary" iconBefore="Lightning" iconAfter="CaretDown" size="small">
                48 Improvement Types
            </Button>
            <Button color="secondary" iconBefore="Loaders" size="small">Targets</Button>
        </template>
    </PageHeader>
    <!-- <PageHeader page-title="Performance" :no-margin="!aboveMobile">
        <template #middle-content>
            <Tabs :links="pageSelectorLinks">
                <template #graphs>Graphs</template>
                <template #segments>Segments</template>
                <template #table>Table</template>
            </Tabs>
        </template>

        <template #right-content>
            <div v-if="routeName === Routes.PerformanceSegments">
                <Select
                    v-model="lookbackWindowSelectValue"
                    :options="[
                        { value: '30', label: 'Last 30 Days' },
                        { value: '90', label: 'Last 90 Days' },
                        { value: '180', label: 'Last 180 Days' },
                    ]"
                />
            </div>
            <div v-else class="date-range-slot-inner">
                <DateRange
                    :presetDates="{ start: startDate, end: endDate }"
                    @apply="applyDateRange"
                    :disable-popout="!aboveMobile ? true : false"
                    :wrapper-classes="!aboveMobile ? 'inline-date-range-popout' : ''"
                >
                    <template v-if="!aboveMobile" #popout="{ api }">
                        <div class="mobile-datepicker">
                            <div v-if="api" class="buttons">
                                <Label>View Last</Label>
                                <template
                                    v-for="(
                                        { days, label, selected }, index
                                    ) in api.presetDateRanges"
                                    :key="days"
                                >
                                    <oButton
                                        color="white"
                                        @clicked="api.selectRange(days)"
                                        :class="{
                                            'preset-active': selected.value,
                                        }"
                                        fullWidth
                                        >{{ label }}</oButton
                                    >
                                    <Spacer
                                        v-if="index !== api.presetDateRanges.length - 1"
                                        height="0.75rem"
                                    />
                                </template>
                            </div>
                            <div class="apply">
                                <oButton color="white" @clicked="api.closePopout(false)"
                                    >Close</oButton
                                >
                                <oButton color="blue" @clicked="api.applyDateRange(true)"
                                    >Apply</oButton
                                >
                            </div>
                        </div>
                    </template>
                </DateRange>
            </div>
            <Spacer width="1rem" class="spacer" v-if="aboveMobile" />
            <ExpandableSidebarButton @clicked="toggleSidebarOpen" class="expandable-sidebar" />
        </template>
    </PageHeader> -->

    <!-- <ExpandableSidebar v-model="sidebarOpen" title="Performance">
        <div class="performance-sidebar">
            <div v-if="routeName === Routes.PerformanceGraphs">
                <Label>Graphs View</Label>
                <oInput
                    v-model="selectedTimePeriod"
                    :button-mode="true"
                    :items="timePeriods"
                    layout="vertical"
                    name="radio"
                    type="radio"
                />
                <Spacer height="1.5rem" />

                <div v-if="aboveMobile">
                    <Label>Graphs Layout</Label>
                    <oInput
                        v-model="selectedLayoutOption"
                        :button-mode="true"
                        :items="layoutOptions"
                        layout="vertical"
                        name="radio"
                        type="radio"
                    />
                    <Spacer height="1.5rem" />
                </div>
            </div>

            <div
                v-if="
                    routeName === Routes.PerformanceGraphs || routeName === Routes.PerformanceTable
                "
            >
                <Label>Campaigns ({{ selectedCampaignCount }})</Label>
                <div
                    v-for="(channel, index) in channels"
                    :key="index"
                    class="performance-sidebar-campaigns"
                >
                    <Tooltip
                        :offset="[0, -10]"
                        :content="channel.channel.length > 36 ? channel.channel : ''"
                        :max-width="600"
                    >
                        <oInput
                            :modelValue="entireChannelSelected(channel.channel)"
                            type="checkbox"
                            :button-mode="true"
                            :label="channel.channel"
                            @click="toggleChannel(channel.channel)"
                            class="campaign-channel"
                        />
                    </Tooltip>
                    <div
                        v-for="(campaign, campaignIndex) in channel.campaigns"
                        :key="campaignIndex"
                        class="performance-sidebar-campaigns"
                    >
                        <Tooltip
                            :content="campaign.name.length > 36 ? campaign.name : ''"
                            :max-width="600"
                        >
                            <oInput
                                :modelValue="campaign.selected"
                                type="checkbox"
                                :button-mode="true"
                                :label="campaign.name"
                                @click="toggleCampaign(campaign.id)"
                            />
                        </Tooltip>
                    </div>
                </div>
                <Spacer height="0.875rem" />
                <Label>Conversion Types ({{ selectedConversionActionCount }})</Label>
                <span v-for="(conversionAction, index) in conversionActions" v-bind:key="index">
                    <Tooltip
                        :content="conversionAction.label.length > 36 ? conversionAction.label : ''"
                        :max-width="600"
                    >
                        <oInput
                            :modelValue="conversionAction.selected"
                            type="checkbox"
                            :button-mode="true"
                            :label="conversionAction.label"
                            @click="toggleConversionAction(index)"
                        />
                    </Tooltip>
                </span>
            </div>
            <div v-if="routeName === Routes.PerformanceGraphs">
                <Spacer height="0.875rem" />
                <Label>Graphs ({{ selectedChartCount }})</Label>
                <span v-for="chart in chartsEnabledStatuses" :key="chart.id">
                    <oInput
                        :modelValue="chart.enabled"
                        type="checkbox"
                        :button-mode="true"
                        :label="chart.label"
                        @click="toggleChart(chart.id)"
                    />
                </span>
            </div>
            <div v-if="routeName === Routes.PerformanceSegments">
                <Label>Campaign Groups</Label>
                <oInput
                    v-if="campaignGroupItems"
                    v-model="selectedGroupId[accountId]"
                    :button-mode="true"
                    :items="campaignGroupItems"
                    layout="vertical"
                    name="radio"
                    type="radio"
                />
            </div>
        </div>
    </ExpandableSidebar> -->

    <!-- Onboarding -->
    <!-- <OnboardingTour
        step-name="tour_performance"
        :buttonText="'Continue to Performance'"
        contentMaxWidth="472px"
    >
        <template v-slot:header>Performance</template>
        <template v-slot:copy>
            Performance is the home of your performance data. See graphs, find your top performing
            segments, make comparisons across time periods, and so much more. You can also use the
            datepicker and sidebar to filter and segment your data as required.
        </template>
    </OnboardingTour> -->

    <router-view />
</template>

<script setup lang="ts">
// Imports
import { computed, ref } from 'vue'
import { Routes } from '@/router/routes'

// Composition
import useMediaQuery from '@/composition/global/useMediaQuery'
import { useAccount } from '@/composition/account/useAccount'
import { providePerformanceControls } from '@/composition/performance/usePerformanceControls'

// Types
import { Platform } from '@opteo/types'

// Components
import { Button } from '@opteo/components-next'
import OnboardingTour from '@/components/user/OnboardingTour.vue'
import PageSwitcher from '@/components/global/PageSwitcher.vue'

// Setup
const { accountPlatform } = useAccount()
const { aboveMobile } = useMediaQuery()
const {} = providePerformanceControls()

// PageSwitcher
const pageSwitcherPages = ref([
    { label: 'Dashboard', route: Routes.PerformanceGraphs },
    { label: 'Scorecard', route: Routes.PerformanceScorecardList },
    { label: 'Segments', route: Routes.PerformanceSegments },
    { label: 'Trends', route: Routes.PerformanceTable },
])
</script>

<style scoped lang="scss"></style>
