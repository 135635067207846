<template>
    <Stack ref="stackRef" :width="68" :padding="[0, 8]">
        <Skeleton v-if="loading" :height="4" :width="52" color="secondary" />
        <Popout
            v-else
            v-model="showPopout"
            trigger="mouseenter"
            placement="top"
            :offset="[0, 10]"
            :delay="[200, 0]"
            :appendTo="stackEl"
            :maxWidth="540"
            fullWidth
        >
            <Stack width="100%" :height="32">
                <ProgressBar
                    :progress="goalProgress"
                    :height="4"
                    width="100%"
                    :transitionDuration="0"
                    :color="progressBarColor"
                />
            </Stack>
            <template #content>
                <Container
                    color="background-plus-two"
                    shadow="large"
                    :borderRadius="16"
                    :width="computedWidth"
                    hideOverflow
                >
                    <Stack v-if="primaryGoal != undefined" :padding="[16, 20]" borderBottom>
                        <Text as="p" size="body-small">
                            This account has generated a total of
                            <b>{{ formattedCurrentValue }}</b> {{ goalTypeText }} so far this
                            {{ cycleName }}. For reference, this means you are
                            <Text as="span" size="body-small" weight="bold" :color="textColor">
                                {{ formattedGoalDifference }}
                            </Text>
                            {{
                                goalStatus === 'ahead'
                                    ? 'ahead of'
                                    : goalStatus === 'behind'
                                      ? 'behind'
                                      : 'on track with'
                            }}
                            your goal.
                        </Text>
                    </Stack>
                    <Stack :padding="18" :gap="24">
                        <Stack :gap="16">
                            <Avatar
                                :initials="accountInitials"
                                :color="mapHexToAvatarColor(account.color)"
                                :platform="account.platform"
                            />
                            <Stack direction="column" :gap="4" align="start">
                                <Stack :gap="6">
                                    <Text
                                        as="h6"
                                        size="button"
                                        :color="mapHexToTextColor(account.color)"
                                        :wrap="false"
                                        :maxWidth="118"
                                        truncate
                                    >
                                        {{ account.name }}
                                    </Text>
                                </Stack>
                                <Stack :padding="[0, 0, 0, 0]">
                                    <Text
                                        v-if="primaryGoal != undefined"
                                        as="span"
                                        size="label-small"
                                        color="foreground-minus-one"
                                        :wrap="false"
                                    >
                                        {{ formattedGoalAmount
                                        }}{{ cycleName ? '/' + cycleName : '' }}
                                    </Text>
                                    <Text
                                        v-else
                                        as="span"
                                        size="label-small"
                                        color="foreground-minus-one"
                                        :wrap="false"
                                    >
                                        No Goals Set
                                    </Text>
                                </Stack>
                            </Stack>
                        </Stack>
                        <Button
                            size="small"
                            color="tertiary"
                            iconAfter="ArrowUpRight"
                            @click="goToSettings({ accountId: account.accountId })"
                        >
                            {{ primaryGoal ? 'Update' : 'Set' }} Goals
                        </Button>
                    </Stack>
                </Container>
            </template>
        </Popout>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { getDomainInitials } from '@/composition/domain/useDomain'
import { mapHexToAvatarColor, mapHexToTextColor } from '@/composition/utils/utils'

// Types
type goalStatus = 'ahead' | 'behind' | 'on'
import { Account, Targets } from '@opteo/types'

// Components
import {
    Popout,
    Container,
    Stack,
    ProgressBar,
    Skeleton,
    Button,
    Text,
    Avatar,
    usePercent,
    useNumber,
    useMoney,
} from '@opteo/components-next'

// Props
const props = defineProps({
    account: {
        type: Object as PropType<Account.Info>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        default: false,
    },
    currency: {
        type: String,
        default: '£',
    },
    goalProgress: {
        type: Number as PropType<number>,
        required: true,
    },
    goalStatus: {
        type: String as PropType<goalStatus>,
        required: true,
    },
    goalDifference: {
        type: Number as PropType<number>,
        required: true,
    },
    currentValue: {
        type: Number as PropType<number>,
        required: true,
    },
    primaryGoal: {
        type: Number as PropType<Targets.PrimaryPerformanceGoal>,
        default: undefined,
    },
    goalCycle: {
        type: Number as PropType<Targets.PerformanceGoalCycle>,
        default: undefined,
    },
    goalAmount: {
        type: Number as PropType<number | null>,
        default: undefined,
    },
})

// Setup
const router = useRouter()
const showPopout = ref(false)

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})

// Goal type text based on primary goal
const goalTypeText = computed(() => {
    if (props.primaryGoal === undefined) return ''
    if (props.primaryGoal === Targets.PrimaryPerformanceGoal.CONVERSION_VALUE) {
        return 'in conversion value'
    }
    return 'conversions'
})

// Cycle name based on goal cycle
const cycleName = computed(() => {
    switch (props.goalCycle) {
        case Targets.PerformanceGoalCycle.DAY:
            return 'day'
        case Targets.PerformanceGoalCycle.WEEK:
            return 'week'
        case Targets.PerformanceGoalCycle.MONTH:
            return 'month'
        case Targets.PerformanceGoalCycle.QUARTER:
            return 'quarter'
        case Targets.PerformanceGoalCycle.YEAR:
            return 'year'
        default:
            return ''
    }
})

// Goal metric based on primary goal
const goalMetric = computed(() => {
    if (props.primaryGoal === undefined) return undefined

    if (props.primaryGoal === Targets.PrimaryPerformanceGoal.CONVERSION_VALUE) {
        return props.currency
    }
    return 'Conv.'
})

// Formatted values
const formattedGoalAmount = computed(() => {
    // If data is undefined, return an empty string
    if (props.goalAmount === undefined || props.primaryGoal === undefined) {
        return ''
    }

    if (props.primaryGoal === Targets.PrimaryPerformanceGoal.CONVERSION_VALUE) {
        // For monetary values, use useMoney
        return useMoney({
            value: props.goalAmount,
            currency: goalMetric.value,
        }).displayValue.value
    } else {
        return useNumber({ value: props.goalAmount }).displayValue.value
    }
})

const formattedCurrentValue = computed(() => {
    // If primary goal is undefined, we can't format properly
    if (props.primaryGoal === undefined) {
        return ''
    }

    // Format based on primary goal type
    if (props.primaryGoal === Targets.PrimaryPerformanceGoal.CONVERSION_VALUE) {
        // For monetary values
        return useMoney({
            value: props.currentValue,
            currency: goalMetric.value,
        }).displayValue.value
    } else {
        // For conversion counts
        return useNumber({ value: props.currentValue }).displayValue.value
    }
})

const formattedGoalDifference = computed(() => {
    return usePercent({
        value: props.goalDifference,
        decimalPlaces: 0,
    }).displayValue.value
})

// goToSettings
function goToSettings({ accountId }: { accountId: Account.ID }) {
    router.push({
        name: Routes.DomainSettings,
        params: { accountId, scroll_position: 'performance-goals' },
    })
}

// Computed
const progressBarColor = computed(() => {
    if (props.goalStatus === 'ahead') return 'green'
    if (props.goalStatus === 'behind') return 'red'
    return 'green'
})

const textColor = computed(() => {
    if (props.goalStatus === 'ahead') return 'green'
    if (props.goalStatus === 'behind') return 'red'
    return 'green'
})

const computedWidth = computed(() => {
    if (props.primaryGoal === undefined) {
        return 'auto'
    }
    // Check if primaryGoal is defined and equals 1.
    if (props.primaryGoal !== undefined && props.primaryGoal === 1) {
        return 346
    }
    // If primaryGoal isn't 1 (or is undefined) and currentValue is greater than 9999.99.
    if (props.currentValue > 9999.99) {
        return 320
    }
    // Default width.
    return 312
})

// accountInitials
const accountInitials = computed(() => getDomainInitials(props.account.name))
</script>
