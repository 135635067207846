<template>
    <FullScreenOverlay @close="handleChatClose">
        <template #buttons v-if="currentChatId && currentChatId.length">
            <oButton
                class="share-chat"
                :circle="true"
                color="white"
                @clicked="copyLinkToClipboard"
                v-tippy="{
                    animateFill: false,
                    animation: 'shift-away',
                    duration: '250',
                    theme: 'close',
                    placement: 'bottom',
                    trigger: 'mouseenter',
                    zIndex: 9999999999,
                }"
                :content="`<span class='f6 fw5 lh-copy'>Share</span>`"
            >
                <template #icon>
                    <ChatShareIcon />
                </template>
            </oButton>

            <OnboardingModal
                v-model="shouldShowShareOnboarding"
                @closed="showShareOnboardingLocalStorage = false"
                position="absolute"
                :set-bounding-rect="{ top: '2.25rem', right: '4rem' }"
                transition="fade-right"
                :column="true"
                maxWidth="400px"
                buttonText="Okay, thanks"
            >
                <template #copy>
                    Chat conversations in Opteo are now shareable. Just click the share button to
                    the right to copy a shareable link to your clipboard. Please note, shareable
                    links are public and can be viewed by anyone with the link.
                </template>
            </OnboardingModal>
        </template>

        <div class="chat-container">
            <div class="sidebar">
                <section>
                    <ChatAccountSelector @account-selected="selectAccount" />
                </section>
                <section>
                    <oButton
                        @clicked="initialiseConversation()"
                        color="dark-blue"
                        class="start-new-chat-button"
                        iconBefore
                    >
                        <template #icon>
                            <ChatInputButtonIcon />
                        </template>
                        Create New Chat
                    </oButton>
                </section>
                <ChatEntityStyles>
                    <section class="conversations-section" :key="accountId">
                        <perfect-scrollbar class="conversations-wrapper">
                            <Transition name="fade" mode="out-in">
                                <TransitionGroup
                                    name="list"
                                    tag="div"
                                    v-if="conversations && conversations.length > 0"
                                    class="conversations"
                                >
                                    <ChatConversation
                                        v-for="item in conversations"
                                        :key="item.id"
                                        :id="item.id"
                                        :current-conversation-id="currentChatId"
                                        :conversation="item"
                                        :entities="entities"
                                        @chat-deleted="handleChatDeletion(item.id)"
                                        @title-updated="handleTitleUpdate(item.id, $event)"
                                        @clicked="selectConversation(item.id)"
                                    />
                                </TransitionGroup>
                                <div v-else class="conversations-empty-state">
                                    <Text
                                        as="p"
                                        size="f-9"
                                        style="
                                            font-size: 0.75rem;
                                            line-height: 0.625rem;
                                            opacity: 0.24;
                                        "
                                    >
                                        Your conversation history will appear here.
                                    </Text>
                                </div>
                            </Transition>
                        </perfect-scrollbar>
                    </section>
                </ChatEntityStyles>
                <section>
                    <ChatSidebarLink @clicked="toggleCheatsheetPanel()">
                        <ChatCheatsheetIcon />
                        Chat Cheatsheet
                    </ChatSidebarLink>
                    <ChatSidebarLink @clicked="toggleEntitiesPanel()">
                        <ChatLinkIcon />
                        Entities & Attached Data
                    </ChatSidebarLink>
                    <ChatSidebarLink @clicked="togglePrivacyPanel()">
                        <ChatPrivacyIcon />
                        Privacy Policy
                    </ChatSidebarLink>
                </section>
            </div>
            <div class="content" :key="accountId">
                <ChatOnboarding
                    v-if="displayMessages.length === 0"
                    :ad-group="exampleAdGroup"
                    :entitiesLoading="chatEntitiesLoading"
                    @onboarding-clicked="(queryString: string) => sendExampleQuery(queryString)"
                />
                <ChatEntityStyles v-if="displayMessages.length > 0">
                    <div class="messages">
                        <div v-for="item in displayMessages" :key="item.key">
                            <ChatQuery
                                v-if="item.role === 'user' && item.content"
                                :content="item.content"
                                :entities="entities"
                                :profileImageUrl="profileImageUrl"
                                @vue:mounted="scrollToBottom"
                            />
                            <ChatResponse
                                v-else-if="item.content"
                                :response="item.content"
                                :stream="streamStatus"
                                @characters-added="scrollToBottom"
                            />
                        </div>
                    </div>
                </ChatEntityStyles>
                <div v-if="responseLoading" class="response-loading">
                    <ChatResponseLoading @vue:mounted="scrollToBottom" />
                </div>
                <ChatError
                    v-if="error"
                    :error="error.error.message"
                    @vue:mounted="scrollToBottom"
                />
            </div>
            <div class="input" :key="accountId">
                <ChatEntityStyles>
                    <ChatInput
                        ref="chatInputComponentRef"
                        v-model="query"
                        @sendQuery="sendQuery()"
                        :button-disabled="responseLoading"
                        :entities="entities"
                        :entitiesLoading="chatEntitiesLoading"
                    />
                </ChatEntityStyles>
            </div>
            <Panel
                :modelValue="cheatsheetPanelOpen"
                @close="toggleCheatsheetPanel()"
                :width="758"
                title="Chat Cheatsheet"
            >
                <template #content>
                    <ChatCheatsheetContent />
                </template>
            </Panel>
            <Panel
                :modelValue="entitiesPanelOpen"
                @close="toggleEntitiesPanel()"
                :width="656"
                title="Entities & Attached Data"
            >
                <template #content>
                    <ChatEntitiesModalContent />
                </template>
            </Panel>
            <Panel
                :modelValue="privacyPanelOpen"
                @close="togglePrivacyPanel()"
                title="Privacy Policy"
                :width="696"
            >
                <template v-slot:content>
                    <ChatPrivacyModalContent />
                </template>
            </Panel>
        </div>
    </FullScreenOverlay>
</template>

<script lang="ts">
/*
    Desired behaviors regarding routing:
    - When the chat loads from a domain route, that domain should be selected
    - When the chat loads from a non-domain route, the most recently visited domain should be selected
    - When the chat is closed, it should go back to the previous page, no matter what domain was selected within the chat
*/

// External
import { useScroll, useLocalStorage } from '@vueuse/core'
import maxBy from 'lodash-es/maxBy'
import sortBy from 'lodash-es/sortBy'

// Vue
import { computed, ref, Ref } from 'vue'
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Opteo
import { Account, Domain, Chat } from '@opteo/types'
import { scrollToTop } from '@/lib/globalUtils'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useAPI, Endpoint, authRequest } from '@/composition/api/useAPI'
import { useDomain } from '@/composition/domain/useDomain'

// Local components
import FullScreenOverlay from '@/components/util/FullScreenOverlay.vue'
import { LS_CHAT_SHARE_ONBOARDING_MODAL } from '@/lib/cookies'

// Chat components
import ChatInput from '@/components/chat/ChatInput.vue'
import ChatQuery from '@/components/chat/ChatQuery.vue'
import ChatResponse from '@/components/chat/ChatResponse.vue'
import ChatResponseLoading from '@/components/chat/ChatResponseLoading.vue'
import ChatAccountSelector from '@/components/chat/ChatAccountSelector.vue'
import ChatEntityStyles from '@/components/chat/ChatEntityStyles.vue'
import ChatConversation from '@/components/chat/ChatConversation.vue'
import ChatInputButtonIcon from '@/components/chat/ChatInputButtonIcon.vue'
import ChatError from '@/components/chat/ChatError.vue'
import ChatCheatsheetContent from '@/components/chat/ChatCheatsheetContent.vue'
import ChatEntitiesModalContent from '@/components/chat/ChatEntitiesModalContent.vue'
import ChatPrivacyModalContent from '@/components/chat/ChatPrivacyModalContent.vue'
import ChatLinkIcon from '@/components/chat/ChatLinkIcon.vue'
import ChatCheatsheetIcon from '@/components/chat/ChatCheatsheetIcon.vue'
import ChatPrivacyIcon from '@/components/chat/ChatPrivacyIcon.vue'
import ChatSidebarLink from '@/components/chat/ChatSidebarLink.vue'
import ChatOnboarding from '@/components/chat/ChatOnboarding.vue'
import ChatShareIcon from '@/components/chat/ChatShareIcon.vue'

// @opteo/components-next
// import { Text, Spacer, Panel, oButton, Modal, OnboardingModal } from '@opteo/components-next'
import { useChatShare } from '@/composition/chat/useChatShare'
import { should } from 'vitest'
import { useAccountList } from '@/composition/user/useAccountList'

// Export
export default {
    name: 'Chat',
    components: {
        //         // Local components
        //         FullScreenOverlay,
        //         // Chat components
        //         ChatInput,
        //         ChatQuery,
        //         ChatResponse,
        //         ChatResponseLoading,
        //         ChatAccountSelector,
        //         ChatEntityStyles,
        //         ChatConversation,
        //         ChatInputButtonIcon,
        //         ChatError,
        //         ChatCheatsheetContent,
        //         ChatEntitiesModalContent,
        //         ChatPrivacyModalContent,
        //         ChatLinkIcon,
        //         ChatCheatsheetIcon,
        //         ChatPrivacyIcon,
        //         ChatSidebarLink,
        //         ChatOnboarding,
        //         // @opteo/components-next
        //         Text,
        //         Spacer,
        //         Panel,
        //         oButton,
        //         Modal,
        //         ChatShareIcon,
        //         OnboardingModal,
    },
    setup() {
        // Setup
        const { userId, profileImageUrl } = useUser()

        const { currentRoute } = useRouter()

        const accountId = computed(() => currentRoute.value.params.accountId as Account.ID)
        const query = ref('')
        const router = useRouter()
        const cheatsheetPanelOpen = ref(false)
        const entitiesPanelOpen = ref(false)
        const privacyPanelOpen = ref(false)
        const chatInputComponentRef = ref<typeof ChatInput>()
        const error: Ref<null | string> = ref(null)

        // Refs to handle stream and scrollToBottom status
        // when opening Conversation History items
        const streamStatus = ref(true)
        const allowScrollToBottom = ref(true)
        const { isScrolling } = useScroll(window)

        // chat history
        const {
            data: chatHistory,
            loading: chatHistoryLoading,
            error: chatHistoryError,
            mutate: chatHistoryMutate,
        } = useAPI<Chat.GetChatHistoryReponse>(Endpoint.getChatHistory, {
            body: () => ({ account_id: accountId.value }),
            uniqueId: () => accountId.value,
            waitFor: () => accountId.value,
        })

        const conversations = computed(() => {
            return chatHistory.value?.conversations
        })

        const selectConversation = (chatId: string) => {
            responseLoading.value = false // In case we're in the middle of a response
            // Add the initial message to the front, followed by the rest of the users messages
            messages.value = [
                ...initialMessages,
                ...(conversations.value?.find(c => c.id === chatId)?.messages ?? []),
            ]

            // Continue with conversation
            currentChatId.value = chatId

            // Scroll to top
            scrollToTop('auto')

            // Temporarily set :stream and :allowScrollToBottom to `false` while new conversation loads
            streamStatus.value = false
            allowScrollToBottom.value = false
            setTimeout(() => {
                streamStatus.value = true
                allowScrollToBottom.value = true
            }, 100)

            // clear the input field when a new conversation is selected
            chatInputComponentRef.value?.clearInput()

            // Clear `error`
            error.value = null

            // Got scrolling to bottom to work, but don't think it's as useful
            // as being scrolled to the top of the conversation — gives more
            // context in terms of what the conversation was about, and if you
            // choose to send another message, you'll automatically be scrolled
            // to the bottom whenever the `sendQuery` function is triggered
        }

        // Chat entities
        const {
            data: chatEntities,
            loading: chatEntitiesLoading,
            error: chatEntitiesError,
            mutate: chatEntitiesMutate,
        } = useAPI<Chat.GetChatEntitiesResponse>(Endpoint.getChatEntities, {
            body: () => ({ account_id: accountId.value }),
            uniqueId: () => accountId.value,
            waitFor: () => accountId.value,
        })
        const entities = computed(() => {
            const escapedKeywords = chatEntities.value?.keywords.map(keyword => {
                const phaseMatch = keyword.name.charAt(0) === `"`
                return {
                    ...keyword,
                    name: phaseMatch ? keyword.name.replaceAll(`"`, '&quot;') : keyword.name,
                }
            })
            return { ...chatEntities.value, keywords: escapedKeywords }
        })

        // initialMessage setup
        const initialMessages: Chat.SendChatMessageRequest['messages'] = [
            {
                role: 'user',
                content: `You are a helpful Google Ads assistant inside a tool called Opteo.
Opteo helps users optimise their Google Ads accounts.
The next message will be from an Opteo user.
It is likely to contain some data from an entity (a campaign, an ad group, a keyword, etc.) in their Google Ads account.
If a user asks a question about their Google Ads account as a whole, let them know that you are a language-based text model and do not have the ability to access their Google Ads account. Make sure to provide extra information about context limits in ChatGPT, explaining that it would be impossible to share the entirety of their Google Ads account data without exceeding these limits. Remind them that they can reference individual campaigns, ad groups and keywords by typing the @ symbol.
If a user mentions Bidding Strategies, or you recommend experimenting with another Bidding Strategy, let the user know they can use Opteo's Bidding Experiments tool to run a cost-limited bidding strategy experiment.
If users need help with Opteo, you please direct them to our help docs (opteo.com/docs) or let them know they can message support using the bubble at the bottom right of the screen.
Try to be as accurate, reasonable and balanced as possible.
Try not to recommend extreme courses of action unless specifically prompted to do so.
When someone says "Hello", you should respond with "Hello! How can I assist you with your Google Ads account today?".`,
            },
        ]

        const messages = ref([...initialMessages])
        const responseLoading = ref(false)
        const currentChatId = ref('')

        // Create messages array without first message
        const displayMessages = computed(() => {
            return messages.value.slice(1).map((msg, idx) => {
                if (!msg.content) throw new Error('Message content is undefined')
                return {
                    ...msg,
                    key: msg.content + '-' + idx, // Might need a better key here?
                }
            })
        })

        const initialiseConversation = () => {
            messages.value = [...initialMessages]

            responseLoading.value = false // In case we're in the middle of a response
            // Clear `error`
            error.value = null
            // new chat about to happen
            currentChatId.value = ''
            // scroll to top
            window.scrollTo({ top: 0, behavior: 'auto' })
            document.getElementsByClassName('ps conversations-wrapper')[0].scrollTop = 0
            // clear chatInput field
            chatInputComponentRef.value?.clearInput()
            // Focus input
            chatInputComponentRef.value?.$refs.chatInputRef.focus()
        }

        // Send query
        const sendQuery = async () => {
            if (!query.value) return

            responseLoading.value = true

            messages.value.push({
                role: 'user',
                content: query.value,
            })

            query.value = ''

            const request: Chat.SendChatMessageRequest = {
                messages: messages.value,
                chat_id: currentChatId.value !== '' ? currentChatId.value : undefined,
            }

            const chatId = currentChatId.value
            const currentAccountId = accountId.value

            const response: Chat.SendChatMessageResponse = await authRequest(
                Endpoint.sendChatMessage,
                {
                    body: () => {
                        return {
                            account_id: accountId.value,
                            user_id: userId.value,
                            request,
                        }
                    },
                }
            )

            // If the user has navigated away from this chat, we don't want to
            // update the messages array with the response.
            if (chatId !== currentChatId.value || currentAccountId !== accountId.value) {
                responseLoading.value = false
                chatHistoryMutate()
                return
            }

            if (response.success) {
                const { response: chatGptResponse } = response
                const answer =
                    chatGptResponse.choices[0].message?.content ??
                    'Unable to get response from ChatGPT'

                messages.value.push({
                    role: 'assistant',
                    content: answer,
                })

                responseLoading.value = false

                currentChatId.value = response.chatId

                // we want to get the latest changes to the chat history
                chatHistoryMutate()
            } else {
                error.value = response.err
                responseLoading.value = false
            }
        }

        // Find the highest spending ad group in the highest spending campaign
        const exampleAdGroup = computed(() => {
            const highestSpendingCampaignContainingAdgroups = sortBy(
                entities.value?.campaigns ?? [],
                // Sorting in descending order
                c => -c.metrics.cost_micros
            ).find(c =>
                (entities.value?.adgroups ?? []).some(
                    ag => ag.parentResourceName === c.resourceName
                )
            )

            if (!highestSpendingCampaignContainingAdgroups) {
                return undefined
            }

            const adGroupsInCampaign = (entities.value?.adgroups ?? []).filter(
                ag =>
                    ag.parentResourceName === highestSpendingCampaignContainingAdgroups.resourceName
            )

            const highestSpendingAdGroup = maxBy(adGroupsInCampaign, ag => ag.metrics.cost_micros)

            return highestSpendingAdGroup
        })

        // For sending example queries from ChatOnboarding buttons
        const sendExampleQuery = (exampleQueryString: string) => {
            query.value = exampleQueryString
            sendQuery()
        }

        // Select account
        const selectAccount = (accountId: Account.ID) => {
            initialiseConversation()
            router.replace({ name: Routes.Chat, params: { accountId } }) // use .replace so that router.go(-1) still works
            chatInputComponentRef.value?.$refs.chatInputRef.focus()
        }

        // Handle close
        const handleChatClose = () => {
            // if ChatInput tippy is open, prevent chat tool closing
            if (chatInputComponentRef.value && chatInputComponentRef.value.tippyOpenRef) return

            // If coming from inside Opteo, use router history
            if (window.history.state.back?.includes('/user/' + userId.value)) {
                router.go(-1)
            }
            // If coming from an external link, or a new tab, return to Account Centre
            else {
                router.push({ name: Routes.AccountCentre })
            }
        }

        // Toggle Cheatsheet panel
        const toggleCheatsheetPanel = () => {
            if (cheatsheetPanelOpen.value === true) {
                cheatsheetPanelOpen.value = false
                chatInputComponentRef.value?.$refs.chatInputRef.focus()
            } else if (cheatsheetPanelOpen.value === false) {
                cheatsheetPanelOpen.value = true
            }
        }

        const toggleEntitiesPanel = () => {
            if (entitiesPanelOpen.value === true) {
                entitiesPanelOpen.value = false
                chatInputComponentRef.value?.$refs.chatInputRef.focus()
            } else if (entitiesPanelOpen.value === false) {
                entitiesPanelOpen.value = true
            }
        }

        const togglePrivacyPanel = () => {
            if (privacyPanelOpen.value === true) {
                privacyPanelOpen.value = false
                chatInputComponentRef.value?.$refs.chatInputRef.focus()
            } else if (privacyPanelOpen.value === false) {
                privacyPanelOpen.value = true
            }
        }

        // Toggle Entities panel

        const handleChatDeletion = (chat_id: string) => {
            if (conversations.value === undefined) return

            // setup a new chat if deleting the current chat
            if (chat_id === currentChatId.value) {
                initialiseConversation()
            }

            // remove the deleted chat from the list of conversations
            const afterDeletion = {
                conversations: [
                    ...(conversations.value.filter(conversation => conversation.id !== chat_id) ??
                        []),
                ],
            }

            // update the chat history in the cache to reflect the deletion
            chatHistoryMutate(() => afterDeletion)
        }

        const handleTitleUpdate = (chat_id: string, title: string) => {
            if (conversations.value === undefined) return

            // remove the deleted chat from the list of conversations
            const afterUpdate = {
                conversations: conversations.value.map(item => {
                    if (item.id === chat_id) {
                        return {
                            ...item,
                            title,
                        }
                    }
                    return item
                }),
            }

            chatHistoryMutate(() => afterUpdate)
        }

        const scrollToBottom = () => {
            if (isScrolling.value) return // dont scroll if already in the middle of scrolling
            if (!allowScrollToBottom.value) return // don't scroll while rendering in new conversations
            window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' })
        }

        const { copyLinkToClipboard } = useChatShare(currentChatId)

        const assistantHasResponded = computed(() => {
            return messages.value.length > 2
        })

        const showShareOnboardingLocalStorage = useLocalStorage(
            LS_CHAT_SHARE_ONBOARDING_MODAL,
            true
        )

        const shouldShowShareOnboarding = computed(() => {
            return assistantHasResponded.value && showShareOnboardingLocalStorage.value
        })

        // Return
        return {
            accountId,
            query,
            handleChatClose,
            cheatsheetPanelOpen,
            entitiesPanelOpen,
            privacyPanelOpen,
            toggleCheatsheetPanel,
            toggleEntitiesPanel,
            togglePrivacyPanel,
            sendQuery,
            entities,
            messages,
            displayMessages,
            responseLoading,
            chatEntitiesLoading,
            selectAccount,
            conversations,
            selectConversation,
            initialiseConversation,
            chatInputComponentRef,
            streamStatus,
            scrollToBottom,
            handleChatDeletion,
            handleTitleUpdate,
            error,
            currentChatId,
            sendExampleQuery,
            exampleAdGroup,
            profileImageUrl,
            copyLinkToClipboard,
            shouldShowShareOnboarding,
            showShareOnboardingLocalStorage,
        }
    },
}
</script>

<style scoped lang="scss">
// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .chat-container {
// //     display: grid;
// //     grid-template-columns: 18.75rem 1fr;
// // }

// // .chat-container .sidebar {
// //     box-shadow: $opteo-shadow;
// //     @include fixed;
// //     height: 100vh;
// //     width: 18.75rem;
// //     @include z-10;
// // }
// // .chat-container .sidebar section {
// //     @include pa-20;
// //     border-bottom: 1px solid $opteo-light-gray;
// // }
// // .chat-container .sidebar section.conversations-section {
// //     @include pa-0;
// // }
// // .chat-container .sidebar .conversations-wrapper {
// //     height: calc(100vh - 25.4375rem);
// //     @include bg-opteo-white;
// //     @include pa-20;
// // }

// // .chat-entities-modal-content {
// //     max-width: 32rem;
// //     margin: 4.25rem auto;
// // }

// // // Scrollbar styles
// // .chat-container .sidebar .conversations-wrapper :deep(.ps__thumb-y) {
// //     background: #cfd0d2;
// // }

// // .chat-container .content {
// //     padding-bottom: 1rem;
// //     padding-top: 3rem;
// //     grid-column: 2 / 3;
// //     // screenHeight - inputHeight
// //     min-height: calc(100vh - 10.875rem);
// //     @include flex;
// //     flex-direction: column;
// //     gap: 2rem;
// // }
// // .chat-container .content .messages {
// //     @include flex;
// //     flex-direction: column;
// //     gap: 2rem;
// // }

// // .chat-container .input {
// //     @include sticky;
// //     bottom: 0;
// //     width: 100%;
// //     left: 0;
// //     pointer-events: none;
// //     z-index: 2;
// //     grid-column: 2 / 3;
// // }

// // .chat-container .sidebar :deep(.start-new-chat-button) {
// //     height: 3.25rem;
// //     font-size: 0.875rem;
// //     padding: 0 1.5rem 0 1.4375rem;
// //     width: 100%;
// // }

// // // Empty states

// // // Transitions

// // // List
// // .list-move,
// // .list-enter-active,
// // .list-leave-active {
// //     transition: all 0.72s cubic-bezier(0.19, 1, 0.22, 1);
// // }

// // .list-enter-from {
// //     opacity: 0;
// //     transform: translateX(2rem);
// // }

// // .list-enter-to {
// //     transition-delay: 0.72s;
// // }
// // .list-move {
// //     transition-delay: 0.36s;
// // }

// // .list-leave-to {
// //     opacity: 0;
// //     transform: translateX(2rem);
// // }

// // // Ensure leaving items are taken out of layout flow so
// // // that moving animations can be calculated correctly.
// // .list-leave-active {
// //     position: absolute;
// //     width: 260px;
// // }

// // // Fade
// // .fade-enter-active,
// // .fade-leave-active {
// //     transition: opacity 0.64s cubic-bezier(0.19, 1, 0.22, 1);
// // }

// // .fade-enter-from,
// // .fade-leave-to {
// //     opacity: 0;
// // }
</style>
