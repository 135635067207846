<template>
    <aside :class="['user-bottom-bar', { desktop: aboveiPadPortrait }]">
        <div class="inner-wrapper">
            <!-- Accounts -->
            <UserSidebarButton
                @click="goToAccountCentre"
                :colorMode="mode"
                :active="isActive(Routes.AccountCentre).value"
            >
                <OpteoLogo :width="36" :height="36" />
            </UserSidebarButton>

            <!-- Chat -->
            <UserSidebarButton
                @click="goToChat"
                :colorMode="mode"
                :active="isActive(Routes.Chat).value"
                v-if="aboveiPadPortrait"
            >
                <UserSidebarIconChat />
            </UserSidebarButton>

            <!-- Reports -->
            <UserSidebarButton
                @click="goToReports"
                :colorMode="mode"
                :active="isActive(Routes.Reports).value"
                v-if="aboveiPadPortrait"
            >
                <UserSidebarIconReports />
            </UserSidebarButton>

            <!-- Basecamp -->
            <UserSidebarButton
                @click="goToBasecamp"
                :colorMode="mode"
                :active="isActive(Routes.Basecamp).value"
                v-if="aboveiPadPortrait"
            >
                <UserSidebarIconBasecamp />
            </UserSidebarButton>

            <!-- LinkedAccounts -->
            <UserSidebarButton
                @click="goToLinkedAccounts"
                :colorMode="mode"
                :active="isActive(Routes.LinkedAccounts).value"
            >
                <UserSidebarIconLinkedAccounts />
            </UserSidebarButton>

            <!-- Alerts -->
            <UserSidebarButton
                @click="goToAlerts"
                :colorMode="mode"
                :active="isActive(Routes.Alerts).value"
            >
                <Count
                    :value="unreadCount"
                    :size="unreadCount > 99 ? 'small' : 'medium'"
                    :class="[{ wide: unreadCount > 99 }, 'alerts-count']"
                />
                <UserSidebarIconAlerts />
            </UserSidebarButton>

            <!-- Account -->
            <UserSidebarButton
                @click="goToUserSettings"
                :colorMode="mode"
                :active="isActive(Routes.UserSettings).value"
            >
                <ProfileImage
                    :size="32"
                    :imageUrl="profileImageUrl"
                    :boringAvatar="currentUserAvatarUrl"
                />
            </UserSidebarButton>
        </div>
    </aside>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'
import { useColorMode } from '@vueuse/core'
import { useRouter } from 'vue-router'
import { useAlerts } from '@/composition/alerts/useAlerts'
import { Routes } from '@/router/routes'

// Composition
import { layout } from '@/composition/layouts/useLayouts'
import { useUser } from '@/composition/user/useUser'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { useSubscription } from '@/composition/billing/useSubscription'

// Components
import { OpteoLogo, ProfileImage, Count, useMediaQuery } from '@opteo/components-next'
import UserSidebarButton from './components/UserSidebarButton.vue'
import UserSidebarIconChat from './components/UserSidebarIconChat.vue'
import UserSidebarIconReports from './components/UserSidebarIconReports.vue'
import UserSidebarIconBasecamp from './components/UserSidebarIconBasecamp.vue'
import UserSidebarIconLinkedAccounts from './components/UserSidebarIconLinkedAccounts.vue'
import UserSidebarIconAlerts from './components/UserSidebarIconAlerts.vue'

// Setup
const { isBillingLocked } = useSubscription()
const { profileImageUrl } = useUser()
const { currentUserAvatarUrl } = useBoringAvatar()
const router = useRouter()
const { unreadCount } = useAlerts()
const { aboveiPadPortrait } = useMediaQuery()

// colorMode
const mode = useColorMode({ disableTransition: false })

// Functions
function isActive(routeName: string) {
    return computed(() => {
        const currentRoute = router.currentRoute.value
        return (
            currentRoute.name === routeName ||
            currentRoute.path.includes(`/${routeName.toLowerCase()}`)
        )
    })
}
function goToAccountCentre() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.AccountCentre })
    }
}
function goToChat() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Chat })
    }
}
function goToReports() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Reports })
    }
}
function goToBasecamp() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Basecamp })
    }
}
function goToLinkedAccounts() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.LinkedAccounts })
    }
}
function goToAlerts() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.Alerts })
    }
}
function goToUserSettings() {
    if (!isBillingLocked.value) {
        router.push({ name: Routes.UserSettings })
    }
}
</script>

<style scoped lang="scss">
.user-bottom-bar {
    @include w-100;
    padding: 0.625rem 0;
    flex-shrink: 0;
    @include flex;
    @include items-center;
    @include justify-center;
    z-index: 4001;
    @include bg-background;
    @include bottom-bar-shadow;
}
.user-bottom-bar .inner-wrapper {
    @include w-100;
    @include flex;
    @include items-center;
    @include justify-between;
    max-width: 20rem;
    @include ph-16;
}
.user-bottom-bar.desktop .inner-wrapper {
    max-width: 40rem;
}
.alerts-count {
    @include absolute;
    top: 0.625rem;
    right: 0.625rem;
}
.alerts-count.wide {
    right: 0.375rem;
}
</style>
