<template>
    <aside class="account-bottom-bar">
        <div class="inner-wrapper">
            <AccountSwitcher nestedMenu @menu-click="emit('menu-clicked')" />
        </div>
    </aside>
</template>

<script setup lang="ts">
// Composition
import { layout } from '@/composition/layouts/useLayouts'

// Components
import AccountSwitcher from './AccountSwitcher.vue'

// Emits
const emit = defineEmits(['menu-clicked'])
</script>

<style scoped lang="scss">
.account-bottom-bar {
    @include w-100;
    padding: 1rem;
    flex-shrink: 0;
    z-index: 3999;
    @include flex;
    @include items-center;
    @include justify-center;
    @include bg-background;
    @include bottom-bar-shadow;
}
.account-bottom-bar .inner-wrapper {
    @include w-100;
    max-width: 42rem;
    @include flex;
    @include items-center;
    @include justify-between;
}
.alerts-count {
    @include absolute;
    top: 0.625rem;
    right: 0.625rem;
}
.alerts-count.wide {
    right: 0.375rem;
}
</style>
