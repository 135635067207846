<template>
    <Modal
        v-model="showInvoiceSettingsModal"
        title="Invoice Settings"
        :maxContentHeight="474"
        @closed="resetUnsavedValues()"
    >
        <template #content>
            <InvoiceSettingsModalContent ref="InvoicesSettingsRef" :vatData="vatData" />
        </template>
        <template #buttons>
            <Button @click="open = false" color="secondary">Cancel</Button>
            <Button @click="handleUpdate" :loading="loading" color="primary">
                Update Invoice Settings
            </Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref } from 'vue'

// Composition
import { useInvoices } from '@/composition/billing/invoices/useInvoices'

// Components
import { Modal, Button, hideSnackbar, showSnackbar } from '@opteo/components-next'
import InvoiceSettingsModalContent from '@/components/billing/Modals/InvoiceSettingsModal/InvoiceSettingsModalContent.vue'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const open = ref(false)
const loading = ref(false)
const {
    vatData,
    updateVatForm,
    resetUnsavedValues,
    InvoicesSettingsRef,
    showInvoiceSettingsModal,
} = useInvoices()

// Functions
const handleUpdate = async () => {
    loading.value = true
    const success = await updateVatForm()
    if (success) {
        showInvoiceSettingsModal.value = false
        showSnackbar({
            message: `Invoice Settings updated succesfully`,
            timeout: 3000,
            actionText: 'Dismiss',
            actionHandler: () => hideSnackbar(),
        })
        loading.value = false
    } else loading.value = false
}
</script>

<style scoped lang="scss"></style>
