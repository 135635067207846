<template>
    <Modal v-model="open" @close="closeModal" title="Connect Google Analytics">
        <template #content>
            <Stack direction="column" align="start" :gap="20">
                <!-- Profile -->
                <Container :borderRadius="20" :width="336" hideOverflow>
                    <Stack
                        borderBottom
                        direction="column"
                        :gap="0"
                        :padding="[0, 0, 16, 0]"
                        align="center"
                    >
                        <Stack
                            :gap="16"
                            align="center"
                            justify="center"
                            width="100%"
                            :padding="[32, 16]"
                        >
                            <Avatar
                                :initials="accountInitials"
                                :color="accountColor[0]"
                                :platform="accountPlatform"
                            />
                            <Stack :gap="8" width="auto">
                                <Container
                                    :width="10"
                                    :height="10"
                                    :borderRadius="999"
                                    :color="['primary-translucent', 'background-plus-two']"
                                    :outline="false"
                                    hideOverflow
                                />
                                <Container
                                    :width="10"
                                    :height="10"
                                    :borderRadius="999"
                                    :color="['primary-translucent', 'background-plus-two']"
                                    :outline="false"
                                    hideOverflow
                                />
                                <Container
                                    :width="10"
                                    :height="10"
                                    :borderRadius="999"
                                    :color="['primary-translucent', 'background-plus-two']"
                                    :outline="false"
                                    hideOverflow
                                />
                            </Stack>
                            <GoogleAnalyticsLogo />
                        </Stack>
                        <Button
                            v-if="hasConnection && accountProfile && accountProfile?.name"
                            color="secondary"
                            size="extra-small"
                            @click="emit('unlink-account')"
                        >
                            Disconnect Google Analytics
                        </Button>
                    </Stack>
                    <Stack :padding="[17, 22]">
                        <Text as="p" size="body-small">
                            Using the form below, select a Google Analytics account, property and
                            view to connect to Opteo.
                        </Text>
                    </Stack>
                </Container>
                <AnalyticsTable
                    :accounts="accounts"
                    :selected="selected"
                    :filtered="filtered"
                    :select-entity="selectEntity"
                    :search-entities="searchEntities"
                />
            </Stack>
        </template>
        <template #buttons>
            <Button @click="closeModal" color="secondary" :disabled="loading">Cancel</Button>
            <Button
                @click="linkView"
                color="primary"
                iconAfter="ArrowUpRight"
                :readOnly="!selected?.profile || loading"
            >
                Connect Analytics View
            </Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch } from 'vue'

// Types
import { AnalyticsTypes } from '@opteo/types'

// Composition
import { mapHexToAvatarColor } from '@/composition/utils/utils'
import { useAccount } from '@/composition/account/useAccount'

// Components
import {
    Modal,
    Button,
    Stack,
    Container,
    Avatar,
    Text,
    GoogleAnalyticsLogo,
} from '@opteo/components-next'
import AnalyticsTable from '@/components/connectors/analytics/components/AnalyticsTable.vue'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
    loading: {
        type: Boolean,
        required: false,
        default: false,
    },
    hasConnection: {
        type: Boolean as PropType<boolean | undefined>,
        required: true,
    },
    accountProfile: {
        type: Object as PropType<AnalyticsTypes.MatadataEntityBase>,
        required: false,
    },
    accounts: {
        type: Array as PropType<AnalyticsTypes.FormattedAccountData[]>,
    },
    selected: {
        type: Object as PropType<{
            account?: AnalyticsTypes.FormattedAccountData
            property?: AnalyticsTypes.FormattedAccountProperty
            profile?: AnalyticsTypes.MatadataEntityBase
        }>,
    },
    filtered: {
        type: Object as PropType<{
            accounts?: AnalyticsTypes.FormattedAccountData[]
            properties?: AnalyticsTypes.FormattedAccountProperty[]
            profiles?: AnalyticsTypes.MatadataEntityBase[]
        }>,
    },
    selectEntity: {
        type: Function as PropType<
            (type: AnalyticsTypes.MatadataEntityType, entityId: string) => void
        >,
        required: true,
    },
    searchEntities: {
        type: Function as PropType<
            (type: AnalyticsTypes.MatadataEntityType, searchQuery: string) => void
        >,
        required: true,
    },
})

// Emits
const emit = defineEmits([
    'update:modelValue',
    'unlink-account',
    'close',
    'link-view',
    'before-oauth-redirect',
])

// Setup
const open = ref(false)
const {
    accountColor: accountColorRaw,
    accountInitials: accountInitialsRaw,
    accountPlatform: accountPlatformRaw,
} = useAccount()

// Variables
const accountInitials = ref(accountInitialsRaw.value)
const accountColor = ref([mapHexToAvatarColor(accountColorRaw.value)])
const accountPlatform = ref(accountPlatformRaw.value)

// Functions
function closeModal() {
    open.value = false
    emit('close')
}
function linkView() {
    console.log('Before linkView call:', props.selected)
    emit('link-view')
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
