import groupBy from 'lodash-es/groupBy'
import parseISO from 'date-fns/parseISO'
import sortBy from 'lodash-es/sortBy'
import { showSnackbar } from '@opteo/components-next'
import cloneDeep from 'lodash-es/cloneDeep'
import { inject, provide, computed } from 'vue'

import { Endpoint, useAPI } from '../api/useAPI'
import { useImprovementPreferences } from './useImprovementPreferences'
import { useDismissedImprovements } from './useDismissedImprovements'
import { useDismissImprovement } from './useDismissImprovement'
import { ProvideKeys } from '@/composition/useProvide'
import { ImprovementSort } from '@/lib/improvement'
import { useLocalStorage } from '@vueuse/core'
import { LS_IMPROVEMENTS_SORTBY } from '@/lib/cookies'
import { useDismissFeedbackModal } from './useDismissFeedbackModal'

import type { Account, Improvement } from '@opteo/types'
import type { DismissDuration, EnhancedImprovement } from './types'
import { useAccount } from '../account/useAccount'

export interface ImprovementGroup {
    label: string
    subLabel: string
    improvementsInGroup: EnhancedImprovement[]
}

export function provideActiveImprovements() {
    const { accountId } = useAccount()
    const { dismissImprovement } = useDismissImprovement()
    const { recActionPreferences } = useImprovementPreferences()
    const { refreshDismissedList } = useDismissedImprovements()

    const {
        data: improvementsRaw,
        loading: activeImpsLoading,
        error,
        mutate: mutateImprovementsList,
    } = useAPI<Improvement.Object[]>(Endpoint.ListImprovements, {
        body: () => ({ account_id: accountId?.value }),
        uniqueId: () => accountId?.value,
        waitFor: () => accountId.value,
        dedupingInterval: 0,
    })

    const loading = computed(() => activeImpsLoading.value || !recActionPreferences.value)

    // Improvements list grouping & ordering
    // TODO(components-updates): Update sorting, grouping logic, types
    // Statically group improvements according to: https://www.notion.so/opteo/Improvement-Categories-17e57605461280afb082d0c226f4d8d3
    // Sort _within_ each static group, by priority, date, etc. with no additional sub-grouping
    const localImprovementSort = useLocalStorage<{ [accountId: Account.ID]: ImprovementSort }>(
        LS_IMPROVEMENTS_SORTBY,
        {}
    )

    const improvementSort = computed<ImprovementSort>(
        () => localImprovementSort.value[accountId.value] ?? 'priority'
    )

    function updateImprovementSort(newSort: any) {
        if (improvementSort.value === newSort) return
        localImprovementSort.value[accountId.value] = newSort
        console.log('updatedImprovementSort: ', newSort)
    }

    function updateImprovementSortDirection(sortDirection: any) {
        console.log('updatedImprovementSortDirection: ', sortDirection.sortDirection)
    }

    function sortImprovements(
        improvements: Improvement.Object[],
        sortField: ImprovementSort
    ): Improvement.Object[] {
        return sortBy(improvements, imp => {
            if (sortField === 'priority') return -imp.priority
            if (sortField === 'type') return imp.static_title
            if (sortField === 'created')
                return -parseISO(imp.created as unknown as string).valueOf()
        })
    }

    const improvements = computed<EnhancedImprovement[]>(() => {
        if (!improvementsRaw.value || !recActionPreferences.value) {
            return []
        }

        return improvementsRaw.value
            .map(imp => {
                const preferences = (recActionPreferences.value ?? []).find(pref =>
                    pref.rec_actions.includes(imp.rec_action)
                )
                return {
                    ...imp,
                    preferences,
                    static_title: preferences?.static_title ?? '',
                }
            })
            .filter(imp => imp.preferences?.selected)
    })

    // TODO(components-updates): Handle categories in luckymetrics to allow for check functionality
    const groupOrder = ['Exclusions', 'Adjustments', 'Keywords', 'Negatives', 'Deprecated']

    const improvementsGrouped = computed<ImprovementGroup[]>(() => {
        const impsGrouped = groupBy(improvements.value, imp => {
            if (
                imp.rec_action === 'check_query_relevance' ||
                imp.rec_action === 'fix_negatives_blocking_keywords' ||
                imp.rec_action === 'increase_keyword_bid_v2' ||
                imp.rec_action === 'adjust_keyword_bid_v2' ||
                imp.rec_action === 'pause_duplicate_keywords_v2' ||
                imp.rec_action === 'pause_keyword_v2' ||
                imp.rec_action === 'remove_broad_keywords_v2'
            ) {
                return 'Keywords'
            } else if (
                imp.rec_action === 'add_robot_negative' ||
                imp.rec_action === 'add_negative_geo_keyword' ||
                imp.rec_action === 'add_negative_ngram' ||
                imp.rec_action === 'add_missing_campaigns_to_shared_set' ||
                imp.rec_action === 'resync_exclusion_lists'
            ) {
                return 'Negatives'
            } else if (
                imp.rec_action === 'disable_interest_locations_v2' ||
                imp.rec_action === 'disable_search_partners' ||
                imp.rec_action === 'exclude_single_demographic' ||
                imp.rec_action === 'exclude_single_device' ||
                imp.rec_action === 'exclude_location' ||
                imp.rec_action === 'exclude_content'
            ) {
                return 'Exclusions'
            } else if (
                imp.rec_action === 'add_frequency_capping' ||
                imp.rec_action === 'adjust_ad_group_multi_targets' ||
                imp.rec_action === 'adjust_single_placement_demo_bid' ||
                imp.rec_action === 'adjust_multiple_placement_demo_bids' ||
                imp.rec_action === 'adjust_single_device_bid' ||
                imp.rec_action === 'adjust_multiple_device_bids' ||
                imp.rec_action === 'adjust_location_bids' ||
                imp.rec_action === 'lift_campaign_budget_cap' ||
                imp.rec_action === 'reduce_campaign_bids' ||
                imp.rec_action === 'reduce_ngram_bids'
            ) {
                return 'Adjustments'
            } else {
                return 'Deprecated'
            }
        })

        const groupedImprovements = groupOrder.map(label => {
            const impGroup = impsGrouped[label]
            const sortedImprovements = sortImprovements(impGroup, improvementSort.value)

            return {
                label,
                subLabel: `${sortedImprovements.length} Improvement${sortedImprovements.length !== 1 ? 's' : ''}`,
                improvementsInGroup: sortedImprovements,
            }
        })

        return groupedImprovements
    })

    const removeImprovements = async (ids: number[]) => {
        if (ids.length === 0) {
            return
        }

        if (!improvementsRaw.value) {
            throw new Error('cannot mutate until improvementsRaw.value is set')
        }
        const newList = improvementsRaw.value.filter(row => !ids.includes(row.improvement_id))
        await mutateImprovementsList(() => newList)
        await mutateImprovementsList()
    }

    const { openDismissFeedbackModal } = useDismissFeedbackModal()

    const dismissImprovementWithFeedBack = async (
        improvementId: number,
        length: DismissDuration
    ) => {
        await dismissImprovement(improvementId, length)

        refreshDismissedList()

        const recentlyDismissedImprovement = cloneDeep(
            improvements.value.find(i => i.improvement_id === improvementId)
        )

        if (!recentlyDismissedImprovement) throw new Error('No dismissed improvement to show')

        await removeImprovements([improvementId])

        showSnackbar({
            message: `Improvement dismissed. How could we do better?`,
            timeout: 10000,
            actionText: 'Share Feedback',
            actionHandler: () =>
                openDismissFeedbackModal({
                    improvementId,
                    improvementTitle: recentlyDismissedImprovement.title,
                    improvementLocation: recentlyDismissedImprovement.location,
                    improvementRecAction: recentlyDismissedImprovement.rec_action,
                }),
            snackbarMaxWidth: 520,
        })
    }

    const toInject = {
        loading,
        error,
        improvements,
        improvementsGrouped,
        improvementSort,
        updateImprovementSort,
        updateImprovementSortDirection,
        removeImprovements,
        dismissImprovement,
        dismissImprovementWithFeedBack,
        mutateImprovementsList,
    }

    provide(ProvideKeys.ActiveImprovementList, toInject)

    return toInject
}

export function useActiveImprovements() {
    const injected = inject<ReturnType<typeof provideActiveImprovements>>(
        ProvideKeys.ActiveImprovementList
    )

    if (!injected) {
        throw new Error(`activeImprovementList not yet injected, something is wrong. `)
    }

    return injected
}
