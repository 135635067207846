<template>
    <Stack :gap="0" width="100%" direction="column" align="center">
        <StickyHeader :title="day.formattedDate">
            <template v-if="loading" #title>
                <Skeleton :width="150" :height="14" />
            </template>
            <template #right>
                <Button size="small" color="secondary" :disabled="loading" @click="add(day.date)">
                    Add Note
                </Button>
            </template>
        </StickyHeader>
        <Stack
            v-if="hasNotes"
            :padding="20"
            width="100%"
            direction="column"
            align="center"
            borderBottom
        >
            <Note
                v-for="note in day.notes"
                :key="note.id + note.text"
                :note="note"
                :accountId="accountId"
                :loading="loading"
            />
        </Stack>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, computed } from 'vue'

// Composition
import { useNotes } from '@/composition/notes/useNotes'
import { useAccount } from '@/composition/account/useAccount'

// Types
type ISODate = string // YYYY-MM-DD

// Components
import { Stack, StickyHeader, Button, Skeleton } from '@opteo/components-next'
import Note from '@/components/notes/Note.vue'

// Props
const props = defineProps({
    day: {
        type: Object,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Setup
const { accountId } = useAccount()
const { addNote } = useNotes(accountId)

// Computed
const hasNotes = computed(() => {
    return Array.isArray(props.day?.notes) && props.day.notes.length > 0
})

// Functions
const add = async (date: ISODate) => addNote(date)
</script>

<style scoped lang="scss"></style>
