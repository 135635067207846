<template>
    <Container :borderRadius="22" :width="above1280 ? 224 : '100%'" :minWidth="224">
        <Stack direction="column" align="start" width="100%" :gap="18" :padding="20">
            <Container
                :borderRadius="999"
                :padding="[5, 10]"
                color="background-plus-two"
                hideOverflow
            >
                <Text
                    as="span"
                    size="button-small"
                    :color="
                        plan.id === currentPlan?.id
                            ? ['green', 'foreground']
                            : ['primary', 'foreground']
                    "
                >
                    {{ plan.name.replace('Yearly ', '') }}
                </Text>
            </Container>
            <Stack direction="row" :gap="2" align="end" width="auto">
                <Text as="h2" size="headline-two" class="price">{{ formatPrice(plan.price) }}</Text>
                <Text as="h6" size="headline-seven" color="foreground-minus-one" class="interval">
                    /{{ plan.period_interval }}
                </Text>
            </Stack>
        </Stack>
        <Stack direction="column" align="start" width="100%" :padding="[16, 20]" :gap="10">
            <Stack direction="row" :gap="12">
                <PlanCheck :active="currentPlan?.id === plan.id" />

                <Text as="span" size="body-small" v-if="plan.account_limit">
                    <b>{{ plan.account_limit }}</b> Accounts
                </Text>
            </Stack>
            <Stack direction="row" :gap="12">
                <PlanCheck :active="currentPlan?.id === plan.id" />

                <Text as="span" size="body-small">
                    <b>{{ formatPrice(plan.spend_limit) }}</b> Spend/mo
                </Text>
            </Stack>
            <Stack direction="row" :gap="12">
                <PlanCheck :active="currentPlan?.id === plan.id" />

                <Text
                    as="span"
                    size="body-small"
                    v-if="plan.id.includes(Billing.StandardPlan.Enterprise)"
                >
                    Account Manager
                </Text>
                <Text
                    as="span"
                    size="body-small"
                    v-else-if="!plan.id.includes(Billing.StandardPlan.Basic)"
                >
                    Priority Support
                </Text>
                <Text as="span" size="body-small" v-else>Live Chat Support</Text>
            </Stack>
            <Stack direction="row" :gap="12">
                <PlanCheck :active="currentPlan?.id === plan.id" />

                <Text as="span" size="body-small">
                    <b v-if="plan.id.includes(Billing.StandardPlan.Enterprise)">6hr</b>
                    <b v-else-if="!plan.id.includes(Billing.StandardPlan.Basic)">12hr</b>
                    <b v-else>24hr</b> Refresh Cycle
                </Text>
            </Stack>
            <Stack direction="row" :gap="12">
                <PlanCheck
                    :active="currentPlan?.id === plan.id"
                    :checked="!plan.id.includes(Billing.StandardPlan.Basic)"
                />

                <Text as="span" size="body-small">Google Ads Reports</Text>
            </Stack>
        </Stack>
        <Stack width="100%" :padding="20">
            <Button
                ref="selectPlanButton"
                @click="updateSubscription"
                :loading="updatingPlan"
                :color="plan.id === currentPlan?.id ? 'green' : 'secondary-highlight'"
                size="medium"
                :iconAfter="plan.id === currentPlan?.id ? 'Sparkle' : 'ArrowUpRight'"
                fullWidth
            >
                <span v-if="plan.id === currentPlan?.id">Current Plan</span>
                <span v-else-if="isEnterprisePlan">Talk to Sales</span>
                <span v-else>Select Plan</span>
            </Button>
        </Stack>
    </Container>
</template>

<script setup lang="ts">
// Imports
import { computed, PropType, ref } from 'vue'

// Composition
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useUser } from '@/composition/user/useUser'
import { useSubscription } from '@/composition/billing/useSubscription'

// Types
import { Billing } from '@opteo/types'

// Components
import { Container, Stack, Button, Text, useMoney, useMediaQuery } from '@opteo/components-next'
import PlanCheck from '@/components/billing/Plan/PlanCheck.vue'

// Props
const props = defineProps({
    plan: {
        type: Object as PropType<Billing.CurrentPlan>,
        required: true,
    },
})

// Setup
const { currentPlan, mutateUserInfo, totalSpend30d } = useUser()
const { mutate: mutateSubscription, mutateCoupon, subscriptionCurrency } = useSubscription()
const updatingPlan = ref(false)
const selectPlanButton = ref()
const { above1280 } = useMediaQuery()

// Computed
const planID = computed(() => props.plan.id)

const isEnterprisePlan = computed(() =>
    [
        Billing.StandardPlan.Enterprise,
        Billing.StandardPlan.YearlyEnterprise,
        Billing.LegacyPlan.Enterprise,
        Billing.LegacyPlan.YearlyEnterprise,
    ].includes(props.plan.id as Billing.StandardPlan)
)

// Functions
async function updateSubscription() {
    if (updatingPlan.value || planID.value === currentPlan.value?.id) {
        return
    }

    if (isEnterprisePlan.value) {
        window
            .open('https://calendly.com/adamdale/opteo-enterprise-plan-upgrade', '_blank')
            ?.focus()

        return
    }

    updatingPlan.value = true

    try {
        await authRequest(Endpoint.UpdateStripePlan, {
            body: {
                new_plan_id: planID.value,
            },
        })

        const overLimit = props.plan.spend_limit
            ? totalSpend30d.value > props.plan.spend_limit * 1.05
            : false

        mutateUserInfo({ current_plan: props.plan, over_limit: overLimit })

        mutateSubscription()
        mutateCoupon()

        updatingPlan.value = false
        selectPlanButton.value.flashSuccess()
    } catch (err) {
        updatingPlan.value = false
        selectPlanButton.value.flashError()
    }
}

/**
 * @description Takes a raw number and formats it with a leading '$' sign.
 * @example
 * const formattedPrice = formatPrice(25_000)
 * console.log(formattedPrice) // Expected result: '$25,000'
 * @param {number | null} rawPrice
 * Note: `rawPrice` should never be null since we're sending info from existing plans, which have a defined number value.
 * The `Billing.CurrentPlan` type includes `null`s for old plans compatibility reasons.
 * @returns A formatted price (or '$0' if `null`)
 */
const formatPrice = (rawPrice: number | null) => {
    return useMoney({ value: rawPrice ?? 0, currency: subscriptionCurrency.value, compact: false })
        .displayValue.value
}
</script>

<style scoped lang="scss">
.price {
    line-height: 1.625rem;
    @include no-select;
}
.interval {
    @include absolute;
    right: -0.125rem;
    transform: translateX(100%);
    @include no-select;
}
</style>
