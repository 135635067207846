<template>
    <Modal v-model="open" title="Renew Subscription" @closed="clearErrorStates">
        <template #content>
            <Container v-if="currentPlan" :maxWidth="380" :borderRadius="20">
                <!-- Before Update -->
                <Stack v-if="!updateSuccessful" direction="column" :gap="0">
                    <!-- Mini Invoice -->
                    <Stack :padding="[18, 22]" direction="column" width="100%" :gap="11">
                        <Stack v-if="creditCard" align="center" justify="between">
                            <Text size="body-small" color="foreground-minus-one" :wrap="false">
                                Payment Method
                            </Text>
                            <Stack :gap="8" justify="end">
                                <Text size="body-small" weight="semibold" :wrap="false">
                                    **** {{ creditCard.last4 }}
                                </Text>
                                <component :is="paymentMethodLogo" :size="16" />
                            </Stack>
                        </Stack>
                        <Stack align="center" justify="between">
                            <Text size="body-small" color="foreground-minus-one" :wrap="false">
                                Pricing Plan
                            </Text>
                            <Stack :gap="8" justify="end">
                                <Text size="body-small" weight="semibold" :wrap="false">
                                    {{ currentPlan.name }}
                                </Text>
                            </Stack>
                        </Stack>
                        <Stack align="center" justify="between">
                            <Text size="body-small" color="foreground-minus-one" :wrap="false">
                                Due Today
                            </Text>
                            <Stack :gap="8" justify="end">
                                <Text
                                    v-if="latestSubscription?.cancel_at_period_end || trialLength"
                                    size="body-small"
                                    weight="semibold"
                                    :wrap="false"
                                >
                                    $0.00
                                </Text>
                                <Text v-else size="body-small" weight="semibold" :wrap="false">
                                    <Money
                                        :value="currentPlan.price"
                                        :currency="currentPlanCurrency"
                                    />
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                    <!-- Explanatory Text -->
                    <Stack :padding="[18, 22]" direction="column" borderTop align="start">
                        <Text v-if="trialLength" size="body-small">
                            To start your {{ trialLength }} day trial, please click the button
                            below. You are currently subscribed to the
                            <strong>{{ currentPlan.name }}</strong> plan. You will be billed
                            <strong><Money :value="currentPlan.price" currency="USD" /></strong>
                            at the end of your trial.
                        </Text>
                        <p v-else-if="!latestSubscription?.cancel_at_period_end">
                            <Text as="span" size="body-small">To continue, please click the</Text>

                            <Text as="span" weight="bold" color="green" size="body-small"
                                >&nbsp;Renew Subscription&nbsp;</Text
                            >
                            <Text as="span" size="body-small"
                                >button below. You will be subscribed to the
                                <strong>{{ currentPlan.name }}</strong> plan. Your chosen payment
                                method will be charged
                                <strong>
                                    <Money :value="currentPlan.price" currency="USD" /> </strong
                                >.</Text
                            >
                        </p>
                        <p v-else>
                            <Text as="span" size="body-small"
                                >To continue using Opteo after your subscription expires, click
                                the</Text
                            >
                            <Text as="span" size="body-small" weight="bold" color="green"
                                >&nbsp;Renew Subscription&nbsp;</Text
                            >
                            <Text as="span" size="body-small"
                                >button below. You will be subscribed to the
                                <strong>{{ currentPlan.name }}</strong> plan. Your next invoice will
                                be for
                                <strong><Money :value="currentPlan.price" currency="USD" /></strong
                                >, to be paid on <strong>{{ cancelsOn }}</strong
                                >.</Text
                            >
                        </p>
                        <Text v-if="!creditCard" color="red" size="body-small">
                            Please <strong>update your payment method</strong> before renewing.
                        </Text>
                        <ErrorMessage v-if="subscriptionUpdateError">
                            {{ subscriptionUpdateError }}
                        </ErrorMessage>
                    </Stack>
                </Stack>
                <!-- After Update -->
                <Stack v-else width="100%">
                    <EmptyState
                        icon="SparklesExtraLarge"
                        :message="successMessage"
                        hideContainer
                        width="100%"
                        height="100%"
                    />
                </Stack>
            </Container>
        </template>
        <template #buttons>
            <template v-if="!updateSuccessful">
                <Button color="secondary" @click="showRenewModal = false" class="cancel-button">
                    Cancel
                </Button>
                <Button
                    v-if="updateErrorAction === 'support'"
                    ref="renewButton"
                    color="secondary"
                    @click="showNewMessage()"
                >
                    Contact Support
                </Button>

                <Button
                    v-else-if="updateErrorAction === 'payment_method' || !creditCard"
                    ref="renewButton"
                    color="primary"
                    @click="emit('update-payment-method')"
                >
                    Update Payment Method
                </Button>

                <Button
                    v-else-if="trialLength && !updateErrorAction"
                    ref="renewButton"
                    color="green"
                    :loading="updatingSubscription"
                    @click="renewSubscription()"
                >
                    Start {{ trialLength }} Day Trial
                </Button>

                <Button
                    v-else-if="!latestSubscription?.cancel_at_period_end && !updateErrorAction"
                    ref="renewButton"
                    color="green"
                    :loading="updatingSubscription"
                    @click="renewSubscription()"
                >
                    Renew Subscription
                </Button>

                <Button
                    v-else-if="!updateErrorAction"
                    ref="renewButton"
                    color="green"
                    :loading="updatingSubscription"
                    @click="renewSubscription()"
                >
                    Renew Subscription
                </Button>
            </template>
            <template v-else>
                <Button color="secondary" @click="showRenewModal = false">Close Modal</Button>
                <Button color="primary" @click="showRenewModal = false">Continue</Button>
            </template>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, computed, ref, watch } from 'vue'
import { useIntercom } from '@/lib/intercom/useIntercom'

// Composition
import { useSubscription } from '@/composition/billing/useSubscription'
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useUser } from '@/composition/user/useUser'

// Stripe
import { STRIPE_PUBLISHABLE_KEY } from '@/lib/env'
import { loadStripe, Stripe } from '@stripe/stripe-js'

// Components
import {
    Money,
    Button,
    Text,
    Stack,
    Modal,
    Container,
    EmptyState,
    useMoney,
    ErrorMessage,
    // Payment Logos
    VisaLogo,
    MastercardLogo,
    MaestroLogo,
    AmexLogo,
    StripeLogo,
} from '@opteo/components-next'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue', 'update-payment-method'])

// Setup
const open = ref(false)
const { trialLength, cancelsOn, creditCard, paymentMethod, latestSubscription, mutate } =
    useSubscription()
const { currentPlan, currentPlanCurrency, userId, userInfo } = useUser()
let stripe: Stripe | null = null
const renewSubscriptionLoading = ref(false)

const loadStripeElements = async () => {
    showRenewModal.value = true

    renewSubscriptionLoading.value = false
    stripe = await loadStripe(STRIPE_PUBLISHABLE_KEY)
}

const renewButton = ref()
const updateSuccessful = ref(false)
const updateErrorAction = ref()
const updatingSubscription = ref(false)
const showRenewModal = ref(false)
const subscriptionUpdateError = ref()
const { showNewMessage } = useIntercom()

async function renewSubscription() {
    if (paymentMethod.value && stripe) {
        renewSubscriptionLoading.value = true
        updatingSubscription.value = true

        try {
            const response = await authRequest(Endpoint.RenewSubscription)
            const requiresAction =
                (response?.latest_invoice?.payment_intent?.status === 'requires_action' ||
                    response?.latest_invoice?.payment_intent?.status ===
                        'requires_payment_method') &&
                response?.status === 'incomplete'

            if (requiresAction) {
                const clientSecret = response?.latest_invoice?.payment_intent?.client_secret

                const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: paymentMethod.value,
                })

                if (error) {
                    throw error
                }

                await authRequest(Endpoint.HandleSuccessfullRenewal, {
                    body: {
                        user_id: userId.value,
                        group_id: userInfo.value?.group_id,
                        customer_id: latestSubscription.value?.customer,
                        name: userInfo.value?.name,
                        username: userInfo.value?.username,
                        opteo_lifecycle_stage: userInfo.value?.opteo_lifecycle_stage,
                        stripe_subscription_status:
                            paymentIntent.status === 'succeeded' ? 'active' : paymentIntent.status,
                    },
                })
            }

            mutate()

            updatingSubscription.value = false
            renewButton.value?.flashSuccess()

            setTimeout(() => (showRenewModal.value = false), 1000)
        } catch (error) {
            updatingSubscription.value = false

            subscriptionUpdateError.value = 'Something went wrong, please contact support.'
            updateErrorAction.value = 'support'
        }
    }
}

function clearErrorStates() {
    subscriptionUpdateError.value = null
    updateErrorAction.value = null
}

// Computed
const successMessage = computed(() => {
    const currentPlanPrice = useMoney({
        currency: currentPlanCurrency.value,
        value: currentPlan.price,
    }).displayValue.value

    if (!latestSubscription?.cancel_at_period_end && !trialLength) {
        return `Your payment of ${currentPlanPrice} was successful. If you have any questions or need assistance, please don't hesitate to message our support team.`
    } else
        return `Thanks for resubscribing to Opteo. If you have any questions or need any assistance, please don't hesitate to message our support team.`
})

const paymentMethodLogo = computed(() => {
    if (!creditCard.value) {
        return null
    }

    const brand = creditCard.value.brand.toLowerCase()
    const logos: Record<string, any> = {
        visa: VisaLogo,
        mastercard: MastercardLogo,
        maestro: MaestroLogo,
        amex: AmexLogo,
        stripe: StripeLogo,
    }

    return logos[brand] ?? null
})

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
