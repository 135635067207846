<template>
    <div class="page-wrapper">
        <ScorecardContent
            v-if="isScorecardAvailable"
            :scorecardBody="scorecard.body"
            :accountInfo="scorecard.account"
            :createdAt="scorecard.createdAt"
            :overviewScore="scorecard.overviewScore"
            :showSection="sectionShown"
            mode="pdf"
        />
    </div>
</template>

<script lang="ts">
import { computed, ref, provide, onMounted } from 'vue'
import { useRouter } from 'vue-router'

import ScorecardContent from '@/components/scorecard/ScorecardContent.vue'
import { handleIsScorecardAvailable } from '@/components/scorecard/utils'

import type { Scorecard } from '@opteo/types'

const baseUrl = 'https://opteo-netlify-cache.netlify.app/.netlify/functions'

export const getBlobCache = async (key: string) => {
    const data = await fetch(`${baseUrl}/cache-get`, {
        method: 'POST',
        body: JSON.stringify({ key }),
    })

    return data.json()
}

export default {
    name: 'ScorecardPdf',
    components: {

//         ScorecardContent,
    
},
    setup() {
        const router = useRouter()
        const scorecardTemporaryId = router.currentRoute.value.query.scorecardTemporaryId as string

        const scorecard = ref<Scorecard.SingleScorecardFull>()
        onMounted(async () => {
            scorecard.value = await getBlobCache(scorecardTemporaryId)
        })

        const sectionShown = router.currentRoute.value.query
            .section as Scorecard.SectionTypeIncCover

        const isScorecardAvailable = computed(
            () => scorecard.value && handleIsScorecardAvailable(scorecard.value)
        )

        provide('isPdf', true)

        return {
            isScorecardAvailable,
            scorecard,
            sectionShown,
        }
    },
}
</script>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .page-wrapper {
// //     padding-top: 6rem;
// //     padding-bottom: 6rem;
// //     max-width: 1148px;
// //     margin: 0 auto;
// // }


</style>
