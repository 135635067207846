<template>
    <svg width="30" height="28" viewBox="0 0 30 28" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M15 9.99933c2.2091 0 4-1.79086 4-4s-1.7909-4-4-4c-2.2091 0-4 1.79086-4 4s1.7909 4 4 4Zm0 1.99997c3.3137 0 6-2.68626 6-5.99997 0-3.31371-2.6863-6.00000139-6-6.00000139S9 2.68562 9 5.99933c0 3.31371 2.6863 5.99997 6 5.99997Zm9 14c2.2091 0 4-1.7908 4-4 0-2.2091-1.7909-4-4-4-2.2091 0-4 1.7909-4 4 0 2.2092 1.7909 4 4 4Zm0 2c3.3137 0 6-2.6863 6-6s-2.6863-6-6-6-6 2.6863-6 6 2.6863 6 6 6Zm-14-6c0 2.2092-1.79086 4-4 4s-4-1.7908-4-4c0-2.2091 1.79086-4 4-4s4 1.7909 4 4Zm2 0c0 3.3137-2.68629 6-6 6s-6-2.6863-6-6 2.68629-6 6-6 6 2.6863 6 6Z"
            fill="#373744"
        />
    </svg>
</template>
