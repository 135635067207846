<template>
    <PageContent>
        <oTable
            v-if="
                !tableDataLoading &&
                !tableDataIsValidating &&
                !accountDataLoading &&
                !tableDataUpdatedDebounced
            "
            :headers="tableHeaders"
            :items="tableItems"
            :fixed-layout="true"
            :border-radius="20"
            responsive-breakpoint="768px"
        >
            <template v-slot:[`column.type`]="row">
                <Text size="f-8">{{ row.row.type }}</Text>
            </template>

            <template v-slot:[`column.delta`]="delta">
                <Delta :delta="delta.row.delta.delta" :reverse="delta.row.delta.reverse" />
            </template>
        </oTable>

        <oTable
            v-else
            :headers="tableHeaders"
            :items="new Array(15).fill({})"
            :border-radius="20"
            responsive-breakpoint="768px"
        >
            <template v-slot:[`column.type`]
                ><Skeleton :height="16" :width="70" class="skeleton-margin"
            /></template>
            <template v-slot:[`column.metric`]
                ><Skeleton :height="16" :width="200" class="skeleton-margin"
            /></template>
            <template v-slot:[`column.previousRange`]
                ><Skeleton :height="16" :width="48" class="skeleton-margin"
            /></template>
            <template v-slot:[`column.selectedRange`]
                ><Skeleton :height="16" :width="48" class="skeleton-margin"
            /></template>
            <template v-slot:[`column.delta`]
                ><Skeleton :height="16" :width="82" class="skeleton-margin"
            /></template>
        </oTable>
    </PageContent>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

// import { oTable, Text, Delta, ColorTag } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'
import { useTable } from '@/composition/performance/useTable'

export default defineComponent({
    // components: { oTable, Text, Delta, Skeleton, ColorTag },
    name: 'PerformanceTable',
    setup() {
        return useTable()
    },
})
</script>

<style scoped lang="scss">
// // @import '@/assets/css/theme.scss';

// // .skeleton-margin {
// //     margin-top: 3px;
// //     margin-bottom: 3px;
// // }
</style>
