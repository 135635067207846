<template>
    <Container :width="296" :borderRadius="22">
        <Stack :padding="[18, 22]" borderBottom>
            <Text size="body-small">
                If you have a discount code, you can add it here. If your code does not work for any
                reason, please message support.
            </Text>
        </Stack>
        <Stack v-if="coupon" :padding="[18, 22]" borderBottom>
            <Stack direction="column" :gap="12" align="start">
                <Stack direction="row" :gap="8" align="center">
                    <Dot :size="10" />
                    <Text as="h6" size="button">
                        {{ coupon.promotionCode?.code ?? coupon.id }}
                    </Text>
                </Stack>
                <Container
                    v-if="coupon?.percent_off"
                    :borderRadius="999"
                    :padding="[4, 11]"
                    color="background-plus-two"
                    hideOverflow
                >
                    <Text as="span" size="body-extra-small" color="foreground">
                        {{ coupon.percent_off }}% Discount
                        <span v-if="coupon.duration != 'forever'">for </span>
                        <b>
                            {{
                                coupon.duration === 'forever'
                                    ? 'Forever'
                                    : coupon.duration === 'repeating' &&
                                        (coupon.duration_in_months ?? 0) > 0
                                      ? `${coupon.duration_in_months} Months`
                                      : undefined
                            }}
                        </b>
                    </Text>
                </Container>
                <Container
                    v-if="coupon?.amount_off"
                    :borderRadius="999"
                    :padding="[4, 11]"
                    color="background-plus-two"
                    hideOverflow
                >
                    <Text as="span" size="body-extra-small" color="foreground">
                        ${{ coupon.amount_off / 100 }} Discount
                        <span v-if="coupon.duration != 'forever'">for </span>
                        <b>
                            {{
                                coupon.duration === 'forever'
                                    ? 'Forever'
                                    : coupon.duration === 'repeating' &&
                                        (coupon.duration_in_months ?? 0) > 0
                                      ? `${coupon.duration_in_months} Months`
                                      : undefined
                            }}
                        </b>
                    </Text>
                </Container>
            </Stack>
        </Stack>
        <Form validateOnSubmit @submitted="addCouponCode">
            <Stack :padding="[20, 22, 22, 22]" borderBottom>
                <TextInput
                    name="couponCode"
                    v-model="couponCode"
                    label="Discount Code"
                    ref="couponCodeInput"
                    required
                />
            </Stack>
            <Stack :padding="[22, 0]" justify="center">
                <Button color="secondary-highlight" size="small" :loading="loading" submit>
                    Apply Discount Code
                </Button>
            </Stack>
        </Form>
    </Container>
</template>

<script setup lang="ts">
import { Button, Container, Form, Stack, TextInput, Text, Dot } from '@opteo/components-next'

import { ref } from 'vue'

import { useSubscription } from '@/composition/billing/useSubscription'
import { authRequest, Endpoint } from '@/composition/api/useAPI'

import { Billing } from '@opteo/types'

const couponCode = ref('')
const couponCodeInput = ref()
const couponCodeButton = ref()

const loading = ref(false)

const { coupon, mutateCoupon } = useSubscription()

async function handleCouponApplicationError(error: any) {
    const message = (error as Error)?.message ?? Billing.CouponApplicationError.UNKNOWN_ERROR

    const errorMessages: Record<string, string> = {
        [Billing.CouponApplicationError.INVALID_CODE]: 'Invalid Discount Code',
        [Billing.CouponApplicationError.NO_SUBSCRIPTION_OR_PRODUCT]:
            'Please start new free trial before using discount code',
        [Billing.CouponApplicationError.UNKNOWN_ERROR]: 'Unknown error. Please contact support',
    }

    const userErrorMessage =
        errorMessages[message] ?? errorMessages[Billing.CouponApplicationError.UNKNOWN_ERROR]

    couponCodeInput.value.setError(userErrorMessage)
}

async function addCouponCode() {
    if (!couponCode.value) {
        return
    }

    loading.value = true

    try {
        const res = await authRequest(Endpoint.SaveCouponForUser, {
            body: { coupon_code: couponCode.value },
        })

        if (res.error || !res.coupon) {
            throw new Error(res.error)
        }

        mutateCoupon(() => res.coupon)
        loading.value = false
        couponCodeButton.value.flashSuccess()
    } catch (err) {
        loading.value = false
        handleCouponApplicationError(err)
    }
}
</script>
