import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import { User } from '@opteo/types'

import { useAPI, Endpoint, noAuthRequest } from '@/composition/api/useAPI'

interface UserProfile {
    ['profile']: User.Info
    message?: string
    error?: boolean
    status?: number
    statusText?: string
}

interface NewPasswordRes {
    data?: object | string
    meta?: { version: string; type: string; original_request: string }
}

export function useInviteWelcome() {
    let password = ref()
    let password2 = ref()
    const showForm = ref(true)
    const route = useRoute()
    const { params } = route
    let passwordRes = ref()
    const passwordLengthError = ref(false)
    const passwordMatchError = ref(false)

    let { data: user, loading } = useAPI<UserProfile>(Endpoint.GetUserInfoFromHash, {
        body: {
            hash: params.hash,
        },
        uniqueId: () => params.hash as string,
        waitFor: () => params.hash as string,
    })

    const errorMessage = computed(() => {
        if (passwordLengthError.value) {
            return `Password must be at least 8 characters.`
        } else if (passwordMatchError.value) {
            return `Passwords do not match.`
        } else if (!!getUserInfoHashError.value || !!updatePasswordHashError.value) {
            return 'Invite link expired or invalid.'
        }

        return 'Something went wrong.'
    })

    const showError = computed(() => {
        if (
            getUserInfoHashError.value ||
            updatePasswordHashError.value ||
            passwordLengthError.value ||
            passwordMatchError.value ||
            updatePasswordError.value
        ) {
            return true
        }
        return false
    })

    const unrecoverable = computed(() => {
        if (!loading.value && user.value && user.value?.error) {
            return true
        }
        return false
    })

    const getUserInfoHashError = computed(() => {
        return !loading.value && user.value && user?.value?.message?.includes('link')
    })

    const updatePasswordHashError = computed(() => {
        return (
            typeof passwordRes?.value?.data === 'string' &&
            passwordRes?.value?.data?.includes('hash')
        )
    })
    const updatePasswordError = computed(() => {
        return (
            typeof passwordRes?.value?.data === 'string' &&
            passwordRes?.value?.data?.includes('error')
        )
    })

    const formLoading = ref(false)
    const newPasswordInput = ref()
    const confirmPasswordInput = ref()

    function newPasswordValidator(value: string | undefined) {
        if (!value || typeof value !== 'string') {
            return 'Password is required.'
        }

        if (value.length < 6 || !/\d/.test(value)) {
            return 'Please choose a password with at least 6 characters, including a number.'
        }
    }

    async function createPassword(formData: Record<string, any>) {
        // Directly access the keys
        const newPassword = formData['newPassword']
        const confirmPassword = formData['confirmPassword']

        if (!newPassword || !confirmPassword) {
            newPasswordInput.value?.setError('Password is required.')
            return
        }

        if (newPassword !== confirmPassword) {
            confirmPasswordInput.value?.setError('Passwords must match.')
            return
        }

        const error = newPasswordValidator(newPassword)
        if (error) {
            newPasswordInput.value?.setError(error)
            return
        }

        formLoading.value = true

        passwordRes.value = await noAuthRequest<NewPasswordRes>(Endpoint.SetNewPasswordFromHash, {
            meta: {
                function: 'auth:setNewPasswordFromHash',
                args: {
                    hash: params.hash,
                    password: newPassword,
                },
            },
        })

        formLoading.value = false

        if (!updatePasswordHashError.value && !updatePasswordError.value) {
            showForm.value = false
        }
    }

    return {
        loading,
        errorMessage,
        showError,
        unrecoverable,
        showForm,
        password,
        password2,
        createPassword,
        formLoading,
        newPasswordInput,
        confirmPasswordInput,
        newPasswordValidator,
    }
}
