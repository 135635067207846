import { computed, ref, watch } from 'vue'
import { RouteRecordName, useRouter } from 'vue-router'

import { Routes } from '@/router/routes'
import { useAccount } from './useAccount'

export type UseAccountSetupBar = ReturnType<typeof useAccountSetupBar>

export type Step = {
    key: string
    name: string
    route: RouteRecordName
}

export type Steps = 'account-setup' | 'targets'

type StepValidators = Partial<
    Record<
        Steps,
        {
            callback: () => void
            loading: boolean
            error: boolean
        }
    >
>

// Account Setup bar states
const setupActive = ref(false)
const activeStepKey = ref<string | null>(null) // Now uses key instead of index

// Validators for each step
const stepValidators = ref<StepValidators>({
    'account-setup': {
        callback: () => {},
        loading: false,
        error: false,
    },
})

export function useAccountSetupBar() {
    const steps = [
        { key: 'account-setup', label: 'Account Setup', route: Routes.Setup },
        { key: 'targets', label: 'Targets', route: Routes.Targets },
    ]

    const { accountColor, accountInitials, accountPlatform } = useAccount()
    const router = useRouter()
    const nextStepButton = ref()

    function skipAccountSetup() {
        router.push({ name: Routes.ImprovementsActive })
    }

    async function goToCampaignGroups() {
        const validator = stepValidators.value?.['account-setup']
        if (validator) {
            validator.loading = true
            await validator.callback()
            validator.loading = false

            if (validator.error) {
                nextStepButton.value?.flashError()
                return
            }
        }

        router.push({ name: Routes.Targets })
        nextStep()
    }

    function completeAccountSetup() {
        hideAccountSetupBar()
        router.push({ name: Routes.ImprovementsActive })
    }

    function stepClicked(stepKey: string) {
        setStepKey(stepKey)
        const step = steps.find(s => s.key === stepKey)
        if (step) {
            router.push({ name: step.route })
        }
    }

    const { currentRoute } = useRouter()

    // Find current step based on key
    const currentStep = computed(() => steps.find(step => step.key === activeStepKey.value) || null)

    function setStepKey(stepKey: string) {
        activeStepKey.value = stepKey
    }

    function nextStep() {
        const currentIndex = steps.findIndex(step => step.key === activeStepKey.value)
        if (currentIndex >= 0 && currentIndex < steps.length - 1) {
            setStepKey(steps[currentIndex + 1].key)
        }
    }

    function showAccountSetupBar() {
        setupActive.value = true
    }
    function hideAccountSetupBar() {
        setupActive.value = false
    }

    // Keep step in sync with current route
    watch(currentRoute, newValue => {
        const step = steps.find(s => s.route === newValue.name)
        if (setupActive.value && step) {
            setStepKey(step.key)
        } else if (setupActive.value && !step) {
            hideAccountSetupBar()
        }
    })

    return {
        showAccountSetupBar,
        hideAccountSetupBar,
        setupActive,
        activeStepKey,
        setStepKey,
        currentStep,
        skipAccountSetup,
        steps,
        completeAccountSetup,
        stepClicked,
        stepValidators,
        goToCampaignGroups,
        nextStepButton,
        accountColor,
        accountInitials,
        accountPlatform,
    }
}
