import { ref, computed, onMounted, reactive, watch } from 'vue'
import { formatDistanceToNow, parseISO } from 'date-fns'
import { showSnackbar } from '@opteo/components-next'

import { useUser } from '@/composition/user/useUser'
import { authRequest, Endpoint, useAPI } from '@/composition/api/useAPI'
import { useTeam } from './useTeam'
import type { TeamMember } from './useTeam'
import { delay } from '@/lib/globalUtils'
import { useIntercom } from '@/lib/intercom/useIntercom'
import { UserSettings } from '@opteo/types'

export function useUserSettings() {
    const {
        userInfo: user,
        isAdmin,
        loading: userLoading,
        mutateUserInfo,
        groupId,
        userId,
    } = useUser()

    /* 
        Password section 
    */

    const savePasswordButton = ref()
    const currentPassword = ref()
    const newPasswordInput = ref('')
    const confirmPassword = ref()
    const passwordButtonLoading = ref(false)

    const submitNewPassword = async (values: {
        currentPassword: string
        newPassword: string
        confirmPassword: string
    }) => {
        if (values.newPassword !== values.confirmPassword) {
            confirmPassword.value.setError('Passwords must match')
            return
        }

        passwordButtonLoading.value = true
        try {
            await setNewPassword(values.currentPassword, values.newPassword)

            showSnackbar({
                message: `Password updated successfully`,
                timeout: 3000,
            })
        } catch (err: any) {
            // TODO: modify the backend to get more user friendly error messages
            currentPassword.value.setError('Incorrect password')
        }
        passwordButtonLoading.value = false
    }

    const setNewPassword = async (currentPassord: string, newPassword: string) => {
        if (!user.value?.username) {
            throw new Error('username data was not ready')
        }

        return authRequest(Endpoint.ChangeUserPassword, {
            body: {
                username: user.value.username,
                password: currentPassord,
                new_password: newPassword,
            },
        })
    }

    const newPasswordValidator = (value: string) => {
        if (value.length < 8) {
            return 'Please choose a password with at least 8 characters'
        }
    }

    const passwordsMatchValidator = (value: string) => {
        if (newPasswordInput.value !== value) {
            return 'Passwords must match.'
        }
    }

    /* 
        Auto-close improvements section 
    */

    const autoCloseImprovements = computed<boolean>(() => !!user.value?.auto_close_improvement)

    const setAutoCloseImprovements = async (autoClose: boolean) => {
        await authRequest(Endpoint.UpdateAutoCloseImprovements, {
            body: { auto_close_improvement: autoClose },
        })

        mutateUserInfo()
    }

    /* 
        Team members section 
    */
    const inviteModalShown = ref(false)
    const removeModalShown = ref(false)

    const inviteUserEmailInput = ref()
    const inviteUserEmailModel = ref()
    const inviteUserNameModel = ref()
    const inviteMemberLoading = ref(false)
    const intercom = useIntercom()
    const memberToRemove = ref<{ name: string; user_id: number }>()

    const { team, mutateTeam } = useTeam()

    const teamTableHeaders = computed(() => {
        const _teamTableHeaders = [
            { key: 'name', label: 'Name', sortable: true, maxWidth: 200, constrainGradient: true },
            { key: 'email', label: 'Email Address', sortable: true, constrainGradient: true },
            { key: 'status', label: 'Status', sortable: true },
            { key: 'lastLogin', label: 'Last Active', sortable: true },
        ]

        return _teamTableHeaders
    })

    const teamTableRows = computed(() => {
        if (!team.value) {
            return []
        }
        return team.value.map(member => {
            return {
                ...member,
                id: member.user_id,
                lastLogin: member.last_login_date
                    ? formatDistanceToNow(parseISO(member.last_login_date), { addSuffix: true })
                    : 'never',
                canBeRemoved: user.value?.user_id !== member.user_id && isAdmin.value,
            }
        })
    })

    const findTeamMember = (userId: number) =>
        teamTableRows.value.find(user => user.user_id === userId)

    const addMember = async (values: { inviteUserEmail: string; inviteUserName: string }) => {
        inviteMemberLoading.value = true
        try {
            await authRequest(Endpoint.AddTeamMember, {
                body: { member_email: values.inviteUserEmail, member_name: values.inviteUserName },
            })

            mutateTeam()
            inviteModalShown.value = false
            inviteUserNameModel.value = ''
            inviteUserEmailModel.value = ''
            intercom.trackEvent('invited_team_member')
        } catch (err: any) {
            inviteUserEmailInput.value.setError(err.message)
        }

        inviteMemberLoading.value = false
    }

    const setTeamMemberRole = async ({ user_id: memberUserId }: TeamMember, role: string) => {
        if (!team.value) {
            throw new Error('team is empty')
        }

        if (user.value?.role !== 'admin') {
            throw new Error('Insufficient permissions to modify user role')
        }

        await authRequest(Endpoint.SetTeamMemberRole, {
            body: { teamId: groupId.value, memberUserId, role },
        })

        showSnackbar({
            message: `User role updated successfully`,
            timeout: 3000,
        })

        mutateTeam()
    }

    const confirmRemoveTeamMember = ({ name, email, user_id: member_user_id }: TeamMember) => {
        memberToRemove.value = { name: name || email, user_id: member_user_id }
        removeModalShown.value = true
    }

    const removeTeamMember = async () => {
        if (!team.value || !memberToRemove.value) {
            throw new Error('team is empty or memberToRemove is empty')
        }

        if (user.value?.role !== 'admin') {
            throw new Error('Insufficient permissions to delete user')
        }

        const memberUserId = memberToRemove.value.user_id
        const adminUserId = userId.value

        const updatedTeam = (team.value ?? []).filter(user => user.user_id !== memberUserId)

        mutateTeam(updatedTeam)

        removeModalShown.value = false
        memberToRemove.value = undefined

        await authRequest(Endpoint.RemoveTeamMember, {
            body: { teamId: groupId.value, adminUserId, memberUserId },
        })

        mutateTeam()
    }

    const focusInviteUserEmailInput = async () => {
        await delay(1)
        inviteUserEmailInput.value.focus()
    }

    /* 
        Profile section
    */
    const profileButtonLoading = ref(false)
    const updateProfileButton = ref()
    const profileEmailInput = ref()
    const uploadedImageUrl = ref('')

    const submitProfileChange = async (values: { name: string; email: string }) => {
        profileButtonLoading.value = true

        const result = await authRequest(Endpoint.UpdateUserInfo, {
            body: {
                user_name: values.name,
                user_email: values.email,
            },
        })

        mutateUserInfo()
        mutateTeam()

        if (result.message === 'UPDATE_SUCCESSFUL') {
            showSnackbar({
                message: `Profile updated successfully`,
                timeout: 3000,
            })
        } else {
            const error_message =
                result.message === 'ER_DUP_ENTRY'
                    ? 'This email already exists'
                    : 'Something went wrong, try again later'
            profileEmailInput.value.setError(error_message)
        }

        profileButtonLoading.value = false
    }

    const onImageUploaded = async (uploadedImage: string) => {
        uploadedImageUrl.value = uploadedImage
        mutateUserInfo()
    }

    const onBeforeImageRemove = () => {
        uploadedImageUrl.value = ''
        mutateUserInfo({ avatar_filename: '' })
    }

    const onAfterImageRemove = () => {
        mutateUserInfo()
    }

    /*
        Alerts section
    */

    const { data: emailSettings } = useAPI<UserSettings.EmailAlertSettings>(
        Endpoint.GetEmailSettings
    )
    const { data: slackSettings } = useAPI<UserSettings.SlackAlertSettings>(
        Endpoint.GetSlackSettings
    )

    const defaults = {
        kpi: false,
        budget: false,
        improvement: false,
        conversion: false,
        flatline: false,
    }

    const alertChannels = computed(() => ({
        slack: {
            type: 'slack',
            display: 'Slack',
            settings: slackSettings.value?.slack ?? defaults,
        },
        email: {
            type: 'email',
            display: 'Email',
            settings: emailSettings.value?.email ?? defaults,
        },
    }))

    const alertTypes = [
        'kpi',
        'budget',
        'improvement',
        'conversion',
        'impressionsFlatline',
        'conversionActionsFlatline',
    ]
    const alertTypesInfo = [
        {
            slug: 'kpi',
            label: 'Unexpected Changes',
            description:
                'Get alerts when key metrics (like Impressions and Cost) spike over a short period of time.',
        },
        {
            slug: 'budget',
            label: 'Budget Alerts',
            description:
                'When a budget exceeds a certain threshold, get an alert with plenty of time to make changes.',
        },
        {
            slug: 'improvement',
            label: 'New Improvements',
            description:
                'Get alerts whenever new improvements become available in any of your linked accounts.',
        },
        {
            slug: 'conversion',
            label: 'Performance Records',
            description:
                'Get regular updates on conversion performance across all of your linked accounts.',
        },
        {
            slug: 'impressionsFlatline',
            label: 'Zero Impressions',
            description:
                "Receive alerts when impressions flatline. Ensure you're prepared for any worst-case scenarios.",
        },
        {
            slug: 'conversionActionsFlatline',
            label: 'Zero Conversions',
            description:
                'Get alerts when a conversion type flatlines. Catch conversion tracking issues early.',
        },
    ]

    const alertSettingsLoading = computed(() => !emailSettings.value || !slackSettings.value)

    const updateAlertSettings = async () => {
        await Promise.all([
            authRequest(Endpoint.SaveSlackSettings, {
                body: {
                    new_settings: alertChannels.value.slack.settings,
                    get_muted_accounts: true,
                },
            }),
            authRequest(Endpoint.SaveEmailSettings, {
                body: { new_settings: alertChannels.value.email.settings },
            }),
        ])
    }

    return {
        setNewPassword,
        setAutoCloseImprovements,
        passwordFormReady: userLoading,
        user,
        submitNewPassword,
        currentPassword,
        newPasswordInput,
        confirmPassword,
        savePasswordButton,
        passwordButtonLoading,
        newPasswordValidator,
        passwordsMatchValidator,
        autoCloseImprovements,
        teamTableHeaders,
        teamTableRows,
        addMember,
        inviteModalShown,
        removeModalShown,
        focusInviteUserEmailInput,
        inviteUserEmailInput,
        inviteUserEmailModel,
        inviteUserNameModel,
        inviteMemberLoading,
        findTeamMember,
        removeTeamMember,
        confirmRemoveTeamMember,
        memberToRemove,
        setTeamMemberRole,
        submitProfileChange,
        profileButtonLoading,
        updateProfileButton,
        profileEmailInput,
        uploadedImageUrl,
        onImageUploaded,
        onBeforeImageRemove,
        onAfterImageRemove,
        alertChannels,
        alertTypes,
        alertTypesInfo,
        emailSettings,
        slackSettings,
        updateAlertSettings,
        alertSettingsLoading,
        mutateUserInfo,
    }
}
