import { useRouter } from 'vue-router'

import { useCreateAccount } from './useCreateAccount'
import { API_URL, STYTCH_PUBLIC_TOKEN, IS_PRODUCTION } from '@/lib/env'

type Strategy = 'google' | 'microsoft' | 'linkedin'

export function useStytchSignIn() {
    const router = useRouter()
    const currentPath = router.currentRoute.value.fullPath

    const { ati, planId } = useCreateAccount()

    const STYTCH_BASE_URL = IS_PRODUCTION ? 'https://login.opteo.com' : 'https://test.stytch.com'

    const STYTCH_OAUTH_URL = `${STYTCH_BASE_URL}/v1/public/oauth/:strategy/start?public_token=${STYTCH_PUBLIC_TOKEN}`

    const login = async (strategy: Strategy) => {
        const customScopes = strategy === 'microsoft' ? `&custom_scopes=User.Read` : ''

        const redirectUrl =
            STYTCH_OAUTH_URL.replace(':strategy', strategy) +
            customScopes +
            `&signup_redirect_url=${API_URL}/login/stytch/oauth/callback/signup` +
            `&login_redirect_url=${API_URL}/login/stytch/oauth/callback/signin`

        window.location.href = redirectUrl
    }

    const register = async (strategy: Strategy) => {
        const params = {
            ati: ati.value ?? '',
            referral: window.Rewardful?.referral ?? '',
            coupon: (window.Rewardful?.coupon && window?.Rewardful?.coupon?.id)?.toString() ?? '',
            accountCreationDomain: window.location.hostname,
            planId: planId.value,
        }

        const customScopes = strategy === 'microsoft' ? `&custom_scopes=User.Read` : ''

        const redirectUrl =
            STYTCH_OAUTH_URL.replace(':strategy', strategy) +
            customScopes +
            `&signup_redirect_url=${API_URL}/login/stytch/oauth/callback/signup?opteoParams=${btoa(JSON.stringify(params))}` +
            `&login_redirect_url=${API_URL}/login/stytch/oauth/callback/signin`

        window.location.href = redirectUrl
    }

    const stytchSSO = async (type: 'login' | 'createaccount', strategy: Strategy) => {
        try {
            if (type === 'createaccount') {
                await register(strategy)
            } else {
                await login(strategy)
            }
        } catch (err: any) {
            const errorMessage = err.message

            router.push({
                name: currentPath,
                query: { error: errorMessage ?? 'There was an error logging in' },
            })
        }
    }

    return {
        stytchSSO,
    }
}
