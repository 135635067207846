<template>
    <Modal v-model="showSelectPlanModal" title="Pricing Plans">
        <template #title>
            <Stack direction="row">
                <Text as="h3" size="headline-three" :wrap="false">Pricing Plans</Text>
                <Tabs
                    v-if="!above1280"
                    size="small"
                    :active="monthlyPlansVisible ? 'monthly' : 'yearly'"
                    @clicked="setActiveBillingDuration"
                    :items="[
                        {
                            key: 'monthly',
                            label: 'Monthly',
                        },
                        {
                            key: 'yearly',
                            label: 'Yearly',
                        },
                    ]"
                >
                    <template #yearly>
                        <Text as="span">Yearly</Text>
                    </template>
                </Tabs>
            </Stack>
        </template>
        <template #content>
            <Container
                width="100%"
                :minWidth="above1280 ? '80vw' : '100%'"
                :padding="containerPadding"
                :borderRadius="20"
                color="transparent"
            >
                <Stack direction="column" justify="center" :gap="32">
                    <Tabs
                        v-if="above1280"
                        size="small"
                        :active="monthlyPlansVisible ? 'monthly' : 'yearly'"
                        @clicked="setActiveBillingDuration"
                        :items="[
                            {
                                key: 'monthly',
                                label: 'Monthly',
                            },
                            {
                                key: 'yearly',
                                label: 'Yearly',
                            },
                        ]"
                    >
                        <template #yearly>
                            <Text as="span">Yearly</Text>
                            <Container
                                :color="['red', 'background-plus-two']"
                                :borderRadius="999"
                                :padding="[4, 8]"
                                hideOverflow
                                class="months-free"
                            >
                                <Text as="p" size="label-small" color="white">2 Months Free</Text>
                            </Container>
                        </template>
                    </Tabs>
                    <Stack
                        v-if="pricingPlans"
                        :direction="above1280 ? 'row' : 'column'"
                        justify="center"
                        width="100%"
                        height="100%"
                        :gap="20"
                    >
                        <template
                            v-for="plan in monthlyPlansVisible ? monthlyPlans : yearlyPlans"
                            :key="plan.id"
                        >
                            <Plan :plan="plan" />
                        </template>
                    </Stack>
                </Stack>
            </Container>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'

// Composition
import { usePlanSelectionModal } from '@/composition/billing/usePlanSelectionModal'

// Components
import { Modal, Container, Tabs, Text, Stack, useMediaQuery } from '@opteo/components-next'
import Plan from '@/components/billing/Plan/Plan.vue'

// Setup
const {
    showSelectPlanModal,
    monthlyPlansVisible,
    setActiveBillingDuration,
    pricingPlans,
    monthlyPlans,
    yearlyPlans,
} = usePlanSelectionModal()
const { above480, above768, above1280 } = useMediaQuery()

// Computed
const containerPadding = computed(() => {
    if (above1280.value) {
        return [64, 0, 64 + 72 - 24 + 16, 0]
    } else if (above768.value) {
        return [48, 128, 48, 128]
    } else if (above480.value) {
        return [48, 88, 48, 88]
    } else {
        return [16, 0, 20, 0]
    }
})
</script>

<style scoped lang="scss">
.months-free {
    @include absolute;
    right: calc(-0.3125rem - 0.5rem);
    top: 50%;
    transform: translateY(-50%) translateX(100%);
    pointer-events: none;
}
</style>
