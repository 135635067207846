<template>
    <PageHeader title="Account Settings">
        <template v-if="aboveMobile" #right>
            <Button color="secondary" size="small" @click="next()">
                {{ isDarkMode ? 'Light' : 'Dark' }} Mode
            </Button>
            <Menu
                buttonLabel="Sections"
                :items="sectionListItems"
                :offset="[4, 6]"
                placement="bottom-end"
                @item-clicked="scrollToSection"
                buttonSize="small"
            />
        </template>
    </PageHeader>

    <PageContent>
        <Stack direction="column" width="100%" :gap="0" grow>
            <!-- Profile Settings -->
            <section id="profile-settings">
                <StickyHeader title="User Profile" />
                <Stack direction="column" align="center" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="300"
                    >
                        <Stack direction="column" align="center" justify="center" borderBottom>
                            <ImageUploader
                                :url="user?.avatar_filename || uploadedImageUrl"
                                location="user"
                                @uploaded="onImageUploaded"
                                @beforeRemove="onBeforeImageRemove"
                                @afterRemove="onAfterImageRemove"
                                :buttonOnBounds="aboveMobile"
                                :showButton="aboveMobile"
                            >
                                <Stack :gap="14" align="center" justify="center">
                                    <ProfileImage
                                        :width="28"
                                        :imageUrl="profileImageUrl"
                                        :boring-avatar="currentUserAvatarUrl"
                                    />
                                    <Stack align="start" direction="column" :gap="4" width="auto">
                                        <template v-if="user">
                                            <Text size="headline-six">{{ user?.name }}</Text>
                                            <Text size="label" color="foreground-minus-one">
                                                {{ user?.username }}
                                            </Text>
                                        </template>
                                        <template v-else>
                                            <Stack :height="18">
                                                <Skeleton :width="128" :height="10" />
                                            </Stack>
                                            <Stack :height="12">
                                                <Skeleton :width="104" :height="8" />
                                            </Stack>
                                        </template>
                                    </Stack>
                                </Stack>
                            </ImageUploader>
                        </Stack>
                        <Form @submitted="submitProfileChange" validateOnSubmit>
                            <Stack
                                direction="column"
                                align="center"
                                borderBottom
                                :gap="20"
                                :padding="[20, 22, 22, 22]"
                            >
                                <TextInput
                                    id="user-settings-name"
                                    name="name"
                                    :modelValue="user?.name"
                                    type="text"
                                    label="Name"
                                    required
                                />
                                <TextInput
                                    id="user-settings-email"
                                    ref="profileEmailInput"
                                    name="email"
                                    :modelValue="user?.username"
                                    type="email"
                                    label="Email Address"
                                    required
                                />
                            </Stack>
                            <Stack align="center" justify="center" :padding="[22, 0]">
                                <Button
                                    id="save-profile"
                                    ref="updateProfileButton"
                                    :loading="profileButtonLoading"
                                    size="small"
                                    color="secondary-highlight"
                                    submit
                                >
                                    Update Profile
                                </Button>
                            </Stack>
                        </Form>
                    </Container>
                </Stack>
            </section>

            <!-- Password Settings -->
            <section id="password-settings">
                <StickyHeader title="Password Settings" />
                <Stack :padding="20" width="100%" direction="column" align="center">
                    <Container
                        color="background-plus-one"
                        :borderRadius="22"
                        width="100%"
                        :max-width="300"
                    >
                        <Form @submitted="submitNewPassword" validateOnSubmit>
                            <Stack
                                direction="column"
                                align="center"
                                borderBottom
                                :gap="20"
                                :padding="[20, 22, 22, 22]"
                            >
                                <TextInput
                                    id="current-password"
                                    name="currentPassword"
                                    ref="currentPassword"
                                    required
                                    label="Current Password"
                                    type="password"
                                    placeholder="••••••••••"
                                    autocomplete="new-password"
                                />
                                <TextInput
                                    id="new-password"
                                    name="newPassword"
                                    v-model="newPasswordInput"
                                    :validator="newPasswordValidator"
                                    label="New Password"
                                    type="password"
                                    required
                                    placeholder="••••••••••"
                                    autocomplete="off"
                                />
                                <TextInput
                                    id="confirm-password"
                                    name="confirmPassword"
                                    ref="confirmPassword"
                                    :validator="passwordsMatchValidator"
                                    label="Confirm New Password"
                                    type="password"
                                    required
                                    placeholder="••••••••••"
                                    autocomplete="off"
                                />
                            </Stack>
                            <Stack align="center" justify="center" :padding="[22, 0]">
                                <Button
                                    id="save-password"
                                    ref="savePasswordButton"
                                    :loading="passwordButtonLoading"
                                    size="small"
                                    color="secondary-highlight"
                                    submit
                                >
                                    Update Password
                                </Button>
                            </Stack>
                        </Form>
                    </Container>
                </Stack>
            </section>

            <!-- Social Sign-in -->
            <section id="social-signin">
                <StickyHeader title="Social Sign-in" />
                <Stack :padding="20" width="100%" direction="column" align="center">
                    <Container
                        color="background-plus-one"
                        :direction="above1200 ? 'row' : 'column'"
                        :borderRadius="22"
                        width="auto"
                    >
                        <ConnectPlatform
                            platform="google"
                            platformUsername="José Camero"
                            platformAvatarUrl="https://ca.slack-edge.com/T02K07WS6-UJ4S4KP60-84c6861f2a1b-192"
                            :connected="false"
                            border
                            @buttonClick="console.log('Clicked Google')"
                        />
                        <ConnectPlatform
                            platform="meta"
                            platformUsername="José Camero"
                            platformAvatarUrl="https://ca.slack-edge.com/T02K07WS6-UJ4S4KP60-84c6861f2a1b-192"
                            :connected="true"
                            border
                            @buttonClick="console.log('Clicked Meta')"
                        />
                        <ConnectPlatform
                            platform="microsoft"
                            platformUsername="José Camero"
                            platformAvatarUrl="https://ca.slack-edge.com/T02K07WS6-UJ4S4KP60-84c6861f2a1b-192"
                            :connected="false"
                            @buttonClick="console.log('Clicked Microsoft')"
                        />
                    </Container>
                </Stack>
            </section>

            <!-- Team Members -->
            <section id="team-members">
                <StickyHeader title="Team Members">
                    <template #right>
                        <Button
                            size="small"
                            color="secondary"
                            iconBefore="UserPlus"
                            @click="inviteModalShown = true"
                        >
                            Add Team Member
                        </Button>
                    </template>
                </StickyHeader>
                <Stack direction="column" align="center" :padding="20">
                    <Stack width="100%">
                        <Table
                            :columns="teamTableHeaders"
                            :rows="teamTableRows"
                            :rowHeight="55"
                            :borderRadius="18"
                            layout="fixed"
                            :columnWidths="{
                                name: '100%',
                                email: '232px',
                                status: '144px',
                                lastLogin: '180px',
                            }"
                            stickyHeader
                            :rowActions="rowActions"
                        >
                            <template v-slot:[`name-data`]="ctx">
                                <Stack align="center" :gap="16" :padding="[0]">
                                    <ProfileImage
                                        :size="28"
                                        :isAdmin="findTeamMember(ctx.row.id)?.role === 'admin'"
                                        :imageUrl="findTeamMember(ctx.row.id)?.avatar_filename"
                                        :boringAvatar="
                                            getAvatarUrl(findTeamMember(ctx.row.id)?.user_id)
                                        "
                                    />
                                    <Text as="p" size="button" color="foreground" :wrap="false">{{
                                        findTeamMember(ctx.row.id)?.name
                                    }}</Text>
                                </Stack>
                            </template>
                        </Table>
                    </Stack>

                    <Modal
                        title="Add Team Member"
                        v-model="inviteModalShown"
                        :width="380"
                        @opened="focusInviteUserEmailInput"
                    >
                        <template #content>
                            <Container
                                color="background-plus-one"
                                :borderRadius="18"
                                :padding="[18, 20, 20, 20]"
                                width="100%"
                                minWidth="20rem"
                            >
                                <Form ref="addTeamMemberForm" @submitted="addMember">
                                    <Stack direction="column" :gap="24" width="100%">
                                        <TextInput
                                            id="invite-user-email"
                                            label="Email Address"
                                            name="inviteUserEmail"
                                            v-model="inviteUserEmailModel"
                                            ref="inviteUserEmailInput"
                                            type="email"
                                            required
                                        />
                                        <TextInput
                                            id="invite-user-name"
                                            label="Name"
                                            name="inviteUserName"
                                            v-model="inviteUserNameModel"
                                            required
                                        />
                                    </Stack>
                                </Form>
                            </Container>
                        </template>
                        <template #buttons>
                            <Button color="secondary" @click="inviteModalShown = false">
                                Cancel
                            </Button>
                            <Button
                                @click="addTeamMemberForm.submit()"
                                :loading="inviteMemberLoading"
                                size="medium"
                            >
                                Add Team Member
                            </Button>
                        </template>
                    </Modal>

                    <Modal v-model="removeModalShown" title="Remove User" :width="420">
                        <template #content>
                            <Container :borderRadius="18" :padding="[16, 20]">
                                <Text as="p" size="body-small">
                                    Please note, removing <b>{{ memberToRemove?.name }}</b> from
                                    your team is permanent. If you would like them to rejoin your
                                    Opteo team, you will have to invite them again.
                                </Text>
                            </Container>
                        </template>
                        <template #buttons>
                            <Button color="secondary" @click="removeModalShown = false">
                                Cancel
                            </Button>
                            <Button @click="removeTeamMember" color="red" size="medium">
                                Remove User
                            </Button>
                        </template>
                    </Modal>
                </Stack>
            </section>

            <!-- Connect Slack -->
            <section id="connect-slack">
                <StickyHeader title="Connect Slack" />
                <Stack direction="column" align="center" :padding="20">
                    <SlackConnectWrapper :is-domain-specific-connection="false" />
                </Stack>
            </section>

            <!-- Alert Settings -->
            <section id="alerts-section">
                <StickyHeader title="Alert Settings" />
                <Stack direction="column" align="center" width="100%" :padding="20">
                    <Container
                        color="background-plus-one"
                        :borderRadius="18"
                        :gap="0"
                        :max-width="640"
                    >
                        <Stack direction="column" :gap="0">
                            <Stack class="table-header" justify="between" :gap="0">
                                <Stack grow width="auto" :padding="[14, 0, 14, 20]" borderBottom>
                                    <Text as="span" :wrap="false">Alert Type</Text>
                                </Stack>
                                <Stack :padding="[0, 12, 0, 0]" :gap="0" width="auto" borderBottom>
                                    <Stack
                                        v-for="type in alertChannels"
                                        :key="type.type"
                                        :width="aboveMobile ? '4.5rem' : '4rem'"
                                        justify="center"
                                        :gap="0"
                                        :padding="[14, 0]"
                                    >
                                        <Text as="span" :wrap="false">
                                            {{ type.display }}
                                        </Text>
                                    </Stack>
                                </Stack>
                            </Stack>
                            <Stack
                                v-for="(type, index) in alertTypesInfo"
                                :key="type.slug"
                                justify="between"
                                :gap="0"
                                :borderBottom="index !== alertTypesInfo.length - 1"
                            >
                                <Stack grow align="start" width="auto">
                                    <Stack
                                        direction="column"
                                        align="start"
                                        :gap="10"
                                        :padding="[18, 20, 16, 20]"
                                    >
                                        <Text as="h6" size="headline-seven">{{ type.label }}</Text>
                                        <Text
                                            as="p"
                                            size="body-small"
                                            color="foreground-minus-one"
                                            maxWidth="18.25rem"
                                        >
                                            {{ type.description }}
                                        </Text>
                                    </Stack>
                                </Stack>
                                <Stack :padding="[0, 12, 0, 0]" :gap="0" width="auto">
                                    <Stack
                                        :width="aboveMobile ? '4.5rem' : '4rem'"
                                        justify="center"
                                        :padding="[18, 0]"
                                    >
                                        <Checkbox
                                            v-if="alertSettingsLoading"
                                            disabled
                                            :model-value="false"
                                        />
                                        <Checkbox
                                            v-else
                                            v-model="alertChannels.slack.settings[type.slug]"
                                            @updated="
                                                updateAlertSettings(
                                                    'Slack',
                                                    type.label,
                                                    alertChannels.slack.settings[type.slug]
                                                )
                                            "
                                        />
                                    </Stack>
                                    <Stack
                                        :width="aboveMobile ? '4.5rem' : '4rem'"
                                        justify="center"
                                        :padding="[18, 0]"
                                    >
                                        <Checkbox
                                            v-if="alertSettingsLoading"
                                            disabled
                                            :model-value="false"
                                        />
                                        <Checkbox
                                            v-else
                                            v-model="alertChannels.email.settings[type.slug]"
                                            @updated="
                                                updateAlertSettings(
                                                    'Email',
                                                    type.label,
                                                    alertChannels.email.settings[type.slug]
                                                )
                                            "
                                        />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Container>
                </Stack>
            </section>
        </Stack>
    </PageContent>
</template>

<script lang="ts" setup>
// Imports
import { ref, computed } from 'vue'

// Composition
import { useUserSettings } from '@/composition/user/useUserSettings'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import SlackConnectWrapper from '@/components/connectors/slack/SlackConnectWrapper.vue'
import { useUser } from '@/composition/user/useUser'

// Components
import {
    StickyHeader,
    Button,
    Container,
    Menu,
    Stack,
    Text,
    ProfileImage,
    Form,
    TextInput,
    Checkbox,
    Table,
    Modal,
    showSnackbar,
    Skeleton,
    useTheme,
    useMediaQuery,
} from '@opteo/components-next'
import ImageUploader from '@/components/global/ImageUploader.vue'
import ConnectPlatform from '@/components/connectors/sso/ConnectPlatform.vue'
import { scrollToSection } from '@/lib/globalUtils'

// Setup
const {
    user,
    updateProfileButton,
    uploadedImageUrl,
    onImageUploaded,
    onBeforeImageRemove,
    onAfterImageRemove,
    updateAlertSettings: updateAlertsRequest,
    profileEmailInput,
    currentPassword,
    newPasswordInput,
    newPasswordValidator,
    confirmPassword,
    savePasswordButton,
    passwordsMatchValidator,
    passwordButtonLoading,
    alertChannels,
    alertTypesInfo,
    alertSettingsLoading,
    profileButtonLoading,
    submitNewPassword,
    submitProfileChange,
    addMember,
    inviteModalShown,
    removeModalShown,
    focusInviteUserEmailInput,
    inviteUserEmailInput,
    inviteUserEmailModel,
    inviteUserNameModel,
    inviteMemberLoading,
    teamTableHeaders,
    teamTableRows,
    findTeamMember,
    removeTeamMember,
    confirmRemoveTeamMember,
    memberToRemove,
    setTeamMemberRole,
} = useUserSettings()
const { isAdmin, profileImageUrl } = useUser()
const { currentUserAvatarUrl, getAvatarUrl } = useBoringAvatar()
const { isDarkMode, next } = useTheme()
const { aboveMobile, above1200 } = useMediaQuery()
const addTeamMemberForm = ref(null)
const sectionListItems = [
    {
        label: 'Profile Settings',
        value: 'profile-settings',
    },
    {
        label: 'Password Settings',
        value: 'password-settings',
    },
    {
        label: 'Social Sign-in',
        value: 'social-signin',
    },
    {
        label: 'Team Members',
        value: 'team-members',
    },
    {
        label: 'Connect Slack',
        value: 'connect-slack',
    },
    {
        label: 'Alert Settings',
        value: 'alerts-section',
    },
]

// Functions
const updateAlertSettings = (channel: string, alertType: string, enabled: boolean) => {
    updateAlertsRequest()
    showSnackbar({
        message: `${channel} alert for ${alertType} successfully ${
            enabled ? 'enabled' : 'disabled'
        }`,
        timeout: 5000,
    })
}

// Computed
const rowActions = computed(() => {
    return isAdmin.value
        ? [
              {
                  label: 'Remove User',
                  value: 'remove',
                  icon: 'Error',
                  disabled: (row?: any) => !row.canBeRemoved,
                  action: (row?: any) => confirmRemoveTeamMember(findTeamMember(row.id)),
              },
              {
                  label: 'Make User Admin',
                  value: 'promote',
                  icon: 'UserPlus',
                  disabled: (row?: any) => !row.canBeRemoved,
                  action: (row?: any) => setTeamMemberRole(findTeamMember(row.id), 'admin'),
              },
              {
                  label: 'Remove as Admin',
                  value: 'demote',
                  icon: 'UserMinus',
                  disabled: (row?: any) => !row.canBeRemoved,
                  action: (row?: any) => setTeamMemberRole(findTeamMember(row.id), 'user'),
              },
          ]
        : []
})
</script>

<style scoped lang="scss">
:deep(.table-header span) {
    @include table-header;
}

section {
    @include w-100;
    @include bb;
    @include border-background;
}
section:last-child {
    border-bottom: none;
}
section:nth-child(1) {
    @include z-1;
}
section:nth-child(2) {
    @include z-2;
}
section:nth-child(3) {
    @include z-3;
}
section:nth-child(4) {
    @include z-4;
}
section:nth-child(5) {
    @include z-5;
}
section:nth-child(6) {
    @include z-6;
}
section:nth-child(7) {
    @include z-7;
}
section:nth-child(9) {
    @include z-9;
}
section:nth-child(9) {
    @include z-9;
}
</style>
