<template>
    <ClickableRow :padding="[12, 24, 12, 22]" :hoverable="false">
        <!-- Skeleton -->
        <Stack :height="32" :gap="12" direction="row" align="center">
            <IconSlot :width="20" :height="20">
                <Checkbox :modelValue="false" disabled />
            </IconSlot>
            <Skeleton :width="rowWidth" :height="16" />
            <template v-for="skid in 2" :key="skid">
                <EntityPill
                    loading
                    color="inactive"
                    type="Campaign"
                    label="Campaign"
                    size="small"
                />
            </template>
        </Stack>
        <!-- Buttons -->
        <Stack v-if="$slots.buttons" direction="row" width="auto" :gap="8" class="buttons">
            <slot name="buttons" />
        </Stack>
    </ClickableRow>
</template>
<script setup lang="ts">
// Imports
import { ref } from 'vue'

// Components
import {
    Skeleton,
    ClickableRow,
    Stack,
    EntityPill,
    Checkbox,
    IconSlot,
} from '@opteo/components-next'

// Setup
const rowWidth = ref(Math.random() * 120 + 100)
</script>

<style scoped lang="scss"></style>
