<template>
    <div v-if="!!error">
        <ChatShared404 />
    </div>
    <a class="shared-chat-opteo-button" tabindex="0" color="white" href="https://opteo.com/">
        <OpteoLogo :width="44" :height="44" style="width: 2.75rem; height: 2.75rem" />
    </a>

    <ChatEntityStyles>
        <div v class="messages" v-if="!loading && !error && chatData">
            <div v-for="item in chatData.messages" :key="item.key">
                <ChatQuery
                    v-if="item.role === 'user'"
                    :content="item.content"
                    :profileImageUrl="chatData.profileImageUrl"
                    :entities="entities"
                    :user-id="chatData.userId"
                />
                <ChatResponse v-else :response="item.content" :stream="false" />
            </div>
        </div>
        <div class="sharedchat-footer">
            <!-- Left container (avatar, name, location) -->
            <div class="sharedchat-footer-left">
                <!-- Account avatar -->
                <Skeleton v-if="loading" :width="36" :height="36" />
                <Avatar
                    v-else
                    :color="chatData.accountColour"
                    v-tippy="{
                        animateFill: 'false',
                        animation: 'shift-away',
                        duration: '250',
                        placement: 'top-start',
                        theme: 'footer-avatar',
                        offset: [0, 8],
                        arrow: false,
                        zIndex: 10001,
                    }"
                    :content="`<span class='f7'>${chatData.accountName}</span>`"
                    :platform="platform"
                    :show-account-type="true"
                >
                    {{ accountInitials }}
                    <Avatar class="phantom-avatar" color="#01010514" />
                </Avatar>
                <!-- Conversation title -->
                <div class="chat-name-container">
                    <Skeleton v-if="loading" :width="160" :height="16" style="margin-left: 1rem" />
                    <Text
                        v-else
                        weight="600"
                        class="chat-name"
                        style="font-size: 1.0625rem; letter-spacing: -0.025rem; line-height: 1.2"
                    >
                        Shared Conversation
                    </Text>
                </div>
            </div>
            <!-- Right container -->
            <div class="sharedchat-footer-right">
                <!-- Copy Sharing Link button -->
                <ChatShareButton v-if="!error" :disabled="loading" :chatId="chatUuid" />
            </div>
        </div>
    </ChatEntityStyles>
    <!-- </div> -->
</template>
<script lang="ts" setup>
// Vue
import { useRoute } from 'vue-router'
import { ref, onMounted, computed } from 'vue'

// Types
import { Chat, Platform } from '@opteo/types'

// Local components
import ChatResponse from '@/components/chat/ChatResponse.vue'
import ChatQuery from '@/components/chat/ChatQuery.vue'
import ChatEntityStyles from '@/components/chat/ChatEntityStyles.vue'
import ChatShareButton from '@/components/chat/ChatShareButton.vue'
import ChatShared404 from '@/components/chat/ChatShared404.vue'

import { useChatEntities } from '@/composition/chat/useChatEntities'

// @opteo/components-next
// import { Avatar, Text, OpteoLogo } from '@opteo/components-next'
import Skeleton from '@/components/util/Skeleton.vue'

import { noAuthRequest } from '@/composition/api/useAPI'
import { useIntercom } from '@/lib/intercom/useIntercom'

const route = useRoute()
const { shutdown } = useIntercom()

const chatUuid = route.params.chatUuid as string

const loading = ref(true)
const error = ref('')

const chatData = ref<Chat.GetSharedChatResponse | null>(null)

const getChatData = async (chatUuid: string): Promise<Chat.GetSharedChatResponse | null> => {
    try {
        const res = await noAuthRequest<Chat.GetSharedChatResponse>(`/chat/${chatUuid}`, {})
        loading.value = false
        return res
    } catch (err) {
        console.error(err)
        error.value = 'something went wrong'
        loading.value = false
        return null
    }
}

const platform = computed(() => chatData.value?.platform ?? 0)

const title = computed(() => chatData.value?.title)
const entities = computed(() => chatData.value?.entities)

const accountInitials = computed(() => {
    return chatData.value?.accountName.slice(0, 2)
})

// Entities in footer
const { HTML } = useChatEntities(title, entities)

onMounted(async () => {
    shutdown()
    chatData.value = await getChatData(chatUuid)
})
</script>

<style scoped lang="scss">

// @import '@/assets/css/theme.scss';
// @import '@/assets/css/variables.scss';

// .shared-chat-opteo-button {
//     position: fixed;
//     top: 1rem;
//     left: 1rem;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     border-radius: 1rem;
//     transition: all 0.25s cubic-bezier(0.19, 1, 0.22, 1);
//     width: 4rem;
//     height: 4rem;
//     padding: 0;
//     cursor: pointer;
//     box-shadow: none;
// }

// .shared-chat-opteo-button:hover {
//     box-shadow:
//         0 0 0 1px rgba(2, 2, 12, 0.05),
//         0 2px 4px -2px rgba(2, 2, 12, 0.1) !important;
// }

// .shared-chat-opteo-button:focus {
//     outline: none !important;
//     box-shadow:
//         0 0 0 3px $opteo-blue-shadow,
//         0 0 0 1px rgba(2, 2, 12, 0.05),
//         0 2px 4px -2px rgba(2, 2, 12, 0.1) !important;
// }

// .shared-chat-opteo-button:active {
//     outline: none !important;
//     box-shadow:
//         0 0 0 3px $opteo-blue-shadow,
//         0 0 0 1px rgba(2, 2, 12, 0.05),
//         0 2px 4px -2px rgba(2, 2, 12, 0.1) !important;
//     transform: translate3d(0, 1px, 0) !important;
// }

// .messages {
//     @include flex;
//     flex-direction: column;
//     gap: 2rem;
//     @include mt-32;
//     margin-bottom: 9rem;
// }

// // Footer
// .sharedchat-footer {
//     @include bg-opteo-white;
//     box-shadow: $opteo-shadow-l;
//     height: 5rem;
//     @include ph-24;
//     @include br-20;
//     touch-action: none;
//     overflow: hidden;
//     position: fixed;
//     bottom: 1.5rem;
//     left: 1.5rem;
//     width: calc(100vw - 3rem);
//     z-index: 10000;
//     @include flex;
//     @include items-center;
//     @include justify-between;
// }
// .sharedchat-footer .account-avatar {
//     border-radius: 100%;
//     width: 2.5rem;
//     height: 2.5rem;
//     flex: 0 0 2.5rem;
// }
// .sharedchat-footer .account-avatar.blue {
//     background-color: $opteo-blue;
// }
// .sharedchat-footer .account-avatar.purple {
//     background-color: $opteo-purple;
// }
// .sharedchat-footer .account-avatar.green {
//     background-color: $opteo-green;
// }
// .sharedchat-footer .account-avatar.pink {
//     background-color: $opteo-pink;
// }
// .sharedchat-footer .account-avatar.orange {
//     background-color: $opteo-orange;
// }
// .sharedchat-footer .account-avatar.teal {
//     background-color: $opteo-teal;
// }

// .sharedchat-footer .phantom-avatar {
//     position: absolute;
//     left: 0;
//     top: 0;
//     display: flex;
//     border-radius: 100%;
//     width: 2.5rem;
//     height: 2.5rem;
//     flex: 0 0 2.5rem;
//     z-index: -1;
// }

// .sharedchat-footer-left {
//     @include flex;
//     max-width: 100%;
//     min-width: 0;
// }
// .sharedchat-footer-right {
//     position: relative;
// }

// .chat-name-container {
//     overflow: hidden;
//     min-height: 100%;
//     display: flex;
//     align-items: center;
// }

// .sharedchat-footer .chat-name {
//     position: relative;
//     margin-left: 1rem;
//     margin-right: 1.5rem;
//     white-space: nowrap;
//     max-width: 12.5rem;
//     text-overflow: ellipsis;
//     overflow: hidden;
//     min-width: 0;
// }

// .sharedchat-footer button {
//     z-index: 2;
// }

// @media screen and (max-width: 768px) {
//     .messages {
//         margin-top: 1.5rem;
//         padding: 0rem 1rem;
//     }
//     .sharedchat-footer {
//         bottom: 1rem;
//         left: 0.75rem;
//         width: calc(100vw - 1.5rem);
//     }
//     .sharedchat-footer .chat-name {
//         margin-right: 0.75rem;
//     }
// }

// @media screen and (min-width: 768px) and (max-width: 1024px) {
//     .messages {
//         padding: 0rem 6rem;
//     }
// }
// @media screen and (max-width: 1024px) {
//     .shared-chat-opteo-button {
//         display: none;
//     }
// }

</style>
