<template>
    <PageWrapper v-if="!improvementOpen">
        <!-- Skeletons -->
        <ImprovementGroup v-if="loading">
            <template #titlePrefix>
                <Skeleton :height="16" :width="128" />
            </template>
            <template #content>
                <template v-for="skid in 20" :key="skid">
                    <ImprovementRowSkeleton />
                </template>
            </template>
        </ImprovementGroup>

        <!-- No Dismissed Improvements -->
        <EmptyState
            v-else-if="noDismissedImps"
            icon="SparklesExtraLarge"
            headline="No Dismissed Improvements"
            message="All of your dismissed improvements will appear here. Switch to the Active tab to take a look at your active improvements."
            hideContainer
            :padding="0"
            :contentMaxWidth="368"
        >
            <Button iconAfter="ArrowUpRight" color="secondary" @click="goToActive">
                Go to Active
            </Button>
        </EmptyState>

        <!-- Dismissed Improvements -->
        <Stack v-else direction="column" width="100%" :gap="0" grow>
            <!-- 1 Week -->
            <ImprovementGroup
                v-if="dismissedImprovements.week?.length > 0"
                title="For 1 Week"
                key="week"
                first
                style="z-index: 3"
            >
                <template #titleSuffix>
                    <IndicatorTag color="red">
                        {{
                            `${dismissedImprovements.week.length} Improvement${
                                dismissedImprovements.week.length === 1 ? '' : 's'
                            }`
                        }}
                    </IndicatorTag>
                </template>
                <template #content>
                    <template
                        v-for="(improvement, index) in dismissedImprovements.week"
                        :key="improvement.improvement_id"
                    >
                        <ImprovementRowDismissed :improvement="improvement" :isDeferred="true" />
                    </template>
                </template>
            </ImprovementGroup>

            <!-- 1 Month -->
            <ImprovementGroup
                v-if="dismissedImprovements.month?.length > 0"
                title="For 1 Month"
                key="month"
                :first="!dismissedImprovements.week?.length"
                style="z-index: 2"
            >
                <template #titleSuffix>
                    <IndicatorTag color="red">
                        {{
                            `${dismissedImprovements.month.length} Improvement${
                                dismissedImprovements.month.length === 1 ? '' : 's'
                            }`
                        }}
                    </IndicatorTag>
                </template>
                <template #content>
                    <template
                        v-for="(improvement, index) in dismissedImprovements.month"
                        :key="improvement.improvement_id"
                    >
                        <ImprovementRowDismissed :improvement="improvement" :isDeferred="true" />
                    </template>
                </template>
            </ImprovementGroup>

            <!-- Forever -->
            <ImprovementGroup
                v-if="dismissedImprovements.forever?.length > 0"
                title="Forever"
                key="forever"
                :first="!dismissedImprovements.week?.length && !dismissedImprovements.month?.length"
                style="z-index: 1"
                last
            >
                <template #titleSuffix>
                    <IndicatorTag color="red">
                        {{
                            `${dismissedImprovements.forever.length} Improvement${
                                dismissedImprovements.forever.length === 1 ? '' : 's'
                            }`
                        }}
                    </IndicatorTag>
                </template>
                <template #content>
                    <template
                        v-for="(improvement, index) in dismissedImprovementsForeverSubset"
                        :key="improvement.improvement_id"
                    >
                        <ImprovementRowDismissed :improvement="improvement" :isDeferred="true" />
                    </template>
                </template>
            </ImprovementGroup>
            <Stack v-if="moreAvailable" :padding="20" width="100%" justify="center">
                <Button @click="limit = limit + 100" color="secondary" size="small">
                    Show More
                </Button>
            </Stack>
        </Stack>
    </PageWrapper>

    <!-- Improvement Template -->
    <router-view v-else />
</template>

<script setup lang="ts">
// Imports
import { useRouter } from 'vue-router'
import { Routes } from '@/router/routes'

// Composition
import { useDismissedImprovements } from '@/composition/improvement/useDismissedImprovements'
import { useDomain } from '@/composition/domain/useDomain'

// Components
import { Button, Stack, EmptyState, Skeleton } from '@opteo/components-next'
import ImprovementRowSkeleton from '@/components/improvement/ImprovementRowSkeleton.vue'
import ImprovementRowDismissed from '@/components/improvement/ImprovementRowDismissed.vue'
import ImprovementGroup from '@/components/improvement/ImprovementGroup.vue'
import IndicatorTag from '@/components/global/IndicatorTag.vue'

// Setup
const {
    dismissedImprovements,
    dismissedImprovementsForeverSubset,
    loading,
    moreAvailable,
    limit,
    noDismissedImps,
    improvementOpen,
} = useDismissedImprovements()
const router = useRouter()

// Functions
function goToActive() {
    router.push({ name: Routes.ImprovementsActive })
}
</script>

<style scoped lang="scss"></style>
