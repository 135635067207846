<template>
    <svg
        class="bg"
        width="268"
        height="364"
        viewBox="0 0 268 364"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect width="268" height="364" rx="28" fill="#fff" />
        <g filter="url(#filter0_dd_131_4143)">
            <rect x="118" y="283" width="113" height="48" rx="8" fill="#fff" />
        </g>
        <rect x="202" y="321" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="321" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="321" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="321" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 314.5h113" />
        <rect x="202" y="305" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="305" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="305" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="305" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 298.5h113" />
        <rect x="202" y="289" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="289" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="289" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="289" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="272" width="45" height="5" rx="2.5" fill="#000" fill-opacity=".1" />
        <g filter="url(#filter1_dd_131_4143)">
            <rect x="118" y="131" width="113" height="128" rx="8" fill="#fff" />
        </g>
        <rect x="202" y="249" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="249" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="249" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="249" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 242.5h113" />
        <rect x="202" y="233" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="233" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="233" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="233" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 226.5h113" />
        <rect x="202" y="217" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="217" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="217" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="217" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 210.5h113" />
        <rect x="202" y="201" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="178" y="201" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="150" y="201" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="126" y="201" width="16" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path stroke="#F6F6F9" d="M118 194.5h113" />
        <path
            d="M174.888 143.618c3.263-.018 6.478.789 9.347 2.347 2.868 1.557 5.298 3.814 7.062 6.561 1.765 2.747 2.808 5.895 3.031 9.153.224 3.257-.378 6.518-1.751 9.479-1.372 2.962-3.47 5.528-6.099 7.461-2.629 1.933-5.703 3.171-8.938 3.597-3.235.427-6.526.029-9.567-1.157-3.041-1.185-5.638-3.015-7.733-5.519"
            stroke="#006DFF"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            opacity=".66"
            d="M156.947 169.555c-.974-2.686-1.345-5.555-1.087-8.402.259-2.847 1.141-5.602 2.583-8.067"
            stroke="#006DFF"
            stroke-width="5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            opacity=".33"
            d="M162.974 147.877c1.578-1.282 3.347-2.306 5.241-3.036"
            stroke="#006DFF"
            stroke-width="5"
            stroke-linecap="round"
        />
        <rect x="118" y="120" width="45" height="5" rx="2.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="104" width="82" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="98" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="92" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="86" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="74" width="94" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="68" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="62" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="56" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="50" width="114" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <rect x="118" y="36" width="45" height="5" rx="2.5" fill="#000" fill-opacity=".1" />
        <path
            opacity=".1"
            d="M98.4931 69c-.0008 4.0053-.7906 7.9714-2.3242 11.6718-1.5335 3.7005-3.7809 7.0628-6.6137 9.895-2.8327 2.8321-6.1955 5.0788-9.8963 6.6115-3.7007 1.5328-7.667 2.3217-11.6723 2.3217-4.0054 0-7.9713-.7889-11.6714-2.3217-3.7001-1.5327-7.0619-3.7794-9.8935-6.6115-2.8316-2.8322-5.0774-6.1945-6.6094-9.895-1.532-3.7004-2.32-7.6665-2.3192-11.6718.0009-4.0053.7906-7.9714 2.3242-11.6718 1.5336-3.7005 3.7809-7.0628 6.6137-9.895 2.8328-2.8321 6.1956-5.0788 9.8963-6.6115C60.0281 39.2889 63.9944 38.5 67.9997 38.5s7.9712.7889 11.6713 2.3217c3.7001 1.5327 7.0619 3.7794 9.8935 6.6115 2.8316 2.8322 5.0775 6.1945 6.6095 9.895 1.5319 3.7004 2.32 7.6665 2.3191 11.6718h0Z"
            stroke="#FF2828"
            stroke-width="3"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M67.9997 38.5c7.427 0 14.5983 2.71 20.1684 7.6216 5.57 4.9116 9.1561 11.6873 10.0853 19.0557.9293 7.3685-.8621 14.8235-5.038 20.9662-4.1759 6.1428-10.4495 10.5513-17.6436 12.3983"
            stroke="#FF2828"
            stroke-width="3"
            stroke-linecap="round"
        />
        <rect x="58" y="78" width="20" height="3" rx="1.5" fill="#000" fill-opacity=".1" />
        <path
            d="M73.5108 72.2698c-2.898 0-4.752-1.566-4.752-3.69 0-1.44.954-2.646 2.286-3.114-1.224-.45-1.998-1.674-1.998-2.88 0-2.124 1.89-3.618 4.464-3.618 2.592 0 4.482 1.494 4.482 3.618 0 1.188-.774 2.43-2.016 2.88 1.332.468 2.304 1.674 2.304 3.114 0 2.124-1.854 3.69-4.77 3.69Zm0-7.722c1.242-.036 2.016-.792 2.016-1.8 0-.918-.756-1.71-2.016-1.71-1.242 0-1.998.792-1.998 1.71 0 1.008.774 1.764 1.998 1.8Zm0 5.652c1.494 0 2.304-.882 2.304-1.872 0-1.008-.792-1.908-2.304-1.908-1.494 0-2.286.9-2.286 1.908 0 .99.81 1.872 2.286 1.872ZM57.4111 69.2458v-2.664l5.382-7.344h3.15v7.83h2.106v2.178h-2.106v2.754h-2.466v-2.754h-6.066Zm6.066-2.178v-5.292l-3.852 5.292h3.852Z"
            fill="#FF2828"
        />
        <defs>
            <filter
                id="filter0_dd_131_4143"
                x="116"
                y="282"
                width="117"
                height="53"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_131_4143"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_131_4143" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_131_4143" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_131_4143" result="effect2_dropShadow_131_4143" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_131_4143" result="shape" />
            </filter>
            <filter
                id="filter1_dd_131_4143"
                x="116"
                y="130"
                width="117"
                height="133"
                filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB"
            >
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology
                    radius="1"
                    operator="dilate"
                    in="SourceAlpha"
                    result="effect1_dropShadow_131_4143"
                />
                <feOffset />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.04 0"
                />
                <feBlend in2="BackgroundImageFix" result="effect1_dropShadow_131_4143" />
                <feColorMatrix
                    in="SourceAlpha"
                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                    result="hardAlpha"
                />
                <feMorphology radius="2" in="SourceAlpha" result="effect2_dropShadow_131_4143" />
                <feOffset dy="2" />
                <feGaussianBlur stdDeviation="2" />
                <feColorMatrix
                    values="0 0 0 0 0.00784314 0 0 0 0 0.00784314 0 0 0 0 0.0470588 0 0 0 0.1 0"
                />
                <feBlend in2="effect1_dropShadow_131_4143" result="effect2_dropShadow_131_4143" />
                <feBlend in="SourceGraphic" in2="effect2_dropShadow_131_4143" result="shape" />
            </filter>
        </defs>
    </svg>
</template>

<style scoped lang="scss">


// // @import '@/assets/css/theme.scss';
// // @import '@/assets/css/variables.scss';

// // .bg {
// //     box-shadow: $opteo-shadow-l;
// //     @include br-28;
// // }


</style>
