<template>
    <div
        :class="{
            'spend-cell': true,
            'spend-cell-disabled': isUnlinkableAccount,
        }"
        @click="handleSelectAccount()"
    >
        <Text as="span" size="f-8"><Money :value="+value" currency="USD" /></Text>
        <Tooltip
            v-if="account.currencyCode !== 'USD'"
            :content="`The monthly spend for this account has been converted into USD to ensure spend figures are comparable across imported accounts.`"
            :max-width="268"
            :delay="[400, 0]"
        >
            <InfoIcon
                class="tooltip-info-icon"
                style="margin-left: 0.5rem; transform: translateY(0.125rem)"
            />
        </Tooltip>
    </div>
</template>

<script lang="ts" setup>
// import { Text, Money, Tooltip } from '@opteo/components-next'
import InfoIcon from '@/components/global/InfoIcon.vue'
import { useLinkedAccounts } from '@/composition/linkedaccounts/useLinkedAccounts'
import { Team, Account } from '@opteo/types'
import { computed } from 'vue'

// TODO: Remove this when we have a proper type for the account object
interface ExtendedAccount extends Team.Account {
    currencyCode: string
}

const props = defineProps<{ value: number; account: ExtendedAccount; linked: boolean }>()

const { selectAccount, queuedLinkedAccounts, queuedUnlinkedAccounts } = useLinkedAccounts()

const accountIsChecked = computed(() => {
    if (!props.account) return false
    const accountId = props.account.accountId as Account.ID

    if (props.linked) return queuedLinkedAccounts.value[accountId] >= 0

    return queuedUnlinkedAccounts.value[accountId] >= 0
})

const isUnlinkableAccount = computed(() => !props.linked && props.account.isUnlinkable)

const handleSelectAccount = () => {
    if (isUnlinkableAccount.value !== true) {
        selectAccount(props.account, props.linked, !accountIsChecked.value)
    }
}
</script>

<style scoped>
/*
.spend-cell {
    height: 4.625rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}
.spend-cell-disabled {
    cursor: default;
}
.spend-cell-disabled:active {
    pointer-events: none;
}
*/
</style>
