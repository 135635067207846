<template>
    <Modal v-model="open" @close="closeModal" title="Settings">
        <template #titlePrefix>
            <ProfileImage
                :size="28"
                :imageUrl="profileImageUrl"
                :boringAvatar="currentUserAvatarUrl"
            />
        </template>
        <template #content>
            <Container :borderRadius="20" width="100%" :minWidth="328">
                <Stack direction="column" :gap="18" :padding="[18, 20, 20, 20]" borderBottom>
                    <Select
                        label="Column One Metric"
                        v-model="columnOneMetric"
                        :options="columnOneMetrics"
                        fullWidth
                    />
                    <Select
                        label="Column Two Metric"
                        v-model="columnTwoMetric"
                        :options="columnTwoMetrics"
                        fullWidth
                    />
                </Stack>
                <Stack direction="column" align="start" :gap="14" :padding="[20]" borderBottom>
                    <Toggle
                        v-model="showAccountGroupColumn"
                        size="small"
                        textSize="button"
                        label="Show Account Group Column"
                    />
                    <Toggle
                        v-model="showAccountManagerColumn"
                        size="small"
                        textSize="button"
                        label="Show Account Manager Column"
                    />
                </Stack>
                <Stack direction="column" :gap="18" :padding="[18, 20, 20, 20]">
                    <SelectableList
                        type="accountGroup"
                        label="Pinned Account Group"
                        defaultButtonLabel="Select Group"
                        v-model="pinnedAccountGroup"
                        :items="accountGroups"
                        :popoutWidth="292"
                        fullWidth
                    />
                    <SelectableList
                        type="user"
                        label="Pinned Team Member"
                        v-model="pinnedTeamMember"
                        :items="teamMembers"
                        :popoutWidth="292"
                        fullWidth
                    />
                </Stack>
            </Container>
        </template>
        <template #buttons>
            <Button @click="closeModal" color="secondary">Cancel</Button>
            <Button @click="saveChanges" color="primary">Save Changes</Button>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch, computed } from 'vue'

// Composition
import { useUser } from '@/composition/user/useUser'
import { useBoringAvatar } from '@/composition/user/useBoringAvatar'
import { useTeam } from '@/composition/user/useTeam'

// Components
import {
    Modal,
    Button,
    Stack,
    Container,
    ProfileImage,
    Select,
    Toggle,
    SelectableList,
} from '@opteo/components-next'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const open = ref(false)
const { profileImageUrl, userId } = useUser()
const { currentUserAvatarUrl } = useBoringAvatar()
const { team } = useTeam()

// columnMetrics
const columnOneMetrics = computed(() => {
    return [
        { label: 'Spend', value: 'spend' },
        { label: 'Conversions', value: 'conversions' },
        { label: 'Conversion Value', value: 'conversionValue' },
        { label: 'CPA', value: 'cpa' },
        { label: 'ROAS', value: 'roas' },
    ]
})
const columnOneMetric = ref('spend')
const columnTwoMetrics = computed(() => {
    return [
        { label: 'Spend', value: 'spend' },
        { label: 'Conversions', value: 'conversions' },
        { label: 'Conversion Value', value: 'conversionValue' },
        { label: 'CPA', value: 'cpa' },
        { label: 'ROAS', value: 'roas' },
    ]
})
const columnTwoMetric = ref('conversions')

// showColumns
const showIssuesColumn = ref(true)
const showBudgetColumn = ref(true)
const showPerformanceGoalColumn = ref(true)
const showAccountManagerColumn = ref(true)
const showAccountGroupColumn = ref(true)

// pinnedAccountGroup
const accountGroups = ref([
    { label: 'Doordash', value: 'doordash', color: 'pink' },
    { label: 'Discord', value: 'discord', color: 'purple' },
    { label: 'Intercom', value: 'intercom', color: 'blue' },
    { label: 'Chime', value: 'chime', color: 'teal' },
    { label: 'Mastercard', value: 'mastercard', color: 'orange' },
])
const pinnedAccountGroup = ref([])

// pinnedTeamMember
const pinnedTeamMember = ref([String(userId.value)])
const teamMembers = computed(() => {
    if (!team.value) return [{ label: 'Unassigned', value: 'unassigned', noImage: true }]

    return [
        ...team.value.map(({ user_id, name, avatar_filename }) => ({
            label: name,
            value: `${user_id}`,
            boringAvatar: `https://api.opteo.io/render/avatar.svg?userId=${user_id}`,
            imageUrl: avatar_filename || undefined,
        })),
    ]
})

// pinnedTeamMember
// const teamMembers = ref([
//     {
//         label: 'Unassigned',
//         value: 'unassigned',
//         noImage: true,
//     },
//     {
//         label: 'Guillaume Devinat',
//         value: 'userId2349',
//         boringAvatar: 'https://api.opteo.io/render/avatar.svg?userId=2349',
//     },
//     {
//         label: 'Callum Nowlan-Dias',
//         value: 'userId2345',
//         boringAvatar: 'https://api.opteo.io/render/avatar.svg?userId=2345',
//     },
//     {
//         label: 'Shaquira Jeyasingh',
//         value: 'userId2347',
//         boringAvatar: 'https://api.opteo.io/render/avatar.svg?userId=2347',
//     },
//     {
//         label: 'Axel Vermeil',
//         value: 'userId2348',
//         boringAvatar: 'https://api.opteo.io/render/avatar.svg?userId=2348',
//     },
// ])
// const pinnedTeamMember = ref(['userId2349'])

// Functions
function closeModal() {
    open.value = false
}
function saveChanges() {
    open.value = false
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
