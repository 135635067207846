<template>
    <Modal v-model="open" @close="closeCancelModal" title="Cancel Subscription" :width="412">
        <template #content>
            <Container :borderRadius="20" :minWidth="360">
                <Stack
                    v-if="!cancellationSuccessful"
                    direction="column"
                    :padding="[16, 20]"
                    borderBottom
                >
                    <Text size="body-small">
                        Select a reason for cancellation and click the button below to cancel your
                        subscription. You will still be able to access Opteo until the end of your
                        current billing cycle, which is on
                        <b>{{ nextChargeDate }}</b
                        >.
                    </Text>
                </Stack>
                <Stack
                    v-if="!cancellationSuccessful"
                    :padding="[18, 20, 20]"
                    direction="column"
                    :gap="20"
                >
                    <Select
                        v-model="selectedCancellationReason"
                        label="Reason for Cancellation"
                        placeholder="Select Reason"
                        name="cancellationReason"
                        :options="userCancellationReasons"
                    />
                    <Textarea
                        v-model="cancellationComments"
                        label="Additional Comments"
                        name="cancellationComments"
                    />
                </Stack>
                <Stack v-if="cancellationSuccessful" direction="column" :gap="10">
                    <Text size="body-small">
                        You can still access Opteo until
                        <b>{{ nextChargeDate }}</b
                        >. Your payment card won't be charged again. We're sorry to see you go.
                    </Text>
                </Stack>
            </Container>
        </template>
        <template #buttons>
            <Button @click="closeCancelModal" color="secondary">Close Modal</Button>
            <Tooltip
                :content="
                    !selectedCancellationReason
                        ? 'Please select a reason for cancellation.'
                        : undefined
                "
            >
                <Button
                    @click="cancelSubscription()"
                    :loading="updatingSubscription"
                    :readOnly="!selectedCancellationReason"
                    color="red"
                >
                    Cancel Subscription
                </Button>
            </Tooltip>
        </template>
    </Modal>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, watch } from 'vue'
import { pushToDatalayer } from '@/lib/datalayer'

// Composition
import { authRequest, Endpoint } from '@/composition/api/useAPI'
import { useSubscription } from '@/composition/billing/useSubscription'

// Components
import {
    Modal,
    Tooltip,
    Button,
    Stack,
    Container,
    Text,
    Textarea,
    Select,
    showSnackbar,
} from '@opteo/components-next'

// Props
const props = defineProps({
    modelValue: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

// Emits
const emit = defineEmits(['update:modelValue'])

// Setup
const { nextChargeDate, mutate, userCancellationReasons } = useSubscription()
const open = ref(false)
const selectedCancellationReason = ref('')
const cancellationComments = ref()
const updatingSubscription = ref(false)
const cancellationSuccessful = ref(false) // Inert

// Functions
function closeCancelModal() {
    open.value = false
    selectedCancellationReason.value = ''
    cancellationComments.value = ''
}
async function cancelSubscription() {
    updatingSubscription.value = true
    await authRequest(Endpoint.CancelSubscription, {
        body: {
            reason: selectedCancellationReason.value,
            comments: cancellationComments.value,
        },
    })

    updatingSubscription.value = false

    mutate()

    pushToDatalayer('gtm_cancelled_subscription')

    closeCancelModal()

    showSnackbar('Your subscription has been cancelled')
}

// Watch
watch(props, async ({ modelValue }) => {
    open.value = modelValue
})

watch(open, async newVal => {
    emit('update:modelValue', newVal)
})
</script>

<style scoped lang="scss"></style>
