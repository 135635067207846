<template>
    <!-- <OnboardingTour
        step-name="tour_ngram"
        :buttonText="'Continue to N-Gram Finder'"
        contentMaxWidth="470px"
    >
        <template v-slot:header>
            <Text as="h4" weight="600" align="center">N-Gram Finder</Text>
        </template>
        <template v-slot:copy>
            Find the worst performing n-grams in your account and add them as negative keywords.
            Decrease wasted spend, reduce irrelevant impressions and improve audience targeting in
            just a few clicks.
        </template>
    </OnboardingTour> -->
    <!-- <PageHeader :title="steps[currentStepIndex].title" /> -->
    <!-- <FullScreenOverlay @close="handleNavigation"> -->
    <!-- <PageWrapper :title="steps[currentStepIndex].title" :loading="nGramDataLoading"> -->
    <!-- <template #right-header>
            <Tabs v-if="currentStepIndex === 0" :links="tabLinks" :active="$route.name" />
        </template>
        <template #content>
            <router-view />
        </template> -->
    <!-- </PageWrapper> -->
    <!-- </FullScreenOverlay> -->
</template>

<script lang="ts" setup>
import { provideNGramTool } from '@/composition/toolkit/nGramTool/useNGramTool'

import { Tabs } from '@opteo/components-next'

const { tabLinks, steps, currentStepIndex, goToNGramSelection, closeNGramTool, nGramDataLoading } =
    provideNGramTool()

function handleNavigation() {
    currentStepIndex.value === 0 ? closeNGramTool() : goToNGramSelection()
}
</script>

<style scoped lang="scss"></style>
