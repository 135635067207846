<template>
    <AccountSwitcher
        :model-value="currentAccount"
        :items="miniAccountList"
        :exceedParentBounds="8"
        :nestedMenu="nestedMenu"
        @update:model-value="changeAccount"
    />
</template>

<script setup lang="ts">
// Imports
import { computed, ref } from 'vue'
import sortBy from 'lodash-es/sortBy'
import { RouteRecordName, useRoute, useRouter } from 'vue-router'
import { Routes } from '@/router/routes'
import { getPlatformFromId } from '@/lib/globalUtils'

// Composition
import { mapHexToAvatarColor } from '@/composition/utils/utils'
import { useAccountList } from '@/composition/user/useAccountList'
import { useAccount } from '@/composition/account/useAccount'

// Types
import { Account, Platform } from '@opteo/types'

// Components
import { AccountSwitcher } from '@opteo/components-next'

// Props
const props = defineProps({
    nestedMenu: {
        type: Boolean,
        required: false,
        default: false,
    },
})

// Setup
const { sortedDomainList, mutateDomainList } = useAccountList()
const { accountId, accountColor, accountName, accountPlatform } = useAccount()
const route = useRoute()
const router = useRouter()

// miniAccountList
const miniAccountList = computed(() => {
    return sortBy(
        sortedDomainList.value
            // .filter(account => {
            //     // TODO: Remove this condition when fully migrated to accounts
            //     if (account.platform === Platform.Platform.GoogleAds) {
            //         return account.accountId.toString() !== accountId.value
            //     }
            //     return account.accountId !== accountId.value
            // })
            .map(account => ({
                ...account,
                color: mapHexToAvatarColor(account.color), // Convert to AvatarColor
            })),
        account => -Date.parse((account.lastVisitedAt as string) || '')
    ).slice(0, 20)
})

// `currentAccount` reflects the selected account ID
const currentAccount = ref(accountId)

// If navigating from Microsoft, only allow access to toolkit or improvements
// If navigating to an unsupported route for an account, redirect to improvements
const changeAccount = async (accountId: Account.ID) => {
    const platform = getPlatformFromId(accountId)

    const permittedRoutes =
        platform === Platform.Platform.MicrosoftAds
            ? [
                  Routes.Improvements,
                  Routes.DomainSettings,
                  Routes.PerformanceGraphs,
                  Routes.PerformanceTable,
                  Routes.PerformanceSegments,
              ]
            : Object.values(Routes)

    const isRoutePermitted = permittedRoutes.includes(route.name as Routes)

    const newRoute = (isRoutePermitted ? route.name : Routes.Improvements) as RouteRecordName

    router.push({
        name: newRoute,
        params: { accountId },
    })
    mutateDomainList(accountId, { lastVisitedAt: new Date().toISOString() })
}
</script>

<style scoped lang="scss"></style>
