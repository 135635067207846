<template>
    <Stack :gap="0" :direction="direction" maxHeight="100%" :align="align" justify="start">
        <slot></slot>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { computed } from 'vue'

// Components
import { Stack, useMediaQuery } from '@opteo/components-next'

// Props
const props = defineProps({
    align: {
        type: String,
        required: false,
        default: 'start',
    },
})

// Setup
const { aboveiPadLandscape } = useMediaQuery()

// Computed
const direction = computed(() => {
    if (aboveiPadLandscape.value === true) {
        return 'row'
    } else return 'column'
})
</script>

<style scoped lang="scss"></style>
