<template>
    <ExternalPageWrapper @logout="logout" showLogoutButton>
        <!-- Default -->
        <Container v-if="!noProfilesDetected" :width="308" :borderRadius="22" hideOverflow>
            <!-- Top -->
            <Stack direction="column" align="start" :gap="0" borderBottom>
                <!-- Description -->
                <Stack direction="column" align="start" :gap="0" :padding="[18, 22, 17, 22]">
                    <Text as="h6" size="headline-six">Connect Google Ads</Text>
                    <Spacer :height="12" />
                    <Text as="p" size="body-extra-small" color="foreground-minus-one">
                        Click below to sign in to Google and connect your Google Ads account to
                        Opteo. Please make sure to connect your primary Google Ads account.
                    </Text>
                </Stack>
                <!-- Error message -->
                <Stack
                    v-if="oauthError"
                    direction="column"
                    align="start"
                    :gap="0"
                    :padding="[18, 22]"
                    borderTop
                    borderBottom
                >
                    <ErrorMessage>Connection failed. Please try again.</ErrorMessage>
                </Stack>
                <Spacer v-if="oauthError" :height="22" />
                <!-- Button -->
                <Stack direction="column" align="start" :padding="[0, 22, 22, 22]" :gap="0">
                    <Button
                        @click="connectGoogleAds"
                        :loading="loading"
                        size="medium"
                        color="secondary"
                        iconAfter="ArrowUpRight"
                        fullWidth
                    >
                        <template #before>
                            <GoogleAdsLogo :size="12" />
                        </template>
                        Connect Google Ads
                    </Button>
                </Stack>
            </Stack>
            <!-- Additional Context -->
            <Stack direction="column" align="start" :gap="0" :padding="0">
                <Stack direction="row" :gap="20" :padding="[18, 22]" borderBottom>
                    <CheckIcon translucent />
                    <Text as="p" size="body-extra-small" color="foreground-minus-one">
                        Opteo needs access to your Google Ads account to gather the data to generate
                        improvements and recommendations.
                    </Text>
                </Stack>
                <Stack direction="row" :gap="20" :padding="[18, 22]" borderBottom>
                    <CheckIcon translucent />
                    <Text as="p" size="body-extra-small" color="foreground-minus-one">
                        Opteo can only make changes in Google Ads with your permission. No hidden or
                        unwanted changes. No matter what.
                    </Text>
                </Stack>
                <Stack direction="row" :gap="20" :padding="[18, 22]">
                    <CheckIcon translucent />
                    <Text as="p" size="body-extra-small" color="foreground-minus-one">
                        Clients never see Opteo in their change history. All changes come directly
                        from your Google Ads email address.
                    </Text>
                </Stack>
            </Stack>
        </Container>
        <!-- No accounts detected -->
        <Container v-else :width="316" :borderRadius="22" hideOverflow>
            <!-- Description -->
            <Stack direction="column" align="start" :gap="0" :padding="[18, 22, 17, 22]">
                <Text as="h6" size="headline-six">No Accounts Detected</Text>
                <Spacer :height="12" />
                <Text as="p" size="body-extra-small" color="foreground-minus-one">
                    Unfortunately, Opteo couldn't find any Google Ads accounts associated with the
                    Google account you just connected. Please connect another account, or message
                    <a onclick="Intercom('show')">support</a> if this issue persists.
                </Text>
            </Stack>
            <!-- Error message -->
            <Stack
                v-if="oauthError"
                direction="column"
                align="start"
                :gap="0"
                :padding="[18, 22]"
                borderTop
                borderBottom
            >
                <ErrorMessage>Connection failed. Please try again.</ErrorMessage>
            </Stack>
            <Spacer v-if="oauthError" :height="22" />
            <!-- Button -->
            <Stack direction="column" align="start" :padding="[0, 22, 22, 22]" :gap="0">
                <Button
                    @click="connectGoogleAds"
                    :loading="loading"
                    size="medium"
                    color="secondary"
                    iconAfter="ArrowUpRight"
                    fullWidth
                >
                    <template #before>
                        <GoogleAdsLogo :size="12" />
                    </template>
                    Connect Google Ads
                </Button>
            </Stack>
        </Container>
    </ExternalPageWrapper>
</template>

<script setup lang="ts">
// Imports
import { useConnectAdwords } from '@/composition/user/useConnectAdwords'

// Layout
import ExternalPageWrapper from '@/components/layout/externalPages/ExternalPageWrapper.vue'

// Components
import {
    Container,
    Stack,
    Text,
    Button,
    Spacer,
    GoogleAdsLogo,
    CheckIcon,
    ErrorMessage,
} from '@opteo/components-next'

// Setup
const { noProfilesDetected, oauthError, connectGoogleAds, loading, logout } = useConnectAdwords()
</script>

<style scoped lang="scss"></style>
