<template>
    <Stack ref="stackRef">
        <Skeleton v-if="loading" :width="60" :height="38" color="secondary" />
        <Tooltip v-else :content="`${currentManagerName}`" :delay="[800, 0]">
            <AvatarSelect
                type="user"
                :modelValue="selectedManager"
                :items="teamMembers"
                :buttonSize="28"
                nested-button="Plus"
                :popoutWidth="232"
                :appendTo="stackEl"
            />
        </Tooltip>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'

// Components
import { Stack, AvatarSelect, Skeleton, Tooltip } from '@opteo/components-next'

// Props
const props = defineProps({
    teamMembers: {
        type: Object as PropType<any[]>,
        required: true,
    },
    selectedManager: {
        type: String as PropType<string>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

const currentManagerName = computed(() => {
    const manager = props.teamMembers.find(g => g.value === props.selectedManager)
    return manager ? manager.label : 'Unassigned'
})

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})
</script>
