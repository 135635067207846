<template>
    <Stack ref="stackRef">
        <Skeleton v-if="loading" :width="60" :height="38" color="secondary" />
        <Tooltip v-else :content="`${currentGroupName}`" :delay="[800, 0]">
            <AvatarSelect
                type="accountGroup"
                :modelValue="selectedGroup"
                :buttonSize="28"
                :items="accountGroups"
                nested-button="Plus"
                :popoutWidth="220"
                :appendTo="stackEl"
            />
        </Tooltip>
    </Stack>
</template>

<script setup lang="ts">
// Imports
import { PropType, ref, computed } from 'vue'

// Components
import { Stack, AvatarSelect, Skeleton, Tooltip } from '@opteo/components-next'

// Props
const props = defineProps({
    accountGroups: {
        type: Object as PropType<any[]>,
        required: true,
    },
    selectedGroup: {
        type: String as PropType<string>,
        required: true,
    },
    loading: {
        type: Boolean as PropType<boolean>,
        required: true,
    },
})

const currentGroupName = computed(() => {
    const group = props.accountGroups.find(g => g.value === props.selectedGroup)
    return group ? group.label : 'Ungrouped'
})

// appendTo
const stackRef = ref<InstanceType<typeof Stack> | null>(null)
const stackEl = computed(() => {
    return stackRef.value?.$el ?? document.body
})
</script>
